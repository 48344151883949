* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}
html {
  scroll-behavior: smooth;
}
a {
  text-decoration: none !important;
  color: #fff;
}
a:hover {
  color: #ddd;
  text-decoration: none !important;
}
/* Header or Navbar */
header {
  width: 100%;
  background-color: #131921 !important;
}
header a {
  color: white;
}
.amazon_navbar {
  height: 60px;
  background-color: #131921;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: #fff;
  max-width: 1280px;
  margin: 0 auto;
}
.nav-logo img {
  margin-top: 10px;
  width: 128px;
}
.address .deliver {
  /* margin-left: 20px; */
  font-size: 0.75rem;
  color: #ccc;
}
.returns span {
  color: #febd68;
  font-weight: 900;
  /* font-family: "Cormorant"; */
  font-size: 20px;
}
.cart {
  background: #febd68;
  padding: 10px;
  border-radius: 100px;
}
.address .map-icon {
  display: flex;
  align-items: center;
}
.nav-search {
  display: flex;
  justify-content: space-evenly;
  max-width: 620px;
  width: 100%;
  height: 40px;
  border-radius: 4px;
}
.select-search {
  background: #f3f3f3;
  width: 50px;
  text-align: center;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border: none;
}
.search-input {
  max-width: 600px;
  width: 100%;
  font-size: 1rem;
  border: none;
  outline: none;
  padding-left: 10px;
}
.search-icon {
  max-width: 45px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  background: #febd68;
  color: #000;
  cursor: pointer;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.sign-in p,
.returns p {
  font-size: 0.75rem;
}
.sign-in,
.returns span {
  font-size: 0.875rem;
  font-weight: 600;
}
.cart {
  display: flex;
}
.cart .cart-icon {
  font-size: 2.5rem;
}
.cart p {
  margin-top: 20px;
  font-weight: 500;
}
.banner {
  padding: 10px 20px;
  background: #222f3d;
  color: #fff;
  font-size: 0.875rem;
}
.banner-content {
  margin: 0 auto;
  max-width: 1280px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.panel {
  max-width: 1280px;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}
.panel span {
  margin-right: 7px;
}
.links {
  display: flex;
  align-items: center;
  list-style: none;
  gap: 15px;
  flex-grow: 1;
  margin-left: 15px;
}
.links a {
  padding: 10px 0;
}
.deals a {
  font-size: 0.9rem;
  font-weight: 500;
  white-space: nowrap;
}
/* Footer */
.footer-title {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #37475a;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 600;
  height: 60px;
}
.footer-items {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin: 0 auto;
  background: #232f3e;
}
.footer-items h3 {
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
  margin: 20px 0 10px 0;
}
.footer-items ul {
  list-style: none;
  margin-bottom: 20px;
}
.footer-items li a {
  color: #ddd;
  font-size: 0.875rem;
}
.footer-items li a:hover {
  text-decoration: underline;
}
.a-spacing-medium,
.a-ws .a-ws-spacing-medium {
  margin-bottom: 16px !important;
}
._octopus-search-result-card_style_apbBrowseSearchResultsRib__1v6y1 {
  background-color: #fff;
  border: 1px solid #d5d9d9;
  border-radius: 8px;
  padding: 14px 18px !important;
}
.a-cardui {
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #fff;
  overflow: auto;
  padding: 20px 0 15px 0;
}
.a-cardui .a-cardui-body {
  padding: 0 20px;
  position: relative;
}
.a-color-base {
  color: #0f1111 !important;
}
.a-size-base {
  font-size: 14px !important;
  line-height: 20px !important;
}
.a-text-normal {
  font-weight: 400 !important;
  font-style: normal !important;
  text-transform: none !important;
  text-decoration: none !important;
}
.a-color-state {
  color: #c45500 !important;
}
