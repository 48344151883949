@font-face {
  font-family: cityFont;
  src: url("../assets/fonts/FlamaBook.otf");
}
.cityRow {
  display: flex;
  justify-content: space-between;
}

ul.pull-right {
  display: flex;
  gap: 20px;
}
.citynav ul li {
  display: inline-block;
  margin: 0px;
  padding: 0px 10px 0px 0px;
  list-style: none;
  font-size: 15px;
}
.linkWrap a {
  font-size: 15px;
  padding: 5px 10px 0px 10px;
  color: #42575e;
  font-family: cityFont;
  color: rgba(0, 28, 37, 0.7);
  display: block;
  text-decoration: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  -moz-border-bottom-left-radius: 5px;
  -moz-border-bottom-right-radius: 5px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
}
div#mobileNumber {
  height: 33px;
  background: none;
  font-size: 15px;
  line-height: 18px;
  color: #42575e;
  color: rgba(0, 28, 37, 0.7);
  display: block;
  text-decoration: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  -moz-border-bottom-left-radius: 5px;
  -moz-border-bottom-right-radius: 5px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  padding: 8px 10px 0px 10px;
  font-family: "cityFont";
}
.citycontainer {
  width: 970px;
  margin: 0 auto;
}
img.citylogoImg {
  width: 226px;
  height: 40px;
  margin: 16px 0px 0px 0px;
  float: left;
  display: inline-block;
}

h1,
h1.a,
.header1 {
  padding: 0px 0px 10px 0px;
  font-family: "cityFont";
  font-size: 55px;
  color: #009de0;
  text-transform: lowercase;
}
.info-block {
  border-radius: 4px 4px 16px 4px;
  background: #ffffff;
  color: #526065;
  border: 1px solid #e3e3e3;
  padding: 20px;
}

.info-block h2 {
  margin: 0px;
  padding: 10px 0px;
  font-family: "cityFont";
  font-size: 25px;
  font-weight: 400;
  color: #526065;
}
.info-block ol li {
  font-size: 15px;
  font-family: "cityFont";
  margin-left: 15px;
}
.cityCol {
  flex: 1;
}
.aib {
  align-items: baseline !important;
}
.cityform {
  font-size: 14px;
  color: #526065;
}

.cityform input[type="text"] {
  width: 170px;
  margin-left: 11px;
}
button.step1 {
  border: 1px solid #526065 !important;
  color: #f8f8ff !important;
  background: #526065 !important;
  background-color: #526065 !important;
  outline: none !important;
  padding: 7px 15px !important;
  font-family: Verdana, Arial, sans-serif;
  font-size: 1.1em;
  position: relative;
  padding: 0;
  margin-right: 0.1em;
  text-decoration: none !important;
  cursor: pointer;
  text-align: center;
  zoom: 1;
  overflow: visible;
}

.loader {
  text-align: center;
}
.loader img {
  width: 50px;
  height: 50px;
}

.modalx {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-contentx {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.closex {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.closex:hover,
.closex:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.qrc {
  padding-left: 48px;
}
.qrc img {
  width: 200px;
  margin: 10px;
  padding: 10px;
}

@media (max-width: 760px) {
  .citycontainer {
    width: 100%;
  }
  .cityRow {
    display: block;
  }
  .cityNav {
    display: none;
  }
  h1,
  h1.a,
  .header1 {
    font-size: 22px;
  }
}
