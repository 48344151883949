@media only screen and (min-width: 1800px) {
  html {
    font-size: 75%;
  }
}

@media only screen and (max-width: 1799px) {
  html {
    font-size: 62.5%;
  }
}

@media only screen and (max-width: 1199px) {
  html {
    font-size: 50%;
  }
}

@media only screen and (max-width: 1199px) {
  html {
    font-size: 50%;
  }
}

@media only screen and (max-width: 899px) {
  html {
    font-size: 50%;
  }
}

@media only screen and (max-width: 599px) {
  html {
    font-size: 50%;
  }
}

html,
body {
  height: 100%;
}

@media only screen and (max-width: 599px) {
  body {
    overflow: auto;
    overflow-x: hidden;
    height: 100vh;
  }
}

.atom #logo {
  width: 100%;
  max-height: 95%;
}

.atom .menu--btn {
  position: relative;
  top: 2.5rem;
  cursor: pointer;
}
.atom .menu--btn img {
  width: 1.8rem;
  max-height: 95%;
  transition: all 0.3s;
}
.atom .menu--btn span {
  position: relative;
  left: 1rem;
  transition: all 0.3s;
}
.atom .menu--btn:hover span {
  left: 0.5rem;
  transition: all 0.3s;
}
.atom .menu--btn.menu--state-open img {
  transition: all 0.3s;
  transform: rotate(90deg);
}

/* Main navigation */
nav#nav--main.atom {
  position: fixed;
  z-index: 15;
  height: 75rem;
  background-color: #ffffff;
  padding-left: 18rem;
  width: 51.5rem;
  left: -51.5rem;
  transition: all 0.3s;
  /* Sub navigation */
}
nav#nav--main.atom.menu--state-open {
  left: 0;
  -webkit-box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.3);
  transition: all 0.3s;
}
nav#nav--main.atom #nav--main-links {
  height: 75rem;
  padding-top: 12.5rem;
  background: #ffffff;
  position: relative;
  z-index: 2;
}
nav#nav--main.atom #nav--main-links * {
  box-shadow: none;
}
nav#nav--main.atom #nav--main-links #nav--main-items li {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0.6756756757rem;
}
nav#nav--main.atom #nav--main-links #nav--main-items li a {
  position: relative;
  display: block;
  color: #000;
  transition: color 0.1s;
}
nav#nav--main.atom #nav--main-links #nav--main-items li a span {
  display: inline-block;
  position: relative;
  text-decoration: none;
}
nav#nav--main.atom #nav--main-links #nav--main-items li:hover a {
  padding-left: 3rem;
  transition: all 0.3s;
}
nav#nav--main.atom #nav--main-links #nav--main-items li.subMenu--sub-open a {
  color: #01aaad;
  transition: all 0.1s;
}
nav#nav--main.atom
  #nav--main-links
  #nav--main-items
  li.subMenu--sub-open
  a:before {
  content: "";
  border-left: 0.25rem solid #01aaad;
  position: absolute;
  left: 0;
  height: 3.5rem;
  top: -0.625rem;
}
nav#nav--main.atom #nav--main-close {
  position: absolute;
  z-index: 3;
  top: 2.5rem;
  right: 2.5rem;
  transition: all 0.3s;
  cursor: pointer;
}
nav#nav--main.atom #nav--main-close:hover {
  transform: rotate(90deg);
  transition: all 0.3s;
}
nav#nav--main.atom #nav--sub {
  height: 75rem;
  width: 27rem;
  background-color: #e2efef;
  color: #ffffff;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  transition: all 0.3s;
  padding-top: 12.5rem;
  padding-right: 2.5rem;
}
nav#nav--main.atom #nav--sub .nav--sub-item {
  height: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
nav#nav--main.atom #nav--sub .nav--sub-item .heading-4.text--heading {
  margin: 0;
}
nav#nav--main.atom #nav--sub.subMenu--open {
  -webkit-box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.3);
  transition: all 0.3s;
  right: -27rem;
}
nav#nav--main.atom #nav--sub.subMenu--open .nav--sub-item.subMenu--sub-open {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s;
}

/* PC small */
@media (min-width: 350px) and (max-width: 1464px) {
  nav#nav--main.atom {
    -webkit-box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.3);
    padding-left: 0;
    width: 30.6075vw;
  }
  nav#nav--main.atom #nav--main-close {
    right: 2.0405vw;
  }
  .atom .menu--btn img {
    width: 1.46916vw;
  }
  .atom .menu--btn span {
    left: 0.8162vw;
  }
  .atom .menu--btn:hover span {
    left: 0.4081vw;
  }
}

/* PC Normal */
/* PC Large */
/* PC Extra Large */
/* Current header */
.atom.header.section {
  height: 3.9rem !important;
  display: block;
  position: fixed;
  width: 100%;
  clear: both;
  border-bottom: 1px solid #dadada;
  background: white;
  z-index: 103;
  top: 0;
}
.atom.header.section .innerSection2 {
  width: 80.01vw;
  margin: 0 10vw;
}
.atom.header.section .menuButtonWrapper {
  display: none;
}
.atom.header.section .homepageLink {
  width: calc((80.01vw - (9 * 2.5rem)) / 10);
  height: 100%;
  display: block;
}
.atom.header.section .homepageLink img {
  height: 70%;
}
.atom.header.section .homepageLinkSml {
  display: none;
  text-decoration: none;
  border: none;
}
.atom.header.section .searchButton {
  width: 20px;
  height: 20px;
  margin-left: 0.5rem;
  background-image: url(https://www.fnb.co.za/_assets/images/generic/skins/00/navigation/search-icon/ink.svg);
  background-size: contain !important;
  background-size: 2rem;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 0;
  display: inline-block;
  border-bottom: none !important;
}
.atom.header.section .menuButtonWrapper .menuButton span {
  display: block;
  width: 100%;
  height: 0.25rem;
  margin-bottom: 4px;
  background: #fff;
}
.atom.header.section .loginFields {
  width: auto;
}
.atom.header.section .loginFields .inputButtonHolder {
  float: right;
  padding: 0;
  width: calc(((80vw - (9 * 2.5rem)) / 10) - 2.5rem);
  position: relative;
  top: 0;
  height: 25px;
  margin: 0 0 0 0.5rem;
  overflow: hidden;
}
.atom.header.section .loginFields .textinput {
  margin: 0;
  padding: 0;
  width: calc(
    (((80.01vw - (9 * 2.5rem)) / 10) + (((80.01vw - (9 * 2.5rem)) / 10) / 2)) -
      1rem
  );
  float: left;
  height: 25px;
}
.tyme-banner img {
  width: 100%;
  margin-top: 98px;
}
.atom.header.section .loginFields .validationWrapper.noLabel {
  float: left;
  width: auto;
}
.atom.header.section .loginFields .validationWrapper.noLabel:nth-child(2) {
  margin: 0 0.5rem 0 0;
}
.atom.header.section input[type="submit"],
.atom.header.section input[type="button"] {
  outline: 0;
  outline-style: none;
  outline-width: 0;
  border-radius: 0;
  box-shadow: none !important;
  background: none;
  border: 1px solid #009999;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  height: 100%;
  line-height: 13px;
  padding: 0px;
  margin: 0;
  background-color: #009999 !important;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-appearance: inherit;
  width: 100%;
  font-family: "Roboto";
  font-size: 13px;
  line-height: 13px;
  letter-spacing: -0.5px;
}

.atom.header.section .usernametext,
.atom.header.section .passwordtext {
  position: absolute;
  width: 80px;
  background: #ffffff;
  color: crimson;
  padding: 0;
  height: 16px;
  padding-left: 5px;
  margin-top: 2px;
  margin-left: 4px;
  display: none;
  font-size: 13px;
}
.atom.header.section input[type="text"],
.atom.header.section input[type="password"] {
  width: 100%;
  height: 100%;
  padding: 0 0 0 10px;
  margin: 0;
  border: none;
  background: #efefef;
  color: #767676;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  outline: none;
  box-shadow: none !important;
  font-family: "FNBSansRegular";
  font-size: 13px;
  line-height: 13px;
  letter-spacing: -0.5px;
}
.atom.header.section a.fontWhite,
.atom.header.section p.fontWhite a {
  border-bottom: 0;
}
.atom.header.section .fontWhite {
  color: black;
}
.atom.header.section .atom.headerLinks {
  margin-right: 1rem;
}
.atom.header.section .atom.headerLinks a {
  padding: 0;
  font-family: "Roboto";
  font-size: 13px;
  line-height: 13px;
  letter-spacing: -0.5px;
  text-decoration: none;
}
.atom.header.section .cookieTrail {
  margin-left: 1rem;
  height: 30px;
  width: auto;
}
.atom.header.section .cookieTrail .fontWhite.arrowButtonBack {
  background: url(https://www.fnb.co.za/_assets/images/generic/skins/00/navigation/icon-back/ink.svg)
    0 center no-repeat;
  background-size: 6px;
  padding: 0 0 0 1rem;
  font-family: "Roboto";
  font-size: 13px;
  line-height: 13px;
  letter-spacing: -0.5px;
}

.atom .innerSection {
  height: 100%;
  margin: 0 auto;
  position: relative;
}

.atom .innerSection2 {
  height: 100%;
}

.atom .hAlignCenter {
  text-align: center;
}

.atom .sectionColFull {
  width: 100%;
}

.atom .sectionColInner {
  height: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}

.atom .sectionColFull .sectionColInner {
  margin: 0;
  height: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}

.atom .column {
  float: left;
  display: block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 100%;
}

.atom .wrapperHeight100 {
  height: 100%;
}

.atom .wrapperFloatLeft {
  float: left;
}

.atom .wrapperFloatRight {
  float: right;
}

.atom .vAlignMiddle {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.atom.headerTabs.section.height2 {
  height: 3.5rem !important;
  top: 3.9rem;
  position: fixed;
}

.atom.headerTabs {
  background: white;
  border-bottom: 0;
  box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.1);
}
.atom.headerTabs a {
  text-decoration: none;
  font-family: f_regular;
  font-size: 13px;
  line-height: 16px;
}
.atom.headerTabs .innerSection2 {
  width: 80.01vw;
  margin: 0 10vw;
}
.atom.headerTabs .CHAM_tabButton {
  border: none;
  width: 100%;
  display: block;
  height: 37px;
  padding-right: 4px;
  padding: 0;
  line-height: 14px;
  top: 0%;
  -webkit-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%);
}
.atom.headerTabs .CHAM_tabButton span {
  position: relative;
  top: 50%;
  color: black;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  display: block;
  font-family: "Roboto";
  font-size: 13px;
  line-height: 13px;
  letter-spacing: -0.5px;
}
.atom.headerTabs .sectionColTenths {
  width: calc((((100% - (9 * 2.5rem)) / 10)));
  margin-right: 2.5rem;
  position: relative;
}
.atom.headerTabs .sectionColTenths:last-child {
  margin-right: 0;
}
.atom.headerTabs .sectionColTenths.borderRightWhite {
  border: none;
}
.atom.headerTabs .sectionColTenths .sectionColInner {
  margin: 0 !important;
}
.atom.headerTabs .sectionColTenths:not(:last-of-type)::after {
  content: "";
  width: 1px;
  height: 2rem;
  position: absolute;
  display: block;
  top: 0.9rem;
  right: -1.15rem;
  border-right: 1px solid #9b9b9b;
}

/* sections */
#zaSkin .atom.section .section-content {
  width: 80.01vw;
  margin: 0 10vw;
}

.smartPhone .atom.header .homepageLinkSml {
  display: block;
  height: 100%;
  margin-right: 1.25rem;
}

.smartPhone .atom.header .menuButtonWrapper {
  display: block;
  height: 100%;
}

.smartPhone .atom.header .menuButtonWrapper .menuButton {
  height: auto;
  width: 2.25rem;
  padding: 0;
  background: none;
}

.smartPhone .atom.header .searchButton {
  width: 2rem;
  height: 2rem;
  background-size: 2rem;
  margin-left: 0.5rem;
}

@media only screen and (max-width: 1199px) {
  /* sections */
  #zaSkin .atom.section .section-content {
    width: 90.01vw;
    margin: 0 5vw;
  }
  .atom.header.section {
    height: 3.625rem !important;
  }
  .atom.header.section .menuButtonWrapper {
    display: none;
  }
  .atom.header.section .innerSection2 {
    width: 90.01vw;
    margin: 0 5vw;
  }
  .atom.header.section .homepageLink {
    width: calc((90.01vw - (9 * 2.5rem)) / 10);
    height: 100%;
    display: block;
  }
  .atom.header.section .homepageLink img {
    height: 70%;
  }
  .atom.header.section .homepageLinkSml {
    display: none;
    text-decoration: none;
    border: none;
  }
  .atom.header.section .searchButton {
    width: 20px;
    height: 20px;
    margin-left: 0.625rem;
    background-size: 1.875rem;
    background-image: url(https://www.fnb.co.za/_assets/images/generic/skins/00/navigation/search-icon/ink.svg);
    padding: 0;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .atom.header.section .loginFields .inputButtonHolder {
    float: right;
    padding: 0;
    width: calc(((90.01vw - (9 * 2.5rem)) / 10) - 2.5rem);
    position: relative;
    top: 0;
    height: 25px;
    margin: 0 0 0 0.625rem;
    min-width: 45px;
  }
  .atom.header.section .loginFields .textinput {
    margin: 0;
    padding: 0;
    width: calc(
      (((90.01vw - (9 * 2.5rem)) / 10) + (((90.01vw - (9 * 2.5rem)) / 10) / 2)) -
        1rem
    );
    float: left;
    height: 25px;
  }
  .atom.header.section .loginFields .validationWrapper.noLabel:nth-child(2) {
    margin: 0 0.625rem 0 0;
  }
  .atom.header.section .atom.headerLinks {
    margin-right: 1.25rem;
  }
  .atom.header.section .cookieTrail {
    margin-left: 1.25rem;
  }
  .atom.header.section .cookieTrail .fontWhite.arrowButtonBack {
    padding: 0 0 0 1.875rem;
  }
  .atom.headerTabs.section.height2 {
    height: 3.75rem !important;
    top: 3.625rem;
    position: fixed;
  }
  .atom.headerTabs .innerSection2 {
    width: 90.01vw;
    margin: 0 5vw;
  }
  .atom.headerTabs .sectionColTenths {
    width: calc((((90.01vw - (9 * 2.5rem)) / 10)));
    margin-right: 2.5rem;
    position: relative;
  }
  .atom.headerTabs .sectionColTenths:last-child {
    margin-right: 0;
  }
  .atom.headerTabs .sectionColTenths .sectionColInner {
    margin: 0 !important;
  }
  .atom.headerTabs .sectionColTenths:not(:last-of-type)::after {
    content: "";
    width: 1px;
    height: 2.25rem;
    position: absolute;
    display: block;
    top: 1.1rem;
    right: -1.1875rem;
    border-right: 1px solid #9b9b9b;
  }
}

@media only screen and (max-width: 1199px) and (orientation: landscape) {
  /* sections */
  #zaSkin .atom.section .section-content {
    width: 90.01vw;
    margin: 0 5vw;
  }
  .atom.header.section {
    height: 3.625rem !important;
  }
  .atom.header.section .menuButtonWrapper {
    display: none;
  }
  .atom.header.section .innerSection2 {
    width: 90.01vw;
    margin: 0 5vw;
  }
  .atom.header.section .searchButton {
    width: 20px;
    height: 20px;
    margin-left: 0.625rem;
  }
  .atom.header.section .loginFields .inputButtonHolder {
    height: 2.375rem;
  }
  .atom.header.section .atom.headerLinks {
    margin-right: 0.625rem;
  }
}

@media only screen and (max-width: 899px) and (orientation: portrait) {
  /* sections */
  #zaSkin .atom.section .section-content {
    width: 90.01vw;
    margin: 0 5vw;
  }
  .atom.header.section {
    height: 7.5rem !important;
  }
  .atom.header.section .menuButtonWrapper {
    display: block;
    height: 100%;
  }
  .atom.header.section .menuButtonWrapper .menuButton {
    display: block;
    height: auto;
    width: 2.25rem;
    padding: 0;
    background: none;
  }
  .atom.header.section .menuButtonWrapper .menuButton span {
    display: block;
    width: 100%;
    height: 0.25rem;
    margin-bottom: 4px;
    background: #000;
  }
  .atom.header.section .menuButtonWrapper .menuButton .menuButton:hover {
    background: none;
  }
  .atom.header.section .innerSection2 {
    width: 90.01vw;
    margin: 0 5vw;
  }
  .atom.header.section .homepageLink {
    display: none;
  }
  .atom.header.section .homepageLinkSml {
    width: 5rem;
    height: 100%;
    display: block;
    margin-right: 1.25rem;
  }
  .atom.header.section .searchButton {
    width: 20px;
    height: 20px;
    margin-left: 0.625rem;
  }
  .atom.header.section input[type="text"],
  .atom.header.section input[type="password"] {
    padding: 0 0 0 3px;
  }
  .atom.header.section .loginFields .textinput {
    width: calc(
      (((90.01vw - (9 * 2.5rem)) / 10) + (((90.01vw - (9 * 2.5rem)) / 10) / 2)) -
        1rem
    );
    min-width: 60px;
  }
  .atom.header.section .loginFields .validationWrapper.noLabel:nth-child(2) {
    margin: 0 0.625rem 0 0;
  }
  .atom.header.section .cookieTrail {
    margin-left: 1.25rem;
  }
  .atom.header.section .cookieTrail .fontWhite.arrowButtonBack {
    padding: 0 0 0 1.875rem;
  }
  .atom.headerTabs.section.height2 {
    display: none;
  }
}

@media only screen and (max-width: 899px) and (max-height: 898px) {
  /* sections */
  #zaSkin .atom.section .section-content {
    width: 90.01vw;
    margin: 0 5vw;
  }
  .atom.header.section {
    height: 7.5rem !important;
    border-bottom: 0;
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.1);
  }
  .atom.header.section .menuButtonWrapper {
    display: block;
    height: 100%;
  }
  .atom.header.section .menuButtonWrapper .menuButton {
    display: block;
    height: auto;
    width: 2.25rem;
    padding: 0;
    background: none;
  }
  .atom.header.section .menuButtonWrapper .menuButton span {
    display: block;
    width: 100%;
    height: 0.25rem;
    margin-bottom: 4px;
    background: #000;
  }
  .atom.header.section .menuButtonWrapper .menuButton .menuButton:hover {
    background: none;
  }
  .atom.header.section .innerSection2 {
    width: 90.01vw;
    margin: 0 5vw;
  }
  .atom.header.section .homepageLink {
    display: none;
  }
  .atom.header.section .homepageLinkSml {
    width: 5rem;
    height: 100%;
    display: block;
    margin-right: 1.25rem;
  }
  .atom.header.section .homepageLinkSml img {
    left: 0px !important;
    width: 40px;
  }
  .atom.header.section .searchButton {
    width: 20px;
    height: 20px;
    margin-left: 0.625rem;
  }
  .atom.header.section input[type="text"],
  .atom.header.section input[type="password"] {
    padding: 0 0 0 3px;
  }
  .atom.header.section .loginFields .textinput {
    width: calc(
      (((90.01vw - (9 * 2.5rem)) / 10) + (((90.01vw - (9 * 2.5rem)) / 10) / 2)) -
        1rem
    );
    min-width: 60px;
  }
  .atom.header.section .loginFields .validationWrapper.noLabel:nth-child(2) {
    margin: 0 0.625rem 0 0;
  }
  .atom.header.section .cookieTrail {
    margin-left: 1.25rem;
  }
  .atom.header.section .cookieTrail .fontWhite.arrowButtonBack {
    padding: 0 0 0 1.875rem;
  }
  .atom.headerTabs.section.height2 {
    display: none;
  }
}

@media only screen and (max-width: 599px) {
  /* sections */
  #zaSkin .atom.section .section-content {
    width: calc(100vw - 6.25rem);
    margin: 0 3.125rem;
  }
  .atom.header.section {
    height: 7.5rem !important;
    position: sticky;
    border-bottom: 0;
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.1);
  }
  .atom.header.section .menuButtonWrapper {
    display: block;
    padding: 0;
  }
  .atom.header.section .innerSection2 {
    width: calc(100vw - 6.25rem);
    margin: 0 3.125rem;
  }
  .atom.header.section .homepageLink {
    display: none;
  }
  .atom.header.section .homepageLinkSml {
    width: 5rem;
    height: 100%;
    display: block;
    margin-right: 1.25rem;
  }
  .atom.header.section .homepageLinkSml img {
    left: 0px !important;
    width: 40px;
  }
  .atom.header.section .searchButton {
    width: 2.5rem;
    height: 2.5rem;
    margin-left: 0;
    background-size: 2.5rem;
  }
  .atom.header.section .atom.headerLinks {
    display: none;
  }
  .atom.header.section .loginFields .textinput {
    width: calc(
      (((90.01vw - (9 * 2.5rem)) / 10) + (((90.01vw - (9 * 2.5rem)) / 10) / 2)) -
        1rem
    );
    min-width: 60px;
  }
  .atom.header.section .loginFields .validationWrapper.noLabel {
    display: none;
  }
  .atom.header.section .loginFields .validationWrapper.noLabel:nth-child(2) {
    margin: 0 0.625rem 0 0;
  }
  .atom.header.section .loginFields .inputButtonHolder {
    display: none;
  }
  .atom.header.section .sectionColInner > .cookieTrail {
    display: block;
  }
  .atom.header.section .sectionColInner > .cookieTrail ~ .cookieTrail {
    display: none;
  }
}

/* Old framework adjustment */
.noSizerSpacer {
  height: 7.5rem;
}

/*# sourceMappingURL=menu.css.map */

.tyme-submit {
  outline: 0;
  outline-style: none;
  outline-width: 0;
  border-radius: 0;
  box-shadow: none !important;
  background: none;
  border: 1px solid #009999;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  height: 100%;
  line-height: 13px;
  padding: 0px;
  margin: 0;
  background: #009999;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-appearance: inherit;
  width: 100%;
  font-family: "Roboto";
  font-size: 13px;
  line-height: 13px;
  letter-spacing: -0.5px;
}

@media (min-width: 760px) {
  .hide-mobile {
    display: none;
  }
}
@media (max-width: 760px) {
  .textinput p {
    padding: 0;
    margin: 0;
  }
  .cookieTrail {
    display: none !important;
  }
  div#divToAfrikaans {
    display: none;
  }
  #SSR-tab-20 {
    display: none;
  }
  .tyme-banner {
    display: none;
  }
  button.tyme-submit {
    width: 90px;
  }
}
.hide-mobile form#LOGIN_FORM {
  text-align: center;
  max-width: 300px;
  margin: 30px auto;
}
.hide-mobile {
  margin: 90px auto;
  text-align: center;
}

header#masthead {
  position: initial;
  height: auto;
  padding-top: 10px;
  background: #2d2d3a;
}
header#masthead {
  padding-top: 20px;
  position: fixed;
  width: 100%;
  z-index: 999;

  background: linear-gradient(
    180deg,
    #191925 0.9%,
    rgba(25, 25, 37, 0.682496) 35%,
    rgba(25, 25, 37, 0.174749) 80.55%,
    rgba(25, 25, 37, 0) 90.84%,
    rgba(25, 25, 37, 0) 99.37%
  );
}

#banking-login-menu {
  text-align: right;
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}

#banking-login-menu li {
  display: inline-block;
}

#banking-login-menu li a {
  text-decoration: none;
  line-height: 15px;
  margin-right: 5px;
}

#banking-login-menu li.login-button a {
  padding: 10px 20px;
  border: 1px solid #fff;
  color: #fff;
  display: block;
  border-radius: 100px;
  transition: 0.5s ease;
}

#banking-login-menu li.open-account a {
  margin-right: 0;
}

#banking-login-menu li.open-account a {
  padding: 10px 20px;
  border: 1px solid #ffdd00;
  color: #000;
  background-color: #ffdd00;
  display: block;
  border-radius: 100px;
  transition: 0.5s ease;
}

#banking-login-menu li.login-button a:hover {
  background-color: #2d2d3a;
}

#banking-login-menu li.open-account a:hover {
  background-color: #2d2d3a;
  border-color: #2d2d3a;
  color: #ffdd00;
}

#site-navigation {
  text-align: center;
}

#site-navigation li {
  display: inline-block;
  margin-right: 20px;
}

ul#primary-menu {
  width: 100%;
  display: block;
}

#primary-menu li a {
  color: #fff;
  padding: 10px 10px;
}

ul#primary-menu .current-menu-item a,
#primary-menu li a:hover {
  color: #ffdd00;
}

.standard-banner {
  position: relative;
}

.home .site-branding img.custom-logo {
  opacity: 0;
  display: none;
  transition: opacity 1.6s ease;
}

.home-banner img {
  width: 100%;
}

.banner-content {
  position: relative;
  overflow: visible;
  /* padding-top: 20%;
  padding-bottom: 15%; */
  width: 100%;
  z-index: 9;
}

.home .banner-content {
  top: 20%;
}

.home-banner {
  position: relative;
}

.slide {
  background-size: cover !important;
}

.standard-banner:after,
.home-banner:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 184px;
  /* background: url(imgs/banner-gradient.png); */
}

.banner-background {
  width: 100%;
}

.banner-content h1 {
  width: 80%;
  color: #fff;
  font-size: 4em;
  text-align: center;
  margin: 0 auto;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
}

.home .banner-content h1 {
  width: 50%;
  color: #ffffff;
  font-size: 4em;
  text-align: left;
  margin: 0;
  margin-bottom: 40px;
  text-shadow: none;
}

.home .banner-logo.sticky .custom-logo {
  width: 180px;
  margin-top: 20px;
  z-index: 9999;
}

.home .banner-logo .custom-logo {
  transition: all 1s ease;
  width: 290px;
}

.home .banner-logo.sticky {
  top: 0;
  position: fixed;
  z-index: 9999;
}

.admin-bar .banner-logo.sticky {
  top: 32px;
}

.home .banner-logo {
  margin-bottom: 30px;
}

.site-branding img.custom-logo {
  max-width: 180px;
}

.banner-content h1 {
  margin-bottom: 40px;
}

ul.app-store-links {
  display: inline-block;
}

.action-buttons {
  text-align: center;
}

.action-button {
  background: #ffdd00;
  color: #191925;
  padding: 15px 25px;
  display: inline-block;
  border-radius: 100px;
  text-decoration: none;
  transition: 0.5s ease;
  vertical-align: middle;
  margin-right: 20px;
  transition: 0.5s ease;
  margin-bottom: 10px;
}

.action-button:hover {
  background-color: #2d2d3a;
  border-color: #2d2d3a;
  color: #ffdd00;
}
.main-navigationx {
  background-color: #171923 !important;
}

.tyme-container {
  height: 100vh;
  background-image: url(https://bank.tymedigital.co.za/static/media/login-bg.73bbe666.jpg);
  width: 100%;
  position: absolute;
  background-size: cover;
}
.tymelogo {
  text-align: center;
  margin-top: 100px;
}
.tymeform {
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 50px;
  display: flex;
  gap: 20px;
}
.tymeformwrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.tymeformwrapper input {
  border-radius: 0;
  color: #231f20;
  height: 48px;
  font-size: 18px;
  box-shadow: none;
  border: 0;
  border-color: #7a7a7a;
  padding: 10px;
}
.tymeformwrapper label {
  color: white;
  font-weight: 100;
  font-size: 18px;
  margin-bottom: 10px;
}
button#tymeSubmit {
  max-width: 270px;
  box-shadow: none;
  outline: none;
  margin: 30px auto;
  transition: background-color 0.3s ease-in-out;
  border-color: rgba(0, 0, 0, 0);
  background-color: #fc0;
  line-height: 46px;
  border-radius: 100px;
  font-family: HelveticaNeue-Light, sans-serif;
  height: 48px;
  font-size: 16px;
  color: #231f20;
  border: 1px solid #7a7a7a;
  min-width: 178px;
}

.sc-AxjAm.kABWGS.sc-fzqNJr.hXQgjp {
  text-align: center;
  margin-bottom: 20px;
  font-size: 18px;
}
a.tymeLink {
  color: #ffcc00;
  text-align: right;
}

.sc-AxjAm.kABWGS.sc-fzqNJr.hXQgjp a {
  color: #ffcc00;
}

@media (max-width: 760px) {
  .tymeform {
    max-width: 100%;
    width: 100% !important;
    margin: 0 auto;
    margin-top: 50px;
    display: flex;
    padding: 10px;
    gap: 20px;
    flex-direction: column;
  }
  .tymeformwrapperBtn {
    text-align: center;
  }
}
