#navFooter.navLeftFooter {
  margin-bottom: 0;
  padding-bottom: 0;
  background-color: #232f3e;
  min-width: 1000px;
  position: relative;
}
#navFooter {
  margin: 30px 0 8px 0;
  padding: 0 0 7px 0;
}
#navFooter .navAccessibility.navFooterVerticalColumn {
  display: table;
  margin: 0 auto;
}
#navFooter.navLeftFooter .navFooterVerticalColumn {
  max-width: 1000px;
}
#navFooter .navAccessibility.navFooterColSpacerInner,
#navFooter .navAccessibility.navFooterLinkCol {
  display: table-cell;
  padding: 0 10px;
}
#navFooter .navAccessibility.navFooterLinkCol {
  line-height: 120%;
}
#navFooter .navAccessibility.navFooterColSpacerInner,
#navFooter .navAccessibility.navFooterLinkCol {
  display: table-cell;
  padding: 0 10px;
}
.navFooterVerticalColumn .navFooterColSpacerInner {
  width: 10%;
  padding: 0 15px;
}
#navFooter.navLeftFooter .navFooterColHead {
  font-weight: 700;
  color: #fff;
}
.navFooterColHead {
  font-family: inherit;
  color: #333;
  font-size: 16px;
  margin: 6px 0 14px 0;
  white-space: nowrap;
}
.navFooterLinkCol ul {
  padding: 0;
  margin: 0;
}
#navFooter.navLeftFooter .navFooterLinkCol ul li {
  margin: 0 0 10px;
}
.navFooterLinkCol ul li {
  list-style-type: none;
  white-space: nowrap;
  margin: 0 0 8px 0;
}
#navFooter.navLeftFooter .navFooterLinkCol ul li {
  margin: 0 0 10px;
}
#navFooter.navLeftFooter .navFooterDescText,
#navFooter.navLeftFooter .nav_last {
  color: #999;
}
.navFooterLinkCol ul li {
  list-style-type: none;
  white-space: nowrap;
  margin: 0 0 8px 0;
}
#navFooter.navLeftFooter a.nav_a:link,
#navFooter.navLeftFooter a.nav_a:visited,
#navFooter.navLeftFooter li.nav_a_carat span.nav_a_carat {
  color: #ddd;
  font-size: 14px;
}
#navFooter a:link,
#navFooter a:visited {
  font-family: inherit;
  color: #004b91;
  text-decoration: none;
}
#navFooter a,
#navFooter span {
  font-family: inherit;
  white-space: normal;
}

#navFooter.navLeftFooter .nav-footer-line {
  border-top: 1px solid #3a4553;
  margin-top: 40px;
}
#navFooter.navLeftFooter div.navFooterLine {
  font-size: 12px;
}
#navFooter.navLeftFooter .navFooterLinkLine {
  margin: 10px auto;
}
#navFooter.navLeftFooter .navFooterLogoLine,
#navFooter.navLeftFooter .navFooterPadItemLine {
  text-align: center;
  max-width: 1000px;
  margin: 30px auto 15px;
}
#navFooter a,
#navFooter span {
  font-family: inherit;
  white-space: normal;
}
.navFooterPadItemLine a,
.navFooterPadItemLine span {
  padding: 0 0.6em;
}
#navFooter.navLeftFooter div.navFooterLine {
  font-size: 12px;
}
#navFooter a,
#navFooter span {
  font-family: inherit;
  white-space: normal;
}
span.icp-container-desktop {
  vertical-align: top;
  margin: 18px auto 0;
  margin-left: 70px;
}
#navFooter.navLeftFooter div.navFooterLine {
  font-size: 12px;
}
div.navFooterLine {
  font-family: inherit;
  color: #ddd;
  font-size: 11px;
  text-align: center;
  line-height: 18px;
  white-space: nowrap;
}
