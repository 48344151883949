/* This is a GULP generated file, DO NOT edit directly. */
@charset "UTF-8";
@import url(//fonts.googleapis.com/css?family=Montserrat:300,400,600,700|Titillium+Web:300,400,600,700&display=swap);
@-webkit-keyframes swiper-preloader-spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes swiper-preloader-spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes static {
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes static {
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@-webkit-keyframes rotate {
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes rotate {
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 204;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  50% {
    stroke-dashoffset: 52;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  to {
    stroke-dashoffset: 204;
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 204;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  50% {
    stroke-dashoffset: 52;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  to {
    stroke-dashoffset: 204;
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@-webkit-keyframes reverse-a0_o {
  0%,
  89.82% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes reverse-a0_o {
  0%,
  89.82% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes reverse-a1_o {
  0%,
  80.24%,
  to {
    opacity: 0;
  }

  89.82% {
    opacity: 1;
  }
}

@keyframes reverse-a1_o {
  0%,
  80.24%,
  to {
    opacity: 0;
  }

  89.82% {
    opacity: 1;
  }
}

@-webkit-keyframes reverse-a2_o {
  0%,
  70.06%,
  89.82%,
  to {
    opacity: 0;
  }

  80.24% {
    opacity: 1;
  }
}

@keyframes reverse-a2_o {
  0%,
  70.06%,
  89.82%,
  to {
    opacity: 0;
  }

  80.24% {
    opacity: 1;
  }
}

@-webkit-keyframes reverse-a3_o {
  0%,
  59.88%,
  80.24%,
  to {
    opacity: 0;
  }

  70.06% {
    opacity: 1;
  }
}

@keyframes reverse-a3_o {
  0%,
  59.88%,
  80.24%,
  to {
    opacity: 0;
  }

  70.06% {
    opacity: 1;
  }
}

@-webkit-keyframes reverse-a4_o {
  0%,
  50.299%,
  70.06%,
  to {
    opacity: 0;
  }

  59.88% {
    opacity: 1;
  }
}

@keyframes reverse-a4_o {
  0%,
  50.299%,
  70.06%,
  to {
    opacity: 0;
  }

  59.88% {
    opacity: 1;
  }
}

@-webkit-keyframes reverse-a5_o {
  0%,
  40.12%,
  59.88%,
  to {
    opacity: 0;
  }

  50.299% {
    opacity: 1;
  }
}

@keyframes reverse-a5_o {
  0%,
  40.12%,
  59.88%,
  to {
    opacity: 0;
  }

  50.299% {
    opacity: 1;
  }
}

@-webkit-keyframes reverse-a6_o {
  0%,
  29.94%,
  50.299%,
  to {
    opacity: 0;
  }

  40.12% {
    opacity: 1;
  }
}

@keyframes reverse-a6_o {
  0%,
  29.94%,
  50.299%,
  to {
    opacity: 0;
  }

  40.12% {
    opacity: 1;
  }
}

@-webkit-keyframes reverse-a7_o {
  0%,
  20.359%,
  40.12%,
  to {
    opacity: 0;
  }

  29.94% {
    opacity: 1;
  }
}

@keyframes reverse-a7_o {
  0%,
  20.359%,
  40.12%,
  to {
    opacity: 0;
  }

  29.94% {
    opacity: 1;
  }
}

@-webkit-keyframes reverse-a8_o {
  0%,
  10.18%,
  29.94%,
  to {
    opacity: 0;
  }

  20.359% {
    opacity: 1;
  }
}

@keyframes reverse-a8_o {
  0%,
  10.18%,
  29.94%,
  to {
    opacity: 0;
  }

  20.359% {
    opacity: 1;
  }
}

@-webkit-keyframes reverse-a9_o {
  0%,
  20.359%,
  to {
    opacity: 0;
  }

  10.18% {
    opacity: 1;
  }
}

@keyframes reverse-a9_o {
  0%,
  20.359%,
  to {
    opacity: 0;
  }

  10.18% {
    opacity: 1;
  }
}

@-webkit-keyframes reverse-a10_o {
  0% {
    opacity: 1;
  }

  10.18%,
  to {
    opacity: 0;
  }
}

@keyframes reverse-a10_o {
  0% {
    opacity: 1;
  }

  10.18%,
  to {
    opacity: 0;
  }
}

@-webkit-keyframes forward-a0_o {
  0%,
  9.29% {
    opacity: 1;
  }

  18.033%,
  to {
    opacity: 0;
  }
}

@keyframes forward-a0_o {
  0%,
  9.29% {
    opacity: 1;
  }

  18.033%,
  to {
    opacity: 0;
  }
}

@-webkit-keyframes forward-a1_o {
  0%,
  27.322%,
  9.29%,
  to {
    opacity: 0;
  }

  18.033% {
    opacity: 1;
  }
}

@keyframes forward-a1_o {
  0%,
  27.322%,
  9.29%,
  to {
    opacity: 0;
  }

  18.033% {
    opacity: 1;
  }
}

@-webkit-keyframes forward-a2_o {
  0%,
  18.033%,
  36.612%,
  to {
    opacity: 0;
  }

  27.322% {
    opacity: 1;
  }
}

@keyframes forward-a2_o {
  0%,
  18.033%,
  36.612%,
  to {
    opacity: 0;
  }

  27.322% {
    opacity: 1;
  }
}

@-webkit-keyframes forward-a3_o {
  0%,
  27.322%,
  45.355%,
  to {
    opacity: 0;
  }

  36.612% {
    opacity: 1;
  }
}

@keyframes forward-a3_o {
  0%,
  27.322%,
  45.355%,
  to {
    opacity: 0;
  }

  36.612% {
    opacity: 1;
  }
}

@-webkit-keyframes forward-a4_o {
  0%,
  36.612%,
  54.645%,
  to {
    opacity: 0;
  }

  45.355% {
    opacity: 1;
  }
}

@keyframes forward-a4_o {
  0%,
  36.612%,
  54.645%,
  to {
    opacity: 0;
  }

  45.355% {
    opacity: 1;
  }
}

@-webkit-keyframes forward-a5_o {
  0%,
  45.355%,
  63.934%,
  to {
    opacity: 0;
  }

  54.645% {
    opacity: 1;
  }
}

@keyframes forward-a5_o {
  0%,
  45.355%,
  63.934%,
  to {
    opacity: 0;
  }

  54.645% {
    opacity: 1;
  }
}

@-webkit-keyframes forward-a6_o {
  0%,
  54.645%,
  72.678%,
  to {
    opacity: 0;
  }

  63.934% {
    opacity: 1;
  }
}

@keyframes forward-a6_o {
  0%,
  54.645%,
  72.678%,
  to {
    opacity: 0;
  }

  63.934% {
    opacity: 1;
  }
}

@-webkit-keyframes forward-a7_o {
  0%,
  63.934%,
  81.967%,
  to {
    opacity: 0;
  }

  72.678% {
    opacity: 1;
  }
}

@keyframes forward-a7_o {
  0%,
  63.934%,
  81.967%,
  to {
    opacity: 0;
  }

  72.678% {
    opacity: 1;
  }
}

@-webkit-keyframes forward-a8_o {
  0%,
  72.678%,
  91.257%,
  to {
    opacity: 0;
  }

  81.967% {
    opacity: 1;
  }
}

@keyframes forward-a8_o {
  0%,
  72.678%,
  91.257%,
  to {
    opacity: 0;
  }

  81.967% {
    opacity: 1;
  }
}

@-webkit-keyframes forward-a9_o {
  0%,
  81.967%,
  to {
    opacity: 0;
  }

  91.257% {
    opacity: 1;
  }
}

@keyframes forward-a9_o {
  0%,
  81.967%,
  to {
    opacity: 0;
  }

  91.257% {
    opacity: 1;
  }
}

@-webkit-keyframes forward-a10_o {
  0%,
  91.257% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes forward-a10_o {
  0%,
  91.257% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: translateX(-20px) rotate(0deg);
    transform: translateX(-20px) rotate(0deg);
  }

  to {
    -webkit-transform: translateX(-20px) rotate(360deg);
    transform: translateX(-20px) rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: translateX(-20px) rotate(0deg);
    transform: translateX(-20px) rotate(0deg);
  }

  to {
    -webkit-transform: translateX(-20px) rotate(360deg);
    transform: translateX(-20px) rotate(360deg);
  }
}

@-webkit-keyframes cycle {
  0% {
    opacity: 0.2;
  }

  50% {
    opacity: 0.5;
  }
}

@keyframes cycle {
  0% {
    opacity: 0.2;
  }

  50% {
    opacity: 0.5;
  }
}

@-webkit-keyframes shakeDelay {
  0%,
  25%,
  to {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  5% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }

  12% {
    -webkit-transform: rotate(-1deg);
    transform: rotate(-1deg);
  }

  18% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }
}

@keyframes shakeDelay {
  0%,
  25%,
  to {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  5% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }

  12% {
    -webkit-transform: rotate(-1deg);
    transform: rotate(-1deg);
  }

  18% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }
}

@-webkit-keyframes example {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0.5;
  }
}

@keyframes example {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0.5;
  }
}

.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.swiper-container-no-flexbox .swiper-slide {
  float: left;
}

.swiper-container-vertical > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  flex-direction: column;
}

.swiper-slide,
.swiper-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.swiper-wrapper {
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.swiper-container-multirow > .swiper-wrapper {
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto;
}

.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex: 0 0 auto;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto;
}

.swiper-container-autoheight .swiper-wrapper {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-transition-property: height, -webkit-transform;
  transition-property: transform, height;
  transition-property: transform, height, -webkit-transform;
}

.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}

.swiper-wp8-horizontal {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.swiper-wp8-vertical {
  -ms-touch-action: pan-x;
  touch-action: pan-x;
}

.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  left: 10px;
  right: auto;
}

.swiper-button-prev.swiper-button-black,
.swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-prev.swiper-button-white,
.swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  right: 10px;
  left: auto;
}

.swiper-button-next.swiper-button-black,
.swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-next.swiper-button-white,
.swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}

.swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 10;
}

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}

.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 10px;
  left: 0;
  width: 100%;
}

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: 0.2;
}

button.swiper-pagination-bullet {
  border: 0;
  margin: 0;
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}

.swiper-pagination-white .swiper-pagination-bullet,
.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #fff;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #007aff;
}

.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000;
}

.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}

.swiper-container-vertical
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 5px 0;
  display: block;
}

.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 5px;
}

.swiper-pagination-progress {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
}

.swiper-pagination-progress .swiper-pagination-progressbar {
  background: #007aff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  transform-origin: left top;
}

.swiper-container-rtl
  .swiper-pagination-progress
  .swiper-pagination-progressbar {
  -webkit-transform-origin: right top;
  transform-origin: right top;
}

.swiper-container-horizontal > .swiper-pagination-progress {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0;
}

.swiper-container-vertical > .swiper-pagination-progress {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
}

.swiper-pagination-progress.swiper-pagination-white {
  background: rgba(255, 255, 255, 0.5);
}

.swiper-pagination-progress.swiper-pagination-white
  .swiper-pagination-progressbar {
  background: #fff;
}

.swiper-pagination-progress.swiper-pagination-black
  .swiper-pagination-progressbar {
  background: #000;
}

.swiper-container-3d {
  -webkit-perspective: 1200px;
  -o-perspective: 1200px;
  perspective: 1200px;
}

.swiper-container-3d .swiper-cube-shadow,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-wrapper {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
}

.swiper-container-3d .swiper-slide-shadow-left {
  background-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(rgba(0, 0, 0, 0.5)),
    to(transparent)
  );
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), transparent);
}

.swiper-container-3d .swiper-slide-shadow-right {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(0, 0, 0, 0.5)),
    to(transparent)
  );
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), transparent);
}

.swiper-container-3d .swiper-slide-shadow-top {
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(0, 0, 0, 0.5)),
    to(transparent)
  );
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent);
}

.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0.5)),
    to(transparent)
  );
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), transparent);
}

.swiper-container-coverflow .swiper-wrapper,
.swiper-container-flip .swiper-wrapper {
  -ms-perspective: 1200px;
}

.swiper-container-cube,
.swiper-container-flip {
  overflow: visible;
}

.swiper-container-cube .swiper-slide,
.swiper-container-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
}

.swiper-container-cube .swiper-slide .swiper-slide,
.swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-cube .swiper-slide-active .swiper-slide-active,
.swiper-container-flip .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right,
.swiper-container-cube .swiper-slide-shadow-top,
.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-right,
.swiper-container-flip .swiper-slide-shadow-top {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.swiper-container-cube .swiper-slide {
  visibility: hidden;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
}

.swiper-container-cube.swiper-container-rtl .swiper-slide {
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
}

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-next + .swiper-slide,
.swiper-container-cube .swiper-slide-prev {
  pointer-events: auto;
  visibility: visible;
}

.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0;
}

.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.swiper-container-fade .swiper-slide {
  pointer-events: none;
  -webkit-transition-property: opacity;
  transition-property: opacity;
}

.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none;
}

.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.swiper-zoom-container > canvas,
.swiper-zoom-container > img,
.swiper-zoom-container > svg {
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1);
}

.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%;
}

.swiper-container-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%;
}

.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0;
}

.swiper-scrollbar-cursor-drag {
  cursor: move;
}

.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  animation: swiper-preloader-spin 1s steps(12, end) infinite;
}

.swiper-lazy-preloader:after {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  background-size: 100%;
  background-repeat: no-repeat;
}

.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

.swiper-button-next {
  background-image: url(https://www.fnb-online.com/assets/images/slideshow-right-arrow.svg);
}

.swiper-button-prev {
  background-image: url(https://www.fnb-online.com/assets/images/slideshow-left-arrow.svg);
}

.swiper-pagination--carousel .swiper-pagination-bullet {
  -webkit-box-shadow: 0 0 6px 4px #000;
  box-shadow: 0 0 6px 4px #000;
  background: #fff;
  opacity: unset !important;
}

.swiper-pagination-bullet-active {
  background: #009cd6 !important;
}

@media all and (max-width: 699px) {
  .swiper-pagination--carousel {
    top: 90%;
  }

  .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    -webkit-box-shadow: 0 0 3px 2px #000 !important;
    box-shadow: 0 0 3px 2px #000 !important;
  }
}

.sr-only {
  position: absolute;
  width: 0.0625rem;
  height: 0.0625rem;
  padding: 0;
  margin: -0.0625rem;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

:root {
  --yxt-color-background-highlight: #fafafa;
  --yxt-color-background-dark: #a8a8a8;
  --yxt-color-brand-primary: #0f70f0;
  --yxt-color-brand-hover: #0c5ecb;
  --yxt-color-brand-white: #fff;
  --yxt-color-text-primary: #212121;
  --yxt-color-text-secondary: #757575;
  --yxt-color-text-neutral: #616161;
  --yxt-color-link-primary: var(--yxt-color-brand-primary);
  --yxt-color-borders: #dcdcdc;
  --yxt-color-error: #940000;
  --yxt-font-weight-bold: 700;
  --yxt-font-weight-semibold: 600;
  --yxt-font-weight-medium: 500;
  --yxt-font-weight-normal: 400;
  --yxt-font-weight-light: 300;
  --yxt-font-size-xs: 0.625rem;
  --yxt-font-size-sm: 0.75rem;
  --yxt-font-size-md: 0.875rem;
  --yxt-font-size-md-lg: 1rem;
  --yxt-font-size-lg: 1.125rem;
  --yxt-font-size-xlg: 1.25rem;
  --yxt-font-size-xxlg: 2.5rem;
  --yxt-line-height-xs: 1;
  --yxt-line-height-sm: 1.2;
  --yxt-line-height-md-sm: 1.3333333333;
  --yxt-line-height-md: 1.4;
  --yxt-line-height-lg: 1.5;
  --yxt-line-height-xlg: 1.6666666667;
  --yxt-line-height-xxlg: 1.7;
  --yxt-font-family: system-ui, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell,
    Helvetica Neue, sans-serif;
  --yxt-base-spacing-sm: 0.75rem;
  --yxt-base-spacing: 1rem;
  --yxt-module-footer-height: 1.5rem;
  --yxt-module-container-height: 1.25rem;
  --yxt-border-default: 0.0625rem solid var(--yxt-color-borders);
  --yxt-border-hover: 0.0625rem solid var(--yxt-color-brand-hover);
  --yxt-border-legacy: 0.0625rem solid #e9e9e9;
  --yxt-z-index-nav-more-modal: 2;
  --yxt-button-focus-border-size: 0.1875rem;
  --yxt-cards-min-width: 13.125rem;
  --yxt-container-desktop-base: 25rem;
}

.yxt-Answers-component {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.yxt-Answers-component :focus {
  outline: 0;
}

.yxt-Answers-component input[type="checkbox"]:focus {
  outline: 0.0625rem solid #000;
}

:root {
  --yxt-searchbar-form-background-color: #fff;
  --yxt-searchbar-form-outline-color-base: var(--yxt-color-borders);
  --yxt-searchbar-focus-shadow-height: 0.625rem;
  --yxt-searchbar-focus-shadow: 0 0 var(--yxt-searchbar-focus-shadow-height) 0
    rgba(0, 0, 0, 0.1);
  --yxt-searchbar-form-border-radius: calc(var(--yxt-base-spacing) * 0.4);
  --yxt-searchbar-text-color: var(--yxt-color-text-primary);
  --yxt-searchbar-text-font-size: var(--yxt-font-size-md-lg);
  --yxt-searchbar-text-line-height: var(--yxt-line-height-lg);
  --yxt-searchbar-text-font-weight: var(--yxt-font-weight-normal);
  --yxt-searchbar-button-background-color-base: #fff;
  --yxt-searchbar-button-background-color-hover: #e9e9e9;
  --yxt-searchbar-button-background-color-active: var(
    --yxt-searchbar-button-background-color-base
  );
  --yxt-searchbar-button-text-color-base: var(--yxt-color-text-primary);
  --yxt-searchbar-button-text-color-active: var(
    --yxt-searchbar-button-text-color-base
  );
}

.yxt-GeoLocationFilter,
.yxt-SearchBar {
  width: 100%;
  height: 3.1875rem;
  margin-bottom: 0;
}

.yxt-GeoLocationFilter button[class*="yxt-"],
.yxt-GeoLocationFilter input[class*="yxt-"],
.yxt-SearchBar button[class*="yxt-"],
.yxt-SearchBar input[class*="yxt-"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.yxt-GeoLocationFilter-container,
.yxt-SearchBar-container {
  position: relative;
  overflow: hidden;
  z-index: 1;
  border-radius: var(--yxt-searchbar-form-border-radius);
  border: 0.0625rem solid var(--yxt-searchbar-form-outline-color-base);
  background-color: var(--yxt-searchbar-form-background-color);
}

.yxt-GeoLocationFilter-container:focus-within,
.yxt-GeoLocationFilter-container:hover,
.yxt-SearchBar-container:focus-within,
.yxt-SearchBar-container:hover {
  -webkit-box-shadow: var(--yxt-searchbar-focus-shadow);
  box-shadow: var(--yxt-searchbar-focus-shadow);
}

.yxt-GeoLocationFilter-form,
.yxt-GeoLocationFilter-input-wrapper,
.yxt-SearchBar-form,
.yxt-SearchBar-input-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
}

.yxt-GeoLocationFilter-input,
.yxt-SearchBar-input {
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-searchbar-text-font-size);
  line-height: var(--yxt-searchbar-text-line-height);
  font-weight: var(--yxt-searchbar-text-font-weight);
  font-style: normal;
  color: var(--yxt-searchbar-text-color);
  border: 0;
  background-color: transparent;
  width: 100%;
  padding: calc(var(--yxt-base-spacing) / 2) var(--yxt-base-spacing);
}

.yxt-GeoLocationFilter-input::-ms-clear,
.yxt-SearchBar-input::-ms-clear {
  display: none;
  padding: 0;
  margin: 0;
}

.yxt-GeoLocationFilter-input:-ms-input-placeholder,
.yxt-SearchBar-input:-ms-input-placeholder {
  color: var(--yxt-color-text-secondary);
}

.yxt-GeoLocationFilter-clear,
.yxt-SearchBar-clear {
  padding-left: calc(var(--yxt-base-spacing) * 3 / 4);
  padding-right: calc(var(--yxt-base-spacing) * 3 / 4);
}

.yxt-GeoLocationFilter-button,
.yxt-SearchBar-button {
  padding-left: calc(var(--yxt-base-spacing) * 3 / 4);
  padding-right: var(--yxt-base-spacing);
}

.yxt-GeoLocationFilter-button,
.yxt-GeoLocationFilter-clear,
.yxt-SearchBar-button,
.yxt-SearchBar-clear {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font: inherit;
  padding-top: calc(var(--yxt-base-spacing) / 2);
  padding-bottom: calc(var(--yxt-base-spacing) / 2);
  background-color: var(--yxt-searchbar-button-background-color-base);
  color: var(--yxt-color-text-secondary);
  cursor: pointer;
  border: 0;
}

.yxt-GeoLocationFilter-button:focus,
.yxt-GeoLocationFilter-clear:focus,
.yxt-SearchBar-button:focus,
.yxt-SearchBar-clear:focus {
  background-color: var(--yxt-searchbar-button-background-color-hover);
}

.yxt-GeoLocationFilter--hidden,
.yxt-SearchBar--hidden {
  display: none;
}

.yxt-GeoLocationFilter-button:focus,
.yxt-GeoLocationFilter-input:focus,
.yxt-SearchBar-button:focus {
  outline: 0;
}

.yxt-GeoLocationFilter-label,
.yxt-SearchBar-label {
  position: absolute;
  width: 0.0625rem;
  height: 0.0625rem;
  padding: 0;
  margin: -0.0625rem;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.yxt-SearchBar-title {
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-font-size-xlg);
  line-height: var(--yxt-line-height-md);
  font-weight: var(--yxt-font-weight-semibold);
  font-style: normal;
  color: var(--yxt-color-text-primary);
  margin-bottom: var(--yxt-base-spacing);
}

@media (max-width: 47.9375rem) {
  .yxt-SearchBar-title {
    margin-left: var(--yxt-base-spacing);
  }
}

.yxt-SearchBar-AnimatedIcon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.yxt-SearchBar-Icon--inactive {
  display: none;
}

.yxt-SearchBar-Icon--inactive.yxt-SearchBar-LoadingIndicator {
  display: block;
  visibility: hidden;
  width: 0;
  position: absolute;
}

.yxt-SearchBar-LoadingIndicator {
  -webkit-animation: rotate 1.4s linear infinite;
  animation: rotate 1.4s linear infinite;
}

.yxt-SearchBar-LoadingIndicator-AnimatedIcon {
  stroke-dasharray: 208;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-animation: dash 1.4s ease-in-out infinite;
  animation: dash 1.4s ease-in-out infinite;
}

.yxt-SearchBar-LoadingIndicator-AnimatedIcon _:-ms-fullscreen,
:root .yxt-SearchBar-LoadingIndicator-AnimatedIcon {
  stroke-dasharray: 150;
}

.yxt-SearchBar-Icon--inactive .yxt-SearchBar-LoadingIndicator-AnimatedIcon {
  -webkit-animation: none;
  animation: none;
}

.yxt-SearchBar-voiceSearch {
  background-color: var(--yxt-searchbar-button-background-color-base);
  border: 0;
  cursor: pointer;
  padding-top: calc(var(--yxt-base-spacing) / 2);
  padding-bottom: calc(var(--yxt-base-spacing) / 2);
}

@media (max-width: 47.9375rem) {
  .yxt-SearchBar-voiceSearch {
    display: none;
  }
}

.yxt-SearchBar-voiceSearch:hover .yxt-SearchBar-mic,
img.lazy.done {
  opacity: 1;
}

.yxt-SearchBar-voiceSearch:focus {
  background-color: var(--yxt-searchbar-button-background-color-hover);
}

.yxt-SearchBar-voiceSearch .yxt-SearchBar-micIconWrapper {
  position: absolute;
}

.yxt-SearchBar-voiceSearch .yxt-SearchBar-listeningIconWrapper {
  position: relative;
}

.yxt-SearchBar .Icon-image,
.yxt-SearchBar-buttonImage .Icon svg {
  width: 2em;
  height: 2em;
}

.yxt-SearchBar-instructions {
  display: none;
}

.yxt-SearchBar-CustomListeningIcon,
.yxt-SearchBar-CustomMicIcon {
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}

.yxt-SearchBar-CustomListeningIcon--active .yxt-SearchBar-CustomListeningIcon,
.yxt-SearchBar-CustomMicIcon--active .yxt-SearchBar-CustomMicIcon {
  opacity: 1;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}

.yxt-GeoLocationFilter-title {
  position: absolute;
  width: 0.0625rem;
  height: 0.0625rem;
  padding: 0;
  margin: -0.0625rem;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.Icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.Icon svg {
  height: 1em;
  width: 1em;
  fill: currentColor;
}

.Icon--sm img,
.Icon--sm svg {
  height: 0.5em;
  width: 0.5em;
}

.Icon--lg img,
.Icon--lg svg {
  height: 2em;
  width: 2em;
}

.Icon-collapseUp svg {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.Icon-collapseDown svg {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.Icon-image {
  width: 1em;
  height: 1em;
  -o-object-fit: contain;
  object-fit: contain;
}

.Icon--chevron {
  -webkit-transform: none;
  transform: none;
}

.Icon--yext_animated_forward svg,
.Icon--yext_animated_reverse svg {
  color: #000;
  background: 0 0;
}

.Icon--yext_animated_forward:not(.yxt-SearchBar-MagnifyingGlass--static)
  svg
  > g,
.Icon--yext_animated_reverse:not(.yxt-SearchBar-Yext--static) svg > g {
  -webkit-animation-duration: 0.18s;
  animation-duration: 0.18s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.Icon--yext_animated_reverse:not(.yxt-SearchBar-Yext--static)
  svg
  > g:first-of-type {
  -webkit-animation-name: reverse-a0_o;
  animation-name: reverse-a0_o;
}

.Icon--yext_animated_reverse:not(.yxt-SearchBar-Yext--static)
  svg
  > g:nth-of-type(2) {
  -webkit-animation-name: reverse-a1_o;
  animation-name: reverse-a1_o;
}

.Icon--yext_animated_reverse:not(.yxt-SearchBar-Yext--static)
  svg
  > g:nth-of-type(3) {
  -webkit-animation-name: reverse-a2_o;
  animation-name: reverse-a2_o;
}

.Icon--yext_animated_reverse:not(.yxt-SearchBar-Yext--static)
  svg
  > g:nth-of-type(4) {
  -webkit-animation-name: reverse-a3_o;
  animation-name: reverse-a3_o;
}

.Icon--yext_animated_reverse:not(.yxt-SearchBar-Yext--static)
  svg
  > g:nth-of-type(5) {
  -webkit-animation-name: reverse-a4_o;
  animation-name: reverse-a4_o;
}

.Icon--yext_animated_reverse:not(.yxt-SearchBar-Yext--static)
  svg
  > g:nth-of-type(6) {
  -webkit-animation-name: reverse-a5_o;
  animation-name: reverse-a5_o;
}

.Icon--yext_animated_reverse:not(.yxt-SearchBar-Yext--static)
  svg
  > g:nth-of-type(7) {
  -webkit-animation-name: reverse-a6_o;
  animation-name: reverse-a6_o;
}

.Icon--yext_animated_reverse:not(.yxt-SearchBar-Yext--static)
  svg
  > g:nth-of-type(8) {
  -webkit-animation-name: reverse-a7_o;
  animation-name: reverse-a7_o;
}

.Icon--yext_animated_reverse:not(.yxt-SearchBar-Yext--static)
  svg
  > g:nth-of-type(9) {
  -webkit-animation-name: reverse-a8_o;
  animation-name: reverse-a8_o;
}

.Icon--yext_animated_reverse:not(.yxt-SearchBar-Yext--static)
  svg
  > g:nth-of-type(10) {
  -webkit-animation-name: reverse-a9_o;
  animation-name: reverse-a9_o;
}

.Icon--yext_animated_reverse:not(.yxt-SearchBar-Yext--static)
  svg
  > g:nth-of-type(11) {
  -webkit-animation-name: reverse-a10_o;
  animation-name: reverse-a10_o;
}

.Icon--yext_animated_reverse.yxt-SearchBar-Yext--static svg > g:first-of-type {
  opacity: 1;
}

.Icon--yext_animated_reverse.yxt-SearchBar-Yext--static
  svg
  > g:nth-of-type(11) {
  opacity: 0;
}

.Icon--yext_animated_forward:not(.yxt-SearchBar-MagnifyingGlass--static)
  svg
  > g:first-of-type {
  -webkit-animation-name: forward-a0_o;
  animation-name: forward-a0_o;
}

.Icon--yext_animated_forward:not(.yxt-SearchBar-MagnifyingGlass--static)
  svg
  > g:nth-of-type(2) {
  -webkit-animation-name: forward-a1_o;
  animation-name: forward-a1_o;
}

.Icon--yext_animated_forward:not(.yxt-SearchBar-MagnifyingGlass--static)
  svg
  > g:nth-of-type(3) {
  -webkit-animation-name: forward-a2_o;
  animation-name: forward-a2_o;
}

.Icon--yext_animated_forward:not(.yxt-SearchBar-MagnifyingGlass--static)
  svg
  > g:nth-of-type(4) {
  -webkit-animation-name: forward-a3_o;
  animation-name: forward-a3_o;
}

.Icon--yext_animated_forward:not(.yxt-SearchBar-MagnifyingGlass--static)
  svg
  > g:nth-of-type(5) {
  -webkit-animation-name: forward-a4_o;
  animation-name: forward-a4_o;
}

.Icon--yext_animated_forward:not(.yxt-SearchBar-MagnifyingGlass--static)
  svg
  > g:nth-of-type(6) {
  -webkit-animation-name: forward-a5_o;
  animation-name: forward-a5_o;
}

.Icon--yext_animated_forward:not(.yxt-SearchBar-MagnifyingGlass--static)
  svg
  > g:nth-of-type(7) {
  -webkit-animation-name: forward-a6_o;
  animation-name: forward-a6_o;
}

.Icon--yext_animated_forward:not(.yxt-SearchBar-MagnifyingGlass--static)
  svg
  > g:nth-of-type(8) {
  -webkit-animation-name: forward-a7_o;
  animation-name: forward-a7_o;
}

.Icon--yext_animated_forward:not(.yxt-SearchBar-MagnifyingGlass--static)
  svg
  > g:nth-of-type(9) {
  -webkit-animation-name: forward-a8_o;
  animation-name: forward-a8_o;
}

.Icon--yext_animated_forward:not(.yxt-SearchBar-MagnifyingGlass--static)
  svg
  > g:nth-of-type(10) {
  -webkit-animation-name: forward-a9_o;
  animation-name: forward-a9_o;
}

.Icon--yext_animated_forward:not(.yxt-SearchBar-MagnifyingGlass--static)
  svg
  > g:nth-of-type(11) {
  -webkit-animation-name: forward-a10_o;
  animation-name: forward-a10_o;
}

.Icon--yext_animated_forward.yxt-SearchBar-MagnifyingGlass--static
  svg
  > g:first-of-type {
  opacity: 0;
}

.Icon--yext_animated_forward.yxt-SearchBar-MagnifyingGlass--static
  svg
  > g:nth-of-type(11) {
  opacity: 1;
}

:root {
  --yxt-nav-text-font-size: var(--yxt-font-size-md);
  --yxt-nav-text-line-height: var(--yxt-line-height-md);
  --yxt-nav-text-font-weight: var(--yxt-font-weight-semibold);
  --yxt-nav-text-color: var(--yxt-color-text-primary);
  --yxt-nav-text-hover-color: #c5cace;
  --yxt-nav-text-active-color: var(--yxt-color-brand-primary);
  --yxt-nav-text-active-border-width: 0.125rem;
  --yxt-nav-text-active-border: var(--yxt-nav-text-active-border-width) solid
    var(--yxt-color-brand-primary);
  --yxt-nav-text-focus-background-color: #e9e9e9;
  --yxt-nav-dropdown-font-size: 0.8125rem;
  --yxt-nav-dropdown-line-height: var(--yxt-line-height-sm);
  --yxt-nav-dropdown-font-weight: var(--yxt-font-weight-semibold);
  --yxt-nav-dropdown-color: var(--yxt-nav-text-color);
  --yxt-nav-dropdown-hover-color: var(--yxt-nav-dropdown-color);
  --yxt-nav-dropdown-hover-background-color: #eeeff0;
  --yxt-nav-dropdown-width: 15.625rem;
  --yxt-nav-border-color: var(--yxt-color-borders);
}

.yxt-Nav-container {
  position: relative;
  border-bottom: var(--yxt-nav-text-active-border-width) solid
    var(--yxt-nav-border-color);
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.yxt-Nav-container,
.yxt-Nav-expanded,
.yxt-Nav-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.yxt-Nav-expanded {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  white-space: nowrap;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.yxt-Nav-item {
  margin-bottom: -0.125rem;
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-nav-text-font-size);
  line-height: var(--yxt-nav-text-line-height);
  font-weight: var(--yxt-nav-text-font-weight);
  font-style: normal;
  color: var(--yxt-nav-text-color);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: var(--yxt-base-spacing);
  padding-right: var(--yxt-base-spacing);
  text-transform: uppercase;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
}

.yxt-Nav-item:hover {
  color: var(--yxt-nav-text-active-color);
}

@media (hover: none) {
  .yxt-Nav-item:hover {
    color: var(--yxt-nav-text-color);
  }
}

.yxt-Nav-item:focus {
  background-color: var(--yxt-nav-text-focus-background-color);
  color: var(--yxt-nav-text-color);
}

.yxt-Nav-item:not(:last-child) {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.yxt-Nav-item--more {
  position: absolute;
  visibility: hidden;
}

.yxt-Nav-item.is-active {
  color: var(--yxt-nav-text-active-color);
  border-bottom: var(--yxt-nav-text-active-border);
  font-weight: var(--yxt-font-weight-semibold);
  padding-bottom: calc(0.375rem + var(--yxt-nav-text-active-border-width));
}

.yxt-Nav-moreContainer {
  margin-left: auto;
  position: relative;
}

.yxt-Nav-more {
  position: relative;
  overflow: visible;
  border: 0;
  background: 0 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.yxt-Nav-more,
.yxt-Nav-moreIcon,
.yxt-Nav-moreTextWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.yxt-Nav-moreTextWrapper {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.yxt-Nav-moreIcon {
  margin-top: 0.0625rem;
  margin-right: 0.4375rem;
}

.yxt-Nav-moreIcon .Icon--kabob {
  height: 0.6875rem;
  width: 0.1875rem;
  margin-top: 0.0625rem;
}

.yxt-Nav-static {
  overflow: auto;
}

.yxt-Nav-static::-webkit-scrollbar {
  display: none;
}

.yxt-Nav-modal {
  display: none;
  position: absolute;
  background-color: #fff;
  -webkit-box-shadow: 0 0.125rem 0.625rem 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0.125rem 0.625rem 0 rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  top: 1.875rem;
  right: 0;
  z-index: var(--yxt-z-index-nav-more-modal);
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: var(--yxt-nav-dropdown-width);
  word-break: break-all;
  white-space: normal;
}

.yxt-Nav-modal.is-active {
  display: block;
}

.yxt-Nav-dropDownItem {
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-nav-dropdown-font-size);
  line-height: var(--yxt-nav-dropdown-line-height);
  font-weight: var(--yxt-nav-dropdown-font-weight);
  font-style: normal;
  color: var(--yxt-nav-dropdown-color);
  display: block;
  padding: 0.375rem 1rem 0.25rem;
  text-align: left;
}

.yxt-Nav-dropDownItem:focus,
.yxt-Nav-dropDownItem:hover {
  color: var(--yxt-nav-dropdown-hover-color);
  background-color: var(--yxt-nav-dropdown-hover-background-color);
}

:root {
  --yxt-direct-answer-border: var(--yxt-border-default);
  --yxt-direct-answer-title-background-color: var(--yxt-color-brand-primary);
  --yxt-direct-answer-content-background-color: #fff;
  --yxt-direct-answer-footer-background-color: var(
    --yxt-color-background-highlight
  );
  --yxt-direct-answer-title-font-size: var(--yxt-font-size-md-lg);
  --yxt-direct-answer-title-line-height: var(--yxt-line-height-lg);
  --yxt-direct-answer-title-font-weight: var(--yxt-font-weight-semibold);
  --yxt-direct-answer-title-color: #fff;
  --yxt-direct-answer-content-font-size: var(--yxt-font-size-xlg);
  --yxt-direct-answer-content-line-height: var(--yxt-line-height-md);
  --yxt-direct-answer-content-font-weight: var(--yxt-font-weight-semibold);
  --yxt-direct-answer-content-color: var(--yxt-color-text-primary);
  --yxt-direct-answer-view-details-font-size: var(--yxt-font-size-md);
  --yxt-direct-answer-view-details-line-height: var(--yxt-line-height-xxlg);
  --yxt-direct-answer-view-details-font-weight: var(--yxt-font-weight-semibold);
  --yxt-direct-answer-footer-font-size: var(--yxt-font-size-md);
  --yxt-direct-answer-footer-line-height: var(--yxt-line-height-md);
  --yxt-direct-answer-footer-font-weight: var(--yxt-font-weight-normal);
  --yxt-direct-answer-footer-color: var(--yxt-color-text-secondary);
  --yxt-direct-answer-footer-height: var(--yxt-module-footer-height);
}

.yxt-DirectAnswer-title,
.yxt-DirectAnswer-titleText {
  font-family: var(--yxt-font-family);
  font-style: normal;
  color: var(--yxt-direct-answer-title-color);
}

.yxt-DirectAnswer-title {
  padding: calc(var(--yxt-base-spacing) / 2) var(--yxt-base-spacing);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--yxt-direct-answer-title-background-color);
  border: var(--yxt-direct-answer-border);
  font-size: var(--yxt-font-size-md);
  line-height: var(--yxt-line-height-xs);
  font-weight: var(--yxt-font-weight-normal);
}

.yxt-DirectAnswer-titleText {
  margin: 0;
  font-size: var(--yxt-direct-answer-title-font-size);
  line-height: var(--yxt-direct-answer-title-line-height);
  font-weight: var(--yxt-direct-answer-title-font-weight);
}

.yxt-DirectAnswer-titleIconWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: calc(var(--yxt-base-spacing) / 2);
}

.yxt-DirectAnswer-titleIconWrapper.yxt-Results-titleIconWrapper {
  color: inherit;
}

.yxt-DirectAnswer-entityName {
  text-transform: uppercase;
}

.yxt-DirectAnswer-iconWrapper {
  margin-right: var(--yxt-base-spacing-sm);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.yxt-DirectAnswer-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: var(--yxt-base-spacing);
  background-color: var(--yxt-direct-answer-content-background-color);
  border-left: var(--yxt-direct-answer-border);
  border-right: var(--yxt-direct-answer-border);
}

.yxt-DirectAnswer-footerWrapper {
  background-color: var(--yxt-direct-answer-footer-background-color);
}

.yxt-DirectAnswer-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  height: var(--yxt-direct-answer-footer-height);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: var(--yxt-base-spacing);
  padding-right: var(--yxt-base-spacing);
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  border: var(--yxt-direct-answer-border);
}

.yxt-DirectAnswer-footerText {
  margin-right: var(--yxt-base-spacing);
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-direct-answer-footer-font-size);
  line-height: var(--yxt-direct-answer-footer-line-height);
  font-weight: var(--yxt-direct-answer-footer-font-weight);
  color: var(--yxt-direct-answer-footer-color);
  font-style: italic;
}

.yxt-DirectAnswer-thumbs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
}

.yxt-DirectAnswer-thumbUpIcon {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.yxt-DirectAnswer-thumbDownIcon,
.yxt-DirectAnswer-thumbUpIcon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: var(--yxt-color-text-secondary);
}

.yxt-DirectAnswer-thumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  cursor: pointer;
  font-size: 1.5rem;
}

.yxt-DirectAnswer-thumb + .yxt-DirectAnswer-thumb {
  margin-left: 0.625rem;
}

.yxt-DirectAnswer-fieldValue {
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-direct-answer-content-font-size);
  line-height: var(--yxt-direct-answer-content-line-height);
  font-weight: var(--yxt-direct-answer-content-font-weight);
  font-style: normal;
  color: var(--yxt-direct-answer-content-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.yxt-AutoComplete-option--item b,
.yxt-AutoComplete-option--item strong,
.yxt-DirectAnswer-fieldValueLink {
  font-weight: var(--yxt-font-weight-semibold);
}

.yxt-DirectAnswer-fieldValueLink:link,
.yxt-DirectAnswer-fieldValueLink:visited {
  color: var(--yxt-color-brand-primary);
  text-decoration: none;
}

.yxt-DirectAnswer-fieldValueLink:active,
.yxt-DirectAnswer-fieldValueLink:focus,
.yxt-DirectAnswer-fieldValueLink:hover {
  color: var(--yxt-color-brand-primary);
  text-decoration: underline;
}

.yxt-DirectAnswer-feedback {
  display: none;
}

.yxt-DirectAnswer-viewMore {
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-direct-answer-view-details-font-size);
  line-height: var(--yxt-direct-answer-view-details-line-height);
  font-weight: var(--yxt-direct-answer-view-details-font-weight);
  font-style: normal;
  color: var(--yxt-color-text-primary);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.yxt-DirectAnswer-viewMore:link,
.yxt-DirectAnswer-viewMore:visited {
  color: var(--yxt-color-brand-primary);
  text-decoration: none;
}

.yxt-DirectAnswer-viewMore:active,
.yxt-DirectAnswer-viewMore:focus,
.yxt-DirectAnswer-viewMore:hover {
  color: var(--yxt-color-brand-primary);
  text-decoration: underline;
}

.yxt-DirectAnswer-viewMoreIcon {
  margin-left: 0.25rem;
}

:root {
  --yxt-results-title-bar-background: var(--yxt-color-background-highlight);
  --yxt-results-filters-background: #fff;
  --yxt-results-view-more-background: var(--yxt-color-background-highlight);
  --yxt-results-title-bar-text-color: var(--yxt-color-text-primary);
  --yxt-results-title-bar-text-font-size: var(--yxt-font-size-md-lg);
  --yxt-results-title-bar-text-line-height: var(--yxt-line-height-lg);
  --yxt-results-title-bar-text-font-weight: var(--yxt-font-weight-bold);
  --yxt-results-title-bar-link-font-size: var(--yxt-font-size-md);
  --yxt-results-title-bar-link-line-height: var(--yxt-line-height-xxlg);
  --yxt-results-title-bar-link-font-weight: var(--yxt-font-weight-semibold);
  --yxt-results-filters-text-color: var(--yxt-color-text-primary);
  --yxt-results-filters-text-font-size: var(--yxt-font-size-md);
  --yxt-results-filters-text-line-height: var(--yxt-line-height-md);
  --yxt-results-filters-text-font-weight: var(--yxt-font-weight-normal);
  --yxt-results-filters-link-font-size: var(--yxt-font-size-md);
  --yxt-results-filters-link-line-height: var(--yxt-line-height-md);
  --yxt-results-filters-link-font-weight: var(--yxt-font-weight-normal);
  --yxt-results-border: var(--yxt-border-default);
  --yxt-results-cards-margin: calc(var(--yxt-base-spacing) / 2);
}

.yxt-Results {
  border-bottom: 0;
  width: 100%;
}

.yxt-Results--searchLoading {
  opacity: 0.5;
}

.yxt-Results + .yxt-Results {
  margin-top: var(--yxt-base-spacing);
}

.yxt-Results-left,
.yxt-Results-titleIconWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.yxt-Results-left {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.yxt-Results-titleIconWrapper {
  margin-right: calc(var(--yxt-base-spacing) / 2);
  color: var(--yxt-color-brand-primary);
}

.yxt-Results-filters {
  margin-top: 0;
  margin-bottom: 0;
  padding: calc(var(--yxt-base-spacing) / 2) var(--yxt-base-spacing);
  background-color: var(--yxt-results-filters-background);
  border-top: var(--yxt-results-border);
  border-right: var(--yxt-border-default);
  border-left: var(--yxt-border-default);
  border-bottom: none;
}

.yxt-Results-filter,
.yxt-Results-filters {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.yxt-Results-filter {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.yxt-Results-filter:not(:first-child):before {
  content: "";
  width: 0.0625rem;
  top: 0;
  height: 40%;
  margin-left: var(--yxt-base-spacing);
  margin-right: var(--yxt-base-spacing);
  display: inline-block;
}

.yxt-Results-filterValue {
  font-size: var(--yxt-results-filters-text-font-size);
  line-height: var(--yxt-results-filters-text-line-height);
  font-weight: var(--yxt-results-filters-text-font-weight);
  color: var(--yxt-results-filters-text-color);
}

.yxt-Results-changeFilters,
.yxt-Results-filterValue {
  font-family: var(--yxt-font-family);
  font-style: normal;
}

.yxt-Results-changeFilters {
  font-size: var(--yxt-results-filters-link-font-size);
  line-height: var(--yxt-results-filters-link-line-height);
  font-weight: var(--yxt-results-filters-link-font-weight);
  color: var(--yxt-color-brand-primary);
  margin-left: calc(var(--yxt-base-spacing) / 2);
}

.yxt-Results-changeFilters:link,
.yxt-Results-changeFilters:visited {
  color: var(--yxt-color-brand-primary);
  text-decoration: underline;
}

.yxt-Results-changeFilters:active,
.yxt-Results-changeFilters:focus,
.yxt-Results-changeFilters:hover {
  color: var(--yxt-color-brand-primary);
  text-decoration: none;
}

.yxt-Results-map {
  height: 18.75rem;
  border-bottom: var(--yxt-results-border);
  border-left: var(--yxt-results-border);
  border-right: var(--yxt-results-border);
}

.yxt-Results-items,
.yxt-Results-viewAll {
  margin-top: 0;
  margin-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.yxt-Results-items {
  padding: 0;
  list-style: none;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}

.yxt-Results-viewAll {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: calc(var(--yxt-base-spacing) / 2) var(--yxt-base-spacing);
  background-color: var(--yxt-results-view-more-background);
  border-right: var(--yxt-results-border);
  border-left: var(--yxt-results-border);
  border-bottom: var(--yxt-results-border);
  text-decoration: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.yxt-Results-viewAll:focus .yxt-Results-viewAllLabel,
.yxt-Results-viewAll:hover .yxt-Results-viewAllLabel {
  text-decoration: underline;
}

.yxt-Results-viewAll svg {
  height: calc(var(--yxt-base-spacing) / 2);
  width: calc(var(--yxt-base-spacing) / 2);
  color: var(--yxt-color-brand-primary);
}

.yxt-Results-viewAllLink {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-results-title-bar-link-font-size);
  line-height: var(--yxt-results-title-bar-link-line-height);
  font-weight: var(--yxt-results-title-bar-link-font-weight);
  font-style: normal;
  color: var(--yxt-color-text-primary);
}

.yxt-Results-viewAllLink:link,
.yxt-Results-viewAllLink:visited {
  color: var(--yxt-color-brand-primary);
  text-decoration: none;
}

.yxt-Results-viewAllLink:active,
.yxt-Results-viewAllLink:focus,
.yxt-Results-viewAllLink:hover {
  color: var(--yxt-color-brand-primary);
  text-decoration: underline;
}

.yxt-Results-viewAllLabel {
  margin-right: calc(var(--yxt-base-spacing) / 2);
  font-family: var(--yxt-font-family);
  font-size: 0.875rem;
  line-height: 1.5;
  font-weight: var(--yxt-font-weight-semibold);
  font-style: normal;
  color: var(--yxt-color-brand-primary);
}

.yxt-Results-titleBar {
  border: var(--yxt-results-border);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: calc(var(--yxt-base-spacing) / 2) var(--yxt-base-spacing);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--yxt-results-title-bar-background);
}

.yxt-Results-title {
  margin: 0;
  text-transform: uppercase;
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-results-title-bar-text-font-size);
  line-height: var(--yxt-results-title-bar-text-line-height);
  font-weight: var(--yxt-font-weight-semibold);
  font-style: normal;
  color: var(--yxt-results-title-bar-text-color);
}

.yxt-Results--universal {
  margin-top: var(--yxt-base-spacing);
}

.yxt-Results--universal .yxt-Results-items {
  margin-right: 0;
}

.yxt-Results--universal.yxt-Accordion {
  border: var(--yxt-border-legacy);
}

.yxt-Results--universal .yxt-Accordion-list,
.yxt-Results--universal.yxt-Accordion .yxt-Results-filters {
  border-left: var(--yxt-border-legacy);
  border-right: var(--yxt-border-legacy);
}

.yxt-Results--universal .yxt-Accordion-list {
  border-top: var(--yxt-border-legacy);
}

.yxt-Accordion .yxt-Results-titleBar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: calc(var(--yxt-base-spacing) / 2) var(--yxt-base-spacing);
  background-color: var(--yxt-results-title-bar-background);
  border: var(--yxt-border-legacy);
  border-bottom: none;
}

.yxt-Accordion .yxt-Results-title {
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-results-title-bar-text-font-size);
  line-height: var(--yxt-results-title-bar-text-line-height);
  font-weight: var(--yxt-results-title-bar-text-font-weight);
  font-style: normal;
  color: var(--yxt-results-title-bar-text-color);
  text-transform: uppercase;
}

.yxt-Accordion .yxt-Results-titleIconWrapper {
  color: inherit;
}

:root {
  --yxt-noresults-font-size: var(--yxt-font-size-md);
  --yxt-noresults-line-height: var(--yxt-line-height-md);
  --yxt-noresults-font-weight: var(--yxt-font-weight-normal);
  --yxt-noresults-query-font-weight: var(--yxt-font-weight-semibold);
}

.yxt-NoResults-wrapper {
  font-size: var(--yxt-noresults-font-size);
  line-height: var(--yxt-noresults-line-height);
  font-weight: var(--yxt-noresults-font-weight);
}

.yxt-NoResults-query {
  font-weight: var(--yxt-noresults-query-font-weight);
}

.yxt-NoResults-returnLinkWrapper,
.yxt-NoResults-suggestions {
  margin-top: var(--yxt-base-spacing);
}

.yxt-NoResults-returnLink:link,
.yxt-NoResults-returnLink:visited {
  color: var(--yxt-color-brand-primary);
  text-decoration: underline;
}

.yxt-NoResults-returnLink:active,
.yxt-NoResults-returnLink:focus,
.yxt-NoResults-returnLink:hover {
  color: var(--yxt-color-brand-primary);
  text-decoration: none;
}

.yxt-NoResults-suggestionsHeader {
  font-style: italic;
}

.yxt-NoResults-suggestionsList {
  margin-top: calc(var(--yxt-base-spacing) / 2);
}

.yxt-NoResults-suggestion {
  margin-left: calc(var(--yxt-base-spacing-sm) * 2);
  list-style-type: disc;
  list-style-position: inside;
}

:root {
  --yxt-alternative-verticals-emphasized-font-weight: var(
    --yxt-font-weight-medium
  );
}

.yxt-AlternativeVerticals {
  font-family: var(--yxt-font-family);
  font-weight: var(--yxt-font-weight-light);
  background-color: var(--yxt-color-brand-white);
  border: var(--yxt-border-default);
}

@media (min-width: 47.9375rem) {
  .yxt-AlternativeVerticals {
    margin-bottom: 1.5rem;
  }
}

.yxt-AlternativeVerticals--notShowingResults {
  border-bottom: var(--yxt-border-default);
}

.yxt-AlternativeVerticals-noResultsInfo {
  background-color: var(--yxt-color-brand-primary);
  color: var(--yxt-color-brand-white);
  font-size: var(--yxt-font-size-md);
  padding: calc(var(--yxt-base-spacing) / 2);
}

.yxt-AlternativeVerticals-noResultsInfo--emphasized {
  font-weight: var(--yxt-alternative-verticals-emphasized-font-weight);
}

@media (min-width: 47.9375rem) {
  .yxt-AlternativeVerticals-noResultsInfo {
    font-size: var(--yxt-font-size-xlg);
    padding: 0.625rem 1.25rem;
  }
}

.yxt-AlternativeVerticals-suggestionsWrapper {
  padding: var(--yxt-base-spacing);
}

.yxt-AlternativeVerticals-details {
  font-size: var(--yxt-font-size-md);
}

.yxt-AlternativeVerticals-details--query {
  font-weight: var(--yxt-font-weight-normal);
}

@media (min-width: 47.9375rem) {
  .yxt-AlternativeVerticals-details {
    font-size: var(--yxt-font-size-md-lg);
  }
}

.yxt-AlternativeVerticals-suggestionsList {
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.yxt-AlternativeVerticals-suggestion {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: calc(var(--yxt-base-spacing) * 0.75) 0;
}

@media (max-width: 47.9375rem) {
  .yxt-AlternativeVerticals-suggestion {
    margin: calc(var(--yxt-base-spacing) / 2) 0;
  }
}

.yxt-AlternativeVerticals-suggestionLink {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-decoration: none;
}

.yxt-AlternativeVerticals-suggestionLink > :not(:last-child) {
  margin-right: 0.3125rem;
}

.yxt-AlternativeVerticals-suggestionLink:focus
  .yxt-AlternativeVerticals-suggestionLink--copy,
.yxt-AlternativeVerticals-suggestionLink:hover
  .yxt-AlternativeVerticals-suggestionLink--copy,
.yxt-AlternativeVerticals-universalLink:focus,
.yxt-AlternativeVerticals-universalLink:hover {
  text-decoration: underline;
}

.yxt-AlternativeVerticals-suggestionLink--copy {
  font-weight: var(--yxt-alternative-verticals-emphasized-font-weight);
}

.yxt-AlternativeVerticals-arrowIconWrapper,
.yxt-AlternativeVerticals-verticalIconWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.yxt-AlternativeVerticals-arrowIconWrapper,
.yxt-AlternativeVerticals-arrowIconWrapper > * {
  width: 0.5625rem;
}

.yxt-AlternativeVerticals-universalLink {
  text-decoration: none;
}

.yxt-AlternativeVerticals-universalDetails {
  font-size: var(--yxt-font-size-md);
}

@media (min-width: 47.9375rem) {
  .yxt-AlternativeVerticals-universalDetails {
    font-size: var(--yxt-font-size-md-lg);
  }
}

.yxt-AlternativeVerticals a {
  color: var(--yxt-color-brand-primary);
}

.yxt-AlternativeVerticals a:active,
.yxt-AlternativeVerticals a:hover {
  color: var(--yxt-color-brand-hover);
}

:root {
  --yxt-autocomplete-background-color: #fff;
  --yxt-autocomplete-box-shadow: 0 0.0625rem 0.375rem 0 rgba(32, 33, 36, 0.47);
  --yxt-autocomplete-selected-background-color: #f9f9f9;
  --yxt-autocomplete-option-hover-background-color: #f9f9f9;
  --yxt-autocomplete-separator-color: var(--yxt-color-borders);
  --yxt-autocomplete-text-font-size: var(--yxt-font-size-md-lg);
  --yxt-autocomplete-text-line-height: var(--yxt-line-height-md);
  --yxt-autocomplete-text-font-weight: var(--yxt-font-weight-normal);
  --yxt-autocomplete-text-color: var(--yxt-color-text-primary);
  --yxt-autocomplete-prompt-header-font-weight: var(--yxt-font-weight-light);
}

.yxt-AutoComplete {
  padding-bottom: 0.625rem;
}

@media (min-width: 48rem) {
  .yxt-AutoComplete:before {
    margin-left: var(--yxt-base-spacing);
    margin-right: var(--yxt-base-spacing);
    margin-bottom: calc(var(--yxt-base-spacing) * 0.625);
    height: 0.0625rem;
    background-color: var(--yxt-autocomplete-separator-color);
    content: "";
    display: block;
  }
}

.yxt-AutoComplete-results {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.yxt-AutoComplete-wrapper {
  background-color: var(--yxt-autocomplete-background-color);
}

.yxt-AutoComplete-option {
  padding: calc(var(--yxt-base-spacing) / 4) var(--yxt-base-spacing);
}

.yxt-AutoComplete-option--promptHeader {
  font-weight: var(--yxt-autocomplete-prompt-header-font-weight);
  font-style: italic;
}

.yxt-AutoComplete-option--item,
.yxt-AutoComplete-option--promptHeader {
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-autocomplete-text-font-size);
  line-height: var(--yxt-autocomplete-text-line-height);
  color: var(--yxt-autocomplete-text-color);
}

.yxt-AutoComplete-option--item {
  cursor: pointer;
  font-weight: var(--yxt-autocomplete-text-font-weight);
  font-style: normal;
}

.yxt-AutoComplete-option--item.yxt-selected {
  background-color: var(--yxt-autocomplete-selected-background-color);
}

.yxt-AutoComplete-option--item:hover {
  background-color: var(--yxt-autocomplete-option-hover-background-color);
}

:root {
  --yxt-accordion-result-border: var(--yxt-border-default);
  --yxt-accordion-result-background-color: #fff;
  --yxt-accordion-result-hover-color: var(--yxt-color-background-highlight);
  --yxt-accordion-result-title-font-size: var(--yxt-font-size-md-lg);
  --yxt-accordion-result-title-line-height: var(--yxt-line-height-lg);
  --yxt-accordion-result-title-font-weight: var(--yxt-font-weight-semibold);
  --yxt-accordion-result-title-color: var(--yxt-color-brand-primary);
  --yxt-accordion-result-details-font-size: var(--yxt-font-size-md);
  --yxt-accordion-result-details-line-height: var(--yxt-line-height-md-sm);
  --yxt-accordion-result-details-font-weight: var(--yxt-font-weight-normal);
  --yxt-accordion-result-details-color: var(--yxt-color-text-primary);
  --yxt-accordion-result-cta-font-size: var(--yxt-font-size-md);
  --yxt-accordion-result-cta-line-height: var(--yxt-line-height-xxlg);
  --yxt-accordion-result-cta-font-weight: var(--yxt-font-weight-normal);
}

.yxt-AccordionResult {
  background-color: var(--yxt-accordion-result-background-color);
  width: 100%;
}

.yxt-AccordionResult + .yxt-AccordionResult {
  border-top: var(--yxt-accordion-result-border);
}

.yxt-AccordionResult:hover,
.yxt-AccordionResult:not(.is-collapsed) {
  background-color: var(--yxt-accordion-result-hover-color);
}

.yxt-AccordionResult-title {
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-accordion-result-title-font-size);
  line-height: var(--yxt-accordion-result-title-line-height);
  font-weight: var(--yxt-accordion-result-title-font-weight);
  font-style: normal;
  color: var(--yxt-accordion-result-title-color);
}

.yxt-AccordionResult-indicatorWrapper {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.yxt-AccordionResult-details {
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-accordion-result-details-font-size);
  line-height: var(--yxt-accordion-result-details-line-height);
  font-weight: var(--yxt-accordion-result-details-font-weight);
  font-style: normal;
  color: var(--yxt-accordion-result-details-color);
  white-space: pre-line;
}

.yxt-AccordionResult-ctasWrapper {
  margin-top: calc(var(--yxt-base-spacing) / 2);
}

.yxt-AccordionResult-ctas {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.yxt-AccordionResult-cta,
.yxt-AccordionResult-ctas,
.yxt-Pagination-icon span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.yxt-AccordionResult-cta {
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-accordion-result-cta-font-size);
  line-height: var(--yxt-accordion-result-cta-line-height);
  font-weight: var(--yxt-accordion-result-cta-font-weight);
  font-style: normal;
  color: var(--yxt-color-text-primary);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.yxt-AccordionResult-cta:link,
.yxt-AccordionResult-cta:visited,
.yxt-SpellCheck a:link,
.yxt-SpellCheck a:visited {
  color: var(--yxt-color-brand-primary);
  text-decoration: none;
}

.yxt-AccordionResult-cta:active,
.yxt-AccordionResult-cta:focus,
.yxt-AccordionResult-cta:hover,
.yxt-SpellCheck a:active,
.yxt-SpellCheck a:focus,
.yxt-SpellCheck a:hover {
  color: var(--yxt-color-brand-primary);
  text-decoration: underline;
}

.yxt-AccordionResult-toggle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: left;
  width: 100%;
  padding-top: calc(var(--yxt-base-spacing) / 2);
  padding-bottom: 0.625rem;
  padding-left: 1em;
  padding-right: 1em;
}

.yxt-AccordionResult-indicator {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transition: -webkit-transform 0.4s ease-in;
  transition: transform 0.4s ease-in;
  transition: transform 0.4s ease-in, -webkit-transform 0.4s ease-in;
}

.yxt-AccordionResult-content {
  padding-top: calc(var(--yxt-base-spacing-sm) / 2);
  padding-bottom: 1em;
  padding-right: 1em;
  padding-left: 1em;
}

.yxt-AccordionResult-body {
  -webkit-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
  overflow: hidden;
}

.yxt-AccordionResult.is-collapsed .yxt-AccordionResult-body {
  height: 0;
  border-top: 0;
}

.yxt-AccordionResult.is-collapsed .yxt-AccordionResult-indicator {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

:root {
  --yxt-spellCheck-text-font-size: var(--yxt-font-size-md-lg);
  --yxt-spellCheck-text-font-weight: var(--yxt-font-weight-normal);
  --yxt-spellCheck-text-color: var(--yxt-color-text-primary);
  --yxt-spellCheck-container-height: var(--yxt-module-container-height);
}

.yxt-SpellCheck {
  margin-top: calc(var(--yxt-base-spacing) * 2);
  padding-bottom: var(--yxt-base-spacing);
  display: block;
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-spellCheck-text-font-size);
  line-height: var(--yxt-line-height-xs);
  font-weight: var(--yxt-spellCheck-text-font-weight);
  font-style: normal;
  color: var(--yxt-spellCheck-text-color);
}

@media (max-width: 47.9375rem) {
  .yxt-SpellCheck {
    margin-left: var(--yxt-base-spacing);
  }
}

.yxt-SpellCheck .yxt-SpellCheck-helpText {
  font-size: var(--yxt-font-size-lg);
}

.yxt-SpellCheck .yxt-SpellCheck-container {
  height: var(--yxt-spellCheck-container-height);
}

.yxt-SpellCheck b,
.yxt-SpellCheck strong {
  font-style: italic;
  font-weight: var(--yxt-font-weight-semibold);
}

.yxt-SpellCheck a {
  text-decoration: none;
}

:root {
  --yxt-pagination-text-color: var(--yxt-color-text-neutral);
  --yxt-pagination-text-color-hover: var(--yxt-color-brand-white);
  --yxt-pagination-text-font-size: var(--yxt-font-size-md-lg);
  --yxt-pagination-text-line-height: var(--yxt-line-height-md);
  --yxt-pagination-text-font-weight: var(--yxt-font-weight-normal);
  --yxt-pagination-color-active-page: var(--yxt-color-borders);
  --yxt-pagination-color-hover: var(--yxt-color-text-secondary);
}

.yxt-Pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-pagination-text-font-size);
  line-height: var(--yxt-pagination-text-line-height);
  font-weight: var(--yxt-pagination-text-font-weight);
  font-style: normal;
  color: var(--yxt-pagination-text-color);
}

.yxt-Pagination--hidden {
  visibility: hidden;
}

.yxt-Pagination-link,
.yxt-Pagination-page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 1.5rem;
  min-width: 1.5rem;
  margin-right: 0.125rem;
  margin-left: 0.125rem;
  border-radius: 0.125rem;
  padding: 0 0.25rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.yxt-Pagination-icon {
  font-size: var(--yxt-font-size-sm);
}

@media (max-width: 47.9375rem) {
  .yxt-Pagination--activeMobile {
    background-color: var(--yxt-pagination-color-active-page);
  }
}

@media (min-width: 48rem) {
  .yxt-Pagination--activeDesktop {
    background-color: var(--yxt-pagination-color-active-page);
  }
}

.yxt-Pagination-link:focus,
.yxt-Pagination-link:hover {
  cursor: pointer;
  color: var(--yxt-pagination-text-color-hover);
  background-color: var(--yxt-pagination-color-hover);
}

.yxt-Pagination-chevron--left svg,
.yxt-Pagination-doubleChevron--left svg {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.yxt-Pagination-doubleChevron,
.yxt-Pagination-doubleChevron--left {
  margin-right: -0.25rem;
}

.yxt-FilterOptions-container--searching .yxt-FilterOptions-option.displaySearch,
.yxt-Pagination-chevron,
.yxt-Pagination-chevron--left,
.yxt-Pagination-doubleChevron,
.yxt-Pagination-doubleChevron--left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media (max-width: 47.9375rem) {
  .yxt-Pagination .mobile-hidden {
    display: none;
  }
}

@media (min-width: 47.9375rem) {
  .yxt-Pagination .desktop-hidden {
    display: none;
  }
}

:root {
  --yxt-locationBias-text-font-size: var(--yxt-font-size-md);
  --yxt-locationBias-text-font-weight: var(--yxt-font-weight-normal);
  --yxt-locationBias-text-color: var(--yxt-color-text-secondary);
  --yxt-locationBias-bullet-size: calc(var(--yxt-font-size-sm) / 2);
  --yxt-locationBias-bullet-color-default: var(--yxt-locationBias-text-color);
}

.yxt-LocationBias {
  padding-bottom: var(--yxt-base-spacing);
  display: block;
  text-align: center;
  padding-top: var(--yxt-base-spacing);
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-locationBias-text-font-size);
  line-height: var(--yxt-line-height-xs);
  font-weight: var(--yxt-locationBias-text-font-weight);
  font-style: normal;
  color: var(--yxt-locationBias-text-color);
}

.yxt-LocationBias b,
.yxt-LocationBias strong {
  font-weight: var(--yxt-font-weight-semibold);
}

.yxt-LocationBias-bullet {
  height: var(--yxt-locationBias-bullet-size);
  width: var(--yxt-locationBias-bullet-size);
  display: inline-block;
  border-radius: 100%;
  background: var(--yxt-locationBias-bullet-color-default);
  margin-bottom: 0.125rem;
}

.yxt-LocationBias-unknownLoc {
  background-color: transparent;
  border: 0.0625rem solid var(--yxt-locationBias-bullet-color-default);
}

.yxt-LocationBias-preciseLoc {
  background: var(--yxt-color-brand-primary);
  border: 0.0625rem solid var(--yxt-color-brand-primary);
}

@media (max-width: 47.9375rem) {
  .yxt-LocationBias-locSource {
    display: block;
  }
}

.yxt-LocationBias-updateLoc {
  cursor: pointer;
  border: 0;
  font-size: var(--yxt-locationBias-text-font-size);
  padding: 0;
  background: 0 0;
}

.yxt-LocationBias-updateLoc,
.yxt-LocationBias-updateLoc:link,
.yxt-LocationBias-updateLoc:visited {
  color: var(--yxt-color-brand-primary);
  text-decoration: none;
}

.yxt-LocationBias-updateLoc:active,
.yxt-LocationBias-updateLoc:focus,
.yxt-LocationBias-updateLoc:hover {
  color: var(--yxt-color-brand-primary);
  text-decoration: underline;
}

:root {
  --yxt-filter-options-checkmark-color: #0f70f0;
  --yxt-filter-options-checkbox-focus-color: #000;
  --yxt-filter-options-options-max-height: none;
}

.yxt-FilterOptions-container {
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-font-size-md);
  line-height: var(--yxt-line-height-md);
  font-weight: var(--yxt-font-weight-normal);
  font-style: normal;
  color: var(--yxt-color-text-primary);
}

.yxt-FilterOptions-container--searching .yxt-FilterOptions-option.hiddenSearch,
.yxt-FilterOptions-container--searching .yxt-FilterOptions-showToggle {
  display: none;
}

.yxt-FilterOptions-controls {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.yxt-FilterOptions-buttonWrapper,
.yxt-FilterOptions-controls {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.yxt-FilterOptions-filter {
  font-size: 0.875rem;
  border: 0.0625rem solid var(--yxt-color-borders);
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  width: 100%;
}

.yxt-FilterOptions-filter:active,
.yxt-FilterOptions-filter:focus,
.yxt-FilterOptions-filter:hover {
  border: var(--yxt-border-hover);
}

.yxt-FilterOptions-filter::-ms-clear {
  display: none;
  height: 0;
  width: 0;
}

.yxt-FilterOptions-fieldSet {
  border: 0;
  padding: 0;
  margin: 0.75rem 0;
}

.yxt-FilterOptions-legend {
  width: 100%;
  padding: 0;
}

.yxt-FilterOptions-legendLabel {
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-font-size-md-lg);
  line-height: var(--yxt-line-height-lg);
  font-weight: var(--yxt-font-weight-semibold);
  font-style: normal;
  color: var(--yxt-color-text-primary);
  text-align: left;
  width: 100%;
}

.yxt-FilterOptions-legendLabel:hover button {
  color: var(--yxt-color-text-secondary);
}

.yxt-FilterOptions-legendLabel,
.yxt-FilterOptions-option {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.yxt-FilterOptions-option:not(:first-child) {
  margin-top: 0.5rem;
}

.yxt-FilterOptions-clearSearch.js-hidden,
.yxt-FilterOptions-option.hidden,
.yxt-FilterOptions-reset.js-hidden {
  display: none;
}

@media (max-width: 47.9375rem) {
  .yxt-FilterOptions-option {
    margin: 1.375rem 0;
  }
}

.yxt-FilterOptions-options {
  margin: 0.75rem 0;
  padding-left: 0;
  max-height: var(--yxt-filter-options-options-max-height);
  overflow-y: auto;
}

.yxt-FilterOptions-search {
  position: relative;
  margin-top: 0.625rem;
}

.yxt-FilterOptions-clearSearch {
  position: absolute;
  right: 0;
  top: 50%;
  max-height: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border: 0;
  background: 0 0;
  padding: 0;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--yxt-color-text-secondary);
}

.yxt-FilterOptions-optionLabel,
.yxt-FilterOptions-radioButtonLabel {
  cursor: pointer;
  word-break: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.yxt-FilterOptions-optionLabel,
.yxt-FilterOptions-selectedCount {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.yxt-FilterOptions-optionLabel {
  position: relative;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-left: 1.375rem;
}

.yxt-FilterOptions-optionLabel:before {
  content: "";
  position: absolute;
  bottom: 50%;
  left: -1.375rem;
  height: 0.75rem;
  width: 0.75rem;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
  border: 0.0625rem solid var(--yxt-color-borders);
  border-radius: 0.125rem;
}

.yxt-FilterOptions-optionLabel:after {
  content: none;
  position: absolute;
  top: calc(50% - 0.1875rem);
  left: -1.25rem;
  width: 0.1875rem;
  height: 0.5625rem;
  -webkit-transform: rotate(45deg) translateY(-50%);
  transform: rotate(45deg) translateY(-50%);
  border-bottom: 0.0625rem solid #0c5ecb;
  border-right: 0.0625rem solid #0c5ecb;
  border-color: var(--yxt-filter-options-checkmark-color);
}

.yxt-FilterOptions-selectedCount {
  font-size: var(--yxt-font-size-xs);
  font-weight: var(--yxt-font-weight-bold);
  font-style: normal;
  color: #fff;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: 0.75rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: var(--yxt-color-background-dark);
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 0.25rem;
}

.yxt-FilterOptions-reset,
.yxt-FilterOptions-selectedCount {
  font-family: var(--yxt-font-family);
  line-height: var(--yxt-line-height-xs);
}

.yxt-FilterOptions-reset {
  font-size: var(--yxt-font-size-md);
  font-weight: var(--yxt-font-weight-normal);
  font-style: italic;
  color: var(--yxt-color-brand-primary);
  background: 0 0;
  border: 0.0625rem solid transparent;
  border-radius: 0.25rem;
  padding: 0;
}

.yxt-FilterOptions-reset:not(:disabled),
.yxt-FilterOptions-showToggle:not(:disabled) {
  cursor: pointer;
}

.yxt-FilterOptions-reset:not(:disabled):hover {
  text-decoration: underline;
}

.yxt-FilterOptions-reset:not(:disabled):focus {
  color: var(--yxt-color-brand-hover);
  border: 0.0625rem solid var(--yxt-color-brand-hover);
}

@media (max-width: 47.9375rem) {
  .yxt-FilterOptions-reset {
    margin-top: 0.75rem;
  }
}

.yxt-FilterOptions-showToggle {
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-font-size-md);
  line-height: var(--yxt-line-height-xs);
  font-weight: var(--yxt-font-weight-normal);
  font-style: italic;
  color: var(--yxt-color-brand-primary);
  background: 0 0;
  border: 0.0625rem solid transparent;
  border-radius: 0.25rem;
  padding: 0;
}

.yxt-FilterOptions-showToggle:not(:disabled):hover {
  text-decoration: underline;
}

.yxt-FilterOptions-showToggle:not(:disabled):focus {
  color: var(--yxt-color-brand-hover);
  border: 0.0625rem solid var(--yxt-color-brand-hover);
}

.yxt-FilterOptions-showToggle.hidden {
  display: none;
}

.yxt-FilterOptions-showToggle svg {
  width: 0.375rem;
  margin-left: 0.3125rem;
}

.yxt-FilterOptions-clickableLegend {
  border: 0.0625rem solid transparent;
  border-radius: 0.25rem;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.yxt-FilterOptions-clickableLegend:focus {
  border: 0.0625rem solid var(--yxt-color-brand-hover);
}

.yxt-FilterOptions-clickableLegend:not(:disabled):hover {
  color: var(--yxt-color-text-secondary);
}

.yxt-FilterOptions-input {
  margin-left: 0;
}

.yxt-FilterOptions-radioButtonInput {
  cursor: pointer;
}

.yxt-FilterOptions-radioButtonInput:focus {
  outline: 0.0625rem solid #000;
}

.yxt-FilterOptions-checkboxInput {
  opacity: 0;
  height: 0;
  width: 0;
  margin: 0;
}

.yxt-FilterOptions-checkboxInput:checked
  + .yxt-FilterOptions-optionLabel:after {
  content: "";
}

.yxt-FilterOptions-checkboxInput:checked
  + .yxt-FilterOptions-optionLabel:before {
  border: var(--yxt-border-hover);
  border-color: var(--yxt-filter-options-checkmark-color);
}

.yxt-FilterOptions-checkboxInput:focus + .yxt-FilterOptions-optionLabel:before {
  border: 0.0625rem solid var(--yxt-filter-options-checkbox-focus-color);
  -webkit-box-shadow: var(--yxt-searchbar-focus-shadow);
  box-shadow: var(--yxt-searchbar-focus-shadow);
}

.yxt-FilterOptions-reset--right {
  margin-left: auto;
  padding: 0;
}

.yxt-FilterOptions-expand {
  margin-left: auto;
}

.yxt-FilterOptions-expand > .Icon--chevron svg {
  height: 0.75rem;
  width: 0.75rem;
}

.yxt-FilterOptions--collapsed {
  display: none;
}

.yxt-FilterOptions-collapseDown,
.yxt-FilterOptions-collapseUp {
  vertical-align: middle;
}

.yxt-FilterOptions-collapseUp {
  padding-top: 0.125rem;
}

.yxt-FilterOptions-collapseUp svg {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.yxt-FilterOptions-collapseDown svg {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.yxt-Facets-container {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 1.5625rem;
  width: 18.75rem;
}

@media (max-width: 47.9375rem) {
  .yxt-Facets-container {
    width: 100%;
  }
}

.yxt-FilterBox-container {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
}

.yxt-FilterBox-titleContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 0 0.25rem;
}

.yxt-FilterBox-titleContainer svg {
  width: 1.125rem;
  height: 1.125rem;
}

.yxt-FilterBox-apply,
.yxt-FilterBox-title {
  font-family: var(--yxt-font-family);
  font-weight: var(--yxt-font-weight-semibold);
  font-style: normal;
  text-transform: uppercase;
}

.yxt-FilterBox-title {
  color: var(--yxt-color-text-primary);
  margin-left: 0.5rem;
  font-size: var(--yxt-font-size-md-lg);
  line-height: var(--yxt-line-height-lg);
}

.yxt-FilterBox-filter + .yxt-FilterBox-filter {
  border-top: 0.0625rem solid var(--yxt-color-borders);
}

.yxt-FilterBox-apply {
  font-size: var(--yxt-font-size-md);
  line-height: var(--yxt-line-height-xs);
  color: #fff;
  border: 0;
  border-radius: 0.1875rem;
  margin-top: 1rem;
  background: var(--yxt-color-brand-primary);
  letter-spacing: 0.03125rem;
  width: 5.625rem;
  height: 2.5rem;
}

.yxt-FilterBox-apply:not(:disabled),
.yxt-FilterBox-reset:not(:disabled) {
  cursor: pointer;
}

.yxt-FilterBox-apply:not(:disabled):focus,
.yxt-FilterBox-apply:not(:disabled):hover {
  background: padding-box var(--yxt-color-brand-hover);
}

.yxt-FilterBox-apply:not(:disabled):focus {
  border: var(--yxt-button-focus-border-size) double #0c5ecb;
}

.yxt-FilterBox-reset {
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-font-size-md);
  line-height: var(--yxt-line-height-xs);
  font-weight: var(--yxt-font-weight-semibold);
  font-style: normal;
  color: var(--yxt-color-brand-primary);
  background: 0 0;
  border: 0.0625rem solid transparent;
  border-radius: 0.25rem;
  padding: 0.3125rem 0.625rem 0.3125rem 0;
  text-decoration: underline;
  letter-spacing: 0.03125rem;
}

.yxt-FilterBox-reset:not(:disabled):focus {
  color: var(--yxt-color-brand-hover);
  border: 0.0625rem solid var(--yxt-color-brand-hover);
}

.yxt-FilterBox-reset:not(:disabled):hover {
  text-decoration: none;
}

.yxt-FilterBox-apply + .yxt-FilterBox-reset {
  padding-left: 0.3125rem;
  margin-left: 0.625rem;
}

:root {
  --yxt-question-submission-font-size: var(--yxt-font-size-md);
  --yxt-question-submission-line-height: var(--yxt-line-height-md);
  --yxt-question-submission-font-weight: var(--yxt-font-weight-normal);
  --yxt-question-submission-color: var(--yxt-color-text-primary);
  --yxt-question-submission-border: var(--yxt-border-default);
  --yxt-question-submission-title-font-size: var(--yxt-font-size-md-lg);
  --yxt-question-submission-title-line-height: var(--yxt-line-height-lg);
  --yxt-question-submission-title-font-weight: var(--yxt-font-weight-semibold);
  --yxt-question-submission-title-color: var(--yxt-color-text-primary);
  --yxt-question-submission-title-bar-background: var(
    --yxt-color-background-highlight
  );
  --yxt-question-submission-description-font-size: var(--yxt-font-size-md);
  --yxt-question-submission-description-line-height: var(--yxt-line-height-md);
  --yxt-question-submission-description-font-weight: var(
    --yxt-font-weight-normal
  );
  --yxt-question-submission-description-color: var(--yxt-color-text-primary);
  --yxt-question-submission-label-font-size: var(--yxt-font-size-md);
  --yxt-question-submission-label-line-height: var(--yxt-line-height-md);
  --yxt-question-submission-label-font-weight: var(--yxt-font-weight-bold);
  --yxt-question-submission-label-color: var(--yxt-color-text-primary);
  --yxt-question-submission-input-font-size: var(--yxt-font-size-md);
  --yxt-question-submission-input-line-height: var(--yxt-line-height-md);
  --yxt-question-submission-input-font-weight: var(--yxt-font-weight-normal);
  --yxt-question-submission-input-color: var(--yxt-color-text-primary);
  --yxt-question-submission-inputError-font-size: var(--yxt-font-size-sm);
  --yxt-question-submission-inputError-line-height: var(
    --yxt-line-height-md-sm
  );
  --yxt-question-submission-inputError-font-weight: var(
    --yxt-font-weight-normal
  );
  --yxt-question-submission-inputError-color: var(--yxt-color-error);
  --yxt-question-submission-formSubmitError-color: var(--yxt-color-error);
  --yxt-question-submission-acknowledgement-bar-bg: var(
    --yxt-color-background-highlight
  );
}

.yxt-QuestionSubmission {
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-question-submission-font-size);
  line-height: var(--yxt-question-submission-line-height);
  font-weight: var(--yxt-question-submission-font-weight);
  font-style: normal;
  color: var(--yxt-question-submission-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border: var(--yxt-question-submission-border);
  background-color: var(--yxt-color-brand-white);
  margin-top: var(--yxt-base-spacing);
}

.yxt-QuestionSubmission-titleBar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: calc(var(--yxt-base-spacing) / 2) var(--yxt-base-spacing);
  background-color: var(--yxt-question-submission-title-bar-background);
  border: 0;
  border-bottom: var(--yxt-question-submission-border);
  cursor: pointer;
}

.yxt-QuestionSubmission-titleBar:focus {
  outline: 0.0625rem solid var(--yxt-color-text-primary);
}

.yxt-QuestionSubmission-left,
.yxt-QuestionSubmission-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.yxt-QuestionSubmission-titleIconWrapper {
  margin-right: var(--yxt-base-spacing-sm);
}

.yxt-QuestionSubmission-titleIconWrapper,
.yxt-QuestionSubmission-toggle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  color: var(--yxt-color-brand-primary);
  background: 0 0;
  border: 0;
}

.yxt-QuestionSubmission-titleIconWrapper svg,
.yxt-QuestionSubmission-toggle svg {
  height: 1.125rem;
  width: 1.125rem;
}

.yxt-QuestionSubmission-toggle--expanded {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.yxt-QuestionSubmission-toggle--collapsed {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.yxt-QuestionSubmission-title {
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-question-submission-title-font-size);
  line-height: var(--yxt-question-submission-title-line-height);
  font-weight: var(--yxt-question-submission-title-font-weight);
  font-style: normal;
  color: var(--yxt-question-submission-title-color);
  text-transform: uppercase;
  width: 100%;
}

.yxt-QuestionSubmission-titleDescription {
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-question-submission-description-font-size);
  line-height: var(--yxt-question-submission-description-line-height);
  font-weight: var(--yxt-question-submission-description-font-weight);
  color: var(--yxt-question-submission-description-color);
  margin-right: calc(var(--yxt-base-spacing) / 2);
  font-style: italic;
}

@media (max-width: 47.9375rem) {
  .yxt-QuestionSubmission-titleDescription {
    display: none;
  }
}

.yxt-QuestionSubmission-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 0;
}

.yxt-QuestionSubmission-formRow {
  margin: var(--yxt-base-spacing);
}

.yxt-QuestionSubmission-formRow + .yxt-QuestionSubmission-formRow {
  margin-top: 0;
}

.yxt-QuestionSubmission-formFieldWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

@media (max-width: 35.9375rem) {
  .yxt-QuestionSubmission-formFieldWrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.yxt-QuestionSubmission-formField {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-right: var(--yxt-base-spacing);
  width: 100%;
}

@media (max-width: 35.9375rem) {
  .yxt-QuestionSubmission-formField {
    margin-bottom: 0;
  }

  .yxt-QuestionSubmission-formField + .yxt-QuestionSubmission-formField {
    margin-top: var(--yxt-base-spacing);
  }
}

.yxt-QuestionSubmission-formLabel {
  font-size: var(--yxt-question-submission-label-font-size);
  line-height: var(--yxt-question-submission-label-line-height);
  font-weight: var(--yxt-question-submission-label-font-weight);
  color: var(--yxt-question-submission-label-color);
  margin-bottom: calc(var(--yxt-base-spacing) / 4);
}

.yxt-QuestionSubmission-formInput,
.yxt-QuestionSubmission-formLabel {
  font-family: var(--yxt-font-family);
  font-style: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.yxt-QuestionSubmission-formInput {
  font-size: var(--yxt-question-submission-input-font-size);
  line-height: var(--yxt-question-submission-input-line-height);
  font-weight: var(--yxt-question-submission-input-font-weight);
  color: var(--yxt-question-submission-input-color);
  width: 100%;
  border: var(--yxt-question-submission-border);
  border-radius: 0.1875rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: calc(var(--yxt-base-spacing) / 2);
}

.yxt-QuestionSubmission-formInput:focus {
  border: 0.0625rem solid var(--yxt-color-text-primary);
}

.yxt-QuestionSubmission-formInput::-webkit-input-placeholder {
  font-style: italic;
}

.yxt-QuestionSubmission-formInput::-moz-placeholder {
  font-style: italic;
}

.yxt-QuestionSubmission-formInput:-ms-input-placeholder,
.yxt-QuestionSubmission-formInput::-ms-input-placeholder {
  font-style: italic;
}

.yxt-QuestionSubmission-formInput::placeholder {
  font-style: italic;
}

.yxt-QuestionSubmission-formInput.yxt-required-error {
  border: 0.0625rem solid var(--yxt-color-error);
}

.yxt-QuestionSubmission-formInput.yxt-required-error::-webkit-input-placeholder {
  color: var(--yxt-color-error);
}

.yxt-QuestionSubmission-formInput.yxt-required-error::-moz-placeholder {
  color: var(--yxt-color-error);
}

.yxt-QuestionSubmission-formInput.yxt-required-error:-ms-input-placeholder,
.yxt-QuestionSubmission-formInput.yxt-required-error::-ms-input-placeholder {
  color: var(--yxt-color-error);
}

.yxt-QuestionSubmission-formInput.yxt-required-error::placeholder {
  color: var(--yxt-color-error);
}

.yxt-QuestionSubmission-formError {
  color: var(--yxt-question-submission-formSubmitError-color);
  font-style: italic;
}

.yxt-QuestionSubmission-acknowledgementBar {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: var(--yxt-base-spacing);
  border-top: var(--yxt-question-submission-border);
  background-color: var(--yxt-question-submission-acknowledgement-bar-bg);
}

.yxt-QuestionSubmission-acknowledgement,
.yxt-QuestionSubmission-acknowledgementBar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.yxt-QuestionSubmission-ackCheckboxInput {
  min-height: 1rem;
  min-width: 1rem;
  margin: 0;
  margin-right: calc(var(--yxt-base-spacing) / 2);
  margin-top: 0.2rem;
}

.yxt-QuestionSubmission-privacyPolicy {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  margin-right: var(--yxt-base-spacing);
}

.yxt-QuestionSubmission-privacyPolicyUrl:link,
.yxt-QuestionSubmission-privacyPolicyUrl:visited {
  color: var(--yxt-color-brand-primary);
  text-decoration: none;
}

.yxt-QuestionSubmission-privacyPolicyUrl:active,
.yxt-QuestionSubmission-privacyPolicyUrl:focus,
.yxt-QuestionSubmission-privacyPolicyUrl:hover {
  color: var(--yxt-color-brand-primary);
  text-decoration: underline;
}

.yxt-QuestionSubmission-inputError {
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-question-submission-inputError-font-size);
  line-height: var(--yxt-question-submission-inputError-line-height);
  font-weight: var(--yxt-question-submission-inputError-font-weight);
  font-style: normal;
  color: var(--yxt-question-submission-inputError-color);
  margin-top: calc(var(--yxt-base-spacing) / 4);
  display: block;
}

.yxt-QuestionSubmission-submitButton {
  border: 0;
  border-radius: 0.1875rem;
  background: var(--yxt-color-brand-primary);
  letter-spacing: 0.03125rem;
  text-transform: uppercase;
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 0;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  padding-left: var(--yxt-base-spacing);
  padding-right: var(--yxt-base-spacing);
  letter-spacing: 0.5px;
  font-size: 0.875rem;
  font-weight: var(--yxt-font-weight-semibold);
  overflow-wrap: break-word;
}

.yxt-QuestionSubmission-submitButton:not(:disabled) {
  cursor: pointer;
}

.yxt-QuestionSubmission-submitButton:not(:disabled):focus,
.yxt-QuestionSubmission-submitButton:not(:disabled):hover {
  background: padding-box var(--yxt-color-brand-hover);
}

.yxt-QuestionSubmission-submitButton:not(:disabled):focus {
  border: var(--yxt-button-focus-border-size) double #0c5ecb;
}

.yxt-QuestionSubmission-submitButton:focus {
  padding: 0.4375rem 0.8125rem;
}

.yxt-SortOptions-container {
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-font-size-md);
  line-height: var(--yxt-line-height-md);
  font-weight: var(--yxt-font-weight-normal);
  font-style: normal;
  color: var(--yxt-color-text-primary);
}

.yxt-SortOptions-fieldSet {
  border: 0;
  padding: 0;
  margin: var(--yxt-base-spacing) 0;
}

.yxt-SortOptions-legend {
  width: 100%;
  padding: 0;
}

.yxt-SortOptions-legendLabel {
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-font-size-md-lg);
  line-height: var(--yxt-line-height-lg);
  font-weight: var(--yxt-font-weight-semibold);
  font-style: normal;
  color: var(--yxt-color-text-primary);
  text-align: left;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.yxt-SortOptions-option {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin: 0.125rem 0;
}

@media (max-width: 47.9375rem) {
  .yxt-SortOptions-option {
    margin: 1.375rem 0;
  }
}

.yxt-SortOptions-options {
  margin: var(--yxt-base-spacing) 0;
}

.yxt-SortOptions-optionLabel {
  display: block;
  margin-left: 0.3125rem;
}

.yxt-SortOptions-optionSelector:focus {
  outline: 0.0625rem auto #000;
}

.yxt-SortOptions-optionSelector:focus _:-ms-fullscreen,
:root .yxt-SortOptions-optionSelector:focus {
  outline: 0.0625rem solid #000;
}

.yxt-SortOptions-reset {
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-font-size-md);
  line-height: var(--yxt-line-height-xs);
  font-weight: var(--yxt-font-weight-normal);
  font-style: italic;
  color: var(--yxt-color-brand-primary);
  background: 0 0;
  border: 0.0625rem solid transparent;
  border-radius: 0.25rem;
  padding: 0;
}

.yxt-SortOptions-reset:not(:disabled) {
  cursor: pointer;
}

.yxt-SortOptions-reset:not(:disabled):hover {
  text-decoration: underline;
}

.yxt-SortOptions-reset:not(:disabled):focus {
  color: var(--yxt-color-brand-hover);
  border: 0.0625rem solid var(--yxt-color-brand-hover);
}

@media (max-width: 47.9375rem) {
  .yxt-SortOptions-reset {
    margin-top: var(--yxt-base-spacing);
  }
}

.yxt-SortOptions-reset.js-hidden {
  display: none;
}

.yxt-SortOptions-showToggle {
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-font-size-md);
  line-height: var(--yxt-line-height-xs);
  font-weight: var(--yxt-font-weight-normal);
  font-style: italic;
  color: var(--yxt-color-brand-primary);
  background: 0 0;
  border: 0.0625rem solid transparent;
  border-radius: 0.25rem;
  padding: 0;
}

.yxt-SortOptions-apply:not(:disabled),
.yxt-SortOptions-showToggle:not(:disabled) {
  cursor: pointer;
}

.yxt-SortOptions-showToggle:not(:disabled):hover {
  text-decoration: underline;
}

.yxt-SortOptions-showToggle:not(:disabled):focus {
  color: var(--yxt-color-brand-hover);
  border: 0.0625rem solid var(--yxt-color-brand-hover);
}

.yxt-SortOptions-showToggle svg {
  width: 0.375rem;
  margin-left: 0.3125rem;
}

.yxt-SortOptions-input {
  margin-left: 0;
}

.yxt-SortOptions-collapseDown,
.yxt-SortOptions-collapseUp {
  vertical-align: middle;
}

.yxt-SortOptions-collapseUp {
  padding-top: 0.125rem;
}

.yxt-SortOptions-collapseUp svg {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.yxt-SortOptions-collapseDown svg {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.yxt-SortOptions-apply {
  font-family: var(--yxt-font-family);
  line-height: var(--yxt-line-height-xs);
  font-style: normal;
  border: 0;
  border-radius: 0.1875rem;
  margin-top: 1rem;
  background: var(--yxt-color-brand-primary);
  letter-spacing: 0.03125rem;
  text-transform: uppercase;
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: var(--yxt-base-spacing-sm);
  letter-spacing: 0.5px;
  font-size: var(--yxt-font-size-md);
  font-weight: var(--yxt-font-weight-semibold);
  overflow-wrap: break-word;
  margin-top: var(--yxt-base-spacing-sm);
}

.yxt-SortOptions-apply:not(:disabled):focus,
.yxt-SortOptions-apply:not(:disabled):hover {
  background: padding-box var(--yxt-color-brand-hover);
}

.yxt-SortOptions-apply:not(:disabled):focus {
  border: var(--yxt-button-focus-border-size) double #0c5ecb;
}

.yxt-SortOptions-apply:focus {
  padding: calc(
    var(--yxt-base-spacing-sm) - var(--yxt-button-focus-border-size)
  );
}

:root {
  --yxt-standard-card-base-spacing: var(--yxt-base-spacing);
  --yxt-standard-card-link-color: var(--yxt-color-brand-primary);
  --yxt-standard-card-subtitle-color: var(--yxt-color-text-secondary);
  --yxt-standard-card-link-hover-color: var(--yxt-color-brand-hover);
  --yxt-standard-card-cta-width: calc(
    var(--yxt-standard-card-base-spacing) * 8
  );
  --yxt-standard-card-wrapper-width: calc(
    var(--yxt-standard-card-base-spacing) * 10
  );
  --yxt-standard-card-ordinal-size: var(--yxt-base-spacing);
  --yxt-standard-card-ordinal-font-size: var(--yxt-font-size-xs);
}

.yxt-StandardCard,
.yxt-StandardCard-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.yxt-StandardCard {
  padding: var(--yxt-standard-card-base-spacing);
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-font-size-md);
  line-height: var(--yxt-line-height-xs);
  font-weight: var(--yxt-font-weight-normal);
  font-style: normal;
  color: var(--yxt-color-text-primary);
}

.yxt-StandardCard-wrapper {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.yxt-StandardCard-ordinalWrapper {
  margin-right: calc(var(--yxt-standard-card-base-spacing) * 3 / 4);
}

.yxt-StandardCard-ordinal {
  padding: calc(var(--yxt-standard-card-ordinal-size) * 3 / 16)
    calc(var(--yxt-standard-card-ordinal-size) * 1 / 8)
    calc(var(--yxt-standard-card-ordinal-size) * 5 / 16);
  border-radius: var(--yxt-standard-card-ordinal-size);
  min-width: calc(var(--yxt-standard-card-ordinal-size) * 7 / 8);
  height: calc(var(--yxt-standard-card-ordinal-size) * 5 / 8);
  background-color: var(--yxt-color-brand-primary);
  border: 0.0625rem solid #000;
  text-align: center;
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-standard-card-ordinal-font-size);
  line-height: var(--yxt-line-height-xs);
  font-weight: var(--yxt-font-weight-bold);
  font-style: normal;
  color: #fff;
}

.yxt-StandardCard-imgTagWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #000;
  padding-top: calc(var(--yxt-base-spacing-sm) / 4);
  padding-bottom: calc(var(--yxt-base-spacing-sm) / 4);
  position: relative;
  top: 10%;
  width: 80%;
  left: 10%;
}

.yxt-StandardCard-img,
.yxt-StandardCard-imgTag {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.yxt-StandardCard-imgTag {
  text-align: center;
  letter-spacing: 0.03125rem;
  text-transform: uppercase;
  word-break: break-word;
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-font-size-md);
  line-height: 1.25rem;
  font-weight: var(--yxt-font-weight-semibold);
  font-style: normal;
  color: #fff;
}

.yxt-StandardCard-img {
  height: calc(var(--yxt-standard-card-base-spacing) * 10);
  min-width: calc(var(--yxt-standard-card-base-spacing) * 10);
  max-width: calc(var(--yxt-standard-card-base-spacing) * 15);
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 0;
  margin-bottom: calc(var(--yxt-standard-card-base-spacing) / 2);
  margin-right: var(--yxt-standard-card-base-spacing);
}

.yxt-StandardCard-contentWrapper {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 3;
  -ms-flex-positive: 3;
  flex-grow: 3;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.yxt-StandardCard-title,
.yxt-StandardCard-titleLink {
  font-weight: var(--yxt-font-weight-bold);
  color: var(--yxt-color-text-primary);
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-font-size-md-lg);
  line-height: var(--yxt-line-height-lg);
  font-style: normal;
}

.yxt-StandardCard-titleLink {
  text-decoration: none;
  display: block;
  font-weight: var(--yxt-font-weight-semibold);
  color: var(--yxt-standard-card-link-color);
}

.yxt-StandardCard-titleLink:focus,
.yxt-StandardCard-titleLink:hover {
  color: var(--yxt-standard-card-link-hover-color);
  text-decoration: underline;
}

.yxt-StandardCard-subtitle {
  padding-bottom: calc(var(--yxt-standard-card-base-spacing) / 2);
  text-decoration: none;
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-font-size-md);
  line-height: 1.25rem;
  font-weight: var(--yxt-font-weight-normal);
  font-style: normal;
  color: var(--yxt-standard-card-subtitle-color);
}

.yxt-StandardCard-content,
.yxt-StandardCard-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
}

.yxt-StandardCard-content {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.yxt-StandardCard-details {
  min-width: calc(var(--yxt-standard-card-base-spacing) * 6);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: calc(var(--yxt-standard-card-base-spacing) / 2);
  margin-right: var(--yxt-standard-card-base-spacing);
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
  word-break: break-word;
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-font-size-md);
  line-height: var(--yxt-line-height-md);
  font-weight: var(--yxt-font-weight-normal);
  font-style: normal;
  color: var(--yxt-color-text-primary);
}

.yxt-StandardCard-details--noImage {
  -ms-flex-preferred-size: calc(var(--yxt-standard-card-base-spacing) * 16);
  flex-basis: calc(var(--yxt-standard-card-base-spacing) * 16);
}

@media (max-width: 47.9375rem) {
  .yxt-StandardCard-details {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
}

.yxt-StandardCard-toggle {
  text-align: left;
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-font-size-md);
  line-height: var(--yxt-line-height-md);
  font-weight: var(--yxt-font-weight-normal);
  font-style: italic;
  color: var(--yxt-color-brand-primary);
  background: 0 0;
  border: 0.0625rem solid transparent;
  border-radius: 0.25rem;
  padding: 0;
}

.yxt-StandardCard-toggle:not(:disabled) {
  cursor: pointer;
}

.yxt-StandardCard-toggle:not(:disabled):hover {
  text-decoration: underline;
}

.yxt-StandardCard-toggle:not(:disabled):focus {
  color: var(--yxt-color-brand-hover);
  border: 0.0625rem solid var(--yxt-color-brand-hover);
}

.yxt-StandardCard-ctas {
  min-width: calc(var(--yxt-standard-card-base-spacing) * 8);
  width: 30%;
  -ms-flex-item-align: start;
  align-self: flex-start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
}

:root {
  --yxt-result-background-color: #fff;
  --yxt-result-event-background-color: var(--yxt-color-background-highlight);
  --yxt-result-day-font-size: var(--yxt-font-size-xxlg);
  --yxt-result-day-line-height: var(--yxt-line-height-sm);
  --yxt-result-day-font-weight: var(--yxt-font-weight-semibold);
  --yxt-result-day-color: var(--yxt-color-text-primary);
  --yxt-result-month-font-size: var(--yxt-font-size-md);
  --yxt-result-month-line-height: var(--yxt-line-height-md);
  --yxt-result-month-font-weight: var(--yxt-font-weight-normal);
  --yxt-result-month-color: var(--yxt-color-text-primary);
  --yxt-result-ordinal-background-color: var(--yxt-color-brand-primary);
  --yxt-result-ordinal-font-size: var(--yxt-font-size-xs);
  --yxt-result-ordinal-line-height: var(--yxt-line-height-xs);
  --yxt-result-ordinal-font-weight: var(--yxt-font-weight-bold);
  --yxt-result-ordinal-color: #fff;
  --yxt-result-details-font-size: var(--yxt-font-size-md);
  --yxt-result-details-line-height: var(--yxt-line-height-md);
  --yxt-result-details-font-weight: var(--yxt-font-weight-normal);
  --yxt-result-details-color: var(--yxt-color-text-primary);
  --yxt-result-title-font-size: var(--yxt-font-size-lg);
  --yxt-result-title-line-height: var(--yxt-line-height-lg);
  --yxt-result-title-font-weight: var(--yxt-font-weight-semibold);
  --yxt-result-title-color: var(--yxt-color-text-primary);
  --yxt-result-subtitle-font-size: var(--yxt-font-size-md);
  --yxt-result-subtitle-line-height: var(--yxt-line-height-md);
  --yxt-result-subtitle-font-weight: var(--yxt-font-weight-normal);
  --yxt-result-subtitle-color: var(--yxt-color-text-secondary);
  --yxt-result-cta-font-size: var(--yxt-font-size-md);
  --yxt-result-cta-line-height: var(--yxt-line-height-md);
  --yxt-result-cta-font-weight: var(--yxt-font-weight-semibold);
  --yxt-result-cta-color: var(--yxt-color-link-primary);
}

.yxt-Result {
  list-style: none;
}

.yxt-Result + .yxt-Result {
  border-top: var(--yxt-border-default);
}

.yxt-Result-title {
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-result-title-font-size);
  line-height: var(--yxt-result-title-line-height);
  font-weight: var(--yxt-result-title-font-weight);
  font-style: normal;
  color: var(--yxt-result-title-color);
  margin-top: 0;
  margin-bottom: calc(var(--yxt-base-spacing) / 2);
}

.yxt-Result-titleLink {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.yxt-Result-titleLink:link,
.yxt-Result-titleLink:visited {
  color: var(--yxt-color-brand-primary);
  text-decoration: none;
}

.yxt-Result-titleLink:active,
.yxt-Result-titleLink:focus,
.yxt-Result-titleLink:hover {
  color: var(--yxt-color-brand-primary);
  text-decoration: underline;
}

@media (max-width: 47.9375rem) {
  .yxt-Result-titleLink {
    display: inline;
  }
}

.yxt-Result-ordinalWrapper {
  margin-right: var(--yxt-base-spacing-sm);
}

.yxt-Result-ordinal {
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-result-ordinal-font-size);
  line-height: var(--yxt-result-ordinal-line-height);
  font-weight: var(--yxt-result-ordinal-font-weight);
  font-style: normal;
  color: var(--yxt-result-ordinal-color);
  background-color: var(--yxt-result-ordinal-background-color);
  height: 1.125rem;
  width: 1.125rem;
  border-radius: 50%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 0.1875rem;
}

.yxt-Result-imageWrapper,
.yxt-Result-ordinal,
.yxt-Result-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.yxt-Result-wrapper {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  max-width: 100%;
  padding: var(--yxt-base-spacing);
  background-color: var(--yxt-result-background-color);
}

.yxt-Result-imageWrapper {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 6.25rem;
  min-width: 6.25rem;
  margin-right: 1rem;
}

.yxt-Result-image {
  width: 100%;
}

.yxt-Result-info {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
}

@media (max-width: 47.9375rem) {
  .yxt-Result-info {
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
}

.yxt-Result-infoCtasWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
}

@media (max-width: 47.9375rem) {
  .yxt-Result-infoCtasWrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.yxt-Result-details {
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-result-details-font-size);
  line-height: var(--yxt-result-details-line-height);
  font-weight: var(--yxt-result-details-font-weight);
  font-style: normal;
  color: var(--yxt-result-details-color);
}

.yxt-Result-ctasWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: top;
  -ms-flex-align: top;
  align-items: top;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

@media (min-width: 48rem) {
  .yxt-Result-ctasWrapper {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-left: calc(var(--yxt-base-spacing-sm) * 2);
  }
}

@media (max-width: 47.9375rem) {
  .yxt-Result-ctasWrapper {
    margin-top: calc(var(--yxt-base-spacing) / 2);
  }
}

.yxt-Result-ctas {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media (max-width: 47.9375rem) {
  .yxt-Result-ctas {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.yxt-Result-cta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  padding: calc(var(--yxt-base-spacing) / 2);
  border-radius: 0.25rem;
  min-width: 3.75rem;
  text-align: center;
}

.yxt-Result-cta:link,
.yxt-Result-cta:visited {
  color: var(--yxt-color-brand-primary);
  text-decoration: none;
}

.yxt-Result-cta:active,
.yxt-Result-cta:focus,
.yxt-Result-cta:hover {
  color: var(--yxt-color-brand-primary);
  text-decoration: underline;
}

@media (max-width: 47.9375rem) {
  .yxt-Result-cta {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    padding-left: 0;
  }
}

.yxt-Result-cta + .yxt-Result-cta {
  margin-left: calc(var(--yxt-base-spacing) / 2);
}

.yxt-Result-ctaIconWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: calc(var(--yxt-base-spacing) * 2);
  padding-right: calc(var(--yxt-base-spacing) * 2);
  margin-bottom: calc(var(--yxt-base-spacing) / 2);
}

@media (max-width: 47.9375rem) {
  .yxt-Result-ctaIconWrapper {
    padding-left: 0;
    padding-right: 0;
    margin-right: 0.5rem;
    margin-bottom: 0;
  }
}

.yxt-Result-ctaIcon {
  width: 1.5rem;
  height: 1.5rem;
}

.yxt-Result-ctaLabel {
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-result-cta-font-size);
  line-height: var(--yxt-result-cta-line-height);
  font-weight: var(--yxt-result-cta-font-weight);
  font-style: normal;
  color: var(--yxt-result-cta-color);
  letter-spacing: 0.03125rem;
  text-transform: uppercase;
}

.yxt-Result-dateWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-left: calc(var(--yxt-base-spacing) * 2);
  margin-right: calc(var(--yxt-base-spacing) * 3);
  padding-left: var(--yxt-base-spacing);
  padding-right: var(--yxt-base-spacing);
}

@media (max-width: 47.9375rem) {
  .yxt-Result-dateWrapper {
    display: none;
  }
}

.yxt-Result-dateInnerWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 0.5rem;
  padding: calc(var(--yxt-base-spacing) / 4);
  text-align: center;
  background-color: var(--yxt-result-event-background-color);
}

.yxt-Result-day,
.yxt-Result-month,
.yxt-Result-subtitle {
  font-size: var(--yxt-result-day-font-size);
  line-height: var(--yxt-result-day-line-height);
  font-weight: var(--yxt-result-day-font-weight);
  color: var(--yxt-result-day-color);
  font-family: var(--yxt-font-family);
  font-style: normal;
}

.yxt-Result-month,
.yxt-Result-subtitle {
  font-size: var(--yxt-result-month-font-size);
  line-height: var(--yxt-result-month-line-height);
  font-weight: var(--yxt-result-month-font-weight);
  color: var(--yxt-result-month-color);
}

.yxt-Result-subtitle {
  font-size: var(--yxt-result-subtitle-font-size);
  line-height: var(--yxt-result-subtitle-line-height);
  font-weight: var(--yxt-result-subtitle-font-weight);
  color: var(--yxt-result-subtitle-color);
  margin-bottom: calc(var(--yxt-base-spacing) / 2);
}

.yxt-Result-features {
  list-style: disc;
  padding-left: 1.125rem;
}

:root {
  --yxt-standard-card-cta-icon-size: calc(var(--yxt-base-spacing) * 1.125);
  --yxt-standard-card-cta-icon-size-solo: calc(var(--yxt-base-spacing) * 1.5);
  --yxt-standard-card-cta-spacing: var(--yxt-base-spacing);
  --yxt-accordion-card-cta-spacing: var(--yxt-base-spacing);
  --yxt-accordion-card-cta-icon-size: var(--yxt-base-spacing);
  --yxt-accordion-card-cta-font-size: var(--yxt-font-size-md);
  --yxt-accordion-card-cta-font-weight: var(--yxt-font-weight-normal);
  --yxt-accordion-card-cta-color: var(--yxt-color-brand-primary);
}

.yxt-CTA {
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  word-break: break-word;
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-font-size-md);
  line-height: var(--yxt-line-height-xs);
  font-weight: var(--yxt-font-weight-semibold);
  font-style: normal;
  color: var(--yxt-color-brand-primary);
}

.yxt-CTA:active,
.yxt-CTA:focus,
.yxt-CTA:hover {
  text-decoration: underline;
}

.yxt-CTA .Icon svg {
  height: calc(var(--yxt-base-spacing) * 1.125);
  width: calc(var(--yxt-base-spacing) * 1.125);
}

.yxt-CTA--StandardCard {
  margin-bottom: calc(var(--yxt-standard-card-cta-spacing) * 0.5);
}

.yxt-CTA--StandardCard .Icon {
  height: var(--yxt-standard-card-cta-icon-size);
  width: var(--yxt-standard-card-cta-icon-size);
  margin-right: calc(var(--yxt-base-spacing) / 2);
}

@media (min-width: 48rem) {
  .yxt-CTA--StandardCard.yxt-CTA--solo {
    text-align: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .yxt-CTA--StandardCard.yxt-CTA--solo .Icon {
    height: var(--yxt-standard-card-cta-icon-size-solo);
    width: var(--yxt-standard-card-cta-icon-size-solo);
    margin-bottom: calc(var(--yxt-standard-card-cta-spacing) / 2);
    margin-right: 0;
  }
}

.yxt-CTA--AccordionCard {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-accordion-card-cta-font-size);
  line-height: var(--yxt-line-height-xs);
  font-weight: var(--yxt-accordion-card-cta-font-weight);
  font-style: normal;
  color: var(--yxt-accordion-card-cta-color);
}

.yxt-CTA--AccordionCard .Icon {
  height: var(--yxt-accordion-card-cta-icon-size);
  width: var(--yxt-accordion-card-cta-icon-size);
  margin-right: calc(var(--yxt-accordion-card-cta-spacing) * 0.5);
}

:root {
  --yxt-cards-margin: calc(var(--yxt-base-spacing) / 2);
}

.yxt-Card,
.yxt-Card-placeholder {
  min-width: var(--yxt-cards-min-width);
  border: var(--yxt-border-default);
  margin-right: var(--yxt-cards-margin);
}

.yxt-Card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: var(--yxt-cards-margin);
}

.yxt-Card-placeholder {
  visibility: hidden;
  border-top: none;
  border-bottom: none;
}

.yxt-Card-child {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  min-height: 0.0625rem;
}

.yxt-Card--universal + .yxt-Card--universal {
  border-top: 0;
}

.yxt-Card _:-ms-fullscreen,
:root .yxt-Card {
  padding-left: 0.0625rem;
}

@media (max-width: 47.9375rem) {
  .yxt-Card {
    margin-bottom: 0;
  }

  .yxt-Card + .yxt-Card {
    border-top: 0;
  }
}

.yxt-Results-items > .yxt-Card,
.yxt-Results-items > .yxt-Card-placeholder {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.yxt-Results-items--1 > .yxt-Card {
  margin-right: 0;
}

@media (min-width: 47.9375rem) {
  .yxt-Results-items--2
    > .yxt-Card
    .yxt-Results-items--2
    > .yxt-Card-placeholder {
    -ms-flex-preferred-size: 45%;
    flex-basis: 45%;
  }

  .yxt-Results-items--3 > .yxt-Card,
  .yxt-Results-items--3 > .yxt-Card-placeholder {
    -ms-flex-preferred-size: 30%;
    flex-basis: 30%;
  }

  .yxt-Results-items--4 > .yxt-Card,
  .yxt-Results-items--4 > .yxt-Card-placeholder {
    -ms-flex-preferred-size: 22%;
    flex-basis: 22%;
  }
}

.yxt-Card--universal {
  border: var(--yxt-border-default);
  border-top: 0;
  margin: 0;
}

:root {
  --yxt-accordion-card-spacing: var(--yxt-base-spacing);
  --yxt-accordion-card-background-color: #fff;
  --yxt-accordion-card-content-background-color: #fff;
  --yxt-accordion-card-toggle-icon-height: var(--yxt-accordion-card-spacing);
  --yxt-accordion-card-toggle-icon-width: calc(
    var(--yxt-accordion-card-spacing) * 7 / 10
  );
  --yxt-accordion-card-title-size: var(--yxt-font-size-md-lg);
  --yxt-accordion-card-title-color: var(--yxt-color-brand-primary);
  --yxt-accordion-card-title-color-hover: var(--yxt-color-background-highlight);
  --yxt-accordion-card-title-line-height: var(--yxt-line-height-lg);
  --yxt-accordion-card-title-weight: var(--yxt-font-weight-normal);
  --yxt-accordion-card-subtitle-size: var(--yxt-font-size-md);
  --yxt-accordion-card-subtitle-color: var(--yxt-color-text-secondary);
  --yxt-accordion-card-details-size: var(--yxt-font-size-md);
  --yxt-accordion-card-details-weight: var(--yxt-font-weight-normal);
  --yxt-accordion-card-details-line-height: calc(
    var(--yxt-accordion-card-details-size) * 10 / 7
  );
  --yxt-accordion-card-details-color: var(--yxt-color-text-primary);
  --yxt-accordion-card-margin: calc(var(--yxt-base-spacing) / 2);
  --yxt-accordion-card-border: var(--yxt-border-default);
  --yxt-accordion-card-background-color--expanded: var(--yxt-base-spacing);
  --yxt-accordion-card-spacing--expanded: var(--yxt-base-spacing);
}

.yxt-AccordionCard {
  background-color: var(--yxt-accordion-card-background-color);
}

@media (max-width: 47.9375rem) {
  .yxt-AccordionCard {
    margin-bottom: 0;
    border-top: 0;
  }
}

.yxt-AccordionCard-toggle {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  background: 0 0;
  border: 0;
  padding: 0;
  padding-right: calc(var(--yxt-accordion-card-spacing) * 0.5);
  -webkit-tap-highlight-color: transparent;
}

.yxt-AccordionCard-toggle:focus,
.yxt-AccordionCard-toggle:hover {
  background-color: var(--yxt-accordion-card-title-color-hover);
}

.yxt-AccordionCard-toggle svg {
  height: var(--yxt-accordion-card-toggle-icon-height);
  width: var(--yxt-accordion-card-toggle-icon-width);
  color: var(--yxt-color-brand-primary);
}

.yxt-AccordionCard-title {
  margin: calc(var(--yxt-accordion-card-spacing) / 2)
    var(--yxt-accordion-card-spacing)
    calc(var(--yxt-accordion-card-spacing) * 5 / 8);
  text-align: left;
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-accordion-card-title-size);
  line-height: var(--yxt-accordion-card-title-line-height);
  font-weight: var(--yxt-accordion-card-title-weight);
  font-style: normal;
  color: var(--yxt-accordion-card-title-color);
}

.yxt-AccordionCard-icon {
  margin-left: auto;
  margin-right: calc(var(--yxt-accordion-card-spacing) / 2);
  margin-top: calc(var(--yxt-accordion-card-spacing) * 0.75);
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.yxt-AccordionCard-body,
.yxt-AccordionCard-icon svg {
  -webkit-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
}

.yxt-AccordionCard-icon svg {
  height: 0.75rem;
  width: 0.5rem;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.yxt-AccordionCard-body {
  overflow: hidden;
}

.yxt-AccordionCard-content {
  padding: calc(var(--yxt-accordion-card-spacing) / 2)
    var(--yxt-accordion-card-spacing);
  height: 0;
}

.yxt-AccordionCard-subtitle {
  font-size: var(--yxt-accordion-card-subtitle-size);
  line-height: var(--yxt-line-height-xs);
  font-weight: var(--yxt-font-weight-normal);
  color: var(--yxt-accordion-card-subtitle-color);
}

.yxt-AccordionCard-details,
.yxt-AccordionCard-subtitle {
  font-family: var(--yxt-font-family);
  font-style: normal;
  padding-bottom: calc(var(--yxt-accordion-card-spacing) / 2);
}

.yxt-AccordionCard-details {
  font-size: var(--yxt-accordion-card-details-size);
  line-height: var(--yxt-accordion-card-details-line-height);
  font-weight: var(--yxt-accordion-card-details-weight);
  color: var(--yxt-accordion-card-details-color);
}

.yxt-AccordionCard-ctas .yxt-CTACollection-item {
  padding-bottom: calc(var(--yxt-accordion-card-spacing) / 2);
}

.yxt-AccordionCard--expanded {
  background-color: var(--yxt-accordion-card-background-color--expanded);
}

.yxt-AccordionCard--expanded .yxt-AccordionCard-icon svg {
  height: 0.75rem;
  width: 0.5rem;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.yxt-AccordionCard--expanded .yxt-AccordionCard-body,
.yxt-AccordionCard--expanded .yxt-AccordionCard-icon svg {
  -webkit-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
}

.yxt-AccordionCard--expanded .yxt-AccordionCard-content {
  height: auto;
  overflow: hidden;
}

.yxt-AccordionCard--expanded .yxt-AccordionCard-ctas {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

:root {
  --yxt-results-header-font-size: var(--yxt-font-size-md);
  --yxt-results-header-spacing: var(--yxt-base-spacing);
  --yxt-results-header-color: var(--yxt-color-text-secondary);
  --yxt-results-header-font-weight: var(--yxt-font-weight-normal);
  --yxt-results-header-line-height: var(--yxt-line-height-md);
  --yxt-results-header-margin-bottom: calc(var(--yxt-base-spacing) / 4);
  --yxt-results-header-count-color: var(--yxt-color-text-secondary);
  --yxt-results-header-count-weight: var(--yxt-font-weight-bold);
  --yxt-results-header-filters-font-size: var(--yxt-font-size-md);
  --yxt-results-header-filters-color: var(--yxt-color-text-secondary);
  --yxt-results-header-filters-line-height: var(--yxt-line-height-md);
  --yxt-results-header-universal-background: var(--yxt-color-brand-white);
}

.yxt-ResultsHeader,
.yxt-ResultsHeader-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}

.yxt-ResultsHeader {
  padding: calc(var(--yxt-results-header-spacing) / 4)
    var(--yxt-results-header-spacing);
  padding-bottom: 0;
}

.yxt-ResultsHeader-wrapper {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.yxt-ResultsHeader-resultsCount {
  margin-right: calc(var(--yxt-results-header-spacing) / 4);
  margin-bottom: 0.25rem;
  white-space: nowrap;
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-results-header-font-size);
  line-height: var(--yxt-results-header-line-height);
  font-weight: var(--yxt-results-header-count-weight);
  font-style: normal;
  color: var(--yxt-results-header-count-color);
}

.yxt-ResultsHeader-appliedFilters {
  margin-right: var(--yxt-results-header-spacing);
  border-top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.yxt-ResultsHeader-filterLabel {
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-results-header-filters-font-size);
  line-height: var(--yxt-results-header-filters-line-height);
  font-weight: var(--yxt-font-weight-normal);
  font-style: normal;
  color: var(--yxt-results-header-filters-color);
}

.yxt-ResultsHeader-filterLabel,
.yxt-ResultsHeader-filterValue {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: calc(var(--yxt-results-header-spacing) / 4);
  margin-bottom: 0.25rem;
}

.yxt-ResultsHeader-filterSeparator,
.yxt-ResultsHeader-filterValueComma,
.yxt-ResultsHeader-filterValueText,
.yxt-ResultsHeader-resultsCountSeparator {
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-results-header-filters-font-size);
  line-height: var(--yxt-results-header-filters-line-height);
  font-weight: var(--yxt-font-weight-normal);
  font-style: italic;
  color: var(--yxt-results-header-filters-color);
}

.yxt-ResultsHeader-filterSeparator,
.yxt-ResultsHeader-resultsCountSeparator {
  margin-right: calc(var(--yxt-results-header-spacing) / 4);
  margin-bottom: calc(var(--yxt-results-header-spacing) / 4);
  font-style: normal;
}

.yxt-ResultsHeader-changeFilters {
  margin-left: calc(var(--yxt-results-header-spacing) / 2);
  margin-bottom: calc(var(--yxt-results-header-spacing) / 4);
  padding-right: var(--yxt-results-header-spacing);
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-results-filters-link-font-size);
  line-height: var(--yxt-results-filters-link-line-height);
  font-weight: var(--yxt-results-filters-link-font-weight);
  font-style: normal;
  color: var(--yxt-color-brand-primary);
}

.yxt-ResultsHeader-changeFilters:active,
.yxt-ResultsHeader-changeFilters:focus,
.yxt-ResultsHeader-changeFilters:hover,
.yxt-ResultsHeader-changeFilters:link,
.yxt-ResultsHeader-changeFilters:visited {
  color: var(--yxt-color-brand-primary);
  text-decoration: underline;
}

.yxt-ResultsHeader-removableFilterTag {
  background-color: var(--yxt-color-borders);
  border-radius: 0.125rem;
  border-width: 0;
  margin-bottom: 0.25rem;
  padding-left: 0.3125rem;
  padding-right: 0.25rem;
  margin-right: calc(var(--yxt-results-header-spacing) / 2);
  white-space: nowrap;
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-font-size-sm);
  line-height: 1.25rem;
  font-weight: var(--yxt-font-weight-normal);
  font-style: italic;
  color: var(--yxt-color-text-neutral);
}

.yxt-ResultsHeader-removableFilterTag:focus,
.yxt-ResultsHeader-removableFilterTag:hover {
  color: var(--yxt-color-brand-white);
  background-color: var(--yxt-color-text-secondary);
  cursor: pointer;
}

.yxt-ResultsHeader-removableFilterX {
  font-style: normal;
}

.yxt-ResultsHeader--universal {
  margin-bottom: 0;
  border: var(--yxt-border-default);
  border-top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background-color: var(--yxt-results-header-universal-background);
}

.yxt-ResultsHeader--removable .yxt-ResultsHeader-filterLabel,
.yxt-ResultsHeader--removable .yxt-ResultsHeader-filterSeparator,
.yxt-ResultsHeader--removable .yxt-ResultsHeader-filterValue,
.yxt-ResultsHeader--removable .yxt-ResultsHeader-resultsCount,
.yxt-ResultsHeader--removable .yxt-ResultsHeader-resultsCountSeparator {
  margin-right: calc(var(--yxt-results-header-spacing) / 2);
}

.yxt-VerticalResultsCount {
  white-space: nowrap;
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-font-size-md);
  line-height: var(--yxt-line-height-md);
  font-weight: var(--yxt-font-weight-bold);
  font-style: normal;
  color: var(--yxt-color-text-secondary);
}

.yxt-AppliedFilters {
  border-top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.yxt-AppliedFilters-filterLabel,
.yxt-AppliedFilters-filterSeparator,
.yxt-AppliedFilters-filterValue {
  margin-right: calc(var(--yxt-base-spacing) / 4);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.yxt-AppliedFilters-filterLabel {
  color: var(--yxt-color-text-secondary);
}

.yxt-AppliedFilters-filterLabel,
.yxt-AppliedFilters-filterSeparator,
.yxt-AppliedFilters-filterValueComma,
.yxt-AppliedFilters-filterValueText {
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-font-size-md);
  line-height: var(--yxt-line-height-md);
  font-weight: var(--yxt-font-weight-normal);
  font-style: normal;
}

.yxt-AppliedFilters-filterValueComma,
.yxt-AppliedFilters-filterValueText {
  color: var(--yxt-color-text-secondary);
  font-style: italic;
}

.yxt-AppliedFilters-filterSeparator {
  color: var(--yxt-color-text-secondary);
}

.yxt-AppliedFilters-removableFilterTag {
  background-color: var(--yxt-color-borders);
  border-radius: 0.125rem;
  border-width: 0;
  margin-bottom: 0.25rem;
  padding-left: 0.3125rem;
  padding-right: 0.25rem;
  margin-right: calc(var(--yxt-base-spacing) / 2);
  white-space: nowrap;
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-font-size-sm);
  line-height: 1.25rem;
  font-weight: var(--yxt-font-weight-normal);
  font-style: italic;
  color: var(--yxt-color-text-neutral);
}

.yxt-AppliedFilters-removableFilterTag:focus,
.yxt-AppliedFilters-removableFilterTag:hover {
  color: var(--yxt-color-brand-white);
  background-color: var(--yxt-color-text-secondary);
  cursor: pointer;
}

.yxt-AppliedFilters-removableFilterX {
  font-style: normal;
}

.swal-overlay .swal-modal {
  -webkit-animation: none;
  animation: none;
}

.swal-overlay .swal-text {
  color: #000;
}

.swal-overlay .swal-button,
.swal-overlay .swal-text {
  font-family: var(--yxt-font-family);
  font-size: var(--yxt-font-size-md-lg);
  line-height: var(--yxt-line-height-xs);
  font-weight: var(--yxt-font-weight-normal);
  font-style: normal;
}

.swal-overlay .swal-button {
  color: #fff;
  border: 0;
  border-radius: 0.1875rem;
  margin-top: 1rem;
  background: var(--yxt-color-brand-primary);
  letter-spacing: 0.03125rem;
  text-transform: uppercase;
}

.swal-overlay .swal-button:not(:disabled) {
  cursor: pointer;
}

.swal-overlay .swal-button:not(:disabled):focus,
.swal-overlay .swal-button:not(:disabled):hover {
  background: padding-box var(--yxt-color-brand-hover);
}

.swal-overlay .swal-button:not(:disabled):focus {
  border: var(--yxt-button-focus-border-size) double #0c5ecb;
  border: 0;
  background: var(--yxt-color-brand-hover);
}

.white {
  color: #fff;
}

.bg-white {
  background-color: #fff;
}

.white90 {
  color: rgba(255, 255, 255, 0.9);
}

.bg-white90 {
  background-color: rgba(255, 255, 255, 0.9);
}

.black {
  color: #000;
}

.bg-black {
  background-color: #000;
}

.black10 {
  color: rgba(0, 0, 0, 0.1);
}

.bg-black10 {
  background-color: rgba(0, 0, 0, 0.1);
}

.black15 {
  color: rgba(0, 0, 0, 0.15);
}

.bg-black15 {
  background-color: rgba(0, 0, 0, 0.15);
}

.black25 {
  color: rgba(0, 0, 0, 0.25);
}

.bg-black25 {
  background-color: rgba(0, 0, 0, 0.25);
}

.black30 {
  color: rgba(0, 0, 0, 0.3);
}

.bg-black30 {
  background-color: rgba(0, 0, 0, 0.3);
}

.black35 {
  color: rgba(0, 0, 0, 0.35);
}

.bg-black35 {
  background-color: rgba(0, 0, 0, 0.35);
}

.black50 {
  color: rgba(0, 0, 0, 0.5);
}

.bg-black50 {
  background-color: rgba(0, 0, 0, 0.5);
}

.black70 {
  color: rgba(0, 0, 0, 0.7);
}

.bg-black70 {
  background-color: rgba(0, 0, 0, 0.7);
}

.black75 {
  color: rgba(0, 0, 0, 0.75);
}

.bg-black75 {
  background-color: rgba(0, 0, 0, 0.75);
}

.boulder {
  color: #767676;
}

.bg-boulder {
  background-color: #767676;
}

.regal-blue,
sup.footnote {
  color: #00387a;
}

.bg-regal-blue {
  background-color: #00387a;
}

.mercury {
  color: #e3e3e3;
}

.bg-mercury {
  background-color: #e3e3e3;
}

.cloudy {
  color: #aeaca6;
}

.bg-cloudy {
  background-color: #aeaca6;
}

.shiraz {
  color: #a80432;
}

.bg-shiraz {
  background-color: #a80432;
}

.gallery {
  color: #efefef;
}

.bg-gallery {
  background-color: #efefef;
}

.transparent-shiraz {
  color: transparent;
}

.bg-transparent-shiraz {
  background-color: transparent;
}

.dove-gray {
  color: #6a6a6a;
}

.bg-dove-gray {
  background-color: #6a6a6a;
}

.cerulean {
  color: #009cd6;
}

.bg-cerulean {
  background-color: #009cd6;
}

.end-blue {
  color: #007fad;
}

.bg-end-blue {
  background-color: #007fad;
}

.robins-egg-blue {
  color: #00cfe0;
}

.bg-robins-egg-blue {
  background-color: #00cfe0;
}

.robins-egg-blue-20 {
  color: rgba(0, 207, 224, 0.2);
}

.bg-robins-egg-blue-20 {
  background-color: rgba(0, 207, 224, 0.2);
}

.anakiwa {
  color: #95f7ff;
}

.bg-anakiwa {
  background-color: #95f7ff;
}

.lunar-green {
  color: #373a36;
}

.bg-lunar-green {
  background-color: #373a36;
}

.desert-storm {
  color: #f4f4f2;
}

.bg-desert-storm {
  background-color: #f4f4f2;
}

.dusty-gray {
  color: #979797;
}

.bg-dusty-gray {
  background-color: #979797;
}

.spindle {
  color: #c8dfee;
}

.bg-spindle {
  background-color: #c8dfee;
}

.sky-blue {
  color: #70c9ea;
}

.bg-sky-blue {
  background-color: #70c9ea;
}

.silver {
  color: rgba(0, 0, 0, 0.2);
}

.bg-silver {
  background-color: rgba(0, 0, 0, 0.2);
}

.link-water {
  color: #e6f1f8;
}

.bg-link-water {
  background-color: #e6f1f8;
}

.quil-gray {
  color: #d0d0cf;
}

.bg-quil-gray {
  background-color: #d0d0cf;
}

.malachite {
  color: #00b316;
}

.bg-malachite {
  background-color: #00b316;
}

.cod-gray {
  color: #1c1c1c;
}

.bg-cod-gray {
  background-color: #1c1c1c;
}

.crystal-blue {
  color: #649cb0;
}

.bg-crystal-blue {
  background-color: #649cb0;
}

.bluegradient {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#003875),
    to(#00aad9)
  );
  background: linear-gradient(90deg, #003875, #00aad9);
}

.gradient {
  padding: 30px;
}

.redgradient {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#a80f1c),
    to(#7b0a1b)
  );
  background: linear-gradient(90deg, #a80f1c, #7b0a1b);
}

.lightbluegradient {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#00387a),
    to(#00b8e0)
  );
  background: linear-gradient(90deg, #00387a, #00b8e0);
}

.darkbluegradient {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#00387a),
    to(#4a90e2)
  );
  background: linear-gradient(90deg, #00387a, #4a90e2);
}

.crystalbluegradient {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#649cb0),
    to(#d0d0cf)
  );
  background: linear-gradient(90deg, #649cb0, #d0d0cf);
}

*,
:after,
:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}



.form,
.legend,
a,
abbr,
address,
article,
aside,
audio,
b,
blockquote,
body,
caption,
cite,
data,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
html,
i,
iframe,
img,
ins,
kdb,
label,
legend,
main,
mark,
math,
menu,
nav,
object,
ol,
output,
pre,
q,
ruby,
s,
samp,
section,
span,
strong,
sub,
summary,
sup,
svg,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
}

hr,
p {
  padding: 0;
  border: 0;
}

code {
  margin: 0;
  border: 0;
}

button {
  margin: 0;
  padding: 0;
}

button,
code,
hr,
p,
textarea {
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
}

article,
aside,
figcaption,
figure,
footer,
header,
main,
nav,
picture img,
section {
  display: block;
  background-color: white;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type="search"] {
  -webkit-appearance: none;
  -moz-appearance: none;
}

body {
  line-height: 1.5;
  color: #000;
}

.i-twitter-after:after,
.i-twitter:before {
  font-family: "borror";
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  text-decoration: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.i-twitter,
.i-twitter-after {
  text-decoration: none;
  overflow: hidden;
}

.module {
  z-index: 1;
}

.grid--side-pad {
  padding-right: 30px;
  padding-left: 30px;
}

.grid:after:after {
  clear: both;
  content: " ";
  display: table;
}

[class^="col-"] {
  float: left;
}

.main {
  overflow: hidden;
  padding-top: 145px;
  position: relative;
  z-index: 1;
}

.main--atm-locator {
  padding-top: 45px;
}

.main--no-nav {
  padding-top: 0;
}

.main--loading {
  z-index: 10001;
}

.main--loading::after {
  background: 0 0;
  position: fixed;
  width: 100%;
  height: 100%;
  content: " ";
  display: block;
  z-index: 10001;
  top: 0;
  left: 0;
}

.main--loading-dark {
  position: relative;
  z-index: 1;
}

.main--loading-dark:after {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  content: " ";
  display: block;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  -webkit-transform: translateX(-20px);
  transform: translateX(-20px);
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  margin: 0;
  top: 35%;
  z-index: 3;
}

.main--loading-dark:before {
  display: block;
  content: " ";
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  width: 100%;
  height: 100%;
  top: -10px;
  left: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  opacity: 1;
  z-index: 3;
  pointer-events: none;
}

.main--loading-dark::after {
  top: 45vh;
}

.col {
  padding-right: 8px;
  width: 16.66667%;
}

.col--mobile-6,
.col-mobile-6 {
  position: relative;
  padding-right: 0;
  width: 100%;
}

.col--12,
fieldset {
  width: 100%;
}

@media all and (min-width: 900px) {
  .main {
    padding-top: 106px;
  }

  .main--no-sub {
    padding-top: 61px;
  }

  .main--tall-nav {
    padding-top: 116px;
  }

  .main--no-nav {
    padding-top: 0;
  }

  .col {
    max-width: 1060px;
  }

  .col-desktop-3,
  .col-desktop-9 {
    padding-right: 8px;
    width: 24.999%;
  }

  .col-desktop-9 {
    width: 74.997%;
  }

  .col-desktop-12,
  .two-col-desktop {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }

  .col-desktop-12 {
    padding-right: 0;
  }

  .col-desktop-12:after,
  .two-col-desktop:after {
    clear: both;
    content: " ";
    display: table;
  }

  .two-col-desktop {
    padding-left: 30px;
    padding-right: 30px;
  }

  .col-desktop--last {
    padding-right: 0;
  }
}

.h1,
.h2 {
  font-size: 2em;
  line-height: 1.14;
}

.h2 {
  font-size: 1.75em;
}

.h3 {
  font-family: "Montserrat", sans-serif;
  font-size: 1.125em;
  font-weight: 700;
}

.smooth {
  image-rendering: -moz-crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  image-rendering: optimizeQuality;
}

.h4,
.h5,
.h6,
h5 {
  font-size: 1em;
}

.p,
p {
  font-family: "Montserrat", sans-serif;
}

.p {
  margin-bottom: 15px;
}

p {
  margin: 0 0 15px;
}

.p:last-child,
p:last-child {
  margin-bottom: 0;
}

.body-img {
  display: block;
  max-width: 100%;
  margin: 0 auto 20px;
}

@media screen and (min-width: 768px) {
  .body-img {
    display: inline;
  }

  .body-img--right {
    float: right;
    margin: 5px 0 20px 20px;
  }
}

.h1 sup,
.h2 sup,
h1 sup,
h2 sup {
  font-size: 0.5em;
  -webkit-transform: none;
  transform: none;
  position: relative;
  top: 0;
  -webkit-transform: translateY(-70%);
  transform: translateY(-70%);
  font-weight: 400;
}

.sup-link,
a,
sup a,
sup a:visited {
  color: #00387a;
}

.a--small,
.a--small:hover {
  text-decoration: underline;
}

.a--small,
.p--small {
  font-size: 0.875em;
}

.indent-with-checkbox {
  padding-left: 29px;
}

.p--large,
h4 {
  font-size: 1.125em;
}

.p--no-margin-bottom {
  margin-bottom: 0;
}

.stacked-paragraphs p {
  margin: 0;
}

.b,
.body-link,
b,
strong {
  font-weight: 700;
}

.sb {
  font-weight: 600;
}

.generic-modal__body p,
.monster {
  font-family: "Montserrat", sans-serif;
}

.tilium {
  font-family: "Titillium Web", sans-serif;
}

@media all and (min-width: 1060px) {
  .h1 {
    font-size: 42px;
  }

  .h1.mobile {
    font-size: 2em;
  }

  .h2 {
    font-size: 44px;
  }

  .h2.mobile {
    font-size: 1.75em;
  }
}

.h-margin-centered {
  margin: 0 auto;
}

.h-text-caps {
  text-transform: uppercase;
}

.h-no-link-style {
  color: inherit;
  text-decoration: inherit;
}

.h-no-h-padding {
  padding-left: 0;
  padding-right: 0;
}

.h-no-v-padding {
  padding-top: 0;
  padding-bottom: 0;
}

.h-no-padding {
  padding: 0;
}

.h-padding-bottom {
  padding-bottom: 1.875em;
}

.h-vertical-padding {
  padding: 1.875em 0;
}

.h-padding-bottom-small {
  padding-bottom: 0.9375em;
}

.h-no-margin {
  margin: 0;
}

.h-relative {
  position: relative;
}

.h-table-fixed {
  table-layout: fixed;
}

.h-vertical-align-middle {
  vertical-align: middle;
}

.h-vertical-center {
  height: 100%;
}

.h-vertical-bottom:after,
.h-vertical-bottom:before,
.h-vertical-center:after,
.h-vertical-center:before {
  content: "";
  width: 1px;
  height: 100%;
  display: inline-block;
  vertical-align: middle;
}

.h-vertical-center > * {
  display: inline-block;
  vertical-align: middle;
  width: 95% !important;
  position: relative;
}

.h-vertical-bottom {
  height: 100%;
}

.h-vertical-bottom > * {
  display: inline-block;
  vertical-align: bottom;
  width: 95% !important;
  position: relative;
}

.h-no-overflow,
html.is-no-scroll {
  overflow: hidden;
}

.h-overflow-visible {
  overflow: visible !important;
}

.h-accessible-text {
  position: absolute;
  left: 999999px;
  top: -999999px;
}

.h-text-centered {
  text-align: center !important;
}

@media only screen and (min-width: 40em) {
  .h-text-centered-40 {
    text-align: center;
  }
}

.h-text-right {
  text-align: right;
}

.h-text-left {
  text-align: left;
}

@media only screen and (min-width: 75em) {
  .h-text-left-75 {
    text-align: left;
  }
}

@media only screen and (min-width: 60em) {
  .h-text-right-60 {
    text-align: right;
  }
}

.h-margin-top-10 {
  margin-top: 0.625em;
}

.h-margin-top-30 {
  margin-top: 1.875em;
}

.h-margin-top-60 {
  margin-top: 3.75em;
}

.h-block-link {
  display: block;
  text-decoration: none;
  color: inherit;
}

.i-twitter-after:after,
.i-twitter:before {
  content: "î¤•";
}

@media only screen and (min-width: 40em) {
  body {
    font-size: 1.125em;
  }
}

h1 {
  font-size: 1.5em;
}

@media only screen and (min-width: 40em) {
  h1 {
    font-size: 2em;
  }
}

h2 {
  font-size: 1.375em;
}

@media only screen and (min-width: 40em) {
  h2 {
    font-size: 1.875em;
  }
}

h3 {
  font-size: 1.25em;
}

@media only screen and (min-width: 40em) {
  h3 {
    font-size: 1.75em;
  }
}

@media only screen and (min-width: 40em) {
  h4 {
    font-size: 1.625em;
  }
}

@media only screen and (min-width: 40em) {
  h5 {
    font-size: 1.5em;
  }
}

h6 {
  font-size: 0.875em;
}

@media only screen and (min-width: 40em) {
  .legend,
  h6,
  legend {
    font-size: 1.375em;
  }
}

em,
i {
  font-style: italic;
}

sup {
  -webkit-transform: translateY(-4px);
  transform: translateY(-4px);
  font-size: 0.85em;
}

del {
  text-decoration: line-through;
}

img,
picture,
sup {
  display: inline-block;
}

.input-wrap--select {
  margin: 0;
  outline: 0;
}



textarea {
  margin: 0;
  outline: 0;
}

.input-wrap--select:-moz-placeholder,
.ui-select:-moz-placeholder,
input:-moz-placeholder[type="email"],
input:-moz-placeholder[type="password"],
input:-moz-placeholder[type="phone"],
input:-moz-placeholder[type="search"],
input:-moz-placeholder[type="tel"],
input:-moz-placeholder[type="text"],
textarea:-moz-placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.input-wrap--select:-ms-input-placeholder,
.ui-select:-ms-input-placeholder,
input:-ms-input-placeholder[type="email"],
input:-ms-input-placeholder[type="password"],
input:-ms-input-placeholder[type="phone"],
input:-ms-input-placeholder[type="search"],
input:-ms-input-placeholder[type="tel"],
input:-ms-input-placeholder[type="text"],
textarea:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.input-wrap--select::-webkit-input-placeholder,
.ui-select::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
input[type="phone"]::-webkit-input-placeholder,
input[type="search"]::-webkit-input-placeholder,
input[type="tel"]::-webkit-input-placeholder,
input[type="text"]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.input-wrap--select::-moz-placeholder,
.ui-select::-moz-placeholder,
input[type="email"]::-moz-placeholder,
input[type="password"]::-moz-placeholder,
input[type="phone"]::-moz-placeholder,
input[type="search"]::-moz-placeholder,
input[type="tel"]::-moz-placeholder,
input[type="text"]::-moz-placeholder,
textarea::-moz-placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.input-wrap--select::-ms-input-placeholder,
.ui-select::-ms-input-placeholder,
input[type="email"]::-ms-input-placeholder,
input[type="password"]::-ms-input-placeholder,
input[type="phone"]::-ms-input-placeholder,
input[type="search"]::-ms-input-placeholder,
input[type="tel"]::-ms-input-placeholder,
input[type="text"]::-ms-input-placeholder,
textarea::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.input-wrap--select::placeholder,
.ui-select::placeholder,
input[type="email"]::placeholder,
input[type="password"]::placeholder,
input[type="phone"]::placeholder,
input[type="search"]::placeholder,
input[type="tel"]::placeholder,
input[type="text"]::placeholder,
textarea::placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.input-wrap--select:focus,
.ui-select:focus,
input:focus[type="email"],
input:focus[type="password"],
input:focus[type="phone"],
input:focus[type="search"],
input:focus[type="tel"],
input:focus[type="text"],
textarea:focus {
  outline: solid #00387a;
}

.form::after,
form::after {
  opacity: 1;
  -webkit-transition: opacity 0.2px;
  transition: opacity 0.2px;
}

.form--busy,
form--busy {
  position: relative;
  pointer-events: none;
}

.form--busy:after,
form--busy:after {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  content: " ";
  display: block;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  -webkit-transform: translateX(-20px);
  transform: translateX(-20px);
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  margin: 0;
  top: 35%;
  z-index: 3;
}

.form--busy:before,
form--busy:before {
  display: block;
  content: " ";
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  width: 100%;
  height: 100%;
  top: -10px;
  left: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  opacity: 1;
  z-index: 3;
  pointer-events: none;
}

.form--busy button,
.form--busy input,
form--busy button,
form--busy input {
  pointer-events: none;
}

.form__bottom-right-buttons,
form__bottom-right-buttons {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-left: auto;
  margin-right: 0;
  margin-top: 30px;
  min-width: 31%;
  text-align: right;
}

.form__bottom-right-buttons > *,
form__bottom-right-buttons > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.form__bottom-right-buttons span,
form__bottom-right-buttons span {
  font-size: 0.875em;
  margin: 0 10px;
}

.hidden {
  display: none !important;
}

.datalist {
  font-family: "Titillium Web", sans-serif;
  -webkit-overflow-scrolling: touch;
  background: #fff;
  left: 0;
  max-height: 250px;
  overflow: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  text-align: left;
}

.datalist--in-page {
  width: calc(100% + 2px);
  border: 1px solid #979797;
  left: -1px;
}

.datalist__item-link {
  background: 0 0;
  border: 0;
  color: #373a36;
  display: block;
  padding: 10px 12px;
  width: 100%;
}

.datalist__item-link:active,
.datalist__item-link:focus {
  outline-color: #00387a;
}

.datalist__item-link span {
  font-weight: 700;
}

.datalist__item-link:active,
.datalist__item-link:focus,
.datalist__item-link:hover {
  background: #00387a;
  color: #fff;
}

.error {
  background: #a80432;
  color: #fff;
  font-size: 0.875em;
  line-height: 1.2;
  margin-top: 5px;
  padding: 3px 8px 5px;
  position: relative;
}

.error::after {
  border: solid transparent;
  border-color: transparent transparent #a80432;
  border-width: 8px;
  bottom: 100%;
  content: " ";
  height: 0;
  left: 10px;
  margin-left: -8px;
  pointer-events: none;
  position: absolute;
  width: 0;
}

.error[aria-hidden] {
  display: none;
}

.error--extra-pad {
  padding: 10px 8px;
}

.error--extra-pad::after {
  left: 20px;
}

.error--with-icon {
  font-size: 1em;
  margin-top: 25px;
  padding-left: 55px;
  position: relative;
}

.error--with-icon i,
.error--with-icon img,
.error--with-icon svg {
  left: 10px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 34px;
}

.error--inline {
  border-radius: 3px;
  display: inline-block;
  padding: 6px;
  width: auto;
}

.legend,
legend {
  max-width: 100%;
  display: block;
  font-size: 12px;
  font-weight: 700;
  padding-top: 15px;
}

.error--inline::after,
.legend--hidden,
legend--hidden {
  display: none;
}

.legend--inline,
legend--inline {
  display: inline;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  font-size: 1em;
  font-weight: 400;
  padding-right: 20px;
  padding-top: 0;
}

.legend--like-label {
  font-size: 1em;
  font-weight: 400;
  margin-bottom: 8px;
}

.field-wrap {
  margin-top: 18px;
  position: relative;
  width: 100%;
}

.field-wrap--third,
.field-wrap__note {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}

.field-wrap--third {
  padding: 0 10px;
}

.field-wrap__note {
  width: 100%;
}

.field-wrap:first-child {
  margin-top: 0;
}

.field-wrap--auto,
.field-wrap--search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.field-wrap--auto {
  width: auto;
  min-height: 39px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: center;
  align-content: center;
  margin: 5px 0 15px;
}

.field-wrap--search {
  padding-left: 20px;
}

.field-wrap--search-dark {
  margin-bottom: 40px;
  padding-left: 0;
  position: relative;
}

.field-wrap--search-dark::after,
.field-wrap--search-popup::after {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#003875),
    to(#00aad9)
  );
  background: linear-gradient(90deg, #003875, #00aad9);
  content: " ";
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
}

.field-wrap--search-dark::after {
  z-index: 1;
  height: 100%;
}

.field-wrap--search-in-page {
  padding-left: 0;
}

.field-wrap--search-popup::after {
  display: block;
  height: 42px;
}

.field-wrap--stacked {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-width: 50%;
}

.field-wrap--radio-stacked {
  margin-bottom: 15px;
}

.field-wrap--center {
  text-align: center;
}

.field-wrap--isnt-first:first-child,
.field-wrap--not-first-child:first-child {
  margin-top: 18px;
}

.field-wrap--checkbox {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.field-wrap--checkbox--stretch {
  display: inline;
}

.field-wrap--with-note {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.field-wrap--customselect {
  font-family: "Montserrat", sans-serif;
  color: #6a6a6a;
}

.field-wrap--with-legend {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.field-wrap--side-by-side,
.field-wrap--zip {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.field-wrap--desktop-side-pad {
  margin-top: 0;
}

.input-wrap--search,
.input-wrap--side-by-side {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 calc(100% - 40px);
  flex: 1 1 calc(100% - 40px);
}

.input-wrap--side-by-side {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.input-wrap--search {
  border: 1px solid transparent;
  width: calc(100% - 40px);
}

.input-wrap--search-dark,
.input-wrap--search-in-page {
  border: thin solid #979797;
}

.input--search::-webkit-input-placeholder {
  color: #6a6a6a;
}

.input--search:-moz-placeholder,
.input--search::-moz-placeholder {
  color: #6a6a6a;
}

.input--search:-ms-input-placeholder,
.input--search::-ms-input-placeholder {
  color: #6a6a6a;
}

.input--search::placeholder {
  color: #6a6a6a;
}

.input--search-dark::-webkit-input-placeholder {
  color: #373a36 !important;
}

.input--search-dark:-moz-placeholder,
.input--search-dark::-moz-placeholder {
  color: #373a36 !important;
}

.input--search-dark:-ms-input-placeholder,
.input--search-dark::-ms-input-placeholder {
  color: #373a36 !important;
}

.input--search-dark::placeholder {
  color: #373a36 !important;
}

.input-wrap--select {
  background: #fff;
  padding: 3px 5px;
}

input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0 30px #fff inset;
  background-color: #fff !important;
  box-shadow: 0 0 0 30px #fff inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

input[type="email"].input--with-icon,
input[type="password"].input--with-icon,
input[type="phone"].input--with-icon,
input[type="search"].input--with-icon,
input[type="tel"].input--with-icon,
input[type="text"].input--with-icon {
  padding-left: 3.125em;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button,
textarea {
  border: thin solid #000;
}

button {
  cursor: pointer;
}

button[disabled] {
  cursor: not-allowed;
}

textarea {
  padding: 0.625em;
  resize: none;
  width: 100%;
}

.legend + .field-wrap {
  margin-top: 0;
}

.input {
  background: #fff;
  border: 0;
  display: block;
  font-size: 16px;
  padding: 4px;
  width: 100%;
}

.input--dark {
  font-size: 1rem !important;
  color: #373a36;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

.input--dark::-webkit-input-placeholder {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #373a36 !important;
}

.input--dark:-moz-placeholder,
.input--dark::-moz-placeholder {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #373a36 !important;
}

.input--dark:-ms-input-placeholder,
.input--dark::-ms-input-placeholder {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #373a36 !important;
}

.input--dark::placeholder {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #373a36 !important;
}

.input-wrap {
  position: relative;
}

.input-wrap img,
.input-wrap svg {
  left: 12px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 20px !important;
  pointer-events: none;
}

.input-wrap--checkbox,
.label--full-width input {
  margin-right: 8px;
}

.form-cell input[type="email"],
.form-cell input[type="password"],
.form-cell input[type="text"],
.input-wrap--full-width {
  width: 100%;
}

.input-wrap--customselect {
  background: #fff;
}

.input-wrap--customselect:after,
.input-wrap--customselect:before {
  display: block;
  position: absolute;
  pointer-events: none;
}

.input-wrap--customselect::before {
  border: 2px solid #fff;
  border-right: 0;
  border-top: 0;
  content: " ";
  height: 18px;
  right: 14px;
  top: 40%;
  -webkit-transform: translate(0, -50%) rotate(-45deg);
  transform: translate(0, -50%) rotate(-45deg);
  width: 18px;
  z-index: 2;
}

.input-wrap--customselect::after {
  background-color: #009cd6;
  content: " ";
  display: block;
  height: 100%;
  right: 0;
  top: 0;
  width: 46px;
  z-index: 1;
}

.input-wrap--customselect-alt {
  max-width: 305px;
  padding: 0;
  position: relative;
}

.input-wrap--customselect-alt::after {
  border: 1px solid #aeaca6;
  border-right: 0;
  border-top: 0;
  content: " ";
  display: block;
  height: 10px;
  position: absolute;
  pointer-events: none;
  right: 15px;
  top: 45%;
  -webkit-transform: translateY(-50%) scaleX(1.5) rotate(-45deg);
  transform: translateY(-50%) scaleX(1.5) rotate(-45deg);
  width: 10px;
}

.input-wrap--cartselect {
  max-width: 100%;
}

.input-wrap--border {
  border: thin solid #979797;
}

.input-wrap--radio-stacked {
  margin-bottom: 15px;
}

.input-wrap--center {
  text-align: center;
}

.input--side-by-side {
  border: 1px solid #979797;
  max-height: 36px;
}

.input--bottom-right-corner {
  bottom: 30px;
  position: absolute !important;
  right: 0;
}

.input--bottom-center {
  bottom: 10px !important;
  position: absolute !important;
  left: 50% !important;
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}

.input--bottom-right-corner-margin {
  bottom: 10px !important;
}

.input--with-icon {
  padding-left: 40px;
}

.input--customselect {
  background: 0 0;
  color: #6a6a6a;
  padding: 12px;
}

.input--customselect.input--dark {
  color: #373a36;
}

.input--customselect.input--dark::-webkit-input-placeholder {
  color: #373a36;
}

.input--customselect.input--dark:-moz-placeholder,
.input--customselect.input--dark::-moz-placeholder {
  color: #373a36;
}

.input--customselect.input--dark:-ms-input-placeholder,
.input--customselect.input--dark::-ms-input-placeholder {
  color: #373a36;
}

.input--customselect.input--dark::placeholder {
  color: #373a36;
}

.input--customselect-light {
  color: #6a6a6a;
}

.input--customselect-alt {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  font-size: 1.125em;
  padding: 8px 38px 8px 8px;
}

.input--customselect-alt:focus {
  outline-color: #00387a;
}

.input--cartselect {
  font-size: 14px;
  color: #6a6a6a;
}

.input--submit {
  background: 0 0;
  border: 1px solid #fff;
  color: #fff;
  font-size: 16px;
  padding: 8px;
  width: auto;
}

.input--submit:active,
.input--submit:focus,
.input--submit:hover {
  background: #fff !important;
  color: #00387a;
}
input#nav-submit {
  background: #003b78 !important;
  border: 1px solid #fff;
  color: #fff;
  font-size: 16px;
  padding: 8px;
  border-radius: 0;
  width: auto;
}

.input--space-above {
  margin-top: 18px;
}

.input--zip {
  color: #6a6a6a;
}

.input--checkbox {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  background: #fff;
  border: 1px solid #979797;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 21px;
  flex: 0 0 21px;
  height: 21px;
  margin: -1px 8px 0 0;
  position: relative;
  width: 21px;
}

.input--checkbox:checked {
  background-image: url(https://www.fnb-online.com/assets/images/checkbox-dark-blue.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 70% auto;
}

.input--checkbox-plain {
  border: 0;
  height: 20px;
  width: 20px;
}

.input--checkbox-no-bg {
  background: 0 0;
}

.input--checkbox-shift {
  margin-top: 1px;
}

.input--radio {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0.2)),
    to(#fff)
  );
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.2), #fff);
  -webkit-appearance: none;
  border: 2px solid #fff;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.25);
  box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.25);
  display: inline-block;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20px;
  flex: 0 0 20px;
  height: 20px;
  margin: 0 4px 0 0;
  max-width: 20px;
}

.input--radio:checked {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#003875),
    to(#00aad9)
  );
  background: linear-gradient(180deg, #003875, #00aad9);
}

.input--radio-icon {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background: 0 0;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  height: 100%;
  cursor: pointer;
  margin-right: 0 !important;
  max-width: none;
  padding: 0;
  opacity: 0;
  width: 100%;
}

.input--radio-icon:checked {
  border-radius: 0;
}

.input--radio-icon:checked ~ * {
  font-weight: 700;
  color: #fff;
  z-index: 2;
}

.input--radio-icon:checked ~ div {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#003875),
    to(#00aad9)
  );
  background: linear-gradient(90deg, #003875, #00aad9);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.input--radio-icon:checked + svg > path {
  fill: #fff;
  z-index: 2;
}

.input--radio-icon:checked + svg > g {
  stroke: #fff;
}

.input--center {
  text-align: center;
}

.field-wrap--third {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 8px;
}

.field-wrap--third .input-wrap {
  width: calc(100% - 44px);
}

.field-wrap--third .input-wrap--customselect-alt {
  width: 100%;
  max-width: 100%;
}

.field-wrap--margin-bottom-sm {
  margin: 4px 8px;
}

.form__flex {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.form--flex,
.form__flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.form--flex-center {
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.label--wraps-checkbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.label--radio,
.label--wraps-checkbox {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.label--radio span {
  position: relative;
  top: -5px;
}

.label--checkbox {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.label--checkbox,
.label--radio {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.label--checkbox-center,
.label--full-width,
.label--radio-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.label--full-width {
  display: block;
  margin-top: 6px;
}

.label--radio-icon {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  border: 1px solid #00387a;
  color: #00387a;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  float: left;
  height: 120px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 20px;
  position: relative;
  text-align: center;
  width: 120px;
}

.label--radio-icon img,
.label--radio-icon svg {
  height: auto;
  left: 50%;
  position: absolute;
  top: 40%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  pointer-events: none;
  width: 50%;
}

.label--radio-icon span {
  bottom: 10px;
  left: 0;
  position: absolute;
  text-align: center;
  top: auto;
  width: 100%;
}

.label--radio-checked {
  font-weight: 700;
}

.icon-button {
  width: 44px;
  border: 0;
  background: 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: center;
  align-content: center;
  padding: 0;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 2;
}

@media screen and (min-width: 768px) {
  .form__flex {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .form__stack {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .form--flex fieldset {
    max-width: 70%;
  }

  .input-wrap--cartselect {
    max-width: calc(100% - 20px);
  }

  .legend--inline {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    white-space: nowrap;
  }

  .field-wrap--cartselect {
    margin: 5px 0 15px 20px;
  }

  .field-wrap--half,
  .field-wrap--quarter {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .field-wrap--half {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .field-wrap--half-even {
    padding-left: 10px;
  }

  .field-wrap--half-odd {
    padding-right: 10px;
  }

  .field-wrap--with-legend {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    max-width: 50%;
  }

  .field-wrap--no-margin-top {
    margin-top: 0;
  }

  .field-wrap--third {
    padding: 0 25px;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 300px;
    flex: 0 1 300px;
  }

  .field-wrap--third:first-child {
    padding-right: 0;
  }

  .field-wrap--third:last-child {
    padding-left: 0;
  }

  .field-wrap--margin-bottom-sm {
    margin-bottom: 8px;
  }

  .field-wrap--left-mobile {
    padding: 0 25px !important;
  }

  .field-wrap--desktop-side-pad {
    padding: 0 8px;
  }
}

@media screen and (min-width: 900px) {
  .field-wrap--side-by-side {
    padding-right: 120px;
  }

  .input--bottom-center {
    right: 0 !important;
    -webkit-transform: none;
    transform: none;
    left: unset !important;
  }
}

@media screen and (min-width: 960px) {
  .form__stack {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .field-wrap--left {
    padding: 0 25px 0 5px !important;
  }
}

@media all and (-ms-high-contrast: none) {
  .input-wrap--customselect-alt::after,
  ::-ms-backdrop {
    display: none;
  }

  .input--dark {
    height: 42px;
  }
}

.rich-text .legend + *,
.rich-text h1 + *,
.rich-text h2 + *,
.rich-text h3 + *,
.rich-text h4 + *,
.rich-text h5 + *,
.rich-text h6 + *,
.rich-text legend + * {
  margin-top: 0.625em;
}

.rich-text > * {
  margin-top: 0;
}

.rich-text > * + * {
  margin-top: 1.25em;
}

.rich-text ul {
  margin-left: 1.25em;
}

.form-row {
  position: relative;
}

@media only screen and (min-width: 60em) {
  .form-row {
    display: table;
    width: 100%;
  }
}

.form-cell {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  position: relative;
  padding-top: 1.875em;
}

@media only screen and (min-width: 60em) {
  .form-cell {
    display: table-cell;
  }

  .form-cell.m-half {
    width: 50%;
    padding-right: 1.875em;
  }

  .form-cell.m-half + .m-half {
    padding-right: 0;
    padding-left: 1.875em;
  }

  .form-cell.m-third {
    width: 33.3333%;
  }

  .form-cell.m-full {
    width: 100%;
  }
}

@media only screen and (min-width: 70em) {
  .form-cell.m-centered {
    text-align: center;
  }

  .form-cell.m-right {
    text-align: right;
  }
}

.form-cell.m-vam {
  vertical-align: middle;
}

.floating-label {
  position: relative;
  display: block;
  margin-top: 0.9375em;
}

.floating-label .label-text {
  position: absolute;
  top: -1.75em;
  -webkit-transition: 0.25s;
  transition: 0.25s;
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
  font-size: 1.125em;
  left: 0.9375em;
  z-index: 3;
}

.floating-label .label-text.m-textarea {
  top: -1.875em;
}

.floating-label.m-empty .label-text {
  z-index: 3;
  opacity: 0;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.floating-label.m-empty input[type="date"]::-webkit-input-placeholder,
.floating-label.m-empty input[type="email"]::-webkit-input-placeholder,
.floating-label.m-empty input[type="text"]::-webkit-input-placeholder,
.floating-label.m-empty textarea::-webkit-input-placeholder {
  overflow: visible;
}

.floating-label.m-empty input[type="date"]:-moz-placeholder,
.floating-label.m-empty input[type="date"]::-moz-placeholder,
.floating-label.m-empty input[type="email"]:-moz-placeholder,
.floating-label.m-empty input[type="email"]::-moz-placeholder,
.floating-label.m-empty input[type="text"]:-moz-placeholder,
.floating-label.m-empty input[type="text"]::-moz-placeholder,
.floating-label.m-empty textarea:-moz-placeholder,
.floating-label.m-empty textarea::-moz-placeholder {
  overflow: visible;
}

.floating-label.m-empty input[type="date"]:-ms-input-placeholder,
.floating-label.m-empty input[type="date"]::-ms-input-placeholder,
.floating-label.m-empty input[type="email"]:-ms-input-placeholder,
.floating-label.m-empty input[type="email"]::-ms-input-placeholder,
.floating-label.m-empty input[type="text"]:-ms-input-placeholder,
.floating-label.m-empty input[type="text"]::-ms-input-placeholder,
.floating-label.m-empty textarea:-ms-input-placeholder,
.floating-label.m-empty textarea::-ms-input-placeholder {
  overflow: visible;
}

.floating-label.m-empty input[type="date"]::placeholder,
.floating-label.m-empty input[type="email"]::placeholder,
.floating-label.m-empty input[type="text"]::placeholder,
.floating-label.m-empty textarea::placeholder {
  overflow: visible;
}

.ui-checkbox {
  width: 1.4375em;
  height: 1.4375em;
  display: inline-block;
  vertical-align: middle;
  background: #000;
}

.ui-checkbox.m-checked {
  border-color: transparent;
  background: #0f0;
}

.ui-checkbox.m-checked:before {
  padding-top: 0.5em;
  padding-left: 0.1875em;
}

.ui-checkbox:before {
  position: absolute;
  left: -0.0625em;
  right: 0;
  top: -0.0625em;
  bottom: 0;
  text-align: center;
  z-index: 1;
  font-size: 0.9375em;
}

.ui-checkbox input,
.ui-radio input,
.ui-select select {
  position: absolute;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
  pointer-events: auto;
}

.ui-checkbox input {
  width: 1.625em;
  height: 1.625em;
  z-index: 2;
  left: -9px;
  top: -8px;
}

.ui-checkbox,
.ui-radio,
.ui-select {
  position: relative;
  pointer-events: none;
}

.ui-radio {
  width: 1em;
  height: 1em;
  top: -0.125em;
  border-radius: 1.25em;
  font-size: 1.5em;
  display: inline-block;
}

.ui-radio.m-checked {
  background-color: #0f0;
}

.ui-radio input,
.ui-select select {
  top: 0;
  left: 0;
  margin: 0;
}

.ui-radio input {
  height: 1em;
  width: 1em;
  z-index: 2;
}

.ui-select {
  padding-right: 3.75em;
  background: 0 0;
  background: #fff;
  border: 1px solid #000;
  display: block;
}

.ui-select:after {
  content: "";
  position: absolute;
  right: 0.625em;
  top: 1em;
  width: 10px;
  height: 10px;
  bottom: auto;
  font-size: 1.125em;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  color: #000;
  background-color: #000;
}

.ui-select .ui-select-text {
  display: block;
  color: inherit;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
}

.ui-select select {
  background: 0 0;
  padding: 0;
  border: 0;
  width: 100%;
  z-index: 5;
}

dialog:not([open]) {
  pointer-events: none;
  opacity: 0;
}

.interactive-modal + .backdrop,
.interactive-modal::-webkit-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: none !important;
}

.interactive-modal + .backdrop,
.interactive-modal::backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: none !important;
}

.interactive-modal + .backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  height: 100vh;
  top: 0;
  width: 100vw;
}

.interactive-modal {
  bottom: 43px;
  color: #00387a;
  left: 0;
  position: fixed;
  width: 314px !important;
  z-index: 1000;
  background: #fff;
  border-top: 4px solid #a80432 !important;
  font-family: Helvetica, Arial, sans-serif;
  border: 0;
  padding: 0;
  display: block;
  opacity: 1 !important;
  pointer-events: inherit !important;
  margin: 0 20px;
  top: unset !important;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
}

.interactive-modal__header--image-wrap,
.interactive-modal__header--section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.interactive-modal__header--section {
  background: #00387a;
  outline: 0;
  width: 100%;
  padding: 15px;
  height: 78px;
}

.interactive-modal__header--image-wrap {
  position: relative;
  width: 44px;
  height: 44px;
  padding: 10px 11px 10px 10px;
  border-radius: 10px;
  border: 1px solid #95f7ff;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
}

.interactive-modal__header--image-wrap img {
  position: absolute;
  left: 8px;
  width: 24px !important;
}

.interactive-modal__header--wrap {
  -ms-flex: 0 0 180px;
  -webkit-box-flex: 0;
  flex: 0 0 180px;
  margin: 14px auto 7px 10px;
  color: #fff;
}

.interactive-modal__header--caption {
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 6px;
}

.interactive-modal__header--link {
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
}

.interactive-modal__content--section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 15px;
  height: 66px;
}

.interactive-modal__content--count {
  background: #00387a;
  border-radius: 26px;
  color: #fff;
  display: block;
  line-height: 26px;
  text-align: center;
  width: 26px;
  height: 26px;
  font-size: 16px;
  font-weight: 500;
  -webkit-box-shadow: 0 0 0 5px #d1dbe7;
  box-shadow: 0 0 0 5px #d1dbe7;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 26px;
  flex: 0 0 26px;
  margin-left: 5px;
}

.interactive-modal__content--wrap {
  -ms-flex: 0 0 180px;
  -webkit-box-flex: 0;
  flex: 0 0 180px;
  margin-right: auto;
  margin-left: 10px;
}

.interactive-modal__content--caption {
  font-size: 10px;
  margin-bottom: 0;
  color: #6a6a6a;
  line-height: 14px;
  font-weight: 400;
}

.interactive-modal__content--title {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.interactive-modal__header--button {
  background: 0 0;
  float: right;
  position: relative;
  border-radius: 32px;
  display: block;
  width: 32px;
  height: 32px;
  -webkit-box-flex: 0;
  cursor: pointer;
  border: 0;
}

.interactive-modal__header--button:active,
.interactive-modal__header--button:focus {
  outline-color: #00387a;
}

.interactive-modal__header--button--collapsed {
  position: relative;
  padding-right: 20px;
  background: 0 0;
  border: 1px solid #fff;
  color: #fff;
}

.interactive-modal__header--button--collapsed:before,
.interactive-modal__header--button--expanded:before {
  border: 1px solid;
  content: " ";
  display: block;
  width: 12px;
  height: 12px;
  position: absolute;
  border-right: 0;
  border-bottom: 0;
}

.interactive-modal__header--button--collapsed:before {
  right: 9px;
  top: 60%;
  -webkit-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
}

.interactive-modal__header--button--expanded {
  position: relative;
  padding-left: 20px;
  background: #fff;
  border: 1px solid #00387a;
}

.interactive-modal__header--button--expanded:before {
  left: 9px;
  top: 40%;
  -webkit-transform: translateY(-50%) rotate(-135deg);
  transform: translateY(-50%) rotate(-135deg);
}

.interactive-modal__body {
  height: 474px;
}

.interactive-modal__body[aria-hidden="true"] {
  display: none;
}

.interactive-modal__body .swiper-slide {
  opacity: 0 !important;
  margin-left: -100%;
}

.interactive-modal__body .swiper-slide-active {
  opacity: 1 !important;
  margin-left: 0;
}

.interactive-modal__image {
  border-top: 1px solid #f0f0f0;
  height: 190px;
}

.interactive-modal__image img,
.interactive-modal__image picture {
  height: 100%;
}

.interactive-modal__description--wrap {
  padding: 20px;
  text-align: center;
  height: 142px;
  border-top: 1px solid #f0f0f0;
  overflow: auto;
}

.interactive-modal__description--wrap-expanded {
  height: 332px !important;
}

.interactive-modal__description--title {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  padding-bottom: 8px;
}

.interactive-modal__description--summary {
  font-size: 12px;
  color: #373a36;
  font-weight: 400;
  line-height: 18px;
}

.interactive-modal__button--wrap {
  padding: 25px 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 80px;
  border-top: 1px solid #f0f0f0;
}

.interactive-modal__swiper--wrap {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.interactive-modal__swiper {
  background: #00387a;
  border-radius: 32px;
  color: #fff;
  display: block;
  width: 32px;
  height: 32px;
  font-size: 16px;
  cursor: pointer;
  border: 0;
  top: 0;
  margin-top: 0;
  left: 0;
}

.interactive-modal__swiper--prev {
  margin-right: 6px;
  position: relative;
}

.interactive-modal__swiper--next:before,
.interactive-modal__swiper--prev:before {
  border: 1px solid;
  content: " ";
  display: block;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 50%;
  border-right: 0;
  border-bottom: 0;
}

.interactive-modal__swiper--prev:before {
  left: 12px;
  -webkit-transform: translateY(-50%) rotate(-45deg);
  transform: translateY(-50%) rotate(-45deg);
}

.interactive-modal__swiper--next {
  position: relative;
  padding-right: 20px;
}

.interactive-modal__swiper--next:before {
  right: 12px;
  -webkit-transform: translateY(-50%) rotate(135deg);
  transform: translateY(-50%) rotate(135deg);
}

.dialog {
  margin-left: 0;
  margin-right: 0;
  color: #6a6a6a;
  background: #fff;
  border: 0;
  -webkit-box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.18);
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.18);
  position: fixed;
  top: 50vh;
  left: 50vw;
  max-width: 788px;
  padding: 10px 10px 40px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: calc(100vw - 60px);
  z-index: 10;
}

.dialog--plain {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: 0 0;
  z-index: 1001;
  position: fixed;
}

.dialog--plain + .backdrop,
.dialog--plain::-webkit-backdrop {
  background-color: rgba(0, 0, 0, 0.7);
  position: static;
  z-index: 1001;
  -webkit-transform: translateY(106px);
  transform: translateY(106px);
  top: 0;
}

.dialog--plain + .backdrop,
.dialog--plain::backdrop {
  background-color: rgba(0, 0, 0, 0.7);
  position: static;
  z-index: 1001;
  -webkit-transform: translateY(106px);
  transform: translateY(106px);
  top: 0;
}

.dialog--anchor-right-top {
  left: auto;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  right: 0;
  top: 0;
  -webkit-transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
}

.dialog--entering-from-right {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.dialog__body {
  clear: both;
  padding: 0 30px;
}

.dialog__body:after {
  clear: both;
  content: " ";
  display: table;
}

.dialog__headline {
  font-size: 20px;
}

.dialog__choice-separator {
  display: inline-block;
  margin: 0 20px;
}

.dialog__close {
  background: 0 0;
  border: 0;
  float: right;
  height: 16px;
  position: relative;
  text-indent: -999em;
  width: 16px;
}

.dialog__close:active,
.dialog__close:focus {
  outline-color: #00387a;
}

.dialog__close::after,
.dialog__close::before {
  background: #00387a;
  content: " ";
  display: block;
  height: 100%;
  left: 50%;
  position: absolute;
  top: 0;
  width: 1px;
}

.dialog__close::before {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.dialog__close::after {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.dialog__close--circle {
  border: 1px solid #00387a;
  border-radius: 15px;
  height: 30px;
  width: 30px;
}

.dialog__close--circle::after,
.dialog__close--circle::before {
  height: 70%;
  top: 4px;
}

.dialog__close--solid {
  border: 0;
  margin-left: 8px;
  background: #6a6a6a;
}

.dialog__close--solid:after,
.dialog__close--solid:before {
  background: #fff;
  width: 2px;
  left: 48%;
}

.dialog__close--white {
  position: absolute;
  right: 20px;
  top: 65px;
}

.dialog__close--white::after,
.dialog__close--white::before {
  background: #fff;
}

.dialog__close--search {
  width: 40px;
  height: 42px;
  background-color: #00387a;
  top: 28px;
  right: 0;
}

.dialog__choice-button {
  border: 1px solid #00387a;
  color: #00387a;
  display: inline-block;
  margin-top: 25px;
  padding: 4px 12px;
}

.dialog__choice-button:active,
.dialog__choice-button:focus,
.dialog__choice-button:hover {
  background: #00387a;
  color: #fff;
}

.dialog + .backdrop,
.dialog::-webkit-backdrop {
  bottom: 0;
  height: 100vh;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  -webkit-transition: opacity 0.15s;
  transition: opacity 0.15s;
}

.dialog + .backdrop,
.dialog::backdrop {
  bottom: 0;
  height: 100vh;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  -webkit-transition: opacity 0.15s;
  transition: opacity 0.15s;
}

.dialog[open] + .backdrop,
.dialog[open]::-webkit-backdrop {
  opacity: 1;
}

.dialog[open] + .backdrop,
.dialog[open]::backdrop {
  opacity: 1;
}

.dialog--leaving + .backdrop,
.dialog--leaving::-webkit-backdrop,
.dialog[open].dialog--leaving + .backdrop,
.dialog[open].dialog--leaving::-webkit-backdrop {
  opacity: 0;
}

.dialog--leaving + .backdrop,
.dialog--leaving::backdrop,
.dialog[open].dialog--leaving + .backdrop,
.dialog[open].dialog--leaving::backdrop {
  opacity: 0;
}

.dialog--leaving {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.generic-modal {
  font-family: "Montserrat", sans-serif;
  border-radius: 4px;
}

.generic-modal__header {
  font-family: "Titillium Web", sans-serif;
  margin-bottom: 15px;
  font-size: 1.25em;
}

.generic-modal__mobile-tall {
  max-height: 90%;
  overflow: auto;
  bottom: auto;
}

.generic-modal--form {
  overflow-y: auto;
  max-height: 90%;
}

.generic-modal--form__header {
  margin-top: 15px;
  margin-bottom: 0;
}

.generic-modal--form__body {
  margin-bottom: 15px !important;
  margin-top: 10px;
}

.generic-modal--form__form-wrap {
  padding: 0 30px;
  margin-bottom: 80px;
}

.generic-modal--form__form {
  margin-bottom: 40px;
  margin-top: 10px;
}

.generic-modal--form__form--rel {
  position: relative;
}

.generic-modal--form__button {
  right: 40px;
  bottom: 80px;
  padding: 6px 50px 6px 12px;
  position: absolute !important;
}

.generic-modal--form__button--rel {
  right: 0 !important;
  bottom: -30px !important;
}

.generic-modal--form__close {
  left: 40px;
  position: absolute !important;
  padding: 5px 34px 5px 31px !important;
  bottom: 80px;
}

.generic-modal--form__close--rel {
  left: 0 !important;
  bottom: -30px !important;
}

.generic-modal--form__disclosures {
  bottom: 10px;
  font-size: 0.8em;
}

.generic-modal--form--loading {
  position: absolute !important;
}

.generic-modal--form--loading:after {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  content: " ";
  display: block;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  -webkit-transform: translateX(-20px);
  transform: translateX(-20px);
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  margin: 0;
  top: 35%;
  z-index: 3;
}

.generic-modal--form--loading:before {
  display: block;
  content: " ";
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  width: 100%;
  height: 100%;
  top: -10px;
  left: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  opacity: 1;
  z-index: 3;
  pointer-events: none;
}

.generic-modal--form--loading::after,
.generic-modal--form--loading::before {
  opacity: 1;
  pointer-events: auto;
}

.generic-modal--form__wrap {
  margin-bottom: 60px;
}

.generic-modal--form__mobile-wide {
  bottom: auto;
}

.generic-modal--side-by-side--content__wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.generic-modal--image__wrap {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-top: 10px;
}

.generic-modal--content {
  -webkit-box-flex: 3;
  -ms-flex: 3;
  flex: 3;
  margin-right: 10px;
}

.mortgage-modal {
  max-width: 1172px;
  max-height: 100vh;
  max-height: calc(100vh - 40px);
  bottom: auto;
  padding: 0;
  overflow: hidden;
}

.generic-modal + .backdrop,
.generic-modal::-webkit-backdrop {
  background-color: rgba(0, 0, 0, 0.7);
}

.generic-modal + .backdrop,
.generic-modal::backdrop {
  background-color: rgba(0, 0, 0, 0.7);
}

.minicart-modal {
  height: 100vh;
  height: calc(100vh - 30px);
  left: auto;
  max-height: 470px;
  max-width: 400px;
  overflow: hidden;
  padding-bottom: 10px;
  right: 0;
  top: 0;
  -webkit-transform: none;
  transform: none;
  z-index: 1010;
  bottom: auto;
}

.minicart-modal__inner-wrap {
  height: 100%;
  position: relative;
  width: 100%;
}

.minicart-modal__title {
  font-size: 1em;
  margin-top: 5px;
  padding-left: 40px;
  text-align: center;
}

.minicart-modal__title-count {
  font-weight: 700;
}

.minicart-modal__products {
  height: calc(100% - 76px);
  margin: 10px 0 0 23px;
  overflow: visible;
}

.minicart-modal__products-scroll {
  height: 100%;
  overflow: auto;
  padding-bottom: 80px;
}

.minicart-modal__view-bag {
  bottom: 0;
  display: block;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 20px;
}

.minicart-modal__view-bag-inner-wrap {
  height: 100%;
  position: relative;
  width: 100%;
}

.minicart-modal__view-bag-inner-wrap:after,
.minicart-modal__view-bag-inner-wrap:before {
  display: block;
  content: " ";
  height: 40%;
  position: absolute;
  left: 0;
  width: 100%;
}

.minicart-modal__view-bag-inner-wrap:after {
  bottom: 10%;
  background: #fff;
}

.minicart-modal__view-bag-inner-wrap:before {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(255, 255, 255, 0)),
    to(#fff)
  );
  background: linear-gradient(180deg, rgba(255, 255, 255, 0), #fff);
  top: 0;
}

.minicart-modal__view-bag-img {
  position: relative;
  z-index: 3;
}

.minicart-modal__view-bag-button {
  z-index: 2;
}

.video-modal {
  z-index: 1050;
}

.video-modal iframe,
.video-modal__video-wrap img {
  left: 50%;
  top: 50%;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.video-modal iframe {
  height: 100%;
  width: 100%;
}

.video-modal__title {
  margin-bottom: 15px;
}

.video-modal__video {
  left: 50%;
  top: 50%;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
}

.video-modal__video-wrap {
  height: 0;
  overflow: hidden;
  padding-bottom: 57.5%;
  position: relative;
}

.video-modal__video-wrap img {
  display: block;
  min-width: 100%;
}

@media screen and (min-width: 768px) {
  .dialog__close--white {
    top: 20px;
    z-index: 3;
  }

  .dialog__close--search {
    top: 10px;
  }

  .generic-modal--image__wrap {
    position: fixed;
    right: 20px;
    top: 24px;
    max-width: 100%;
  }

  .generic-modal--content {
    margin-right: 150px;
  }
}

@media all and (min-width: 900px) {
  .minicart-modal {
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-top: 0;
    right: 30px;
  }

  .minicart-modal + .backdrop,
  .minicart-modal::-webkit-backdrop {
    background: 0 0;
  }

  .minicart-modal + .backdrop,
  .minicart-modal::backdrop {
    background: 0 0;
  }
}

@media all and (min-width: 900px) and (max-height: 700px) {
  .dialog.video-modal,
  .video-modal {
    top: 150px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

@media all and (max-width: 700px) {
  .generic-modal--form__form-wrap,
  .generic-modal__body--form {
    padding: 0 10px;
  }

  .generic-modal--form__close {
    margin-top: 120px;
    left: 20px;
  }

  .generic-modal--form__button {
    margin-top: 120px;
    right: 20px;
  }

  .generic-modal img,
  .generic-modal--image__wrap {
    max-width: 100px;
  }
}

@media all and (max-width: 360px) {
  .generic-modal--form {
    width: calc(100vw - 40px);
  }

  .generic-modal--form__mobile-wide,
  .generic-modal__mobile-tall {
    width: calc(100vw - 20px);
    bottom: auto;
  }
}

@media all and (max-width: 414px) {
  .interactive-modal {
    left: 0;
    bottom: 0;
    width: 100% !important;
    margin: 0 !important;
    max-width: none !important;
  }
}

@media screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .generic-modal--form__button,
  .generic-modal--form__close {
    margin-top: 80px;
  }

  .generic-modal--image__wrap {
    position: relative !important;
    top: -32px;
  }
}

.compare-link {
  padding-left: 25px;
  position: relative;
  display: inline-block;
  font-size: 14px;
  margin-left: 5px;
}

.action-button img,
.action-button svg,
.compare-link img,
.compare-link svg {
  left: 0;
  max-width: 25px;
  overflow: visible;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.compare-link:active,
.compare-link:focus,
.compare-link:hover {
  text-decoration: underline;
}

.button--hidden-until-focus {
  opacity: 0;
}

.button--hidden-until-focus:active,
.button--hidden-until-focus:focus {
  opacity: 1;
}

.button-separator {
  margin: 0 10px;
}

.action-button {
  background: 0 0;
  border: 0;
  padding-left: 35px;
  position: relative;
  color: #00387a;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 35px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.875em;
}

.action-button:active,
.action-button:focus {
  outline-color: #00387a;
}

.action-button:first-child {
  margin-left: 0;
}

@media screen and (min-width: 900px) {
  .action-button {
    margin-right: 0;
  }
}

.a--previous {
  position: relative;
  padding-left: 20px;
}

.a--previous::before {
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  content: " ";
  border: 1px solid #00387a;
  border-right: 0;
  border-bottom: 0;
  height: 10px;
  width: 10px;
  -webkit-transform: translateY(-50%) scaleY(1.8) rotate(-45deg);
  transform: translateY(-50%) scaleY(1.8) rotate(-45deg);
}

@media all and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .a--previous:before {
    left: 5px;
  }
}

.a--previous-disabled {
  pointer-events: none;
  opacity: 0;
}

.search-button {
  background: 0 0;
  border: 0;
  background: center center no-repeat
    url(https://www.fnb-online.com/assets/images/icons/icon-search.svg);
  background-size: 80% auto;
  text-indent: -999em;
  width: 40px;
  cursor: pointer;
}

.search-button:active,
.search-button:focus {
  outline-color: #00387a;
}

.search-button:active,
.search-button:focus,
.search-button:hover {
  background-color: #f4f4f2;
}

.search-button--dark {
  background: center center no-repeat
    url(https://www.fnb-online.com/assets/images/icons/icon-search-white.svg);
  background-size: 70% auto;
  z-index: 2;
}

.search-button--dark:active,
.search-button--dark:focus,
.search-button--dark:hover {
  background-color: #00387a;
}

.white-blue-gradient-button {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ddf2f5),
    to(#fff)
  );
  background: linear-gradient(90deg, #ddf2f5, #fff);
  border: 1px solid #00387a;
  padding: 5px 7px;
  color: #00387a;
}

.white-blue-gradient-button--arrow-right,
.white-blue-gradient-button--next-arrow {
  position: relative;
  padding-right: 50px;
}

.white-blue-gradient-button--arrow-right:before,
.white-blue-gradient-button--next-arrow:before {
  border: 1px solid #00387a;
  content: " ";
  display: block;
  width: 12px;
  height: 12px;
  right: 25px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(135deg);
  transform: translateY(-50%) rotate(135deg);
  border-right: 0;
  border-bottom: 0;
  -webkit-transition: -webkit-transform 0.15s;
  transition: transform 0.15s;
  transition: transform 0.15s, -webkit-transform 0.15s;
}

.white-blue-gradient-button--arrow-right:active:before,
.white-blue-gradient-button--arrow-right:focus:before,
.white-blue-gradient-button--arrow-right:hover:before,
.white-blue-gradient-button--next-arrow:active:before,
.white-blue-gradient-button--next-arrow:focus:before,
.white-blue-gradient-button--next-arrow:hover:before {
  -webkit-transform: translateY(-50%) translateX(15px) rotate(135deg);
  transform: translateY(-50%) translateX(15px) rotate(135deg);
  border-color: #00387a;
}

@media all and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  img[src*=".svg"] {
    width: 100%;
  }
}

.filter-trigger {
  background: 0 0;
  border: 0;
  padding-left: 30px;
  position: relative;
  color: #00387a;
  font-size: 14px;
  float: right;
}

.filter-trigger:active,
.filter-trigger:focus,
.play-button:active,
.play-button:focus {
  outline-color: #00387a;
}

.filter-trigger img,
.filter-trigger svg,
.remove-button img,
.remove-button svg,
.video-link img,
.video-link svg {
  left: 0;
  max-width: 25px;
  overflow: visible;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.filter-trigger--centered {
  float: none;
}

@media all and (min-width: 900px) {
  .filter-trigger {
    display: none;
  }
}

.plain-button {
  background: 0 0;
  border: 0;
  padding: 0;
  width: auto;
}

.plain-button--small {
  font-size: 0.875em;
}

.play-button {
  background: 0 0;
  border: 1px solid #00387a;
  background: #00387a;
  border-radius: 4px;
  text-indent: -999em;
  width: 39px;
  height: 24px;
  bottom: 0;
  position: absolute;
  right: 12px;
}

.play-button-inner-wrap {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}

.play-button-inner-wrap:after {
  left: 50%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent transparent transparent #fff;
  border-width: 8px;
  margin-top: -8px;
  -webkit-transform: translateX(-15%) scaleY(0.75);
  transform: translateX(-15%) scaleY(0.75);
}

@media all and (min-width: 768px) {
  .play-button {
    background: 0 0;
    border: 1px solid #00387a;
    background: #00387a;
    border-radius: 4px;
    text-indent: -999em;
    width: 39px;
    height: 24px;
  }

  .play-button:active,
  .play-button:focus {
    outline-color: #00387a;
  }

  .play-button-inner-wrap {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
  }

  .play-button-inner-wrap:after {
    left: 50%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: transparent transparent transparent #fff;
    border-width: 8px;
    margin-top: -8px;
    -webkit-transform: translateX(-15%) scaleY(0.75);
    transform: translateX(-15%) scaleY(0.75);
  }
}

@media all and (min-width: 768px) and (min-width: 768px) {
  .play-button {
    width: 54px;
    height: 33px;
  }
}

@media all and (min-width: 768px) {
  .play-button--dont-grow {
    background: 0 0;
    border: 1px solid #00387a;
    background: #00387a;
    border-radius: 4px;
    text-indent: -999em;
    width: 39px;
    height: 24px;
  }

  .play-button--dont-grow:active,
  .play-button--dont-grow:focus {
    outline-color: #00387a;
  }

  .play-button--dont-grow-inner-wrap {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
  }

  .play-button--dont-grow-inner-wrap:after {
    left: 50%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: transparent transparent transparent #fff;
    border-width: 8px;
    margin-top: -8px;
    -webkit-transform: translateX(-15%) scaleY(0.75);
    transform: translateX(-15%) scaleY(0.75);
  }
}

@media all and (max-width: 768px) {
  .blue-block-button--small-m,
  .white-block-button--small-m {
    display: block !important;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 20px 0 !important;
  }
}

.app-button {
  margin-right: 12px;
}

.ib {
  display: inline-block;
  margin: 20px auto;
}

.remove-button {
  background: 0 0;
  border: 0;
  padding-left: 30px;
  position: relative;
  font-family: "Titillium Web", sans-serif;
  color: #00387a;
  font-weight: 700;
}

.remove-button:active,
.remove-button:focus,
.video-link:active,
.video-link:focus {
  outline-color: #00387a;
}

.inline-link {
  color: #00387a;
  font-weight: 700;
}

.inline-link--next-arrow {
  display: inline-block;
  padding-right: 16px;
  position: relative;
}

.inline-link--next-arrow::after {
  border: 2px solid #00387a;
  border-left: 0;
  border-top: 0;
  content: " ";
  display: block;
  height: 8px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-5px) rotate(-45deg);
  transform: translateY(-5px) rotate(-45deg);
  width: 8px;
}

.video-link {
  background: 0 0;
  border: 0;
  padding-left: 35px;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
}

.login-box a:active,
.login-box a:focus,
.login-box a:hover,
.login-box__text a:active,
.login-box__text a:focus,
.login-box__text a:hover,
.video-link:active,
.video-link:focus,
.video-link:hover {
  text-decoration: underline;
}

.video-link--center {
  margin: 0 auto;
}

.video-link--blue {
  color: #95f7ff;
}

.video-link--large {
  font-size: 16px;
}

@media all and (min-width: 900px) {
  .compare-link,
  .video-link {
    font-size: 16px;
  }
}

.cover-link {
  display: block;
  width: 100%;
}

.cover-link--flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.form-button {
  background: 0 0;
  border: 1px solid #00387a;
  border-radius: 0;
  color: #00387a;
  padding: 6px 10px;
}

.form-button:active,
.form-button:active:active,
.form-button:active:focus,
.form-button:focus,
.form-button:focus:active,
.form-button:focus:focus,
.form-button:hover:active,
.form-button:hover:focus {
  outline-color: #00387a;
}

.form-button:active,
.form-button:focus,
.form-button:hover {
  background: 0 0;
  -webkit-appearance: none;
  background: #00387a;
  border: 1px solid #00387a;
  color: #fff;
  border-radius: 0;
  padding: 6px 10px;
}

.form-button:first-child {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-left: 0;
  padding-right: 0;
  text-align: left;
}

.form-button:first-child:active,
.form-button:first-child:focus,
.form-button:first-child:hover {
  background: #fff;
  color: #00387a;
  text-decoration: underline;
}

.form-button--no-border {
  border: 0;
}

.blue-gradient-button {
  background: 0 0;
  border: 0;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#003875),
    to(#00aad9)
  );
  background: linear-gradient(90deg, #003875, #00aad9);
  font-family: "Titillium Web", sans-serif;
  -ms-flex-item-align: center;
  align-self: center;
  color: #fff;
}

.blue-gradient-button:active,
.blue-gradient-button:focus {
  outline-color: #00387a;
}

.blue-gradient-button:active,
.blue-gradient-button:focus,
.blue-gradient-button:hover {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#003875),
    to(#003875)
  );
  background: linear-gradient(90deg, #003875, #003875);
  color: #fff;
}

.blue-gradient-button__inner {
  position: relative;
}

.blue-gradient-button--disabled {
  background: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.blue-gradient-button--disabled:active,
.blue-gradient-button--disabled:focus,
.blue-gradient-button--disabled:hover {
  background: rgba(0, 0, 0, 0.25);
}

.blue-gradient-button--fw {
  width: 100%;
  text-align: center;
}

.blue-gradient-button--pad,
.blue-gradient-button--stacked {
  display: inline-block;
  margin-top: 20px;
}

.blue-gradient-button--pad {
  font-size: 16px;
  line-height: 24px;
  padding: 6px 16px;
}

.blue-gradient-button--pad-small {
  padding: 6px 16px;
}

.blue-gradient-button--small-mt {
  margin-top: 12px;
}

.blue-gradient-button--on-dark-blue {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#005f97),
    to(#009cd6)
  );
  background: linear-gradient(90deg, #005f97, #009cd6);
  border: 1px transparent solid;
  border-left: 1px solid #003875;
}

.blue-gradient-button--on-dark-blue:active,
.blue-gradient-button--on-dark-blue:focus,
.blue-gradient-button--on-dark-blue:hover {
  background: 0 0;
  border: 1px solid #fff;
  border-left: 1px solid #fff;
  color: #fff;
}

.blue-gradient-button--on-dark-blue:active:after,
.blue-gradient-button--on-dark-blue:focus:after,
.blue-gradient-button--on-dark-blue:hover:after {
  border-color: #fff;
}

.blue-gradient-button--no-margin {
  margin-top: 0;
}

.blue-gradient-button--arrow-right,
.blue-gradient-button--next-arrow {
  padding-right: 40px;
  position: relative;
}

.blue-gradient-button--arrow-right::after,
.blue-gradient-button--next-arrow::after {
  border: 1px solid #fff;
  border-left: 0;
  border-top: 0;
  content: " ";
  display: block;
  height: 10px;
  position: absolute;
  right: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%) scaleY(1.8) rotate(-45deg);
  transform: translateY(-50%) scaleY(1.8) rotate(-45deg);
  width: 10px;
}

.blue-gradient-button--side-by-side {
  margin-top: 0;
}

.blue-gradient-button--stretch-right {
  left: 30px;
  margin: 0;
  position: absolute;
  text-align: right;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: calc(100% - 30px);
}

.white-block-button {
  background: 0 0;
  border: 1px solid #fff;
  color: #fff;
  display: inline-block;
  font-size: 16px;
  padding: 4px 12px;
  white-space: nowrap;
}

.white-block-button--equal-pad {
  padding: 8px 16px;
}

.white-block-button--arrow-right,
.white-block-button--next-arrow {
  padding-right: 50px;
  position: relative;
}

.white-block-button--arrow-right::after,
.white-block-button--next-arrow::after {
  border: 1px solid #fff;
  border-bottom: 0;
  border-left: 0;
  content: " ";
  display: block;
  height: 8px;
  position: absolute;
  right: 25px;
  top: 50%;
  top: calc(50% - 4px);
  -webkit-transform: translate(0, 0) scale(1, 2) rotate(45deg);
  transform: translate(0, 0) scale(1, 2) rotate(45deg);
  -webkit-transition: -webkit-transform 0.15s ease-out;
  transition: transform 0.15s ease-out;
  transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
  width: 8px;
}

.white-block-button--arrow-right:active::after,
.white-block-button--arrow-right:focus::after,
.white-block-button--arrow-right:hover::after,
.white-block-button--next-arrow:active::after,
.white-block-button--next-arrow:focus::after,
.white-block-button--next-arrow:hover::after {
  border-color: #fff;
  -webkit-transform: translate(0, 0) scale(1, 2) rotate(45deg);
  transform: translate(0, 0) scale(1, 2) rotate(45deg);
}

.blue-block-button {
  background: 0 0;
  font-family: "Titillium Web", sans-serif;
  -ms-flex-item-align: center;
  align-self: center;
  border: 1px solid #00387a;
  color: #00387a;
  display: inline-block;
  font-size: 16px;
  padding: 4px 12px;
  -webkit-transition: background 0.2s;
  transition: background 0.2s;
  white-space: nowrap;
}

.blue-block-button:active,
.blue-block-button:focus {
  outline-color: #00387a;
}

.blue-block-button:active,
.blue-block-button:focus,
.blue-block-button:hover {
  background: #00387a;
  color: #fff;
}

.blue-block-button:active::before,
.blue-block-button:focus::before,
.blue-block-button:hover::before {
  border-color: #fff;
}

.blue-block-button--no-border {
  border-color: transparent;
}

.blue-block-button--extra-pad,
.blue-block-button--extra-pad-big,
.blue-block-button--side-by-side,
.blue-block-button--side-by-side-big {
  padding: 5px 12px;
}

.blue-block-button--fw {
  width: 100%;
}

.blue-block-button--pad {
  padding: 8px 16px;
}

.blue-block-button--small-m {
  margin: 0 20px;
}

.blue-block-button--arrow-right,
.blue-block-button--next-arrow {
  padding-right: 50px;
  position: relative;
}

.blue-block-button--arrow-right::after,
.blue-block-button--next-arrow::after {
  border: 1px solid #00387a;
  border-bottom: 0;
  border-left: 0;
  content: " ";
  display: block;
  height: 8px;
  position: absolute;
  right: 25px;
  top: 50%;
  top: calc(50% - 4px);
  -webkit-transform: translate(0, 0) scale(1, 2) rotate(45deg);
  transform: translate(0, 0) scale(1, 2) rotate(45deg);
  -webkit-transition: -webkit-transform 0.15s ease-out;
  transition: transform 0.15s ease-out;
  transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
  width: 8px;
}

.blue-block-button--arrow-right:active::after,
.blue-block-button--arrow-right:focus::after,
.blue-block-button--arrow-right:hover::after,
.blue-block-button--next-arrow:active::after,
.blue-block-button--next-arrow:focus::after,
.blue-block-button--next-arrow:hover::after {
  border-color: #fff;
  -webkit-transform: translate(0, 0) scale(1, 2) rotate(45deg);
  transform: translate(0, 0) scale(1, 2) rotate(45deg);
}

.blue-block-button--on-blue-bg,
.login-box a,
.login-box__text a {
  color: #fff;
}

.blue-block-button--on-blue-bg::before {
  border-color: #fff;
}

.blue-block-button--round {
  border-radius: 100px;
  font-size: 14px;
  line-height: 30px;
  font-weight: 500;
  padding: 1px 20px !important;
}

.blue-block-button--teal {
  border-color: #95f7ff;
  color: #95f7ff;
}

.blue-block-button--teal::after {
  border-color: #95f7ff;
}

.blue-block-button--teal:focus .blue-block-button--teal:hover,
.blue-block-button--teal:hover {
  color: #95f7ff;
}

.blue-block-button--teal:focus .blue-block-button--teal:hover::after,
.blue-block-button--teal:hover::after {
  border-color: #95f7ff;
}

@media all and (min-width: 768px) {
  .blue-block-button,
  .white-block-button {
    padding: 5px 12px;
  }

  .blue-block-button--pad,
  .white-block-button--pad {
    padding: 8px 16px;
  }

  .blue-block-button--extra-pad,
  .white-block-button--extra-pad {
    padding: 5px 16px;
  }

  .blue-block-button--arrow-right,
  .blue-block-button--next-arrow,
  .white-block-button--arrow-right,
  .white-block-button--next-arrow {
    padding-right: 50px;
  }
}

.form-buttons--side-by-side {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.form-buttons--side-by-side > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(50% - 8px);
  flex: 0 0 calc(50% - 8px);
}

.form-buttons--pad {
  padding: 0 30px 30px;
}

.form-buttons__desktop-only {
  display: none;
}

@media all and (min-width: 900px) {
  .product-detail-intro__buttons > * {
    margin-bottom: 10px;
    margin-right: 20px;
  }

  .form-buttons--pad {
    padding: 20px 20px 20px 0;
  }

  .form-buttons__desktop-only {
    display: inline;
  }
}

.block-img {
  display: block;
  width: 100%;
}

hr {
  margin: 30px;
  background: #000;
  width: 100%;
  height: 1px;
}

.block-img,
img {
  height: auto;
}

img.lazy {
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-out;
  transition: opacity 0.2s ease-out;
  will-change: opacity;
}

.locator-wrapper {
  padding: 40px 30px;
  max-width: 1000px;
  margin: 0 auto;
}

@media all and (min-width: 768px) {
  .locator-wrapper {
    max-width: calc(100% - 60px);
    padding: 40px 0;
  }

  .burger-navigation--always-burger {
    max-width: 350px;
  }
}

.offscreen-until-focus {
  height: 1px;
  left: -999px;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px;
  z-index: -999;
}

.offscreen-until-focus:active,
.offscreen-until-focus:focus {
  background-color: #000;
  border: 4px solid #ff0;
  border-radius: 15px;
  color: #fff;
  font-size: 1.2em;
  height: auto;
  left: auto;
  margin: 10px 35%;
  overflow: auto;
  padding: 5px;
  text-align: center;
  top: auto;
  width: 30%;
  z-index: 999;
}

body {
  font-family: "Titillium Web", sans-serif;
  font-size: 16px;
  min-height: 100vh;
  position: relative;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
}

a {
  text-decoration: none;
}

.or {
  display: inline-block;
  margin: 0 12px;
}

.center {
  text-align: center;
}

.navbar__mobile-hidden {
  display: none !important;
}

.navbar__close-page {
  background: 0 0;
  border: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 1em;
  color: #6a6a6a;
}

.navbar__close-page:active,
.navbar__close-page:focus {
  outline-color: #00387a;
}

.navbar__pagetitle {
  display: none;
}

.navbar__under {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  color: #00387a;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  font-size: 12px;
  max-width: 100%;
  overflow: hidden;
  height: 40px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  line-height: 1.15;
  padding: 0 5px 0 0;
  position: relative;
  -webkit-transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
  will-change: transform;
  z-index: 1001;
}

.navbar__under,
.navbar__under-icons,
.navbar__under-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.navbar__under-list {
  height: 100%;
  -webkit-box-flex: 2;
  -ms-flex: 2 1 auto;
  flex: 2 1 auto;
}

.navbar__under-icons {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.navbar__under-item,
.navbar__under-link {
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.navbar__under-item {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.3%;
  flex: 0 0 33.3%;
  max-width: 76px;
}

.navbar__under-link {
  border-top: 3px solid transparent;
  color: #6a6a6a;
  text-align: center;
  width: 100%;
}

.navbar__under-link--active {
  font-weight: 700;
  border-color: #a80432;
}

.navbar__under-piece {
  padding-left: 22px;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #00387a;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.navbar__under-piece img,
.navbar__under-piece svg {
  left: 0;
  max-width: 25px;
  overflow: visible;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.navbar__under-piece-icon {
  height: auto;
  width: 20px;
}

.navbar__under--gone {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  z-index: 1001;
}

.navbar__top {
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-height: 45px;
  position: relative;
  z-index: 1002;
}

.navbar__top--slide {
  -webkit-box-shadow: none;
  box-shadow: none;
  padding-top: 15px;
}

.navbar__link {
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  min-width: 40px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: visible;
  position: relative;
}

.navbar__link-text {
  padding-right: 5px;
  color: rgba(0, 0, 0, 0.7);
  font-size: 16px;
}

.navbar__link img,
.navbar__link svg {
  height: auto;
  -webkit-transition: -webkit-transform 0.15s;
  transition: transform 0.15s;
  transition: transform 0.15s, -webkit-transform 0.15s;
  width: 25px;
  will-change: transform;
}

.navbar__link:active img,
.navbar__link:active svg,
.navbar__link:focus img,
.navbar__link:focus svg,
.navbar__link:hover img,
.navbar__link:hover svg {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.navbar__link:focus {
  outline: 1px dotted #000;
}

.navbar__link:visited {
  color: #00387a;
}

.navbar__link:first-child {
  margin-left: 0;
}

.navbar__link:last-child {
  margin-right: 0;
}

.navbar__left {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fff),
    to(#d8d8d8)
  );
  background: linear-gradient(180deg, #fff, #d8d8d8);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 60px;
  flex: 0 0 60px;
  min-height: 45px;
  position: relative;
  z-index: 1002;
}

.navbar__left--atm-locator {
  display: none;
}

.navbar__left--slide {
  background: 0 0;
  padding-left: 10px;
}

.navbar__center {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fff),
    to(#d8d8d8)
  );
  background: linear-gradient(180deg, #fff, #d8d8d8);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 100vw;
  flex: 1 1 100vw;
  -ms-flex: 1 1 calc(100vw - 110px);
  flex: 1 1 calc(100vw - 110px);
  height: 45px;
  overflow: hidden;
  position: relative;
  z-index: 1004;
}

.navbar__center-up--gone {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

.navbar__center-up {
  height: 45px;
  left: 0;
  padding: 0 10px;
  position: absolute;
  top: 0;
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  width: 100%;
  will-change: transform;
}

.navbar__center-up-wrap,
.navbar__right {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.navbar__center-up-wrap {
  height: 100%;
}

.navbar__right {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fff),
    to(#d8d8d8)
  );
  background: linear-gradient(180deg, #fff, #d8d8d8);
  -webkit-box-flex: 0;
  -ms-flex: 0 0 55px;
  flex: 0 0 55px;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  min-height: 45px;
  overflow: hidden;
  width: 60px;
  z-index: 1004;
}

.navbar__right--atm-locator {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 55px;
  flex: 1 0 55px;
  width: auto;
  padding-right: 10px;
}

.navbar__right--slide {
  background: 0 0;
  margin-left: auto;
  margin-right: 0;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  max-width: none;
  padding-left: 10px;
}

.navbar__close {
  font-family: "Montserrat", sans-serif;
  text-align: right;
  margin-right: 60px;
  margin-left: auto;
  font-size: 1em;
  position: relative;
  padding-right: 20px;
  text-decoration: underline;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.navbar__close--slide {
  margin-right: 20px;
}

.navbar__close:after,
.navbar__close:before {
  display: inline-block;
  content: " ";
  top: 50%;
  width: 1px;
  height: 20px;
  background-color: #fff;
  right: 6px;
  position: absolute;
}

.navbar__close:after {
  -webkit-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
}

.navbar__close:before {
  -webkit-transform: translateY(-50%) rotate(-45deg);
  transform: translateY(-50%) rotate(-45deg);
}

@media screen and (min-width: 360px) {
  .navbar__right--slide {
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
  }
}

.sbold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.login-box-toggle {
  border: 0;
  display: block;
  height: 100%;
  margin-left: 0;
  margin-top: 0 !important;
  min-height: 45px;
  position: relative;
  text-align: center;
  width: 100%;
}

.login-box-toggle:active,
.login-box-toggle:focus,
.minicart-toggle:active,
.minicart-toggle:focus {
  outline-color: #00387a;
}

.minicart-toggle {
  background: 0 0;
  border: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-left: 0;
  max-width: 40px;
  position: relative;
  text-align: center;
}

.minicart-toggle__count {
  background: #a80432;
  border-radius: 7px;
  color: #fff;
  display: block;
  font-size: 10px;
  font-weight: 700;
  height: 14px;
  line-height: 14px;
  position: absolute;
  left: 50%;
  left: calc(50% + 5px);
  text-align: center;
  top: 0;
  width: 14px;
}

.minicart-toggle__count--no-text {
  left: auto;
  right: -2px;
}

.minicart-toggle__count:empty {
  display: none;
}

.main-nav {
  left: 0;
  min-height: 45px;
  position: fixed;
  top: 0;
  width: 100vw;
  max-width: 100%;
  z-index: 1002;
}

.main-nav::after {
  background-color: rgba(0, 0, 0, 0.5);
  content: " ";
  display: block;
  height: 100vh;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  width: 100%;
  z-index: 1001;
}

.main-nav__logo--mobile-up {
  max-height: 20px;
  margin: 2px auto 0;
  width: 100%;
}

.burger-button--level-2-with-sub[aria-expanded="true"]::before,
.main-nav__logo--desktop {
  display: none;
}

.main-nav__logo--mobile-only {
  margin-right: auto;
}

.main-nav__logo--mobile-only-small {
  max-width: 79px;
}

.main-nav__logo-link--mobile-only,
.main-nav__up {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.main-nav__logo-link--mobile-only {
  margin-right: auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-nav__up {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  background: #fff;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  height: 45px;
  position: relative;
  z-index: 1002;
}

.main-nav__up--slide {
  background: #00387a;
  height: 60px;
}

.main-nav--burger-open::after {
  opacity: 1;
}

.pagemode-edit .main-nav {
  position: relative !important;
}

li:last-child .burger-button--level-2::before {
  display: none;
}

.burger-button {
  color: #00387a;
  display: block;
  font-size: 16px;
  padding: 12px 45px 12px 30px;
  position: relative;
  line-height: 1.5;
}

.burger-button img,
.burger-button svg {
  height: auto;
  left: 15px;
  position: absolute;
  top: auto;
  width: 22px;
}

.burger-button--level-1 {
  padding: 12px 12px 12px 30px;
}

.burger-button--level-1-alternate {
  background-color: rgba(0, 0, 0, 0.1);
}

.burger-button--sub::after {
  background: #fff;
  bottom: 0;
  content: " ";
  display: none;
  height: 2px;
  position: absolute;
  right: 0;
  width: 100%;
}

.burger-button--level-2 {
  padding-left: 40px;
}

.burger-button--level-2::after {
  width: 80%;
  width: calc(100% - 30px);
}

.burger-button--level-2::before {
  background: #fff;
  bottom: 0;
  content: " ";
  display: block;
  height: 2px;
  position: absolute;
  right: 0;
  width: 70%;
  width: calc(100% - 35px);
}

.burger-button--level-2-last:before {
  display: none;
}

.burger-button--level-3 {
  background: #fff;
  padding-left: 50px;
}

.burger-button--level-3::after {
  width: 80%;
  width: calc(100% - 50px);
}

.burger-button--level-3-first {
  padding-left: 40px;
}

.burger-button--bottom {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 16px;
  height: 100%;
  padding: 12px 12px 12px 50px;
}

.burger-button--bottom img,
.burger-button--bottom svg {
  height: 25px;
  left: 15px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: auto;
}

.burger-button--first {
  border-top: 0;
}

.burger-button--last::after {
  display: none;
}

.burger-button--external {
  background-color: #00387a;
  color: #fff;
  margin-top: 1px;
}

.burger-button--external img,
.burger-button--external svg {
  float: left;
  left: auto;
  margin-right: 10px;
  position: relative;
  top: auto;
}

.burger-button--has-dropdown::after {
  border: 1px solid #00387a;
  border-right: 0;
  border-top: 0;
  content: " ";
  display: block;
  height: 15px;
  position: absolute;
  right: 20px;
  top: 30%;
  -webkit-transform: scaleX(1.5) rotate(-45deg) translateY(-20%);
  transform: scaleX(1.5) rotate(-45deg) translateY(-20%);
  width: 15px;
}

.burger-button--has-dropdown[aria-expanded="true"]::after {
  right: 30px;
  -webkit-transform: scaleX(1.5) rotate(135deg) translateY(-50%);
  transform: scaleX(1.5) rotate(135deg) translateY(-50%);
}

.burger-button-wrap--half {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  border-right: 1px solid #00387a;
}

.burger-button-wrap--half:last-child {
  border-right: 0;
}

.visuallyhidden {
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
}

.hidden {
}

.login-or-x {
  display: block;
}

.login-or-x--burger-open {
  text-indent: -999em;
}

.login-or-x--burger-open::after,
.login-or-x--burger-open::before {
  background-color: #fff;
  content: " ";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  top: 50%;
  width: 24px;
}

.login-or-x--burger-open::before {
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
}

.login-or-x--burger-open::after {
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
}

.burger-navigation,
.login-box {
  -webkit-overflow-scrolling: touch;
  max-height: calc(100vh - 45px);
  overflow: auto;
  position: fixed;
  scroll-behavior: smooth;
  top: 45px;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  z-index: 1002;
}

.burger-navigation[aria-hidden="true"],
.login-box[aria-hidden="true"] {
  opacity: 0;
  pointer-events: none;
}

.blue-block-white-border-button {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #00387a;
  border: 1px solid #fff;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 6px 12px;
  width: 100%;
}

.blue-block-white-border-button img,
.blue-block-white-border-button svg {
  margin-right: 10px;
  max-width: 19px;
}

.login-box {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#003875),
    to(#00aad9)
  );
  background: linear-gradient(90deg, #003875, #00aad9);
  color: #fff;
  padding: 15px 0 30px;
  right: 0;
  width: calc(100vw - 27px);
}

.login-box__desktop-only {
  /* display: none; */
}

.login-box--overlay {
  -webkit-box-shadow: 2px 2px 13px 0 rgba(255, 255, 255, 0.18);
  box-shadow: 2px 2px 13px 0 rgba(255, 255, 255, 0.18);
}

.login-box--inline {
  display: none;
  position: absolute;
}

.login-box__level-1 {
  background-color: #00387a;
  padding: 4px 0 0;
}

.login-box__level-1-item {
  margin-left: 26px;
}

.login-box__level-1:last-child {
  background: 0 0;
  padding: 20px 0 0;
}

.login-box__level-1:last-child.login-box__level-1--with-bg {
  background-color: #00387a;
  padding: 4px 0 0;
}

.login-box__level-2 {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#003875),
    to(#00aad9)
  );
  background: linear-gradient(90deg, #003875, #00aad9);
  margin-top: 4px;
  padding: 5px 10px 5px 33px;
}

.login-box__level-2-item {
  margin: 4px;
}

.login-box__level-2--no-level-1 {
  border-top: 7px solid #00387a;
}

.login-box__tabs {
  margin-bottom: 15px;
}

.login-box__tab {
  background: 0 0;
  border: 0;
  border-bottom: 2px solid transparent;
  color: #fff;
  margin-right: 10px;
  padding-bottom: 4px;
}

.login-box__tab:active,
.login-box__tab:focus {
  outline-color: #00387a;
}

.login-box__tab[aria-selected="true"] {
  border-bottom-color: #00cfe0;
  pointer-events: none;
}

.login-box__tabpanel[aria-hidden="true"] {
  display: none;
}

.login-box__hidden-until-focus {
  max-height: 0;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: opacity 0.2s linear 0.2s, max-height 0.2s linear;
  transition: opacity 0.2s linear 0.2s, max-height 0.2s linear;
}

.login-box__hidden-until-focus--focused {
  max-height: 200px;
  opacity: 1;
  pointer-events: auto;
}

.login-box__pad {
  padding: 0 26px;
}

.login-box__text {
  font-size: 14px;
  margin: 5px 0;
}

.login-box__title {
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 5px;
}

.login-box__bottom {
  background-color: #00387a;
  padding: 38px 30px 19px;
}

.login-box__level-2-item--with-border {
  margin-top: 15px;
}

.login-box__level-2-item--with-border a {
  border-top: 2px solid #00cfe0;
  padding-top: 10px;
  display: inline-block;
}

.burger-navigation {
  background-color: #fff;
  left: 0;
  width: calc(100vw - 70px);
  line-height: 1.25;
}

.burger-navigation--always-burger {
  top: 61px;
}

.burger-navigation__submenu {
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    from(#fff),
    to(#e4e4e4)
  );
  background: linear-gradient(270deg, #fff 0%, #e4e4e4 100%);
  margin-top: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.burger-navigation__submenu[aria-hidden="true"] {
  display: none;
  opacity: 0;
  pointer-events: none;
}

.burger-navigation__submenu--first {
  margin-top: 0;
}

.burger-navigation__menu-title {
  background: #00387a;
  color: #fff;
  font-size: 16px;
  padding: 12px;
}

.burger-dropdown-toggle,
.burger-navigation__bottom {
  background-color: rgba(0, 0, 0, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.burger-navigation__bottom {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0;
}

.burger-dropdown-toggle {
  background: 0 0;
  border: 0;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 60px;
  flex: 0 0 60px;
  height: 100%;
  min-height: 45px;
  text-align: center;
  width: 60px;
}

.burger-dropdown-toggle:active,
.burger-dropdown-toggle:focus {
  outline-color: #00387a;
}

.burger-dropdown-toggle--always-burger {
  background-color: transparent;
}

.burger-or-x {
  background: #00387a;
  display: block;
  height: 18px;
  margin: 14px auto;
  position: relative;
  width: 24px;
}

.burger-or-x--slide {
  background: #fff;
}

.burger-or-x--slide::after,
.burger-or-x--slide::before {
  background-color: #00387a !important;
}

.burger-or-x::after,
.burger-or-x::before {
  background-color: #e6e1dd;
  content: " ";
  display: block;
  height: 6px;
  left: 0;
  position: absolute;
  width: 100%;
}

.burger-or-x::after {
  top: 2px;
}

.burger-or-x::before {
  bottom: 2px;
}

.burger-or-x--slide--burger-open::after,
.burger-or-x--slide--burger-open::before {
  background-color: #fff !important;
}

.burger-or-x--burger-open {
  background: 0 0;
}

.burger-or-x--burger-open::after,
.burger-or-x--burger-open::before {
  background-color: #00387a;
  height: 2px;
  left: 50%;
  top: 50%;
}

.burger-or-x--burger-open::before {
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
}

.burger-or-x--burger-open::after {
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
}

.desktop-navigation {
  display: none;
}

@media all and (min-width: 375px) {
  .main-nav__logo--mobile-up {
    width: auto;
    height: 20px;
  }

  .navbar__under {
    font-size: 14px;
  }

  .navbar__under-item {
    max-width: none;
  }

  .navbar__link:first-child {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .navbar__center-roll-up,
  .navbar__center-up {
    padding: 0 14px;
  }

  .navbar__right {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 70px;
    flex: 0 0 70px;
    width: 70px;
  }

  .navbar__right--atm-locator {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    font-size: 12px;
    max-width: 100px;
    text-align: right;
    text-transform: none;
    width: auto;
  }

  .navbar__right--slide {
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
  }
}

@media all and (min-width: 414px) {
  .main-nav__logo--mobile-up {
    height: 25px;
  }
}

@media all and (min-width: 500px) {
  .main-nav__logo--mobile-up {
    height: 28px;
  }
}

@media all and (min-width: 900px) {
  .burger-navigation--always-burger {
    left: 10px;
  }

  .login-box {
    max-height: none;
    max-width: 360px;
    overflow: visible;
    right: 30px;
    top: 53px;
  }

  .login-box--inline {
    display: block;
    max-width: 360px;
    top: 0;
  }

  .login-box__text {
    font-size: 16px;
  }

  .login-box__mobile-only {
    display: none;
  }

  .login-box__desktop-only {
    display: block;
  }

  .navbar__mobile-hidden {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .navbar__top {
    -webkit-box-shadow: none;
    box-shadow: none;
    min-height: 61px;
    position: static;
  }

  .navbar__top--slide {
    padding-top: 0;
  }

  .navbar__under {
    display: none;
  }

  .navbar__link {
    margin: 0 3px;
    max-width: 70px;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }

  .navbar__link:first-child {
    margin-left: 3px;
  }

  .navbar__link img,
  .navbar__link svg {
    width: 34px;
    height: 35px;
  }

  .navbar__search-button {
    max-width: 100px;
  }

  .navbar__left {
    background: 0 0;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 316px;
    flex: 0 0 316px;
    max-height: 61px;
    overflow: hidden;
    padding: 15px 0 0;
  }

  .navbar__center {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    background: 0 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 273px;
    flex: 1 0 273px;
    height: 61px;
    overflow: visible;
    position: static;
  }

  .navbar__center-up {
    display: none;
  }

  .navbar__center--pagetitle {
    padding-bottom: 9px;
    height: 61px;
    min-height: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    text-align: left;
    padding-left: 20px;
    color: #6a6a6a;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    font-size: 0.73em;
  }

  .navbar__right {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: 0 0;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 210px;
    flex: 1 0 210px;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    max-height: 61px;
    min-width: 200px;
    overflow: hidden;
    width: auto;
  }

  .navbar__close {
    font-family: "Montserrat", sans-serif;
    text-align: right;
    margin-right: 60px;
    margin-left: auto;
    font-size: 1em;
    position: relative;
    padding-right: 20px;
    text-decoration: underline;
    float: right;
  }

  .navbar__close--slide {
    margin-right: 20px;
  }

  .navbar__close:after,
  .navbar__close:before {
    display: inline-block;
    content: " ";
    top: 50%;
    width: 1px;
    height: 20px;
    background-color: #fff;
    right: 6px;
    position: absolute;
  }

  .navbar__close:after {
    -webkit-transform: translateY(-50%) rotate(45deg);
    transform: translateY(-50%) rotate(45deg);
  }

  .navbar__close:before {
    -webkit-transform: translateY(-50%) rotate(-45deg);
    transform: translateY(-50%) rotate(-45deg);
  }

  .login-box-toggle {
    height: auto;
    margin-left: 10px;
    min-width: 60px;
    padding: 5px 12px;
  }

  .burger-dropdown-toggle {
    display: none;
  }

  .burger-dropdown-toggle--always-burger {
    display: block;
    background-color: transparent;
  }

  .minicart-toggle {
    padding: 0;
  }

  .desktop-navigation,
  .desktop-navigation__submenu--tall .desktop-navigation__submenu-item a {
    display: block;
  }

  .desktop-navigation__up {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .desktop-navigation__up-link:last-child {
    max-width: 90px;
  }

  .desktop-navigation__up-button {
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 7px solid transparent;
    border-top: transparent solid 7px;
    color: #6a6a6a;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 16px;
    height: 61px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    line-height: 1.25;
    padding: 0 12px;
    text-align: center;
  }

  .desktop-navigation__up-button--active,
  .desktop-navigation__up-button:focus,
  .desktop-navigation__up-button:hover {
    background: #fff;
    border-top-color: #a80432;
  }

  .desktop-navigation__up-button--active {
    font-weight: 700;
  }

  .desktop-navigation__submenu {
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    left: 0;
    max-height: 46px;
    line-height: 1.15;
    padding: 0 30px;
    position: absolute;
    text-align: left;
    width: 100%;
  }

  .desktop-navigation__submenu--tall {
    max-height: 56px;
  }

  .desktop-navigation__submenu--tall .desktop-navigation__submenu-item {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    text-align: center;
    height: 56px;
    max-width: 12%;
  }

  .desktop-navigation__submenu-item {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    margin-right: 0;
    height: 46px;
    position: static;
    z-index: 1;
  }

  .desktop-navigation__submenu-item:last-child {
    z-index: 1002;
  }

  .desktop-navigation__submenu-item:last-child
    .desktop-navigation__submenu-button,
  .desktop-navigation__submenu-item:last-child a {
    overflow: hidden;
  }

  .desktop-navigation__submenu-item:focus-within {
    z-index: 2;
  }

  .desktop-navigation__submenu-item:last-child::after {
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#efefef),
      color-stop(50%, rgba(215, 213, 206, 0.5))
    );
    background: linear-gradient(
      90deg,
      #efefef 0%,
      rgba(215, 213, 206, 0.5) 50%
    );
    content: " ";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    -webkit-transform: translateX(40px);
    transform: translateX(40px);
    width: 50vw;
    z-index: 1001;
  }

  .desktop-navigation__submenu-item:last-child > a {
    padding-left: 50px;
    z-index: 1002;
  }

  .desktop-navigation__submenu-item:last-child > a::before {
    background: rgba(0, 0, 0, 0.1);
    background: #efefef;
    content: " ";
    display: block;
    height: 38px;
    left: 0;
    position: absolute;
    top: 6px;
    -webkit-transform: scaleY(1) rotate(-67deg);
    transform: scaleY(1) rotate(-67deg);
    width: 90px;
    z-index: 1001;
  }

  .desktop-navigation__submenu-button,
  .desktop-navigation__submenu-button-text {
    font-size: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
  }

  .desktop-navigation__submenu-button {
    color: rgba(0, 0, 0, 0.7);
    display: inline-block;
    padding: 0 34px 0 0;
    width: 100%;
  }

  .desktop-navigation__submenu-button:hover {
    z-index: 2;
  }

  .desktop-navigation__submenu-button:hover::after,
  .desktop-navigation__submenu-button:hover::before {
    display: none;
    content: " ";
    top: 10px;
    width: 50px;
    position: absolute;
    height: 120%;
    background: 0 0;
    opacity: 0;
  }

  .desktop-navigation__submenu-button:hover:after {
    left: 80%;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .desktop-navigation__submenu-button:hover:before {
    left: 20%;
    opacity: 1 !important;
    -webkit-transform: translateX(-100%) rotate(45deg);
    transform: translateX(-100%) rotate(45deg);
  }

  .desktop-navigation__submenu-button:hover
    .desktop-navigation__submenu-button-text,
  .desktop-navigation__submenu-button[aria-expanded="true"]
    .desktop-navigation__submenu-button-text {
    border-bottom-color: #00387a;
  }

  .desktop-navigation__submenu-button-text {
    border-bottom: 3px solid transparent;
    z-index: 1002;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
  }

  .main-nav {
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    background: #fff !important;
    min-height: 106px;
  }

  .main-nav--slide {
    background: 0 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .main-nav--slide::after {
    background: 0 0 !important;
  }

  .main-nav::after {
    background: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(#fff),
      to(#000)
    );
    background: linear-gradient(0deg, #fff 0%, #000 100%);
    bottom: 45px;
    content: " ";
    display: block;
    height: 34px;
    opacity: 0.1;
    position: absolute;
    top: auto;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .main-nav--tall::after {
    bottom: 55px;
  }

  .main-nav--no-sub {
    min-height: 61px;
  }

  .main-nav--no-sub::after {
    bottom: 0;
  }

  .main-nav--no-sub .desktop-navigation__submenu {
    display: none;
  }

  .main-nav__up {
    background: 0 0;
    height: 106px;
    margin: 0 auto;
    max-width: 1060px;
    padding: 0 10px;
  }

  .main-nav__up--no-sub {
    height: 61px;
  }

  .main-nav__up--tall {
    height: 116px;
  }

  .main-nav__up--slide {
    background: #00387a;
  }

  .main-nav__logo {
    height: 31px;
    margin-top: 0;
    width: 316px;
  }

  .main-nav__logo--desktop {
    display: block;
  }

  .flyout-menu {
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    background-color: #f4f4f2;
    border-bottom: 2px solid #aeaca6;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    left: 0;
    max-width: 1060px;
    padding: 25px 10px 12px;
    position: absolute;
    text-align: left;
    top: 46px;
    -webkit-transition: opacity 0.15s;
    transition: opacity 0.15s;
    width: 100%;
    z-index: 1003;
  }

  .flyout-menu--tall {
    top: 56px;
  }

  .flyout-menu[aria-hidden="true"] {
    opacity: 0;
    pointer-events: none;
  }

  .flyout-menu__half,
  .flyout-menu__section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }

  .flyout-menu__half {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-transform: translateX(2px);
    transform: translateX(2px);
  }

  .flyout-menu__section {
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-left: 1px solid #979797;
    border-right: 1px solid #979797;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    max-width: 50%;
    overflow: hidden;
    padding: 0 15px;
    -webkit-transform: translateX(-1px);
    transform: translateX(-1px);
  }

  .flyout-menu__section:first-child {
    border-left: 0;
    -webkit-transform: none;
    transform: none;
  }

  .flyout-menu__section:last-child {
    padding-right: 0;
  }

  .flyout-menu__section:nth-child(3) {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }

  .flyout-menu__section--first {
    border: 0;
    -webkit-transform: none;
    transform: none;
  }

  .flyout-menu__section--last {
    border-right: 0;
  }

  .flyout-menu__section--full {
    max-width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }

  .flyout-menu__section-bottom {
    margin-top: auto;
    max-width: 100%;
  }

  .flyout-menu__section-bottom--no-stretch {
    margin-top: 0;
  }

  .flyout-menu__section-bottom a {
    margin: 4px 0;
  }

  .flyout-menu__section-bottom,
  .flyout-menu__section-list {
    color: #00387a;
    font-size: 14px;
  }

  .flyout-menu__section-list {
    margin: 12px 0;
    max-width: 100%;
  }

  .flyout-menu__section-list--split {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    width: 100%;
  }

  .flyout-menu__section-list a {
    color: #00387a;
  }

  .flyout-menu__section-list:last-child {
    margin-bottom: 0;
  }

  .flyout-menu__section-item {
    margin: 15px 0 0;
  }

  .flyout-menu__section-item--with-image {
    margin-top: 10px;
  }

  .flyout-menu__section-item:first-child {
    margin: 0;
  }

  .flyout-menu__section-item-link {
    color: #00387a;
    display: inline-block;
    overflow: hidden;
    position: relative;
    -webkit-transition: padding-left 0.2s ease-out;
    transition: padding-left 0.2s ease-out;
  }

  .flyout-menu__section-item-link::after {
    border: 1px solid #00387a;
    border-bottom: 0;
    border-left: 0;
    content: " ";
    display: block;
    height: 5px;
    position: absolute;
    right: 30px;
    top: 50%;
    top: calc(50% - 4px);
    -webkit-transform: translate(0, 0) scale(1, 2) rotate(45deg);
    transform: translate(0, 0) scale(1, 2) rotate(45deg);
    -webkit-transition: -webkit-transform 0.15s ease-out;
    transition: transform 0.15s ease-out;
    transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
    width: 5px;
  }

  .flyout-menu__section-item-link:active::after,
  .flyout-menu__section-item-link:focus::after,
  .flyout-menu__section-item-link:hover::after {
    border-color: #00387a;
    -webkit-transform: translate(0, 0) scale(1, 2) rotate(45deg);
    transform: translate(0, 0) scale(1, 2) rotate(45deg);
  }

  .flyout-menu__section-item-link:after {
    -webkit-transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
    transition: transform 0.2s ease-out, opacity 0.2s ease-out;
    transition: transform 0.2s ease-out, opacity 0.2s ease-out,
      -webkit-transform 0.2s ease-out;
    opacity: 0;
    left: -10px;
    right: auto;
    top: 50%;
  }

  .flyout-menu__section-item-link--with-icon:after,
  .flyout-menu__section-item-link--with-image:after {
    display: none;
  }

  .flyout-menu__section-item-link:active,
  .flyout-menu__section-item-link:focus,
  .flyout-menu__section-item-link:hover {
    text-decoration: none;
    padding-left: 15px;
  }

  .flyout-menu__section-item-link:active:after,
  .flyout-menu__section-item-link:focus:after,
  .flyout-menu__section-item-link:hover:after {
    -webkit-transform: translate(10px, -50%) scaleY(2) rotate(45deg);
    transform: translate(10px, -50%) scaleY(2) rotate(45deg);
    opacity: 1;
  }

  .flyout-menu__section-item-link--with-icon:active,
  .flyout-menu__section-item-link--with-icon:focus,
  .flyout-menu__section-item-link--with-icon:hover {
    padding-left: 16px;
    text-decoration: underline;
  }

  .flyout-menu__section-item-link--with-image:active,
  .flyout-menu__section-item-link--with-image:focus,
  .flyout-menu__section-item-link--with-image:hover {
    padding-left: 30px;
    text-decoration: underline;
  }

  .flyout-menu__section-item-link--with-icon {
    padding-left: 16px;
    position: relative;
    font-size: 14px;
    max-width: 100%;
  }

  .flyout-menu__section-item-link--with-icon img,
  .flyout-menu__section-item-link--with-icon svg {
    left: 0;
    max-width: 25px;
    overflow: visible;
    position: absolute;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 12px !important;
    height: auto;
    top: 2px;
    -webkit-transform: none;
    transform: none;
  }

  .flyout-menu__section-item-link--with-image {
    padding-left: 30px;
    min-height: 24px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .flyout-menu__section-item-link--with-image img {
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    max-width: 24px !important;
  }

  .flyout-menu__section-item-link:visited {
    color: #00387a;
  }

  .flyout-menu__section-intro {
    color: #373a36;
    font-size: 0.875em;
    line-height: 1.42;
    margin-top: 8px;
    max-width: 100%;
  }

  .flyout-menu__section-title {
    color: #00387a;
    font-size: 1.125em;
    font-weight: 600;
    margin: 0;
    max-width: 100%;
    width: 100%;
  }

  .flyout-menu__section-title--business {
    padding: 0 15px 10px;
    border-right: 1px solid #979797;
    margin-right: 2px;
  }

  .flyout-menu__section-title-link:active,
  .flyout-menu__section-title-link:focus,
  .flyout-menu__section-title-link:hover {
    color: #00387a;
    text-decoration: underline;
  }

  .flyout-menu__promo {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 270px;
    flex: 1 1 270px;
  }

  .flyout-menu__bottom {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 100%;
    flex: 0 1 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-width: 100%;
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
    margin-top: 22px;
    padding-right: 8px;
  }

  .flyout-menu__bottom--right {
    float: right;
    padding-right: 0;
    padding-left: 8px;
  }

  .flyout-menu__bottom img {
    display: block;
    width: 100%;
  }

  .flyout-menu__bottom a {
    display: block;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
}

.body--busy {
  pointer-events: none;
}

@media all and (min-width: 1060px) {
  .locator-wrapper {
    max-width: 1000px;
  }

  .burger-navigation--always-burger {
    left: calc((100vw - 1060px) / 2 + 30px);
    max-width: 350px;
  }

  .main-nav__up {
    padding: 0 30px;
  }

  .navbar__right--atm-locator {
    font-size: 14px;
    max-width: none;
    white-space: nowrap;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    padding-bottom: 14px;
  }

  .navbar__left--atm-locator {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .navbar__pagetitle {
    text-transform: none;
    letter-spacing: 0;
    color: #6a6a6a;
    display: block;
  }

  .flyout-menu {
    left: 30px;
    max-width: 1000px;
  }

  .login-box {
    right: calc(((100% - 1031px) / 2) + 15px);
  }

  .login-box--inline {
    right: calc((100% - 1060px) / 2 + 60px);
  }
}

@media all and (-ms-high-contrast: none) {
  .flyout-menu[aria-hidden="true"],
  ::-ms-backdrop {
    display: none;
  }

  .navbar__right {
    max-width: 300px;
  }

  .navbar__right--slide,
  ::-ms-backdrop {
    max-width: none;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .navbar__close,
  ::-ms-backdrop {
    margin: 0;
  }
}

.grecaptcha-badge {
  z-index: 99999 !important;
  bottom: 90px !important;
}

.breadcrumb {
  font-family: "Titillium Web", sans-serif;
  -webkit-box-shadow: 0 2px 21px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 21px 0 rgba(0, 0, 0, 0.1);
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  font-size: 0.8125em;
  padding: 7px 15px;
}

.breadcrumb:empty {
  opacity: 0;
  min-height: 20px;
  pointer-events: none;
}

.breadcrumb--monster {
  font-family: "Montserrat", sans-serif;
}

.breadcrumb--desktop-only {
  display: none;
}

.breadcrumb__item {
  margin-right: 8px;
  padding-right: 13px;
  position: relative;
}

.breadcrumb__item::after {
  content: "/";
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.breadcrumb.white__item::after {
  color: #fff;
}

.breadcrumb__list,
.notices__notice li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.breadcrumb--inside-module {
  color: #6a6a6a;
  background: #fff;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5;
}

.breadcrumb--inside-module .breadcrumb__item::after,
.breadcrumb--inside-module a {
  color: #6a6a6a;
}

.breadcrumb--inside-module a:hover {
  text-decoration: underline;
}

.breadcrumb--inside-module a:active,
.breadcrumb--inside-module a:focus,
.breadcrumb--inside-module a:hover {
  color: #6a6a6a;
}

.breadcrumb--non-abs {
  position: relative;
}

@media all and (min-width: 900px) {
  .breadcrumb {
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-left: auto;
    margin-right: auto;
    max-width: 1060px;
    padding-left: 30px;
    padding-right: 30px;
    position: relative;
    font-size: 0.875em;
  }

  .breadcrumb--mobile-only {
    display: none;
  }

  .breadcrumb--desktop-only {
    display: block;
  }

  .breadcrumb--inside-module {
    background: 0 0;
    color: #fff;
  }

  .breadcrumb--inside-module .breadcrumb__item--white::after,
  .breadcrumb--inside-module a {
    font-size: 16px;
    color: #fff;
  }

  .breadcrumb--inside-module a:hover {
    text-decoration: underline;
  }

  .breadcrumb--inside-module a:active,
  .breadcrumb--inside-module a:focus,
  .breadcrumb--inside-module a:hover {
    color: #fff;
  }

  .breadcrumb--inside-white-module {
    color: #6a6a6a;
    margin-bottom: 25px;
    padding-left: 50px;
  }

  .breadcrumb--inside-white-module a {
    color: #6a6a6a;
  }

  .breadcrumb--inside-white-module a:hover {
    text-decoration: underline;
  }

  .breadcrumb--inside-white-module a:active,
  .breadcrumb--inside-white-module a:focus,
  .breadcrumb--inside-white-module a:hover {
    color: #6a6a6a;
  }

  .breadcrumb--no-margin-bottom {
    margin-bottom: 0;
  }

  .breadcrumb--no-pad {
    padding-left: 0;
  }
}

.generic-modal + .backdrop {
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  left: 0;
  height: 100vh;
  top: 0;
  width: 100vw;
}

.slideshow {
  max-width: 1060px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}

.slideshow .swiper-slide {
  opacity: 0 !important;
}

.slideshow .swiper-slide-active {
  opacity: 1 !important;
}

.slideshow__slide-max-width-wrap {
  margin: 0 auto;
  max-width: 712px;
}

.slideshow__slide-max-width-wrap--mobile {
  max-width: 500px;
}

.slideshow .simple-image-and-copy p,
.slideshow p {
  color: #fff !important;
}

.slideshow__slide-img-wrap {
  position: relative;
  height: 0;
  padding-bottom: 77.8%;
  z-index: 1;
  overflow: hidden;
  max-width: 712px;
  margin: 0 auto;
}

.slideshow__slide-img-wrap--mobile {
  max-width: 500px;
  padding-bottom: 212%;
}

.slideshow__slide-hotspot {
  position: absolute;
  border: 0;
  background-color: #00387a;
  opacity: 0.2;
  -webkit-transition: opacity 0.2s ease-out;
  transition: opacity 0.2s ease-out;
  text-indent: -999em;
  z-index: 5;
  border-radius: 4px;
  -webkit-animation: cycle 2s infinite;
  animation: cycle 2s infinite;
  overflow: hidden;
}

.slideshow__slide-img-hotspots {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.slideshow__slide-hotspot:hover {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
  opacity: 0.5;
}

.slideshow__slide-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  max-width: 712px;
}

.slideshow__slide-info {
  padding: 30px;
  text-align: center;
  background-color: #fff;
}

@media all and (min-width: 768px) {
  .slideshow {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media all and (min-width: 1060px) {
  .slideshow {
    padding-left: 45px;
    padding-right: 45px;
  }
}

.kiosk-back-button {
  -webkit-box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.35);
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.35);
  position: fixed;
  bottom: calc(50vh - 120px);
  background: #373a36;
  right: 50px;
  border-radius: 4px;
  overflow: hidden;
  width: 144px;
  color: #fff;
  text-align: center;
  z-index: 250;
  -webkit-transform-origin: left top;
  transform-origin: left top;
  padding: 15px 0 0;
  top: auto;
}

.footer,
.kiosk-back-button span {
  font-family: "Montserrat", sans-serif;
}

.kiosk-back-button span {
  background-color: #a80404;
  display: block;
  margin-top: 8px;
  font-weight: 700;
  font-size: 14px;
  padding: 15px 5px;
}

.kiosk-back-button__img {
  -webkit-transform-origin: left top;
  transform-origin: left top;
  -webkit-animation: shakeDelay 8s ease-in-out infinite;
  animation: shakeDelay 8s ease-in-out infinite;
  max-width: 60%;
  display: block;
  margin: 0 auto 15px;
}

.footer {
  background: #6a6a6a;
  border-top: 8px solid #a80432;
  padding: 25px 20px;
  position: relative;
  text-align: center;
  z-index: 2;
}

.footer,
.footer a {
  color: #fff;
}

.footer__disclaimer {
  font-size: 10px;
  margin: 25px auto 0;
  max-width: 650px;
  text-align: left;
}

.footer__disclaimer a:active,
.footer__disclaimer a:focus,
.footer__disclaimer a:hover,
.footer__link-list-item a:active,
.footer__link-list-item a:focus,
.footer__link-list-item a:hover,
.footer__link-list-item-link:active,
.footer__link-list-item-link:focus,
.footer__link-list-item-link:hover {
  text-decoration: underline;
}

.footer__icons {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.footer__icon {
  margin: 0 15px;
}

.footer__link-list,
.footer__link-list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.footer__link-list {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-size: 14px;
  margin: 20px auto 10px;
  max-width: 650px;
  text-align: left;
}

.footer__link-list-item {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  margin-bottom: 5px;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.footer__link-list-item:nth-child(odd) {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 40%;
  flex: 1 1 40%;
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.footer__link-list-item--with-icon {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  margin: 0 0 10px;
}

.footer__link-list-item--with-icon:nth-child(odd) {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}

.footer__link-list-item--with-icon img,
.footer__link-list-item--with-icon svg {
  height: 24px;
  max-height: 24px;
  margin-right: 8px;
  -webkit-transform: translateY(5px);
  transform: translateY(5px);
  width: auto;
}

.footer__link-list-item:first-child {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  font-size: 14px;
  max-width: 50%;
  padding-right: 15px;
  text-align: right;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.footer__link-list-item:first-child::after {
  background: #fff;
  content: " ";
  display: block;
  height: 12px;
  position: absolute;
  right: 0;
  top: 4px;
  width: 1px;
}

.footer__link-list-item:nth-child(2) {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  font-size: 14px;
  padding-left: 15px;
  text-align: left;
}

.footer__link-list-item:nth-child(3) {
  margin-top: 10px;
}

.footer__link-list-item:nth-child(5) {
  margin-bottom: 20px;
}

.footer__back-to-top {
  background: 0 0;
  border: 0;
  color: #fff;
  height: 38px;
  position: relative;
  text-indent: -999em;
  width: 38px;
}

.footer__back-to-top:active,
.footer__back-to-top:focus {
  outline-color: #00387a;
}

.footer__back-to-top::after {
  border: 2px solid #fff;
  border-bottom: 0;
  border-left: 0;
  content: " ";
  display: block;
  height: 60%;
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
  width: 60%;
}

@media all and (min-width: 650px) {
  .footer__disclaimer {
    text-align: center;
  }

  .footer__link-list {
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 16px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 30px auto;
    text-align: center;
  }

  .footer__link-list-item {
    -ms-flex-item-align: start;
    align-self: flex-start;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding: 0 15px;
    position: relative;
    display: list-item;
  }

  .footer__link-list-item--odd,
  .footer__link-list-item:nth-child(odd) {
    -ms-flex-item-align: start;
    align-self: flex-start;
  }

  .footer__link-list-item--with-icon:nth-child(odd) {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
  }

  .footer__link-list-item:nth-child(odd) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .footer__link-list-item::after {
    background: #fff;
    content: " ";
    height: 10px;
    left: 0;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 1px;
  }

  .footer__link-list-item:first-child::after {
    display: none;
  }

  .footer__link-list-item--desktop-row-1,
  .footer__link-list-item:first-child,
  .footer__link-list-item:nth-child(2) {
    max-width: 50%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }

  .footer__link-list-item--with-icon,
  .footer__link-list-item:nth-child(3) {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    margin-bottom: 30px;
    margin-top: 30px;
    min-height: 36px;
  }

  .footer__link-list-item--with-icon::after,
  .footer__link-list-item:nth-child(3)::after,
  .footer__link-list-item:nth-child(6)::after {
    display: none;
  }
}

@media all and (-ms-high-contrast: none) {
  .footer__link-list,
  ::-ms-backdrop {
    max-width: 720px;
  }
}

.icon-listing {
  background: url(https://www.fnb-online.com/assets/images/weave-bg-ltgray.png)
    center top;
  background-size: 229px auto;
  overflow: hidden;
  padding: 30px;
  position: relative;
  text-align: center;
}

.icon-listing:after,
.icon-listing:before {
  display: block;
  content: " ";
  position: absolute;
  z-index: 3;
}

.icon-listing:after {
  clear: both;
  display: table;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  -webkit-transform: translateX(-20px);
  transform: translateX(-20px);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin: 0;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  left: 50%;
  top: 35%;
}

.icon-listing:before {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  top: -10px;
  left: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  opacity: 1;
  pointer-events: none;
}

.icon-listing--blue-weave-bg {
  background: url(https://www.fnb-online.com/assets/images/banner-bg-blue.png)
    center center;
  background-size: 460px auto;
}

.icon-listing--blue-weave-bg .icon-listing__title-inner {
  color: #95f7ff;
}

.icon-listing--blue-weave-bg .icon-listing__body,
.icon-listing--blue-weave-bg .icon-listing__body a,
.icon-listing--blue-weave-bg .icon-listing__list-item a {
  color: #fff;
}

.icon-listing--gray-weave-bg {
  background: url(https://www.fnb-online.com/assets/images/weave-bg-ltgray.png)
    center top;
  background-size: 229px auto;
}

.icon-listing--red-bg {
  background: #a80432;
}

.icon-listing--red-bg .icon-listing__title-inner {
  color: #95f7ff;
}

.icon-listing--red-bg .icon-listing__body,
.icon-listing--red-bg .icon-listing__body a,
.icon-listing--red-bg .icon-listing__list-item a {
  color: #fff;
}

.icon-listing--gray-bg {
  background: #f4f4f2;
  color: #00387a;
}

.icon-listing--light-blue-bg {
  background: #c8dfee;
  color: #00387a;
}

.icon-listing--dark-gray-bg {
  background: #6a6a6a;
}

.icon-listing--dark-gray-bg .icon-listing__title-inner {
  color: #95f7ff;
}

.icon-listing--dark-gray-bg .icon-listing__body,
.icon-listing--dark-gray-bg .icon-listing__body a,
.icon-listing--dark-gray-bg .icon-listing__list-item a {
  color: #fff;
}

.icon-listing--blue-bg {
  background: #00387a;
}

.icon-listing--blue-bg .icon-listing__title-inner {
  color: #95f7ff;
}

.icon-listing--blue-bg .icon-listing__body,
.icon-listing--blue-bg .icon-listing__body a,
.icon-listing--blue-bg .icon-listing__list-item a {
  color: #fff;
}

.icon-listing::after,
.icon-listing::before {
  opacity: 0;
  pointer-events: none;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.icon-listing--loading::after,
.icon-listing--loading::before {
  opacity: 1;
  pointer-events: auto;
}

.icon-listing__slide-title {
  font-size: 1.25em;
  line-height: 1.175;
  margin-top: 12px;
  padding-left: 0;
  margin-bottom: 50px;
  width: 100%;
}

.icon-listing__prev-button {
  background: 0 0;
  border: 0;
  color: #00387a;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 50px;
  flex: 1 1 50px;
  display: inline-block;
  overflow: visible;
  position: absolute;
  left: 40px;
  bottom: 20px;
  min-width: 50px;
}

.icon-listing__prev-button:active,
.icon-listing__prev-button:focus {
  outline-color: #00387a;
}

.icon-listing__prev-button::after {
  border: 1px solid #00387a;
  border-bottom: 0;
  border-right: 0;
  content: " ";
  display: block;
  height: 10px;
  left: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-20px, -50%) rotate(-45deg);
  transform: translate(-20px, -50%) rotate(-45deg);
  -webkit-transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  width: 10px;
  z-index: 3;
}

.icon-listing__prev-button:active::after,
.icon-listing__prev-button:focus::after,
.icon-listing__prev-button:hover::after {
  -webkit-transform: translate(-10px, -50%) scaleY(1.4) rotate(-45deg);
  transform: translate(-10px, -50%) scaleY(1.4) rotate(-45deg);
}

.icon-listing__swiper-container {
  margin-left: 0;
}

.icon-listing__choice-button {
  border: 1px solid #00387a;
  color: #00387a;
  display: inline-block;
  margin-top: 25px;
  padding: 4px 12px;
  margin-right: 25px;
  margin-left: 0;
}

.icon-listing__choice-button:active,
.icon-listing__choice-button:focus,
.icon-listing__choice-button:hover {
  background: #00387a;
  color: #fff;
}

.icon-listing__choice-button--result {
  padding: 4px 36px;
}

.icon-listing__decision-view-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.icon-listing__decision-view-content,
.icon-listing__decision-view-question {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.icon-listing__decision-view-content {
  padding-bottom: 150px;
  position: relative;
}

.icon-listing__decision-view-question {
  text-align: left;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  padding-left: 32%;
}

.icon-listing__wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  z-index: 2;
}

.icon-listing__title {
  position: static;
  z-index: 3;
}

.icon-listing__title-inner {
  position: relative;
  z-index: 2;
}

.icon-listing__title::before {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fff),
    to(rgba(255, 255, 255, 0))
  );
  background: linear-gradient(to bottom, #fff 0%, rgba(255, 255, 255, 0) 100%);
  content: " ";
  display: block;
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}

.icon-listing__title--no-fade {
  position: static;
  z-index: 3;
}

.icon-listing__title--no-fade-inner {
  position: relative;
  z-index: 2;
}

.icon-listing__slide {
  text-align: left;
}

.icon-listing__default-view {
  width: 100%;
}

.icon-listing__decision-view {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  overflow: hidden;
  -webkit-transition: opacity 0.3s ease-in-out,
    -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out,
    -webkit-transform 0.3s ease-in-out;
  width: 100%;
  opacity: 0;
  padding-top: 30px;
}

.icon-listing__decision-view--active {
  opacity: 1;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.icon-listing__default-view {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  overflow: hidden;
  -webkit-transition: opacity 0.3s ease-in-out,
    -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out,
    -webkit-transform 0.3s ease-in-out;
}

.icon-listing__default-view--gone {
  opacity: 0;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.icon-listing__body {
  font-size: 0.875em;
  margin-top: 10px;
  z-index: 2;
}

.icon-listing__body a {
  color: #00387a;
}

.icon-listing__list {
  color: #00387a;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 30px;
  padding-left: 8px;
  z-index: 2;
}

.icon-listing__list-item {
  padding-right: 8px;
  width: 33.33333%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 col-width(2);
  flex: 0 0 col-width(2);
  line-height: 1.25em;
  font-size: 0.875em;
  margin-bottom: 20px;
}

.icon-listing-item {
  position: relative;
}

.icon-listing-item svg {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: block;
  height: auto;
  left: 53%;
  top: 50%;
  width: 34%;
  z-index: 3;
}

.icon-listing-item--standalone {
  font-size: 14px;
  left: 0;
  margin: 0 auto 30px;
  max-width: 30%;
  position: absolute;
  text-align: center;
  top: 0;
}

.icon-listing-item--block {
  margin: 0 auto;
  max-width: 130px;
}

.icon-listing-item--block .icon-listing-item__img-wrap img {
  left: 50%;
}

.icon-listing-item__img-wrap {
  height: 0;
  padding-bottom: 85%;
  position: relative;
}

.icon-listing-item__boxes,
.icon-listing-item__img-wrap img {
  position: absolute;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: opacity 0.2s ease-out, -webkit-transform 0.2s;
  transition: transform 0.2s, opacity 0.2s ease-out;
  transition: transform 0.2s, opacity 0.2s ease-out, -webkit-transform 0.2s;
  left: 50%;
}

.icon-listing-item__boxes {
  height: auto;
  top: 5px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  -webkit-transition: -webkit-transform 0.15s;
  transition: transform 0.15s;
  transition: transform 0.15s, -webkit-transform 0.15s;
  width: 40%;
  z-index: 1;
}

.icon-listing-item__boxes--block {
  top: 0;
}

.icon-listing-item__bag {
  position: relative;
  width: 100%;
  z-index: 2;
}

.icon-listing-item__link {
  color: #00387a;
}

.icon-listing-item__link--standalone {
  position: relative;
}

.icon-listing-item__link--standalone svg {
  top: 20%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.icon-listing-item__link:hover .icon-listing-item__boxes {
  -webkit-transform: scale(1.1) translate(-50%, -15%);
  transform: scale(1.1) translate(-50%, -15%);
}

.body--is-touch .icon-listing__choice-button:hover {
  color: #00387a;
  background: #fff;
}

@media all and (min-width: 475px) {
  .icon-listing-item__link--standalone svg {
    top: 25%;
  }
}

@media all and (min-width: 550px) {
  .icon-listing-item {
    font-size: 1em;
  }

  .icon-listing-item__link--standalone svg {
    top: 28%;
  }
}

@media all and (min-width: 850px) {
  .icon-listing__prev-button {
    display: block;
    position: relative;
    text-indent: -999em;
    width: 50px;
    margin-right: 50px;
    max-width: 50px;
    overflow: hidden;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50px;
    flex: 0 0 50px;
    min-height: 60px;
  }

  .icon-listing__prev-button::after {
    border: 1px solid #00387a;
    border-bottom: 0;
    border-right: 0;
    content: " ";
    display: block;
    height: 30px;
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%) scaleY(1.6) rotate(-45deg);
    transform: translate(0, -50%) scaleY(1.6) rotate(-45deg);
    -webkit-transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
    width: 30px;
  }

  .icon-listing__slide-title {
    padding: 0;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    width: 100%;
  }

  .icon-listing__choice-button {
    margin-top: 25px;
    margin-left: 0;
  }

  .icon-listing__choice-button--first {
    margin-top: 25px;
  }

  .icon-listing__decision-view {
    padding-top: 60px;
  }

  .icon-listing__decision-view-content {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    width: 100%;
  }

  .icon-listing__decision-view-question {
    width: calc(100% - 200px);
    padding-left: 0;
  }

  .icon-listing-item--standalone {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 200px;
    flex: 0 0 200px;
    font-size: 16px;
    margin-bottom: 0;
    position: relative;
    min-width: 200px;
  }
}

@media all and (min-width: 1000px) and (-ms-high-contrast: none) {
  .icon-listing-item__link--standalone svg,
  ::-ms-backdrop {
    max-height: 100px;
    top: 18%;
  }
}

@media all and (min-width: 1060px) {
  .icon-listing {
    margin-bottom: 0;
  }

  .icon-listing::before {
    top: 0;
  }

  .icon-listing__body,
  .icon-listing__list {
    margin-left: auto;
    margin-right: auto;
    max-width: 790px;
  }

  .icon-listing__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0;
  }

  .icon-listing__list-item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 160px;
    flex: 0 0 160px;
  }

  .icon-listing__list-item--briefcase {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 138px;
    flex: 0 0 138px;
  }

  .icon-listing__desciion-view {
    min-width: 1060px;
  }

  .icon-listing__body {
    padding-left: 8.33333%;
    padding-right: 8.33333%;
  }

  .icon-listing-item {
    margin-left: 18px;
    margin-right: 18px;
    padding-right: 0;
  }

  .icon-listing-item--block {
    margin-left: auto;
    margin-right: auto;
  }
}

.icon-scroller {
  background: #00387a;
  max-width: 100vw;
  overflow: hidden;
  padding: 30px 0 10px;
  text-align: center;
}

.icon-scroller__video-link {
  background: 0 0;
  border: 0;
}

.icon-scroller__video-link:active,
.icon-scroller__video-link:focus {
  outline-color: #00387a;
}

.icon-scroller .swiper-button-next,
.icon-scroller .swiper-button-prev {
  background: 0 0;
  height: 50px;
  width: 30px;
}

.icon-scroller .swiper-button-next::after,
.icon-scroller .swiper-button-prev::after {
  border: 1px solid #fff;
  border-bottom: 0;
  border-left: 0;
  content: " ";
  display: block;
  height: 18px;
  right: 30px;
  top: calc(50% - 4px);
  -webkit-transform: translate(0, 0) scale(1, 2) rotate(45deg);
  transform: translate(0, 0) scale(1, 2) rotate(45deg);
  -webkit-transition: -webkit-transform 0.15s ease-out;
  transition: transform 0.15s ease-out;
  transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
  width: 18px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  position: absolute;
  top: 50%;
  left: 50%;
}

.icon-scroller .swiper-button-next:active::after,
.icon-scroller .swiper-button-next:focus::after,
.icon-scroller .swiper-button-next:hover::after,
.icon-scroller .swiper-button-prev:active::after,
.icon-scroller .swiper-button-prev:focus::after,
.icon-scroller .swiper-button-prev:hover::after {
  border-color: #009cd6;
  -webkit-transform: translate(0, 0) scale(1, 2) rotate(45deg);
  transform: translate(0, 0) scale(1, 2) rotate(45deg);
}

.icon-scroller .swiper-button-next {
  right: -30px;
}

.icon-scroller .swiper-button-next:active,
.icon-scroller .swiper-button-next:focus,
.icon-scroller .swiper-button-next:hover {
  right: -30px;
}

.icon-scroller .swiper-button-next:after,
.icon-scroller .swiper-button-prev:after {
  left: 0;
  top: 16px;
}

.icon-scroller .swiper-button-prev {
  left: -30px;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.icon-scroller .swiper-button-prev:active,
.icon-scroller .swiper-button-prev:focus,
.icon-scroller .swiper-button-prev:hover {
  left: -30px;
}

.icon-scroller .swiper-button-disabled {
  display: none;
}

.icon-scroller .swiper-pagination-bullet {
  background: 0 0;
  border: 1px solid #009cd6;
  height: 13px;
  width: 13px;
}

.icon-scroller .swiper-pagination-bullet-active {
  background: #009cd6;
}

.icon-scroller__title {
  padding-left: 8.33333%;
  padding-right: 8.33333%;
}

.icon-scroller__wrap {
  margin: 25px auto 0;
  width: 50%;
}

.icon-scroller__container {
  max-width: 100vw;
  overflow: visible;
  padding-bottom: 50px;
}

.icon-scroller-slide {
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-out 0.3s;
  transition: opacity 0.2s ease-out 0.3s;
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s;
  pointer-events: none;
}

.icon-scroller-slide img.icon-scroller-slide__inline-img,
.icon-scroller-slide svg.icon-scroller-slide__inline-img,
.icon-scroller-slide__inline-img {
  position: relative;
  height: auto;
  width: 15px;
  top: auto;
  left: auto;
  -webkit-transform: none;
  transform: none;
  display: inline;
}

.icon-scroller-slide--enter.swiper-slide-active,
.icon-scroller-slide--in.swiper-slide-active,
.icon-scroller-slide.swiper-slide-active {
  opacity: 1;
  pointer-events: auto;
}

.icon-scroller-slide img,
.icon-scroller-slide svg {
  left: 50%;
  top: 50%;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: block;
  height: 100%;
  width: auto;
  will-change: transform;
}

.icon-scroller-slide__icon-wrap {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  height: 0;
  margin-bottom: 12px;
  overflow: hidden;
  padding-bottom: 30%;
  position: relative;
}

.icon-scroller-slide__body {
  margin-top: 8px;
}

.icon-scroller-slide--enter,
.icon-scroller-slide--in {
  opacity: 0;
}

.icon-scroller-slide:first-child {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.icon-scroller-slide:last-child {
  -webkit-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

@media screen and (max-width: 699px) {
  .icon-scroller-slide--enter,
  .icon-scroller-slide--in {
    -webkit-transition-delay: 0s !important;
    transition-delay: 0s !important;
  }
}

@media all and (min-width: 700px) {
  .icon-scroller .swiper-button-next,
  .icon-scroller .swiper-button-prev {
    z-index: 10;
    overflow: visible;
  }

  .icon-scroller .swiper-button-next:after,
  .icon-scroller .swiper-button-prev:after {
    pointer-events: none;
  }

  .icon-scroller .swiper-button-next:active,
  .icon-scroller .swiper-button-next:focus,
  .icon-scroller .swiper-button-next:hover,
  .icon-scroller .swiper-button-prev:active,
  .icon-scroller .swiper-button-prev:focus,
  .icon-scroller .swiper-button-prev:hover {
    cursor: pointer;
  }

  .icon-scroller .swiper-button-next:active:after,
  .icon-scroller .swiper-button-next:focus:after,
  .icon-scroller .swiper-button-next:hover:after,
  .icon-scroller .swiper-button-prev:active,
  .icon-scroller .swiper-button-prev:focus,
  .icon-scroller .swiper-button-prev:hover {
    left: 0;
  }

  .icon-scroller .swiper-button-prev {
    -webkit-transform: rotate(180deg) translateY(50%);
    transform: rotate(180deg) translateY(50%);
    left: 0;
  }

  .icon-scroller .swiper-button-next:after,
  .icon-scroller .swiper-button-prev:after {
    top: 16px;
    -webkit-transform: translate(0, 0) scale(1, 2) rotate(45deg);
    transform: translate(0, 0) scale(1, 2) rotate(45deg);
  }

  .icon-scroller .swiper-button-prev:active:after,
  .icon-scroller .swiper-button-prev:focus:after,
  .icon-scroller .swiper-button-prev:hover:after {
    -webkit-transform: translate(5px, 0) scale(1, 2) rotate(45deg);
    transform: translate(5px, 0) scale(1, 2) rotate(45deg);
    left: 0;
    top: 16px;
  }

  .icon-scroller .swiper-button-next {
    right: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .icon-scroller .swiper-button-next:active,
  .icon-scroller .swiper-button-next:focus,
  .icon-scroller .swiper-button-next:hover {
    right: 0;
  }

  .icon-scroller .swiper-button-next:active:after,
  .icon-scroller .swiper-button-next:focus:after,
  .icon-scroller .swiper-button-next:hover:after {
    top: 16px;
    -webkit-transform: translate(5px, 0) scale(1, 2) rotate(45deg);
    transform: translate(5px, 0) scale(1, 2) rotate(45deg);
  }

  .icon-scroller__wrap {
    width: 80%;
  }

  .icon-scroller__container {
    overflow: hidden;
  }

  .icon-scroller .icon-scroller-slide--enter,
  .icon-scroller .icon-scroller-slide--in {
    opacity: 1;
    pointer-events: auto;
  }

  .icon-scroller .swiper-pagination {
    display: none;
  }

  .icon-scroller .swiper-pagination--more-than-3 {
    display: block;
  }
}

@media all and (min-width: 1060px) {
  .icon-scroller {
    padding: 45px 60px;
  }

  .icon-scroller > div {
    overflow: hidden;
  }

  .icon-scroller__wrap {
    margin: 60px auto 0;
    max-width: 950px;
    overflow: hidden;
    width: 100%;
  }

  .icon-scroller__container {
    overflow: hidden;
    max-width: 950px;
    opacity: 0;
    -webkit-transition: opacity ease-out 0.3s, -webkit-transform ease-out 0.6s;
    transition: opacity ease-out 0.3s, transform ease-out 0.6s;
    transition: opacity ease-out 0.3s, transform ease-out 0.6s,
      -webkit-transform ease-out 0.6s;
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
    will-change: opacity, transform;
  }

  .icon-scroller__container--inited,
  .icon-scroller__container.swiper-container--inited {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }

  .icon-scroller-slide {
    border-radius: 10px;
    padding: 20px;
    -webkit-transition: all 0.15s ease-out 0.3s;
    transition: all 0.15s ease-out 0.3s;
    will-change: transform;
    z-index: 1;
    pointer-events: auto;
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    opacity: 0;
  }

  .icon-scroller-slide--enter {
    -webkit-transition-delay: 0s !important;
    transition-delay: 0s !important;
  }

  .icon-scroller-slide:last-child {
    margin-right: 0 !important;
  }

  .icon-scroller-slide:hover {
    z-index: 2;
    background: #001b4b;
    -webkit-box-shadow: 0 11px 40px -20px rgba(0, 0, 0, 0.5);
    box-shadow: 0 11px 40px -20px rgba(0, 0, 0, 0.5);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.image-over-copy__edge-image,
.popular-products__edge-image,
.product-bundle__edge-image {
  display: none;
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: auto;
}

.image-over-copy__edge-image--left,
.popular-products__edge-image--left,
.product-bundle__edge-image--left {
  left: 0;
}

.image-over-copy__edge-image--right,
.popular-products__edge-image--right,
.product-bundle__edge-image--right {
  right: 0;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.product-bundle__buttons a,
.product-bundle__buttons button,
.product-bundle__buttons span {
  margin-bottom: 20px;
}

.image-over-copy,
.product-bundle {
  overflow: hidden;
  text-align: left;
}

.image-over-copy--gray,
.product-bundle--gray {
  background: rgba(0, 0, 0, 0.1);
}

.image-over-copy__img-wrap,
.product-bundle__img-wrap {
  height: 0;
  overflow: hidden;
  padding-bottom: 53%;
  position: relative;
}

.image-over-copy__img-wrap img,
.product-bundle__img-wrap img {
  left: 50%;
  top: 50%;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: none;
  width: 120%;
}

.image-over-copy__img-wrap--dark-blue,
.image-over-copy__img-wrap--light,
.product-bundle__img-wrap--dark-blue,
.product-bundle__img-wrap--light {
  height: auto;
  margin: 30px;
  padding-bottom: 0;
}

.image-over-copy__img-wrap--dark-blue img,
.image-over-copy__img-wrap--light img,
.product-bundle__img-wrap--dark-blue img,
.product-bundle__img-wrap--light img {
  left: auto;
  position: relative;
  top: auto;
  -webkit-transform: none;
  transform: none;
  width: 100%;
}

.image-over-copy--light,
.product-bundle--light {
  background: rgba(0, 0, 0, 0.15);
  color: #373a36;
}

.image-over-copy--dark-blue,
.product-bundle--dark-blue {
  background: #00387a;
  color: #fff;
  padding-top: 20px;
}

.image-over-copy__headline,
.product-bundle__headline {
  font-family: "Titillium Web", sans-serif;
  margin-bottom: 15px;
}

.image-over-copy__headline--light,
.product-bundle__headline--light {
  color: #00387a;
}

.image-over-copy__headline--small,
.product-bundle__headline--small {
  font-size: 1.75em;
}

.image-over-copy__body,
.product-bundle__body {
  color: #373a36;
  font-size: 0.875em;
}

.image-over-copy__body--dark-blue,
.product-bundle__body--dark-blue {
  color: #fff;
}

.image-over-copy--blue-weave-bg,
.product-bundle--blue-weave-bg {
  background: url(https://www.fnb-online.com/assets/images/banner-bg-blue.png)
    center center;
  background-size: 460px auto;
}

.image-over-copy--blue-weave-bg .image-over-copy__headline,
.product-bundle--blue-weave-bg .image-over-copy__headline {
  color: #95f7ff;
}

.image-over-copy--blue-weave-bg .image-over-copy__body,
.image-over-copy--blue-weave-bg .image-over-copy__body a,
.product-bundle--blue-weave-bg .image-over-copy__body,
.product-bundle--blue-weave-bg .image-over-copy__body a {
  color: #fff;
}

.image-over-copy--gray-weave-bg,
.product-bundle--gray-weave-bg {
  background: url(https://www.fnb-online.com/assets/images/weave-bg-ltgray.png)
    center top;
  background-size: 229px auto;
}

.image-over-copy--red-bg,
.product-bundle--red-bg {
  background: #a80432;
}

.image-over-copy--red-bg .image-over-copy__headline,
.product-bundle--red-bg .image-over-copy__headline {
  color: #95f7ff;
}

.image-over-copy--red-bg .image-over-copy__body,
.image-over-copy--red-bg .image-over-copy__body a,
.product-bundle--red-bg .image-over-copy__body,
.product-bundle--red-bg .image-over-copy__body a {
  color: #fff;
}

.image-over-copy--gray-bg,
.product-bundle--gray-bg {
  background: #f4f4f2;
}

.image-over-copy--light-blue-bg,
.product-bundle--light-blue-bg {
  background: #c8dfee;
}

.image-over-copy--dark-gray-bg,
.product-bundle--dark-gray-bg {
  background: #6a6a6a;
}

.image-over-copy--dark-gray-bg .image-over-copy__headline,
.product-bundle--dark-gray-bg .image-over-copy__headline {
  color: #95f7ff;
}

.image-over-copy--dark-gray-bg .image-over-copy__body,
.image-over-copy--dark-gray-bg .image-over-copy__body a,
.product-bundle--dark-gray-bg .image-over-copy__body,
.product-bundle--dark-gray-bg .image-over-copy__body a {
  color: #fff;
}

.image-over-copy--blue-bg,
.product-bundle--blue-bg {
  background: #00387a;
}

.image-over-copy--blue-bg .image-over-copy__headline,
.product-bundle--blue-bg .image-over-copy__headline {
  color: #95f7ff;
}

.image-over-copy--blue-bg .image-over-copy__body,
.image-over-copy--blue-bg .image-over-copy__body a,
.product-bundle--blue-bg .image-over-copy__body,
.product-bundle--blue-bg .image-over-copy__body a {
  color: #fff;
}

.image-over-copy__buttons,
.product-bundle__buttons {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 30px;
}

.image-over-copy__separator,
.product-bundle__separator {
  display: inline-block;
  margin: 0 12px;
}

.image-over-copy__copy-wrap,
.product-bundle__copy-wrap {
  margin: 30px;
  padding: 30px 0 40px;
  position: relative;
}

.image-over-copy__copy-wrap::before,
.product-bundle__copy-wrap::before {
  background: #a80432;
  content: " ";
  display: block;
  height: 4px;
  left: 0;
  position: absolute;
  top: 0;
  width: 50%;
}

.image-over-copy__copy-wrap--dark-blue::before,
.image-over-copy__copy-wrap--light::before,
.image-over-copy__copy-wrap--no-border::before,
.product-bundle__copy-wrap--dark-blue::before,
.product-bundle__copy-wrap--light::before,
.product-bundle__copy-wrap--no-border::before {
  display: none;
}

.image-over-copy__copy-wrap--light,
.product-bundle__copy-wrap--light {
  padding-top: 0;
}

.image-over-copy__copy-wrap--dark-blue,
.product-bundle__copy-wrap--dark-blue {
  margin-top: 0;
  padding-bottom: 10px;
  padding-top: 0;
}

@media all and (min-width: 1060px) {
  .image-over-copy,
  .popular-products,
  .product-bundle {
    position: relative;
  }

  .image-over-copy__edge-image,
  .popular-products__edge-image,
  .product-bundle__edge-image {
    display: block;
  }

  .image-over-copy--bottom-border-desktop,
  .product-bundle--bottom-border-desktop {
    border-bottom: 6px solid #a80432;
  }

  .image-over-copy--blue-weave,
  .product-bundle--blue-weave {
    background: url(https://www.fnb-online.com/assets/images/banner-bg-blue.png)
      center center;
    background-size: 460px auto;
  }

  .image-over-copy--gray-weave,
  .product-bundle--gray-weave {
    background: url(https://www.fnb-online.com/assets/images/weave-bg-ltgray.png)
      center top;
    background-size: 229px auto;
  }

  .image-over-copy--no-border,
  .product-bundle--no-border {
    border-bottom: 0;
  }

  .image-over-copy--desktop-blue,
  .product-bundle--desktop-blue {
    background: #c8dfee;
  }

  .image-over-copy__wrap,
  .product-bundle__wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 auto;
    max-width: 1080px;
    padding: 80px 60px;
  }

  .image-over-copy__wrap--no-bottom-pad,
  .product-bundle__wrap--no-bottom-pad {
    padding-bottom: 0;
  }

  .image-over-copy__wrap--dark-blue,
  .image-over-copy__wrap--light,
  .product-bundle__wrap--dark-blue,
  .product-bundle__wrap--light {
    padding-right: 0;
  }

  .image-over-copy__copy-wrap,
  .product-bundle__copy-wrap {
    margin: 0;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    padding-right: 50px;
    width: 57%;
  }

  .image-over-copy__copy-wrap--desktop-right,
  .product-bundle__copy-wrap--desktop-right {
    margin-left: 50px;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    padding-right: 0;
  }

  .image-over-copy__copy-wrap--dark-blue,
  .image-over-copy__copy-wrap--light,
  .product-bundle__copy-wrap--dark-blue,
  .product-bundle__copy-wrap--light {
    padding-left: 30px;
  }

  .image-over-copy__copy-wrap--no-border,
  .product-bundle__copy-wrap--no-border {
    padding-bottom: 0;
  }

  .image-over-copy__copy-wrap--no-border-desktop,
  .product-bundle__copy-wrap--no-border-desktop {
    padding-bottom: 0;
    padding-top: 0;
  }

  .image-over-copy__copy-wrap--no-border-desktop::before,
  .product-bundle__copy-wrap--no-border-desktop::before {
    display: none;
  }

  .image-over-copy__body,
  .product-bundle__body {
    font-family: "Montserrat", sans-serif;
    font-size: 1em;
  }

  .image-over-copy__img-wrap,
  .product-bundle__img-wrap {
    height: auto;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    overflow: visible;
    padding-bottom: 0;
    padding-left: 0;
    width: 43%;
  }

  .image-over-copy__img-wrap--desktop-left,
  .product-bundle__img-wrap--desktop-left {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .image-over-copy__img-wrap--dark-blue,
  .product-bundle__img-wrap--dark-blue {
    margin: 0;
  }

  .image-over-copy__img-wrap--capped,
  .product-bundle__img-wrap--capped {
    height: 0;
    padding-bottom: 39%;
  }

  .image-over-copy__img-wrap img,
  .product-bundle__img-wrap img {
    left: auto;
    position: relative;
    top: auto;
    -webkit-transform: none;
    transform: none;
    width: 100%;
  }
}

.image-promos {
  background: url(https://www.fnb-online.com/assets/images/weave-bg-ltgray.png)
    center top;
  background-size: cover;
  padding: 30px;
}

.image-promos:after {
  clear: both;
  content: " ";
  display: table;
}

.image-promo__img-wrap:after {
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 20;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.image-promo {
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  display: block;
  margin: 0 auto 20px;
  max-width: 500px;
  -webkit-transition: -webkit-box-shadow 0.15s;
  transition: box-shadow 0.15s;
  transition: box-shadow 0.15s, -webkit-box-shadow 0.15s;
  position: relative;
}

.image-promo:last-child {
  margin-bottom: 0;
}

.image-promo__img-wrap {
  height: 0;
  overflow: hidden;
  padding-bottom: 79.73856%;
  position: relative;
}

.image-promo__img-wrap img {
  left: 50%;
  top: 50%;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: block;
  min-width: 100%;
  width: 100%;
  height: auto;
}

.image-promo::before {
  background: #a80432;
  content: " ";
  display: block;
  height: 3px;
  left: 0;
  position: absolute;
  top: -8px;
  -webkit-transform-origin: left top;
  transform-origin: left top;
  -webkit-transition: -webkit-transform 0.15s ease-out;
  transition: transform 0.15s ease-out;
  transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
  width: 30%;
}

.image-promo:active::before,
.image-promo:focus::before,
.image-promo:hover::before {
  -webkit-transform: scaleX(1.3);
  transform: scaleX(1.3);
}

@media all and (min-width: 630px) {
  .image-promos {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 60px;
  }

  .image-promos--desktop-3-wide {
    padding: 40px 60px;
  }

  .image-promo {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    -ms-flex: 0 0 calc(50% - 20px);
    flex: 0 0 calc(50% - 20px);
    margin-bottom: 0;
  }

  .image-promo--desktop-3-wide {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3%;
    flex: 0 0 33.3%;
    -ms-flex: 0 0 calc(33.3% - 20px);
    flex: 0 0 calc(33.3% - 20px);
  }
}

@media all and (min-width: 1060px) {
  .image-promos {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 100px 60px;
  }

  .image-promo {
    margin: 0 30px;
    max-width: 442px;
  }

  .image-promo--desktop-3-wide {
    max-width: 293.33px;
  }
}

.edit-carousel {
  margin: 1px;
}

.edit-slide {
  margin: 5px 5px 0;
}

.edit-button-next {
  background-image: url(https://www.fnb-online.com/assets/images/slideshow-right-arrow.svg);
}

.edit-button-prev {
  background-image: url(https://www.fnb-online.com/assets/images/slideshow-left-arrow.svg);
}

.edit-pagination--carousel .edit-pagination-bullet {
  -webkit-box-shadow: 0 0 6px 4px #000;
  box-shadow: 0 0 6px 4px #000;
  background: #fff;
  opacity: unset !important;
}

.edit-pagination-bullet-active {
  background: #009cd6 !important;
}

@media all and (max-width: 699px) {
  .edit-pagination--carousel {
    top: 90%;
  }

  .edit-pagination-bullet {
    width: 6px;
    height: 6px;
    -webkit-box-shadow: 0 0 3px 2px #000 !important;
    box-shadow: 0 0 3px 2px #000 !important;
  }
}

.header-carousel {
  overflow: visible;
  z-index: 2;
}

.full-width-header {
  position: relative;
  z-index: 2;
}

.full-width-header__img-wrap,
.full-width-header__img-wrap-edit {
  height: 0;
  padding-bottom: 60.8%;
  position: relative;
  overflow: hidden;
}

.full-width-header__img-wrap img,
.full-width-header__img-wrap-edit img {
  display: block;
  height: 100%;
  left: 50%;
  max-height: none;
  max-width: none;
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: auto;
}

.full-width-header__img-wrap-edit img {
  left: 0 !important;
  -webkit-transform: none !important;
  transform: none !important;
}

.full-width-header__cta {
  color: #00387a;
  display: block;
  padding-right: 55px;
  position: relative;
}

.full-width-header__cta::after {
  border: 1px solid #00387a;
  border-left: 0;
  border-top: 0;
  content: " ";
  display: block;
  height: 15px;
  position: absolute;
  right: 10px;
  top: 55%;
  -webkit-transform: translateY(-50%) scaleY(1.8) rotate(-45deg);
  transform: translateY(-50%) scaleY(1.8) rotate(-45deg);
  -webkit-transition: -webkit-transform 0.15s;
  transition: transform 0.15s;
  transition: transform 0.15s, -webkit-transform 0.15s;
  width: 15px;
}

.full-width-header__cta:active,
.full-width-header__cta:focus,
.full-width-header__cta:hover {
  color: #00387a;
}

.full-width-header__cta:active::after,
.full-width-header__cta:focus::after,
.full-width-header__cta:hover::after {
  -webkit-transform: translate(10px, -50%) scaleY(1.8) rotate(-45deg);
  transform: translate(10px, -50%) scaleY(1.8) rotate(-45deg);
}

.full-width-header__cta-wrap {
  background: rgba(255, 255, 255, 0.9);
  bottom: 20px;
  left: 0;
  max-width: 83.33333%;
  padding: 14px 10px 20px 30px;
  position: absolute;
}

.full-width-header__headline {
  font-size: 26px;
  line-height: 1.07;
}

.full-width-header__headline-emphasis {
  font-weight: 700;
}

.full-width-header__subheadline {
  display: none;
  font-size: 16px;
  font-weight: 700;
}

@media screen and (max-width: 699px) and (orientation: landscape) {
  .full-width-header {
    max-height: calc(100vh - 135px);
    overflow: hidden;
  }
}

@media all and (min-width: 700px) {
  .full-width-header__subheadline {
    display: block;
  }

  .full-width-header__cta {
    padding-right: 40px;
  }

  .full-width-header__img-wrap,
  .full-width-header__img-wrap-edit {
    height: 0;
    overflow: hidden;
    padding-bottom: 29.16667%;
    position: relative;
  }

  .full-width-header__img-wrap img,
  .full-width-header__img-wrap-edit img {
    left: 50%;
    position: absolute;
    display: block;
    top: 0;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    will-change: transform;
    width: 100%;
    height: auto;
    max-width: none;
    min-width: 0;
  }

  .full-width-header__cta-wrap {
    bottom: 60px;
    max-width: calc(100% - 375px);
    padding-left: 30px;
  }
}

@media all and (min-width: 1060px) {
  .full-width-header__cta-wrap {
    padding-left: calc(30px + ((100vw - 1060px) / 2));
  }

  .full-width-header__headline,
  .full-width-header__subheadline {
    max-width: 385px;
  }

  .full-width-header__headline {
    font-size: 2em;
  }
}

.page-header-title-with-image {
  font-family: "Titillium Web", sans-serif;
  background: #00387a;
  color: #fff;
  padding: 30px;
  position: relative;
}

.page-header-title-with-image__title {
  font-size: 26px;
  font-weight: 400;
  margin-bottom: 15px;
}

.page-header-title-with-image p {
  font-size: 14px;
}

.page-header-title-with-image__img {
  display: none;
}

.page-header-title-with-image__wrap-col--right {
  margin-top: 20px;
  font-size: 14px;
}

.page-header-title-with-image__wrap--breadcrumbs {
  margin-top: 20px;
}

@media all and (min-width: 900px) {
  .page-header-title-with-image {
    background: url(https://www.fnb-online.com/assets/images/weave-bg-ltgray.png)
      center top;
    background-size: 229px auto;
    color: #000;
    overflow: hidden;
    padding: 0;
    position: relative;
  }

  .page-header-title-with-image p {
    font-size: 16px;
  }

  .page-header-title-with-image--new {
    background: url(https://www.fnb-online.com/assets/images/banner-bg-blue.png)
      center center;
    background-size: 460px auto;
    color: #fff;
    font-size: 16px;
  }

  .page-header-title-with-image--new p {
    line-height: 1.625em;
  }

  .page-header-title-with-image__wrap {
    margin: 0 auto;
    max-width: 1060px;
    padding: 15px 40% 30px 30px;
    position: relative;
    z-index: 2;
  }

  .page-header-title-with-image__wrap--new {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-right: 30px;
  }

  .page-header-title-with-image__wrap--breadcrumbs {
    padding-top: 10px;
  }

  .page-header-title-with-image__wrap-col {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    padding-right: 6%;
    max-width: 50%;
  }

  .page-header-title-with-image__wrap-col--right {
    margin-top: 10px;
    font-size: 16px;
  }

  .page-header-title-with-image__title {
    font-size: 2.25em;
    line-height: 1em;
  }

  .page-header-title-with-image__heading {
    color: #a80432;
    font-size: 18px;
  }

  .page-header-title-with-image__heading--new {
    color: #fff;
  }

  .page-header-title-with-image__img {
    bottom: 0;
    display: block;
    height: 110%;
    left: 60%;
    position: absolute;
    width: auto;
    z-index: 1;
  }
}

@media all and (min-width: 1350px) {
  .page-header-title-with-image__img {
    left: auto;
    right: 0;
  }
}

.rate-table {
  color: #373a36;
  font-size: 16px;
  margin-top: 40px;
  text-align: left;
}

.rate-table--last {
  margin-top: 0;
}

.rate-table__table {
  font-size: 14px;
  width: 100%;
  table-layout: fixed;
}

.rate-table__title {
  color: #00387a;
  font-weight: 700;
  font-size: 1em;
  margin-bottom: 5px;
}

.rate-table__title--regional {
  margin-bottom: 15px;
}

.rate-table--regional {
  position: relative;
}

.rate-table--regional:after {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  content: " ";
  display: block;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  -webkit-transform: translateX(-20px);
  transform: translateX(-20px);
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  margin: 0;
  top: 35%;
  z-index: 3;
}

.rate-table--regional:before {
  display: block;
  content: " ";
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  width: 100%;
  height: 100%;
  top: -10px;
  left: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  opacity: 1;
  z-index: 3;
  pointer-events: none;
}

.rate-table::after,
.rate-table::before {
  opacity: 0;
  pointer-events: none;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.rate-table--loading::after,
.rate-table--loading::before {
  opacity: 1;
  pointer-events: auto;
}

.rate-table__row {
  border-top: 2px solid #979797;
}

.rate-table__header .rate-table__row:only-child {
  border-top: 0;
}

.rate-table tbody .rate-table__row:only-child {
  border-bottom: 2px solid #979797;
}

.rate-table__cell {
  padding: 8px 0;
}

.rate-table__cell:last-child {
  text-align: right;
  width: 33.3%;
}

.rate-table__cell--header {
  padding-top: 0;
  vertical-align: bottom;
}

.rate-table__header {
  font-weight: 700;
}

label.accordion__content-title {
  display: inline-block;
  margin-top: 0;
}

.accordion {
  background: url(https://www.fnb-online.com/assets/images/weave-bg-ltgray.png)
    center top;
  background-size: 229px auto;
  font-size: 14px;
  overflow: hidden;
  padding: 12px 0 0;
  text-align: left;
}

.accordion a {
  text-decoration: underline;
}

.accordion--article-detail {
  background: 0 0;
}

.accordion--no-pad {
  padding: 0;
}

.accordion__separator {
  border-top: 2px solid #979797;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 12px;
}

.accordion__controls {
  position: relative;
}

.accordion__promo {
  display: block;
  height: 7px;
  left: 0;
  overflow: hidden;
  padding-right: 8px;
  position: absolute;
  text-indent: -999em;
  top: 0;
  width: 20%;
}

.accordion__promo-inner,
.accordion__promo-inner::after {
  background: #a80432;
  display: block;
  height: 100%;
  position: relative;
  width: 100%;
}

.accordion__promo-inner::after {
  content: " ";
  height: 12px;
  position: absolute;
  right: -4px;
  top: -6px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  width: 12px;
}

.accordion__single-piece--article-detail {
  padding: 3px 0;
}

.accordion__tabs {
  border-radius: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto 30px;
}

.accordion__tabs[aria-hidden="true"],
.accordion__toggle[aria-expanded="true"]::before {
  display: none;
}

.accordion__tab {
  background: 0 0;
  border: 0;
  font-family: "Titillium Web", sans-serif;
}

.accordion__tab:active,
.accordion__tab:focus {
  outline-color: #00387a;
}

.accordion__tab:focus {
  outline: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__toggle {
  background: 0 0;
  border: 0;
  background: #fff;
  -webkit-box-shadow: 0 2px 21px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 21px 0 rgba(0, 0, 0, 0.1);
  color: #00387a;
  font-size: 16px;
  margin-bottom: 2px;
  padding: 12px 30px 12px 45px;
  position: relative;
  text-align: left;
  width: 100%;
}

.accordion__toggle:active,
.accordion__toggle:focus {
  outline-color: #00387a;
}

.accordion__toggle::after,
.accordion__toggle::before {
  background: #00387a;
  content: " ";
  display: inline-block;
  height: 12px;
  left: 30px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 1px;
}

.accordion__toggle::after {
  -webkit-transform: translateY(-50%) rotate(90deg);
  transform: translateY(-50%) rotate(90deg);
}

.accordion__toggle--last {
  margin-bottom: 0;
}

.accordion__toggle--article-detail {
  color: #00387a !important;
  background: rgba(0, 0, 0, 0.1);
  font-size: 1.28571em;
  padding-left: 65px;
  padding-right: 32px;
}

.accordion__toggle--article-detail:after,
.accordion__toggle--article-detail:before {
  left: 45px;
}

.accordion__list-item {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
}

.accordion__list-item:first-child {
  margin-top: 0;
}

.accordion__text-list {
  font-family: "Montserrat", sans-serif;
  margin-bottom: 20px;
  margin-left: 5px;
  padding-left: 12px;
}

.accordion__text-list-item {
  margin: 4px 0;
  padding-left: 22px;
  position: relative;
}

.accordion__text-list-item::before {
  background: #6a6a6a;
  border-radius: 50%;
  content: " ";
  display: block;
  height: 3px;
  left: 0;
  position: absolute;
  top: 8px;
  width: 3px;
}

.accordion__img {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 56px;
  flex: 0 0 56px;
  height: auto;
  margin-right: 20px;
  width: 100%;
  max-width: 56px;
}

.accordion__content {
  font-family: "Titillium Web", sans-serif;
  color: #6a6a6a;
  max-height: 500vh;
  -webkit-transition: max-height 0.3s ease-out;
  transition: max-height 0.3s ease-out;
  width: 100%;
}

.accordion__content-wrap {
  padding: 20px 30px 30px;
}

.accordion__content-wrap--article-detail {
  font-size: 16px;
}

.accordion__content[aria-hidden] {
  max-height: 0;
  overflow: hidden;
  pointer-events: none;
}

.accordion__content-title {
  color: #00387a;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 20px;
}

.accordion__content-title:first-child {
  margin-top: 0;
}

.accordion__content-title--promo {
  font-size: 1.75em;
  font-weight: 400;
}

.accordion__content-title--link {
  position: relative;
  padding-right: 20px;
}

.accordion__content-title--link:before {
  border: 2px solid #00387a;
  content: " ";
  display: block;
  width: 8px;
  height: 8px;
  right: 0;
  position: absolute;
  top: 64%;
  -webkit-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
  border-right: 0;
  border-bottom: 0;
}

@media all and (min-width: 768px) {
  .accordion--no-pad {
    background: 0 0;
    padding-top: 0;
  }

  .accordion__toggle {
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  }
}

@media all and (min-width: 960px) {
  .rate-table__table {
    max-width: 70%;
  }

  .accordion {
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#fff),
      to(rgba(106, 106, 106, 0.24))
    );
    background: linear-gradient(180deg, #fff, rgba(106, 106, 106, 0.24));
    padding-bottom: 70px;
    padding-top: 40px;
    text-align: center;
  }

  .accordion--stay-on-desktop {
    background: 0 0;
    font-size: 1em;
    padding: 0;
  }

  .accordion--no-pad {
    background: 0 0;
    padding-top: 0;
  }

  .accordion__promo {
    height: 25px;
    width: 33px;
  }

  .accordion__promo-inner {
    display: none;
  }

  .accordion__controls {
    -webkit-transition: min-height 0.35s ease-out;
    transition: min-height 0.35s ease-out;
  }

  .accordion__tabs {
    background: #fff;
    background-clip: content-box;
    -webkit-box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.15);
    display: inline-block;
    font-size: 16px;
  }

  .accordion__tabs-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .accordion__content {
    left: 50%;
    max-height: none;
    opacity: 1;
    position: absolute;
    top: 0;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
    transition: transform 0.5s, opacity 0.5s;
    transition: transform 0.5s, opacity 0.5s, -webkit-transform 0.5s;
  }

  .accordion__content[aria-hidden] {
    max-height: none;
    opacity: 0;
  }

  .accordion__content--stay-on-desktop {
    border: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;
    -webkit-transform: none;
    transform: none;
    left: auto;
    top: auto;
    max-height: 150vh;
    -webkit-transition: max-height 0.3s ease-out;
    transition: max-height 0.3s ease-out;
  }

  .accordion__content--stay-on-desktop[aria-hidden] {
    max-height: 0;
    overflow: hidden;
    opacity: 1;
    pointer-events: none;
  }

  .accordion__content-wrap--flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .accordion__content-wrap--article-detail {
    padding-bottom: 50px;
  }

  .accordion__content-section {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    padding-bottom: 20px;
  }

  .accordion__content--entering {
    opacity: 0;
    -webkit-transform: translate(-50%, -100%);
    transform: translate(-50%, -100%);
  }

  .accordion__content--leaving {
    opacity: 0;
    -webkit-transform: translate(-50%, 100%);
    transform: translate(-50%, 100%);
  }

  .accordion__separator,
  .accordion__tab {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }

  .accordion__tab {
    border-right: 2px solid #979797;
    color: #6a6a6a;
    display: inline-block;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding: 10px 25px;
    position: relative;
    -webkit-transition: background 0.2s;
    transition: background 0.2s;
  }

  .accordion__tab:last-child {
    border-bottom-right-radius: 30px;
    border-right: 0;
    border-top-right-radius: 30px;
    padding-right: 35px;
  }

  .accordion__tab:first-child {
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
    padding-left: 35px;
  }

  .accordion__tab:active,
  .accordion__tab:focus,
  .accordion__tab:hover {
    background: #70c9ea;
    color: #000;
  }

  .accordion__tab--active {
    background: #70c9ea;
    color: #000;
    overflow: visible;
  }

  .accordion__tab--active::after {
    border: solid transparent;
    border-color: #70c9ea transparent transparent;
    border-width: 12px;
    content: " ";
    height: 0;
    left: 50%;
    margin-left: -12px;
    pointer-events: none;
    position: absolute;
    top: 100%;
    width: 0;
  }

  .accordion__toggle {
    display: none;
  }

  .accordion__toggle--stay-on-desktop {
    display: block;
    font-size: 1.375em;
    margin-bottom: 0;
    padding-left: 55px;
  }

  .accordion__toggle--stay-on-desktop::after,
  .accordion__toggle--stay-on-desktop::before {
    background: #009cd6;
    left: 35px;
    height: 20px;
  }

  .accordion__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 18px;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .accordion__list--center,
  .accordion__list-item {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .accordion__list-item {
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 0;
    max-width: 200px;
    text-align: center;
  }

  .accordion__single-piece {
    margin: 0 auto;
    max-width: 960px;
    padding: 0 30px;
    position: relative;
    text-align: left;
  }

  .accordion__single-piece:after {
    clear: both;
    content: " ";
    display: table;
  }

  .accordion__single-piece--article-detail {
    background: #fff;
    padding: 5px 0;
    max-width: none;
  }

  .accordion__img {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    margin: 0 0 20px;
    max-width: 75px;
    min-height: 50px;
  }
}

.accordion__list-item p {
  width: 100%;
}

.digital-banking-features {
  background: url(https://www.fnb-online.com/assets/images/weave-bg-ltgray.png)
    center top;
  background-size: 229px auto;
  color: #00387a;
  font-size: 0.875em;
  line-height: 1em;
  margin: 0 auto;
  padding: 30px 5px;
  text-align: center;
}

.digital-banking-features--has-form {
  padding: 0 5px;
  background: 0 0;
  margin-top: -125px;
}

.digital-banking-features--no-bg {
  background: 0 0;
}

.digital-banking-features--dark-bottom {
  padding-bottom: 0;
  position: relative;
}

.digital-banking-features--dark-bottom::after {
  background: #6a6a6a;
  bottom: 0;
  content: " ";
  display: block;
  height: 80px;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.digital-banking-features--no-shift {
  margin-bottom: 0;
  margin-top: 0;
  -webkit-transform: none;
  transform: none;
}

.digital-banking-features__wrap {
  background: url(https://www.fnb-online.com/assets/images/digital-features-bg-mobile.png)
    no-repeat center top;
  background-size: 100% auto;
  margin: 0 auto;
  max-width: 355px;
  min-height: 165px;
  padding: 8px 10px 20px;
  position: relative;
  z-index: 2;
}

.digital-banking-features__wrap--has-form {
  min-height: 230px;
  background-size: 100% 230px;
}

.digital-banking-features__wrap--has-form-3 {
  min-height: 250px;
  background-size: 100% 250px;
}

.digital-banking-features__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  margin: 0 auto;
}

.digital-banking-features__list-item {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 33.3%;
  flex: 1 1 33.3%;
  padding: 0 4px;
}

.digital-banking-features__list-item img {
  display: block;
  height: auto;
  margin: 0 auto 5px;
  width: 30px;
}

.content-page-header picture,
.digital-banking-features__list-item a,
.toned-image-copy picture {
  display: block;
}

.digital-banking-features__title {
  font-family: "Titillium Web", sans-serif;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.375;
  margin-bottom: 12px;
}

.digital-banking-features__title--has-form {
  font-size: 1rem;
  margin-bottom: 30px;
}

.digital-banking-features__title--has-form--bm {
  margin-bottom: 10px;
}

.digital-banking-features__desktop-only {
  display: none;
}

@media all and (min-width: 375px) {
  .digital-banking-features__wrap {
    padding: 10px 15px 20px;
  }

  .digital-banking-features__wrap--has-form {
    padding-top: 15px;
  }
}

@media all and (min-width: 768px) {
  .digital-banking-features--has-form {
    margin-top: 0;
  }

  .digital-banking-features--dark-bottom {
    margin-bottom: -150px;
    z-index: 27 !important;
  }

  .digital-banking-features--dark-bottom::after {
    display: none;
  }
}

@media all and (min-width: 960px) {
  .digital-banking-features + .product-strip--blue-weave {
    margin-top: -100px;
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
    padding-top: 100px;
    margin-bottom: -100px;
  }

  .solutions-center .digital-banking-features + .product-strip--blue-weave {
    -webkit-transform: none;
    transform: none;
    margin-top: 0;
    margin-bottom: 0;
  }

  .digital-banking-features + .callouts {
    -webkit-transform: translateY(-60px);
    transform: translateY(-60px);
    margin-bottom: -60px;
  }

  .digital-banking-features + .callouts--solutions-center-alt {
    -webkit-transform: none;
    transform: none;
    margin-bottom: 0;
  }

  .digital-banking-features {
    background: url(https://www.fnb-online.com/assets/images/digital-features-bg.png)
      no-repeat center center;
    background-size: 100% auto;
    font-size: 1.125em;
    height: 154px;
    margin-bottom: -100px;
    overflow: hidden;
    padding: 30px 12px 0;
    position: relative;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 832px;
    z-index: 27;
  }

  .digital-banking-features--has-form {
    padding-right: 10px;
    background-size: 100% 185px;
    background-position: center top;
    min-height: 185px;
  }

  .digital-banking-features--no-shift {
    margin-bottom: 0;
    margin-top: 0;
    -webkit-transform: none;
    transform: none;
  }

  .digital-banking-features--dark-bottom {
    margin-bottom: -150px;
    z-index: 3 !important;
  }

  .digital-banking-features__wrap {
    background: 0 0;
    max-width: none;
    padding: 0;
  }

  .digital-banking-features__title {
    font-size: 1.5rem;
    margin-bottom: 8px;
  }

  .digital-banking-features__title--has-form {
    margin-bottom: 15px;
  }

  .digital-banking-features__desktop-only {
    display: inline;
  }

  .digital-banking-features__list {
    max-width: none;
  }

  .digital-banking-features__list-item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .digital-banking-features__list-item,
  .digital-banking-features__list-item a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .digital-banking-features__list-item img {
    height: auto;
    margin: 0 5px 0 0;
    width: 30px;
    display: inline;
  }
}

.knowledge-articles--weave {
  background: url(https://www.fnb-online.com/assets/images/weave-bg-ltgray.png)
    center top;
  background-size: 229px auto;
}

.knowledge-articles__wrap {
  padding: 30px;
}

.knowledge-articles__title {
  font-family: "Titillium Web", sans-serif;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  font-weight: 400;
  margin-bottom: 20px;
  text-align: center;
}

.callout p,
.knowledge-articles__title--small-left {
  text-align: left;
}

.knowledge-article {
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  background: #fff;
  font-size: 0.875em;
  margin-bottom: 40px;
  position: relative;
}

.knowledge-article__img-wrap {
  height: 0;
  overflow: hidden;
  padding-bottom: 41.76955%;
  position: relative;
}

.knowledge-article__img-wrap img {
  left: 50%;
  top: 50%;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: block;
  min-width: 100%;
}

.knowledge-article:last-child {
  margin-bottom: 0;
}

.knowledge-article__copy-wrap {
  padding: 20px;
}

.knowledge-article__title {
  font-size: 1.375em;
  line-height: 1.27;
  margin-bottom: 20px;
}

.knowledge-article__cover-link::before {
  background: #a80432;
  content: " ";
  display: block;
  height: 4px;
  left: 0;
  position: absolute;
  top: -12px;
  -webkit-transform-origin: left top;
  transform-origin: left top;
  -webkit-transition: -webkit-transform 0.15s ease-out;
  transition: transform 0.15s ease-out;
  transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
  width: 45%;
  will-change: transform;
}

.knowledge-article__cover-link:active::before,
.knowledge-article__cover-link:focus::before,
.knowledge-article__cover-link:hover::before {
  -webkit-transform: scaleX(1.2);
  transform: scaleX(1.2);
}

.knowledge-article__cover-link:active .knowledge-article__link::before,
.knowledge-article__cover-link:focus .knowledge-article__link::before,
.knowledge-article__cover-link:hover .knowledge-article__link::before {
  -webkit-transform: translateY(-50%) translateX(15px) rotate(135deg);
  transform: translateY(-50%) translateX(15px) rotate(135deg);
}

.knowledge-article__link {
  position: relative;
  padding-right: 20px;
  font-size: 16px;
}

.knowledge-article__link:before {
  border: 1px solid #00387a;
  content: " ";
  display: block;
  width: 12px;
  height: 12px;
  right: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(135deg);
  transform: translateY(-50%) rotate(135deg);
  border-right: 0;
  border-bottom: 0;
  -webkit-transition: -webkit-transform 0.15s;
  transition: transform 0.15s;
  transition: transform 0.15s, -webkit-transform 0.15s;
}

.knowledge-article__link:active:before,
.knowledge-article__link:focus:before,
.knowledge-article__link:hover:before,
.product-box__title a:active:before,
.product-box__title a:focus:before,
.product-box__title a:hover:before {
  -webkit-transform: translateY(-50%) translateX(15px) rotate(135deg);
  transform: translateY(-50%) translateX(15px) rotate(135deg);
  border-color: #00387a;
}

@media all and (min-width: 700px) {
  .knowledge-articles__wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .knowledge-article {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 49%;
    flex: 0 1 49%;
    -ms-flex: 0 0 calc(50% - 15px);
    flex: 0 0 calc(50% - 15px);
    margin-bottom: 20px;
  }

  .knowledge-article:last-child {
    margin-bottom: 20px;
  }

  .knowledge-article--3-wide-desktop {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 32%;
    flex: 0 1 32%;
    -ms-flex: 0 0 calc(33.3% - 12px);
    flex: 0 0 calc(33.3% - 12px);
  }
}

@media all and (min-width: 870px) {
  .knowledge-articles__title {
    font-size: 44px;
    margin-bottom: 60px;
  }

  .knowledge-articles__title--small-left {
    font-size: 1.625em;
    margin-bottom: 30px;
  }

  .knowledge-articles__wrap {
    margin: 0 auto;
    max-width: 1060px;
    padding: 60px 30px;
  }
}

.notices {
  font-size: 14px;
  margin: 0 auto;
  max-width: 1060px;
  padding: 30px;
  text-align: left;
  width: 100%;
}

.callout a,
.notices a {
  text-decoration: underline;
}

.callout a.blue-block-button,
.notices a.notices__trigger {
  text-decoration: none;
}

.notices--no-side-pad {
  padding: 20px 0 0;
}

.notices__notice li span {
  width: 18px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 18px;
  flex: 0 0 18px;
}

.notices--no-pad {
  padding: 0;
}

.notices--solutions-center {
  background: #6a6a6a;
  color: #fff;
  position: relative;
  z-index: 4;
}

.notices__trigger {
  background: 0 0;
  border: 0;
  position: relative;
  padding-left: 20px;
  color: #00387a;
}

.notices__trigger:active,
.notices__trigger:focus {
  outline-color: #00387a;
}

.notices__trigger:before {
  border: 1px solid #00387a;
  content: " ";
  display: block;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 40%;
  -webkit-transform: translateY(-50%) rotate(-135deg);
  transform: translateY(-50%) rotate(-135deg);
  border-right: 0;
  border-bottom: 0;
  left: 2px;
}

.notices__notice.lunar-green,
.notices__trigger.lunar-green {
  color: #373a36;
}

.notices__trigger.lunar-green:before {
  border-color: #373a36;
}

.notices__trigger[aria-expanded="true"]::before {
  -webkit-transform: translateY(4%) rotate(-135deg) scale(-1, -1);
  transform: translateY(4%) rotate(-135deg) scale(-1, -1);
}

.notices__trigger--bold {
  font-weight: 700;
}

.notices__trigger--solutions-center {
  color: #fff;
}

.notices__trigger--solutions-center:before {
  border-color: #fff;
}

.notices__trigger--plus {
  padding-left: 15px;
}

.notices__trigger--plus::after,
.notices__trigger--plus::before {
  background: #00387a;
  content: " ";
  display: block;
  height: 6px;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 50%;
  width: 2px;
}

.notices__trigger--plus::before,
.notices__trigger--plus[aria-expanded="true"]::before {
  -webkit-transform: translate(5px, -50%);
  transform: translate(5px, -50%);
}

.notices__trigger--plus::after {
  -webkit-transform: translate(5px, -50%) rotate(90deg);
  transform: translate(5px, -50%) rotate(90deg);
}

.notices__trigger--plus[aria-expanded="true"]::before {
  opacity: 0;
}

.notices__notice {
  color: #00387a;
  font-size: 12px;
  margin-top: 20px;
}

.notices__notice--solutions-center {
  color: #fff;
}

.callout[aria-hidden="true"],
.notices__notice[aria-hidden="true"],
.tooltip[aria-hidden="true"] {
  display: none;
}

@media all and (min-width: 768px) {
  .notices--desktop-gray {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    color: #6a6a6a;
    font-size: 16px;
    margin-bottom: 40px;
    padding: 20px 60px;
  }

  .notices__notice--desktop-gray {
    color: #6a6a6a;
    font-size: 16px;
    max-width: 600px;
  }

  .notices__notice--pad-bottom-desktop {
    padding-bottom: 40px;
  }

  .notices__notice[aria-hidden="true"].notices__notice--desktop-gray {
    display: block;
  }

  .notices__trigger--desktop-gray {
    color: #6a6a6a;
  }

  .notices__trigger--plus {
    padding-left: 0;
  }

  .notices__trigger--plus::after,
  .notices__trigger--plus::before {
    display: none;
  }
}

@media all and (min-width: 900px) {
  .notices {
    padding: 60px 30px 80px;
  }

  .notices--no-pad-top-desktop {
    padding-top: 0;
  }

  .notices--no-desktop-vertical-pad {
    padding: 0 30px;
  }

  .notices--equal-vertical-pad-desktop {
    padding: 60px 30px;
  }

  .notices--no-side-pad,
  .notices--no-side-pad-desktop {
    padding-left: 0;
    padding-right: 0;
  }

  .notices--no-bottom-pad-desktop {
    padding-bottom: 20px;
  }

  .notices--desktop-gray {
    padding: 20px 60px;
  }

  .notices--small-pad-desktop {
    padding: 25px 30px;
  }
}

.solutions-center .notices table,
.solutions-center .notices td {
  border-color: #fff !important;
}

.notices img,
.notices svg {
  max-width: 15px !important;
}

.content-page__wrap {
  padding: 30px;
  text-align: center;
}

.content-page__title {
  font-size: 3.375em;
}

.content-page__body {
  font-size: 0.875em;
  margin: 20px auto 40px;
}

.content-page__search {
  display: block;
  margin: 40px auto;
  max-width: 400px;
}

.content-page__list {
  margin-bottom: -30px;
  margin-top: 100px;
  width: 100%;
}

.content-page__list--no-margin {
  margin-top: 0;
}

.content-page__list-wrap {
  background: url(https://www.fnb-online.com/assets/images/weave-bg-ltgray.png)
    center top;
  background-size: 229px auto;
  text-align: center;
}

.content-page__list-item {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#00387a),
    to(#00b8e0)
  );
  background: linear-gradient(90deg, #00387a, #00b8e0);
  color: #fff;
  margin: 0 30px 30px;
  padding-bottom: 15px;
  padding-top: 4px;
  -webkit-transform: translateY(-30px);
  transform: translateY(-30px);
}

.content-page__list-item--solutions-center {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#a80f1c),
    to(#7b0a1b)
  );
  background: linear-gradient(90deg, #a80f1c, #7b0a1b);
}

.content-page__list-item--business-banking {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#00387a),
    to(#4a90e2)
  );
  background: linear-gradient(90deg, #00387a, #4a90e2);
}

.content-page__list-item-cover-link {
  color: #fff;
  display: block;
}

.content-page__list-item-img {
  left: 50%;
  top: 50%;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: auto;
  width: 100%;
  z-index: 1;
}

.content-page__list-item-title {
  font-size: 1.625em;
  margin-top: 10px;
  position: relative;
  z-index: 2;
}

.content-page__list-item-wrap {
  height: 0;
  overflow: hidden;
  padding-bottom: 57.51634%;
  position: relative;
}

.content-page__list-item-wrap img {
  left: 50%;
  top: 50%;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: block;
  min-width: 100%;
}

@media all and (min-width: 750px) {
  .content-page__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 100px auto 0;
    max-width: 1060px;
    padding: 0 30px 80px;
  }

  .content-page__list-item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(33.3% - 8px);
    flex: 0 0 calc(33.3% - 8px);
    margin: 0;
    width: calc(33.3%- 8px);
    -webkit-transform: translateY(-55px);
    transform: translateY(-55px);
    overflow: hidden;
  }

  .content-page__list-item-title {
    font-size: 1.75em;
  }

  .content-page__list-item-wrap {
    height: 0;
    padding-bottom: 79.0625%;
    position: relative;
    overflow: hidden;
  }

  .content-page__list-item-wrap img {
    left: 50%;
    top: 50%;
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: block;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    max-width: 140%;
  }
}

.tooltip {
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  background: #fff;
  border: 1px solid #979797;
  color: #373a36;
  font-size: 12px;
  left: 100%;
  left: calc(100% + 15px);
  max-width: 100vw;
  position: absolute;
  top: -17px;
  width: 300px;
  z-index: 15;
}

.tooltip--left {
  left: auto;
  max-width: calc(100% - 35px);
  right: 55px;
}

.tooltip--anchor-left {
  left: 30px;
}

.tooltip--above {
  left: 15px;
  top: 0;
  -webkit-transform: translateY(-110%);
  transform: translateY(-110%);
}

.tooltip--full-width {
  width: 100vw;
  width: calc(100vw - 30px);
}

.tooltip__wrap {
  display: block;
  padding: 10px;
  position: relative;
  width: 100%;
}

.tooltip__wrap::after,
.tooltip__wrap::before {
  border: solid transparent;
  content: " ";
  height: 0;
  pointer-events: none;
  position: absolute;
  right: 100%;
  top: 0;
  width: 0;
}

.tooltip__wrap::after {
  border-color: transparent #fff transparent transparent;
  border-width: 12px;
  margin-top: 14px;
}

.tooltip__wrap::before {
  border-color: transparent #979797 transparent transparent;
  border-width: 13px;
  margin-top: 13px;
}

.tooltip__wrap--left::after,
.tooltip__wrap--left::before {
  left: 100%;
}

.tooltip__wrap--left::after {
  border-color: transparent transparent transparent #fff;
  border-width: 12px;
  margin-top: 14px;
}

.tooltip__wrap--left::before {
  border-color: transparent transparent transparent #979797;
  border-width: 13px;
  margin-top: 13px;
}

.tooltip__wrap--above::after,
.tooltip__wrap--above::before {
  border: solid transparent;
  content: " ";
  height: 0;
  left: 50%;
  margin-top: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  width: 0;
}

.tooltip__wrap--above::after {
  border-color: #fff transparent transparent;
  border-width: 12px;
  margin-left: -12px;
}

.tooltip__wrap--above::before {
  border-color: #979797 transparent transparent;
  border-width: 13px;
  margin-left: -13px;
}

.tooltip__icon {
  background: #fff;
  border: 2px solid #00387a;
  border-radius: 8px;
  color: #767676;
  float: right;
  height: 16px;
  margin-left: auto;
  margin-top: 1px;
  position: relative;
  text-indent: -999em;
  width: 16px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16px;
  flex: 0 0 16px;
  min-width: 16px;
  max-width: 16px;
  text-rendering: optimizeLegibility;
}

.tooltip__icon::after {
  color: #00387a;
  content: "?";
  display: block;
  font-size: 10px;
  font-weight: 700;
  height: 100%;
  left: 51%;
  line-height: 14px;
  position: absolute;
  text-align: center;
  text-indent: 0;
  top: 47%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
}

.tooltip__icon--i::after {
  content: "i";
  left: 51%;
}

.tooltip__icon--inline {
  display: inline-block;
  float: none;
  margin-left: 6px;
}

.tooltip__icon--in-table-body {
  margin-left: 6px;
  margin-top: 0;
}

.has-tooltip {
  padding-right: 8px;
  position: relative;
  width: 100%;
}

.has-tooltip--no-expand {
  width: auto;
}

@media all and (min-width: 768px) {
  .tooltip {
    font-size: 14px;
  }
}

@media all and (min-width: 800px) {
  .tooltip__icon--inline {
    position: absolute;
    right: 3px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .tooltip--full-width {
    max-width: 300px;
  }
}

.callout {
  font-size: 14px;
  padding: 30px;
  line-height: 1.35;
  text-align: center;
}

.callout--simple {
  font-family: "Montserrat", sans-serif;
  margin-top: 50px;
  padding: 0;
  line-height: 1.5;
}

.callout--no-results {
  width: 100%;
  text-align: center;
}

.callout--hmd,
.callout--hmd p,
.callout--no-results p {
  text-align: center;
}

.callout--hmd {
  font-size: 1em;
}

.callout--no-side-pad {
  padding-right: 0;
  padding-left: 0;
}

.callout__desktop-only,
.compare-dock .blue-block-button::before {
  display: none;
}

.callout--full-width {
  text-align: center;
}

.callouts {
  color: #6a6a6a;
  padding: 30px 0;
}

.callouts--solutions-center {
  background: #6a6a6a;
  color: #fff;
}

.callouts a {
  max-width: 100%;
  white-space: normal;
}

@media all and (min-width: 550px) {
  .callout {
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
    text-align: center;
  }

  .callout--full-width,
  .callout--hmd {
    max-width: none;
  }

  .callout p,
  .callout--hmd {
    text-align: center;
  }
}

@media all and (min-width: 640px) {
  .callout {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }

  .callout,
  .callout p {
    text-align: left;
  }

  .callout--full-width,
  .callout--full-width p,
  .callout--hmd,
  .callout--hmd p {
    text-align: center;
  }

  .callout--hmd {
    padding-bottom: 60px;
  }

  .callouts {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0 30px;
  }
}

@media all and (min-width: 768px) {
  .callouts--solutions-center {
    background: #6a6a6a;
    margin: 0;
    max-width: none;
    padding: 100px 0;
    z-index: 4;
    position: relative;
  }
}

@media all and (min-width: 900px) {
  .callout,
  .callout__desktop-only {
    display: block;
  }

  .callout--mobile-only,
  .callout__mobile-only {
    display: none;
  }

  .callout {
    margin-top: 30px;
  }

  .callout--simple {
    margin-top: 50px;
  }

  .callout--with-bg {
    background: #f4f4f2;
    text-align: center;
    padding: 20px 10px;
    margin-bottom: 20px;
    font-size: 16px;
    margin-top: 0;
    margin-right: 12px;
  }

  .callout--with-bg:first-child {
    margin-top: 90px;
  }

  .filter-box-desktop .callout {
    padding-top: 0;
  }

  .callout--no-results {
    display: block;
  }

  .callout--no-results[aria-hidden="true"] {
    display: none;
  }
}

@media all and (min-width: 960px) {
  .callout {
    font-size: 16px;
  }

  .callouts {
    margin-left: auto;
    margin-right: auto;
    max-width: 833px;
    padding: 40px 0;
    z-index: 3;
  }

  .callouts--solutions-center {
    max-width: none;
    padding-top: 80px;
  }

  .callouts--solutions-center-alt {
    padding-top: 40px;
  }
}

.alert {
  font-family: "Montserrat", sans-serif;
  margin-top: 10px;
  font-size: 1em;
}

.alert__headline {
  font-size: 1em;
  background: #a80432;
  border-bottom: 2px solid #fff;
  padding: 2px 4px;
}

.alert i,
.alert img,
.alert svg {
  position: absolute;
  left: 3px;
  top: 5px;
  width: 34px;
  min-height: 34px;
}

.alert__body {
  padding-left: 54px;
  border-top: 1px solid #00387a;
  padding-top: 8px;
  position: relative;
}

.alert a {
  text-decoration: underline;
}

.compare-dock {
  background: #00387a;
  bottom: 0;
  -webkit-box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.15);
  color: #fff;
  left: 0;
  padding: 10px 20px 30px;
  position: fixed;
  -webkit-transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
  width: 100%;
  z-index: 10;
}

.compare-dock .blue-block-button,
.compare-dock .blue-gradient-button {
  display: inline-block;
  margin-right: 4px;
  padding-right: 0;
}

.compare-dock .blue-gradient-button {
  padding-right: 30px;
}

.compare-dock__remove {
  background: 0 0;
  border: 0;
  color: #fff;
  height: 40px;
  position: absolute;
  right: 20px;
  text-indent: -999em;
  top: 0;
  width: 40px;
}

.compare-dock__remove:active,
.compare-dock__remove:focus {
  outline-color: #00387a;
}

.compare-dock__remove-wrap {
  display: block;
  height: 100%;
  position: relative;
  width: 100%;
}

.compare-dock__remove-wrap:after,
.compare-dock__remove-wrap:before {
  left: 50%;
  top: 50%;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #fff;
  content: " ";
  display: block;
  width: 12px;
  height: 2px;
}

.compare-dock__remove-wrap:after {
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
}

.compare-dock__remove-wrap:before {
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
}

.compare-dock__buttons {
  float: right;
  margin-bottom: 10px;
}

.compare-dock__wrap {
  margin: 0 auto;
  max-width: 1020px;
  position: relative;
}

.compare-dock[aria-hidden="true"] {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

.compare-dock--open {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.compare-dock--hidden {
  -webkit-transform: translateY(73%);
  transform: translateY(73%);
  -webkit-transform: translateY(calc(100% - 60px));
  transform: translateY(calc(100% - 60px));
}

.compare-dock--hidden .compare-dock__buttons a:last-child {
  opacity: 0;
  pointer-events: none;
}

.compare-dock__remove-buttons {
  position: absolute;
  right: 0;
}

.compare-dock__remove-buttons[aria-hidden="true"] {
  display: none;
  pointer-events: none;
}

.compare-dock__item,
.compare-dock__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.compare-dock__list {
  clear: both;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.compare-dock__item {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 119px;
  min-width: 57px;
  padding: 30px 0 0;
  position: relative;
}

.compare-dock__item img {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 80px;
  flex: 0 0 80px;
  margin-right: -12px;
  max-width: 80px;
  -webkit-transform: translateX(-12px);
  transform: translateX(-12px);
}

.compare-dock__item-title {
  font-size: 0.875em;
  font-weight: 600;
  padding-left: 5px;
}

.compare-dock__item--placeholder {
  background: url(https://www.fnb-online.com/assets/images/product-box-placeholder.png)
    left 30px no-repeat;
  background-size: 50px auto;
}

.compare-dock__item--placeholder:nth-child(1),
.compare-dock__item--placeholder:nth-child(2) {
  -webkit-animation-name: example;
  animation-name: example;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.compare-dock__item--placeholder + .compare-dock__item--placeholder {
  -webkit-animation-name: none;
  animation-name: none;
}

.compare-dock__item:nth-child(3) {
  display: none;
}

@media all and (min-width: 375px) {
  .compare-dock {
    padding-left: 30px;
    padding-right: 0;
  }

  .compare-dock .blue-block-button,
  .compare-dock .blue-gradient-button {
    padding-right: 40px;
  }

  .compare-dock .blue-block-button::before {
    display: block;
  }

  .compare-dock__item img {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100px;
    flex: 0 0 100px;
    max-width: 100px;
  }

  .compare-dock__item--placeholder {
    background-size: 57px auto;
  }

  .compare-dock__item:nth-child(2) {
    padding-right: 10px;
  }
}

@media all and (min-width: 768px) {
  .compare-dock__mobile-only {
    display: none;
  }

  .compare-dock__item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3%;
    flex: 0 0 33.3%;
    max-width: 33.3%;
    padding-right: 40px;
    padding-top: 20px;
  }

  .compare-dock__item--placeholder {
    background-position: left 20px;
  }

  .compare-dock__item-title {
    font-size: 1em;
  }

  .compare-dock__item:nth-child(3) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .compare-dock__list {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
}

.minicart-item {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 15px;
}

.minicart-item__promotion {
  max-width: 155px !important;
}

.minicart-item__promotion-img {
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.minicart-item__promotion-tagline {
  color: #00387a;
  font-family: "Titillium Web", sans-serif;
  font-size: 0.875rem;
  margin: 0;
}

.minicart-item__img {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 90px;
  flex: 0 0 90px;
  height: auto;
  width: 90px;
}

.minicart-item__img--promotion {
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.minicart-item__title {
  color: #00387a;
  font-size: 1em;
  margin: 0 0 5px;
}

.minicart-item__note {
  color: #6a6a6a;
  font-size: 0.75em;
}

.minicart-item__copy {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(100% - 90px);
  flex: 0 0 calc(100% - 90px);
  padding-bottom: 10px;
}

.minicart-item__copy--promotion {
  margin-top: 25px;
}

.promo-modal:not([open]) {
  pointer-events: none;
  opacity: 0;
  display: none;
}

.promo-modal + .backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  height: 100vh;
  top: 0;
  width: 100vw;
}

.promo-modal {
  z-index: 1003;
  background: #fff;
  display: block;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  top: 50vh;
  left: 50vw;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  overflow: hidden;
  border-radius: 10px;
  width: 354px;
  max-height: 90vh;
  font-family: "Titillium Web", sans-serif;
  padding: 0;
  border: 0;
  position: fixed;
  bottom: auto;
  margin: inherit;
}

.promo-modal__large {
  width: 80%;
  max-width: 950px;
}

.promo-modal + .backdrop,
.promo-modal::-webkit-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: none !important;
}

.promo-modal + .backdrop,
.promo-modal::backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: none !important;
}

.promo-modal__tooltip {
  left: 0;
}

.promo-modal__content {
  font-family: "Titillium Web", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.promo-modal__content__list {
  list-style-type: disc;
  list-style-position: inside;
  font-size: 8px;
}

.promo-modal__modal-header {
  height: 232px;
}

.promo-modal__modal-header__validated {
  height: 190px;
}

.promo-modal__modal-header__large {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #00387a;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.promo-modal__modal-header__large img {
  width: 100%;
  max-height: 400px;
}

.promo-modal__modal-header__large--side-by-side {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.promo-modal__modal-header__large--side-by-side img {
  height: 100%;
}

.promo-modal__modal-header__large--side-by-side .promo-modal__h1 {
  color: #00cfe0;
}

.promo-modal__modal-header__title-wrap {
  padding: 40px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: wrap;
  flex-flow: wrap;
  color: #fff;
  font-size: 20px;
  font-family: "Titillium Web", sans-serif;
}

.promo-modal__modal-header__title-wrap--vertical h2 {
  margin: 0;
  color: #fff;
  font-weight: 200;
}

.promo-modal__modal-header__title-wrap--vertical p {
  margin: auto;
  color: #00cfe0;
  font-weight: 600;
}

.promo-modal__img-wrap--side-by-side {
  width: 100%;
  max-height: 400px;
  max-width: 270px;
}

.promo-modal__expiration-date {
  font-size: 14px;
}

.promo-modal__add-to-cart {
  margin-left: auto;
  margin-top: -20px;
}

.promo-modal__modal-body {
  padding: 20px;
  height: auto;
  max-height: calc(90vh - 232px) !important;
  overflow-y: auto;
}

.promo-modal__modal-body__validated {
  margin: 20px 34px 10px;
  height: auto;
  padding-bottom: 10px;
  max-height: calc(90vh - 310px) !important;
  overflow-y: auto;
}

.promo-modal__disclosure-body {
  padding: 40px;
  color: #000;
  font-size: 12px;
}

.promo-modal__disclosure-text-wrap {
  overflow-y: auto;
  height: auto;
  max-height: calc(80vh - 620px);
  padding-right: 10px;
}

.promo-modal__disclosure-text-wrap--side-by-side {
  max-height: calc(80vh - 265px);
}

.promo-modal__modal-footer {
  padding: 20px 0 60px;
  height: 40px;
  width: auto;
}

.promo-modal__modal-footer__validated {
  padding: 10px 0 80px;
  height: 40px;
  width: 190px;
  margin: auto;
}

.promo-modal__close-modal {
  border: 0;
  text-indent: -999em;
  border-radius: 90px;
  position: absolute;
  top: 20px;
  left: 20px;
  height: 40px;
  width: 40px;
  background: #fff;
  cursor: pointer;
}

.promo-modal__close-modal:active,
.promo-modal__close-modal:focus {
  outline-color: #00387a;
  outline: 2px solid #00387a;
}

.promo-modal__close-modal--right {
  left: auto;
  right: 20px;
}

.promo-modal__close-modal__with-border {
  border: 1px solid #00387a;
}

.promo-modal__close-modal::after,
.promo-modal__close-modal::before {
  background: #00387a;
  content: " ";
  display: block;
  height: 50%;
  left: 50%;
  position: absolute;
  top: 25%;
  width: 2px;
}

.promo-modal__close-modal::before {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.promo-modal__close-modal::after {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.promo-modal__close-modal--no-bg {
  background: 0 0;
}

.promo-modal__close-modal--no-bg::after,
.promo-modal__close-modal--no-bg::before {
  background: #fff;
}

.promo-modal__close-modal--dark::after,
.promo-modal__close-modal--dark::before {
  background: #000;
}

.promo-modal__input {
  width: 100%;
  height: 44px;
  background: #fff;
  border: 1px solid #979797;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 2px;
  margin: auto;
  padding-left: 13px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #00387a;
}

.promo-modal__error {
  background: #a80432;
  color: #fff;
  font-size: 0.875em;
  line-height: 1.2;
  margin-top: 5px;
  padding: 3px 8px 5px;
  position: relative;
}

.promo-modal__error[aria-hidden] {
  display: none;
}

.promo-modal__h1 {
  font-family: "Titillium Web", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #00387a;
}

.promo-modal__h1__validated {
  text-align: center;
}

.promo-modal__h1--bold {
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 15px;
}

.promo-modal__h1--light-blue {
  color: #00cfe0;
}

.promo-modal__review {
  margin-bottom: -2px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.promo-modal__completed {
  height: 110px;
  padding-top: 50px;
}

.promo-modal__completed::after,
.promo-modal__completed::before {
  content: " ";
  display: block;
  position: relative;
  top: 2px;
  margin: auto;
}

.promo-modal__completed::after {
  border: 3px solid #00387a;
  border-right: 0;
  border-top: 0;
  height: 25px;
  top: -72px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  width: 45px;
}

.promo-modal__completed::before {
  border: 3px solid #00387a;
  border-radius: 90px;
  height: 110px;
  width: 110px;
  background: #e1efff;
}

.promo-modal__submit {
  font-family: "Titillium Web", sans-serif;
  width: 158px;
  height: 40px;
  color: #fff;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  background: #00387a;
  border-radius: 2px;
  border: 0;
  float: right;
  cursor: pointer;
}

.promo-modal__submit__validated {
  line-height: 40px;
  display: inline-block;
  width: 190px;
  float: none;
}

.promo-modal__submit:hover {
  background: #00387a;
  color: #fff;
}

.promo-modal__submit:disabled {
  background: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

@media all and (max-width: 414px) {
  .promo-modal {
    margin: 0 !important;
    max-height: 90vh !important;
    max-width: 354px;
    width: 100%;
  }
}

@media all and (max-width: 1000px) {
  .promo-modal__large {
    max-width: 300px;
  }

  .promo-modal__modal-header__title-wrap {
    padding: 20px;
  }

  .promo-modal__modal-header__title-wrap--vertical
    .promo-modal__expiration-date {
    margin: 10px 0 0;
  }

  .promo-modal__modal-header__large padding,
  .promo-modal__modal-header__title-wrap p {
    font-size: 12px;
  }

  .promo-modal__modal-header__title-wrap .promo-modal__h1 {
    color: #fff;
  }

  .promo-modal__modal-header__large,
  .promo-modal__modal-header__title-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .promo-modal__modal-header__large img {
    width: 100%;
    max-height: 120px;
  }

  .promo-modal__img-wrap--side-by-side {
    max-width: none;
  }

  .promo-modal__h1--bold {
    font-weight: 200;
  }

  .promo-modal__h1--light-blue {
    color: #fff;
  }

  .promo-modal__expiration-date {
    color: #00cfe0;
    font-weight: 700;
    font-family: "Titillium Web", sans-serif;
  }

  .promo-modal__add-to-cart {
    width: 100%;
  }

  .promo-modal__add-to-cart button {
    width: 100%;
    text-align: start;
  }

  .promo-modal__disclosure-body {
    padding: 20px;
  }

  .promo-modal__disclosure-text-wrap {
    max-height: calc(80vh - 314px);
  }
}

.product-box {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fff),
    to(#e8e8e8)
  );
  background: linear-gradient(180deg, #fff, #e8e8e8);
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 25px;
  opacity: 1;
  position: relative;
  text-align: left;
  -webkit-transition: opacity 0.2s ease-out;
  transition: opacity 0.2s ease-out;
}

.product-box__body {
  font-family: "Montserrat", sans-serif;
  color: #6a6a6a;
  font-size: 16px;
}

.product-box__cover-link {
  height: 100%;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 25px 30px;
  position: relative;
  width: 100%;
  z-index: 1;
}

.product-box__cover-link::before {
  background: #a80432;
  content: " ";
  display: block;
  height: 4px;
  left: 0;
  position: absolute;
  top: -11px;
  -webkit-transform-origin: left top;
  transform-origin: left top;
  -webkit-transition: -webkit-transform 0.15s ease-out;
  transition: transform 0.15s ease-out;
  transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
  width: 106px;
}

.product-box__cover-link--simple {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 25px 20px;
}

.product-box:active .product-box__cover-link::before,
.product-box:focus .product-box__cover-link::before,
.product-box:hover .product-box__cover-link::before,
.product-box__cover-link:active::before,
.product-box__cover-link:focus::before,
.product-box__cover-link:hover::before {
  -webkit-transform: scaleX(1.2);
  transform: scaleX(1.2);
}

.product-box:active .product-box__promo-inner-wrap,
.product-box:focus .product-box__promo-inner-wrap,
.product-box:hover .product-box__promo-inner-wrap,
.product-box__cover-link:active .product-box__promo-inner-wrap,
.product-box__cover-link:focus .product-box__promo-inner-wrap,
.product-box__cover-link:hover .product-box__promo-inner-wrap {
  padding-left: 120px;
}

.product-box__promo {
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  left: 0;
  overflow: hidden;
  padding-right: 40px;
  position: absolute;
  text-transform: uppercase;
  top: -2px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: padding-left 0.15s ease-out;
  transition: padding-left 0.15s ease-out;
  z-index: 5;
}

.product-box__promo--no-shift {
  text-align: left;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}

.product-box__promo--no-shift-solutions-center {
  text-align: left;
  top: 40px;
  -webkit-transform: none;
  transform: none;
}

.product-box__promo::after {
  background: #a80432;
  height: 35px;
  width: 35px;
  content: " ";
  display: block;
  right: 20px;
  pointer-events: none;
  position: absolute;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  bottom: 5px;
  z-index: 1;
}

.product-box__promo-inner-wrap {
  background: #a80432;
  display: block;
  height: 100%;
  padding: 0 10px 0 50px;
  position: relative;
  -webkit-transition: padding-left 0.15s ease-out;
  transition: padding-left 0.15s ease-out;
  width: 100%;
  z-index: 2;
}

.product-box__promo-inner-wrap--no-shift-solutions-center {
  padding-left: 15px;
  font-size: 8px;
}

.product-box__promo-inner-wrap--no-shift {
  padding-left: 10px;
}

.product-box--leaving,
.product-box--loading {
  opacity: 0;
}

.product-box__compare-box {
  color: #00387a;
  margin-top: 0;
}

.product-box__description {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}

.product-box__img-and-title-and-features {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 5px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.product-box__img-wrap {
  overflow: hidden;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  width: 100%;
}

.product-box__img-wrap img {
  height: auto;
  -webkit-transform: translateX(-15%);
  transform: translateX(-15%);
  width: 100%;
  display: block;
  margin: 0 auto;
  max-width: 165px;
}

.product-box__img-wrap--simple {
  margin-bottom: 30px;
}

.product-box__title {
  font-family: "Titillium Web", sans-serif;
  color: #00387a;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  font-size: 1.375em;
  line-height: 1.25;
  margin-left: 0;
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}

.product-box__title a {
  position: relative;
  display: block;
  padding-right: 50%;
}

.product-box__title a:before {
  border: 1px solid #00387a;
  content: " ";
  display: block;
  width: 15px;
  height: 15px;
  right: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(135deg);
  transform: translateY(-50%) rotate(135deg);
  border-right: 0;
  border-bottom: 0;
  -webkit-transition: -webkit-transform 0.15s;
  transition: transform 0.15s;
  transition: transform 0.15s, -webkit-transform 0.15s;
}

.product-box__features {
  font-family: "Montserrat", sans-serif;
  color: rgba(0, 0, 0, 0.7);
  font-size: 0.875em;
  line-height: 1.125em;
  list-style: none;
  margin: 0;
  padding: 0;
}

.product-box__buttons {
  font-family: "Titillium Web", sans-serif;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 10px 30px 20px;
  position: relative;
  z-index: 2;
  width: 100%;
}

.product-box__buttons > * {
  overflow: visible;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  max-width: 50%;
}

.product-box__buttons > :only-child {
  max-width: 100%;
}

.product-box__buttons > :first-child {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.product-box__buttons--simple > * {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.product-box__buttons--simple > :last-child:only-child,
.product-box__buttons > :last-child:only-child {
  -webkit-box-flex: 0 !important;
  -ms-flex: 0 0 auto !important;
  flex: 0 0 auto !important;
  width: auto !important;
  margin: 0 auto;
}

.product-box__buttons > :last-child {
  margin-right: 0;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.product-box__buttons > :last-child:only-child {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.product-box__buttons--upper-right-desktop > :last-child:only-child {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.product-box__buttons--simple {
  padding-top: 0;
}

.product-box__feature {
  line-height: 1.5;
  margin-top: 10px;
  padding-left: 16px;
  position: relative;
}

.product-box__feature::after,
.product-box__feature::before {
  content: " ";
  display: block;
  left: 0;
  position: absolute;
  top: 2px;
}

.product-box__feature::after {
  border: 1px solid rgba(0, 0, 0, 0.7);
  border-right: 0;
  border-top: 0;
  height: 3px;
  left: 3px;
  top: 6px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  width: 6px;
}

.product-box__feature::before {
  border: 1px solid rgba(0, 0, 0, 0.7);
  border-radius: 6px;
  height: 12px;
  width: 12px;
}

@media all and (min-width: 740px) {
  .product-box {
    text-align: left;
  }

  .product-box__body--simple {
    margin-bottom: 30px;
  }

  .product-box__body--simple:last-child {
    margin-bottom: 30px;
  }

  .product-box__features {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }

  .product-box .blue-gradient-button {
    justify-self: flex-end;
    margin-right: 0;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }

  .product-box__img-and-title-and-features {
    padding-right: 5%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .product-box__img-wrap {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 35%;
    flex: 0 0 35%;
    width: 35%;
  }

  .product-box__title-and-features--simple {
    padding-left: 20px;
  }

  .product-box--simple {
    padding-bottom: 0;
    padding-right: 0;
  }
}

@media all and (min-width: 768px) {
  .product-box__buttons {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    white-space: nowrap;
  }

  .product-box__buttons .field-wrap {
    margin-left: 20px;
    width: auto;
  }

  .product-box__buttons > :first-child,
  .product-box__buttons > :last-child {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .product-box__title {
    padding-right: 100px;
  }

  .product-box__buttons--upper-right-desktop {
    position: absolute;
    top: 15px;
    right: 30px;
  }
}

@media all and (min-width: 1060px) {
  .product-box {
    margin-top: 45px;
  }

  .product-box,
  .product-box__compare-box {
    font-size: 16px;
  }

  .product-box::before {
    width: 25%;
  }

  .product-box__img-and-title-and-features {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    margin-top: 10px;
    padding-right: 0;
  }

  .product-box__img-and-title-and-features--simple {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 calc(100% - 130px);
    flex: 0 1 calc(100% - 130px);
    margin-top: 0;
    padding-top: 20px;
    max-width: calc(100% - 130px);
  }

  .product-box__img-and-title-and-features--no-filter {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 100%;
    flex: 0 1 100%;
  }

  .product-box__cover-link {
    padding-bottom: 60px;
  }

  .product-box__cover-link::after {
    border: 1px solid #00387a;
    border-bottom: 0;
    border-left: 0;
    content: " ";
    display: block;
    height: 18px;
    position: absolute;
    right: 30px;
    top: 50%;
    top: calc(50% - 4px);
    -webkit-transform: translate(0, 0) scale(1, 2) rotate(45deg);
    transform: translate(0, 0) scale(1, 2) rotate(45deg);
    -webkit-transition: -webkit-transform 0.15s ease-out;
    transition: transform 0.15s ease-out;
    transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
    width: 18px;
  }

  .product-box__cover-link:active::after,
  .product-box__cover-link:focus::after,
  .product-box__cover-link:hover::after {
    border-color: #00387a;
    -webkit-transform: translate(0, 0) scale(1, 2) rotate(45deg);
    transform: translate(0, 0) scale(1, 2) rotate(45deg);
  }

  .product-box__cover-link--simple {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    padding-bottom: 0;
  }

  .product-box__cover-link--no-filter {
    padding: 25px 30px 0;
  }

  .product-box__cover-link--no-arrow::after,
  .product-box__title a::before {
    display: none;
  }

  .product-box__buttons {
    bottom: 10px;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    left: auto;
    max-width: calc(100% - 80px);
    padding: 0;
    position: absolute;
    right: 40px;
    width: 100%;
  }

  .product-box__buttons--upper-right-desktop {
    bottom: auto;
    width: auto;
  }

  .product-box__buttons > * {
    display: inline-block !important;
    white-space: nowrap;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    margin-right: 40px;
    width: auto;
    max-width: none;
  }

  .product-box__buttons > :last-child {
    margin-right: 0;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .product-box__buttons > :last-child:only-child {
    width: auto;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .product-box__buttons--simple {
    bottom: auto;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 120px;
    flex: 1 1 120px;
    padding-left: 20px;
    position: absolute;
    right: 20px;
    top: 50%;
    width: auto;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .product-box__buttons--no-cart {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    padding-top: 30px;
    top: auto;
    bottom: 0;
    right: 30px;
  }

  .product-box__description {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    width: 100%;
  }

  .product-box__features {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    line-height: 1.5em;
    margin-right: 30px;
  }

  .product-box__title-and-features {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 70%;
    flex: 1 1 70%;
  }

  .product-box__img-wrap {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 35%;
    flex: 0 0 35%;
    width: 35%;
    max-width: 165px;
    overflow: visible;
  }

  .product-box__img-wrap img {
    -webkit-transform: translateX(-15%);
    transform: translateX(-15%);
  }

  .product-box__img-wrap--simple {
    margin-right: 0;
    overflow: hidden;
  }

  .product-box__img-wrap--simple img {
    -webkit-transform: none;
    transform: none;
  }

  .product-box__feature {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 33.3%;
    flex: 1 1 33.3%;
    padding: 0 10px;
    text-align: center;
  }

  .product-box__feature::before {
    border-radius: 13.5px;
    display: block;
    height: 27px;
    margin-bottom: 12px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    width: 27px;
    border-width: 2px;
  }

  .product-box__feature:after {
    border-width: 2px;
  }

  .product-box__feature::after {
    height: 6px;
    left: 50%;
    -webkit-transform: translateX(-50%) rotate(-45deg);
    transform: translateX(-50%) rotate(-45deg);
    width: 13px;
    top: 11px;
  }

  .product-box__promo-inner-wrap--no-shift-solutions-center {
    padding-left: 20px;
    font-size: 12px;
  }

  .product-box__title {
    font-size: 1.625em;
    line-height: 1.25;
  }

  .product-box__title--with-promo {
    margin-top: 10px;
  }

  .product-box__title a {
    padding-right: 0;
  }

  .product-box__buttons--simple > :last-child:only-child,
  .product-box__buttons > :last-child:only-child {
    margin-right: 0;
  }

  .product-box__cover-link:active:after,
  .product-box__cover-link:focus:after,
  .product-box__cover-link:hover:after {
    -webkit-transform: translate(10px, 0) scale(1, 2) rotate(45deg);
    transform: translate(10px, 0) scale(1, 2) rotate(45deg);
  }
}

@media all and (min-width: 1060px) and (-ms-high-contrast: none) {
  .product-box__buttons > :last-child:only-child,
  ::-ms-backdrop {
    margin: 0;
  }
}

.page-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  overflow: hidden;
  padding: 50px 30px 30px;
  position: relative;
  text-align: left;
}

.page-header--with-breadcrumb {
  padding-top: 85px;
}

.page-header__play {
  background: 0 0;
  border: 1px solid #00387a;
  background: #00387a;
  border-radius: 4px;
  text-indent: -999em;
  width: 39px;
  height: 24px;
  left: 47%;
  position: absolute;
  top: 50%;
}

.page-header__play:active,
.page-header__play:focus {
  outline-color: #00387a;
}

.page-header__play-inner-wrap {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}

.page-header__play-inner-wrap:after {
  left: 50%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent transparent transparent #fff;
  border-width: 8px;
  margin-top: -8px;
  -webkit-transform: translateX(-15%) scaleY(0.75);
  transform: translateX(-15%) scaleY(0.75);
}

@media all and (min-width: 768px) {
  .page-header__play {
    width: 54px;
    height: 33px;
  }
}

.page-header__play:active,
.page-header__play:focus,
.page-header__play:hover {
  border-color: #009cd6;
  background-color: #009cd6;
}

.page-header__wrap {
  font-size: 0.875em;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  width: 50%;
  margin-bottom: 20px;
}

.page-header__wrap--right {
  padding: 10px 0;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}

.page-header__heading {
  font-size: 1.15em;
}

.page-header__buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 16px;
  margin-top: 20px;
  position: relative;
  z-index: 2;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.page-header__buttons--with-caption {
  margin-top: 0 !important;
}

.page-header__button--caption {
  font-size: 0.75em;
  padding-top: 20px;
  color: #6a6a6a;
  margin-bottom: -10px;
}

.page-header__title {
  font-size: 2em;
  line-height: 1.125;
  margin-bottom: 20px;
  text-align: left;
}

.page-header__img-wrap {
  background: 0 0;
  border: 0;
  padding-right: 5%;
  width: 50%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  margin-bottom: 20px;
  padding-left: 10px;
}

.page-header__img-wrap:active,
.page-header__img-wrap:focus {
  outline-color: #00387a;
}

.page-header__img-wrap--left {
  max-width: 240px;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}

.page-header__img-wrap--logo {
  padding-right: 0;
}

.page-header__img-wrap--box {
  max-width: 220px;
}

.page-header__img-wrap:active .page-header__play,
.page-header__img-wrap:focus .page-header__play,
.page-header__img-wrap:hover .page-header__play {
  background-color: #009cd6;
  border-color: #009cd6;
}

.page-header__img-wrap-inner {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  position: relative;
  width: 100%;
  padding: 0 20px 20px;
}

.page-header__img-wrap-inner--logo {
  padding: 10px 0 0;
}

.page-header__img-wrap img {
  max-height: none;
  width: 100%;
  height: auto;
}

@media all and (min-width: 600px) {
  .page-header__wrap--box {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(100% - 220px);
    flex: 0 0 calc(100% - 220px);
    width: calc(100% - 220px);
  }
}

@media all and (min-width: 900px) {
  .page-header {
    max-width: 1120px;
    margin: 0 auto;
    padding-bottom: 60px;
    padding-top: 40px;
    position: relative;
    z-index: 2;
  }

  .page-header--with-breadcrumb {
    padding-top: 5px;
  }

  .page-header__lower-content {
    padding: 0 30% 0 75px;
  }

  .page-header__wrap {
    font-size: 0.9375em;
    padding: 0 100px 0 75px;
    width: 75%;
    max-width: 75%;
  }

  .page-header__wrap,
  .page-header__wrap--right {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
  }

  .page-header__wrap--hmd {
    margin-bottom: 0;
  }

  .page-header__img-wrap {
    width: 25%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    margin-bottom: 0;
    right: 100px;
  }

  .page-header__img-wrap--left {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    margin-left: 0;
    margin-right: 0;
  }

  .page-header__img-wrap--box {
    position: absolute;
    right: 0;
    top: 65px;
    max-width: none;
  }

  .page-header__img-wrap-inner {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .page-header__img-wrap--logo {
    max-width: 280px;
    top: 95px;
    position: absolute;
    height: 100%;
    right: 50px;
    height: calc(100% - 200px);
  }

  .page-header__img-wrap--hmd {
    top: 35px;
  }

  .page-header__title {
    font-size: 2.625em;
  }

  .page-header__heading {
    font-size: 1.125em;
  }

  .page-header--hmd {
    padding-bottom: 75px;
  }
}

@media all and (min-width: 1060px) {
  .page-header__lower-content {
    padding-right: 330px;
  }

  .page-header__wrap {
    padding-right: 50px;
  }

  .page-header__img-wrap {
    max-width: 240px;
    padding-right: 0;
  }

  .page-header__img-wrap--logo {
    max-width: 280px;
  }

  .page-header__img-wrap--box {
    right: 150px;
    width: 35%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 35%;
    flex: 0 0 35%;
  }

  .page-header__img-wrap-inner {
    padding: 0;
  }
}

.toned-image-copy {
  color: #fff;
  overflow: hidden;
  padding: 15px 30px 60px;
  position: relative;
}

.toned-image-copy__title {
  font-family: "Titillium Web", sans-serif;
  font-size: 1.5em;
}

.toned-image-copy__intro {
  font-family: "Titillium Web", sans-serif;
  margin-bottom: 0;
  margin-top: 12px;
}

.toned-image-copy__body {
  display: none;
}

.toned-image-copy__list {
  list-style-position: inside;
  list-style-type: disc;
}

.toned-image-copy__list-item {
  margin-top: 8px;
}

.toned-image-copy::before {
  background: #00387a;
  content: " ";
  display: block;
  height: 100%;
  left: 0;
  opacity: 0.87;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}

.toned-image-copy--background-pattern {
  background: url(https://www.fnb-online.com/assets/images/banner-bg-blue.png)
    center center;
  background-size: 460px auto;
}

.toned-image-copy--background-pattern::before {
  display: none;
}

.toned-image-copy img {
  display: block;
  height: auto;
  left: 50%;
  max-width: none;
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: auto;
  z-index: 1;
}

.toned-image-copy__cta {
  margin-top: 15px;
}

.toned-image-copy__content-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 1000px;
  position: relative;
  z-index: 3;
}

@media all and (min-width: 768px) {
  .toned-image-copy {
    padding: 80px 0;
  }

  .toned-image-copy__title {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 100%;
    flex: 1 0 100%;
    font-size: 2.25em;
  }

  .toned-image-copy__intro {
    padding-right: 100px;
  }

  .toned-image-copy__cta {
    clear: left;
    float: left;
  }

  .toned-image-copy__ctas {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .toned-image-copy__ctas:after {
    clear: both;
    content: " ";
    display: table;
  }

  .toned-image-copy__content-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0 60px;
  }

  .toned-image-copy__body,
  .toned-image-copy__desktop-left {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }

  .toned-image-copy__body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding-right: 60px;
    padding-top: 67px;
  }

  .toned-image-copy__body--background-pattern {
    padding-top: 0;
  }
}

.content-page-header {
  background-color: #00387a;
  color: #fff;
  overflow: hidden;
  position: relative;
}

.content-page-header::before {
  background: #00387a;
  content: " ";
  display: block;
  height: 100%;
  left: 0;
  opacity: 0.87;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}

.content-page-header__logo-img {
  width: 60%;
  margin: 0 auto;
  height: auto;
  z-index: 22;
  position: relative;
}

.content-page-header__logo-wrap {
  overflow: hidden;
  background-color: #fff;
  padding: 30px;
  position: relative;
  text-align: center;
}

.content-page-header__logo-wrap::after,
.content-page-header__logo-wrap::before {
  content: " ";
  display: block;
  width: 50vw;
  z-index: 20;
  background-color: #fff;
  pointer-events: none;
  position: absolute;
}

.content-page-header__logo-wrap::before {
  -webkit-transform-origin: left top;
  transform-origin: left top;
  -webkit-transform: rotate(23deg);
  transform: rotate(23deg);
  height: 600px;
  left: calc(50% + 100px);
  top: -20px;
}

.content-page-header__logo-wrap::after {
  left: 100%;
  left: calc(50% + 110px);
  height: 200%;
  top: -30px;
}

.content-page-header--with-breadcrumb {
  padding: 0;
}

.content-page-header--with-breadcrumb::before {
  display: none;
}

.content-page-header--full-width-body {
  padding-top: 45px;
}

.content-page-header__title {
  font-family: "Titillium Web", sans-serif;
  color: #95f7ff;
  font-size: 1.5em;
  margin: 15px 30px;
  position: relative;
}

.content-page-header__title--full-width-body {
  padding-left: 80px;
}

.content-page-header__title--with-breadcrumb {
  margin: 15px 30px;
  position: relative;
  z-index: 4;
}

.content-page-header__title i,
.content-page-header__title imag,
.content-page-header__title svg {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
}

.content-page-header__intro {
  font-family: "Titillium Web", sans-serif;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 12px;
}

.content-page-header--background-pattern::before,
.content-page-header__body {
  display: none;
}

.content-page-header__body--full-width {
  display: block;
  margin-top: 25px;
}

.content-page-header--background-pattern {
  background: url(https://www.fnb-online.com/assets/images/banner-bg-blue.png)
    center center;
  background-size: 460px auto;
}

.content-page-header__bg {
  display: block;
  height: auto;
  left: 50%;
  max-width: none;
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: auto;
  z-index: 1;
}

.content-page-header__ctas a,
.content-page-header__ctas button {
  margin-top: 15px;
  margin-right: 10px;
}

.content-page-header__content-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 1060px;
  position: relative;
  z-index: 3;
}

.content-page-header__content-wrap::before {
  background: #00387a;
  content: " ";
  display: none;
  height: 100%;
  left: 0;
  opacity: 0.87;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}

.content-page-header__content-wrap--not-relative {
  position: static;
  z-index: 4;
  padding-bottom: 30px;
}

.content-page-header__content-wrap--not-relative::before {
  display: block;
}

.content-page-header__content-wrap--background-pattern::before {
  display: none;
}

.content-page-header .breadcrumb {
  z-index: 3;
}

.content-page-header__desktop-left {
  padding: 0 30px 30px;
}

.content-page-header__body--with-breadcrumb,
.content-page-header__desktop-left--with-breadcrumb {
  z-index: 4;
  position: relative;
  margin-right: 30px;
  margin-left: 30px;
}

.content-page-header__content-wrap--background-pattern .blue-gradient-button {
  border: 1px solid transparent;
  border-left: 1px solid #003875;
}

.content-page-header__content-wrap--background-pattern
  .blue-gradient-button:active,
.content-page-header__content-wrap--background-pattern
  .blue-gradient-button:focus,
.content-page-header__content-wrap--background-pattern
  .blue-gradient-button:hover {
  background: 0 0;
  border: 1px solid #fff;
  border-left: 1px solid #fff;
  color: #fff;
}

.content-page-header__content-wrap--background-pattern
  .blue-gradient-button:active:after,
.content-page-header__content-wrap--background-pattern
  .blue-gradient-button:focus:after,
.content-page-header__content-wrap--background-pattern
  .blue-gradient-button:hover:after,
.simple-image-and-copy--blue-bg .blue-gradient-button:active:after,
.simple-image-and-copy--blue-bg .blue-gradient-button:focus:after,
.simple-image-and-copy--blue-bg .blue-gradient-button:hover:after,
.simple-image-and-copy--blue-weave-bg .blue-gradient-button:active:after,
.simple-image-and-copy--blue-weave-bg .blue-gradient-button:focus:after,
.simple-image-and-copy--blue-weave-bg .blue-gradient-button:hover:after {
  border-color: #fff;
}

@media all and (min-width: 768px) {
  .content-page-header {
    padding-bottom: 30px;
    padding-top: 5px;
  }

  .content-page-header--with-breadcrumb {
    padding-top: 0;
  }

  .content-page-header__title {
    color: #95f7ff;
    font-size: 2.25em;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    padding-left: 60px;
    padding-right: 50%;
    margin-left: 0;
    margin-right: 0;
  }

  .content-page-header__title--full-width-body {
    padding-left: 80px;
  }

  .content-page-header__intro {
    padding-right: 100px;
  }

  .content-page-header__ctas {
    padding-bottom: 20px;
  }

  .content-page-header__ctas:after {
    clear: both;
    content: " ";
    display: table;
  }

  .content-page-header__ctas a,
  .content-page-header__ctas button {
    clear: left;
    float: left;
  }

  .content-page-header__logo-wrap {
    background-color: transparent;
    width: 50%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    z-index: 21;
    overflow: visible;
    position: static;
    padding: 0 60px 30px 100px;
    text-align: right;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 50%;
  }

  .content-page-header__logo-img {
    position: relative;
    width: 100%;
    height: auto;
    max-width: 260px;
    display: inline-block;
    z-index: 22;
    margin-right: 0;
  }

  .content-page-header__desktop-left {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    padding-left: 60px;
    padding-bottom: 0;
    max-width: 50%;
    margin-left: 0;
    margin-right: 0;
  }

  .content-page-header__body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding-right: 60px;
    margin-top: 12px;
    max-width: 50%;
  }

  .content-page-header__body--with-breadcrumb {
    margin-left: 0;
    margin-right: 0;
  }

  .content-page-header__body--background-pattern {
    padding-top: 0;
  }

  .content-page-header__body--full-width {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    padding-left: 80px;
    max-width: 100%;
  }
}

@media all and (min-width: 62.5em) {
  .content-page-header__logo-wrap {
    padding-bottom: 60px;
  }
}

.cta-strip {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#003875),
    to(#00aad9)
  );
  background: linear-gradient(90deg, #003875, #00aad9);
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 15px 20px 20px;
  text-align: left;
  position: relative;
}

.cta-strip:after {
  clear: both;
  content: " ";
  display: table;
}

.cta-strip__solutions-center {
  padding: 20px 40px;
  margin: 0 -30px;
}

.cta-strip__solutions-center .cta-strip__content-wrap {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.cta-strip__solutions-center .cta-strip__icon-wrap {
  -webkit-box-flex: inherit;
  -ms-flex: inherit;
  flex: inherit;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: block;
  margin-top: 8px;
}

.cta-strip__solutions-center .cta-strip__text {
  font-size: 1em;
  margin: 0 0 12px 60px;
  min-height: 60px;
}

.cta-strip__solutions-center .cta-strip__cta {
  margin: 0 auto 0 20px;
}

.cta-strip--side-by-side {
  padding: 15px 25px;
}

.cta-strip--small {
  padding-bottom: 20px;
}

.cta-strip--gray {
  background: url(https://www.fnb-online.com/assets/images/weave-bg-ltgray.png)
    center top;
  background-size: 229px auto;
  color: #00387a;
}

.cta-strip--blue {
  background: url(https://www.fnb-online.com/assets/images/banner-bg-blue.png)
    center center;
  background-size: 460px auto;
  color: #fff;
}

.cta-strip--gray-weave-bg {
  background: url(https://www.fnb-online.com/assets/images/weave-bg-ltgray.png)
    center top;
  background-size: 229px auto;
  color: #00387a;
}

.cta-strip--blue-weave-bg {
  background: url(https://www.fnb-online.com/assets/images/banner-bg-blue.png)
    center center;
  background-size: 460px auto;
  color: #fff;
}

.cta-strip--red-bg {
  background: #a80432;
}

.cta-strip--gray-bg {
  background: #f4f4f2;
  color: #00387a;
}

.cta-strip--light-blue-bg {
  background: #c8dfee;
  color: #00387a;
}

.cta-strip--dark-gray-bg {
  background: #6a6a6a;
}

.cta-strip--blue-bg {
  background: #00387a;
}

.cta-strip--margin-top-small {
  margin-top: 20px;
}

.cta-strip--margin-top-medium {
  margin-top: 40px;
}

.cta-strip--margin-top-large {
  margin-top: 60px;
}

.cta-strip__text {
  font-family: "Montserrat", sans-serif;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  float: left;
  font-size: 1.125em;
  font-weight: 700;
  line-height: 1.25;
  margin: 12px 0 12px 100px;
}

.cta-strip__text-non-bold {
  font-weight: 400;
}

.cta-strip__text--side-by-side {
  margin-top: 12px;
  padding-left: 10px;
  text-align: left;
  max-width: calc(100% - 90px);
}

.cta-strip__text--small {
  font-size: 1em;
}

.cta-strip__icon {
  display: block;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  height: 30px;
  margin: 0 auto;
  width: 30px;
}

.cta-strip__icon--medium {
  width: 50px;
  height: 50px;
}

.cta-strip__icon--large {
  width: 70px;
  height: 70px;
}

.cta-strip__icon-wrap {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 90px;
  flex: 0 0 90px;
  float: left;
  min-height: 66px;
  margin: 0 auto 12px;
  text-align: center;
  width: 90px;
  position: absolute;
  top: 15px;
  left: 20px;
}

.cta-strip__icon-wrap--side-by-side {
  margin: 0 0 12px;
}

.article-detail-header__secondary-content--no-mar p,
.cta-strip__icon-wrap--small {
  margin: 0;
}

.cta-strip--no-icon {
  padding: 15px;
  margin-bottom: 40px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#649cb0),
    to(#d0d0cf)
  );
  background: linear-gradient(90deg, #649cb0, #d0d0cf);
}

.cta-strip--no-icon .cta-strip__text-wrap {
  margin-bottom: 10px;
}

.cta-strip--no-icon a {
  display: block;
  width: 100%;
}

.cta-strip__cta {
  margin: 0 auto 0 100px;
}

.cta-strip__cta--side-by-side {
  margin-left: 76px;
}

.cta-strip__cta--small {
  margin-top: 0;
  font-size: 0.875em;
}

.cta-strip__content-wrap {
  margin: 0 auto;
  max-width: 1000px;
  width: 100%;
}

.cta-strip__content-wrap--no-icon {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.cta-strip__content-wrap--no-icon .cta-strip__text-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-line-pack: center;
  align-content: center;
}

.cta-strip__content-wrap--no-icon .cta-strip__text {
  margin: 0;
  max-width: none;
}

.cta-strip__content-wrap--no-icon .cta-strip__text:last-child {
  margin-top: 12px;
}

.cta-strip__content-wrap--no-icon .cta-strip__cta {
  margin: 0;
  font-size: 16px;
}

.cta-strip__content-wrap--side-by-side {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

@media all and (min-width: 768px) {
  .cta-strip {
    padding: 12px 0;
    text-align: left;
  }

  .cta-strip__solutions-center {
    padding: 20px 40px;
    margin: 0;
  }

  .cta-strip__solutions-center .cta-strip__icon-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 0;
  }

  .cta-strip__solutions-center .cta-strip__text {
    margin: 12px 0 12px 100px;
    min-height: inherit;
  }

  .cta-strip__icon-wrap {
    margin: 0 15px 0 0;
    position: relative;
    left: auto;
    top: auto;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .cta-strip--no-icon {
    padding: 15px;
    margin-bottom: 40px;
  }

  .cta-strip__cta {
    float: right;
    justify-self: flex-end;
    margin-top: 0;
    font-size: 1em;
  }

  .cta-strip__text {
    font-size: 1.125em;
    margin-left: 0;
    max-width: calc(100% - 120px);
  }

  .cta-strip__text--side-by-side {
    margin-top: 0;
  }

  .cta-strip__content-wrap {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .cta-strip__content-wrap--no-icon {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .cta-strip__content-wrap--no-icon .cta-strip__text-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-line-pack: center;
    align-content: center;
  }

  .cta-strip__content-wrap--no-icon .cta-strip__text {
    margin: 0;
    max-width: none;
  }

  .cta-strip__content-wrap--no-icon .cta-strip__text:last-child {
    margin-top: 12px;
  }

  .cta-strip__content-wrap--no-icon .cta-strip__cta {
    margin: 0;
    font-size: 16px;
  }

  .cta-strip__content-wrap--side-by-side {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

@media all and (min-width: 1000px) {
  .cta-strip {
    padding: 20px 0;
    margin: 0;
  }

  .cta-strip__solutions-center {
    padding: 20px 40px;
  }

  .cta-strip__solutions-center .cta-strip__icon-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 0;
  }

  .cta-strip__solutions-center .cta-strip__text {
    margin: 12px 0 12px 100px;
    min-height: inherit;
  }

  .cta-strip--no-icon {
    padding: 15px;
    margin-bottom: 40px;
  }

  .cta-strip--no-icon .cta-strip__text-wrap {
    margin-bottom: 0;
  }

  .cta-strip--no-icon a {
    width: auto;
  }
}

.simple-image-and-copy ol {
  list-style: decimal;
  list-style-position: inside;
  font-family: "Montserrat", sans-serif;
  color: #6a6a6a;
  font-size: 0.875em;
  text-align: left;
}

.simple-image-and-copy__content-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 1060px;
  padding: 40px 30px;
}

.simple-image-and-copy__content-wrap--dark-bg {
  padding-right: 10px;
  padding-left: 10px;
}

.simple-image-and-copy__content-wrap--no-body {
  padding-bottom: 0;
}

.simple-image-and-copy__content-wrap--bg {
  background-color: #f4f4f2;
  max-width: 1000px;
}

.simple-image-and-copy__content-wrap--bg-light-blue {
  background: #c8dfee;
  max-width: 1000px;
}

.simple-image-and-copy__content-wrap--bg-dark-blue {
  background: #00387a;
  max-width: 1000px;
}

.simple-image-and-copy--bg {
  margin: 20px 0;
}

.simple-image-and-copy .read-more-button::before {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(255, 255, 255, 0)),
    to(#fff)
  );
  background: linear-gradient(180deg, rgba(255, 255, 255, 0), #fff);
  display: block;
  content: " ";
  width: 100vw;
  position: absolute;
  left: 0;
  top: -120px;
  height: 100px;
}

.simple-image-and-copy--gray-weave-bg {
  background: url(https://www.fnb-online.com/assets/images/weave-bg-ltgray.png)
    center top;
  background-size: 229px auto;
}

.simple-image-and-copy--blue-weave-bg .read-more-button::before,
.simple-image-and-copy--gray-weave-bg .read-more-button::before {
  content: none;
}

.simple-image-and-copy--blue-weave-bg {
  background: url(https://www.fnb-online.com/assets/images/banner-bg-blue.png)
    center center;
  background-size: 460px auto;
}

.simple-image-and-copy--blue-bg .blue-gradient-button,
.simple-image-and-copy--blue-weave-bg .blue-gradient-button {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#005f97),
    to(#009cd6)
  );
  background: linear-gradient(90deg, #005f97, #009cd6);
  border: 1px transparent solid;
  border-left: 1px solid #003875;
}

.simple-image-and-copy--blue-bg .blue-gradient-button:active,
.simple-image-and-copy--blue-bg .blue-gradient-button:focus,
.simple-image-and-copy--blue-bg .blue-gradient-button:hover,
.simple-image-and-copy--blue-weave-bg .blue-gradient-button:active,
.simple-image-and-copy--blue-weave-bg .blue-gradient-button:focus,
.simple-image-and-copy--blue-weave-bg .blue-gradient-button:hover {
  background: 0 0;
  border: 1px solid #fff;
  border-left: 1px solid #fff;
  color: #fff;
}

.simple-image-and-copy--gray-bg {
  background-color: #f4f4f2;
}

.simple-image-and-copy--gray-bg .read-more-button::before {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(255, 255, 255, 0)),
    to(#f4f4f2)
  );
  background: linear-gradient(180deg, rgba(255, 255, 255, 0), #f4f4f2);
}

.simple-image-and-copy--blue-bg {
  background: #00387a;
}

.simple-image-and-copy--blue-bg .read-more-button::before {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(255, 255, 255, 0)),
    to(#00387a)
  );
  background: linear-gradient(180deg, rgba(255, 255, 255, 0), #00387a);
}

.simple-image-and-copy img {
  max-width: 100%;
}

.simple-image-and-copy .play-button {
  bottom: auto;
  top: 12px;
  right: auto;
  left: 12px;
}

.simple-image-and-copy .play-button--right {
  left: auto;
  right: 12px;
}

.simple-image-and-copy .play-button--wrap {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.simple-image-and-copy__copy {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  padding-top: 20px;
  text-align: left;
}

.simple-image-and-copy__copy--no-image {
  padding-top: 0;
}

.simple-image-and-copy__copy--alt-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.simple-image-and-copy__alt-content {
  background: url(https://www.fnb-online.com/assets/images/banner-bg-blue.png)
    center center;
  background-size: 460px auto;
  color: #fff;
  padding: 20px 25px 25px;
  margin-bottom: 20px;
  margin-top: 40px;
  -webkit-box-ordinal-group: 21;
  -ms-flex-order: 20;
  order: 20;
}

.simple-image-and-copy__title {
  color: #00387a;
  font-size: 25px;
  margin-bottom: 20px;
  text-align: left;
  font-family: "Roboto", sans-serif;
}

.simple-image-and-copy__title--gray {
  color: #6a6a6a;
}

.simple-image-and-copy__title--light-blue {
  color: #c8dfee;
}

.simple-image-and-copy__subtitle {
  font-weight: 700;
  color: #373a36;
  font-size: 1em;
  font-family: "Montserrat", sans-serif;
  letter-spacing: -0.25px;
  margin-bottom: 10px;
}

.simple-image-and-copy__body.white,
.simple-image-and-copy__subtitle.white {
  color: #fff;
}

.simple-image-and-copy__img-wrap {
  -ms-flex-item-align: start;
  align-self: flex-start;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  height: auto;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  overflow: hidden;
  position: relative;
  text-align: center;
  z-index: 1;
}

.simple-image-and-copy__img-wrap--bg {
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

.simple-image-and-copy__img-wrap--text-wrap {
  padding-bottom: 20px;
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}

.simple-image-and-copy__body {
  font-family: "Montserrat", sans-serif;
  color: #6a6a6a;
  font-size: 16px;
  text-align: left;
}

.simple-image-and-copy__body a {
  text-decoration: underline;
  font-size: 16px;
}

.simple-image-and-copy__link {
  background: 0 0;
  border: 0;
  max-width: 100%;
}

.simple-image-and-copy__link:active,
.simple-image-and-copy__link:focus {
  outline-color: #00387a;
}

.simple-image-and-copy__link:active .play-button,
.simple-image-and-copy__link:focus .play-button,
.simple-image-and-copy__link:hover .play-button {
  background: #009cd6;
  border-color: #009cd6;
}

.simple-image-and-copy + .simple-image-and-copy .simple-image-and-copy,
.simple-image-and-copy-no-bottom-padding
  + .simple-image-and-copy
  .simple-image-and-copy__content-wrap,
.simple-image-and-copy-no-bottom-padding
  + .simple-image-and-copy
  .simple-image-and-copy__copy {
  padding-top: 0;
}

.simple-image-and-copy-no-bottom-padding .simple-image-and-copy__content-wrap {
  padding-bottom: 0;
}

@media all and (min-width: 768px) {
  .simple-image-and-copy ol {
    font-size: 1em;
  }

  .simple-image-and-copy--gray-weave {
    background: url(https://www.fnb-online.com/assets/images/weave-bg-ltgray.png)
      center top;
    background-size: 229px auto;
  }

  .simple-image-and-copy--blue-weave {
    background: url(https://www.fnb-online.com/assets/images/banner-bg-blue.png)
      center center;
    background-size: 460px auto;
  }

  .simple-image-and-copy__img--bg {
    width: 100%;
    height: auto;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center center;
    object-position: center center;
    max-width: none !important;
    min-height: 100%;
    min-width: 100%;
  }

  .simple-image-and-copy__img-wrap {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 348px;
    flex: 1 1 348px;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .simple-image-and-copy__img-wrap--text-wrap {
    width: 348px;
    float: right;
    margin-left: 30px;
    padding-bottom: 0;
  }

  .simple-image-and-copy__img-wrap--align-body {
    margin-top: 85px;
  }

  .simple-image-and-copy__img-wrap--big {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 223px;
    flex: 0 1 223px;
  }

  .simple-image-and-copy__link--bg {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .simple-image-and-copy__alt-content {
    width: 50%;
    float: right;
    margin-left: 20px;
    margin-top: 0;
  }

  .simple-image-and-copy__body {
    font-size: 16px;
  }

  .simple-image-and-copy__content-wrap {
    padding-bottom: 30px;
  }

  .simple-image-and-copy__content-wrap--big {
    padding-top: 90px;
  }

  .simple-image-and-copy__content-wrap--no-body {
    padding-bottom: 0;
  }

  .simple-image-and-copy__content-wrap--bg {
    padding: 0;
  }

  .simple-image-and-copy__content-wrap--dark-bg {
    padding-left: 0;
    padding-right: 0;
  }

  .simple-image-and-copy__copy {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 66%;
    flex: 1 1 66%;
    -ms-flex: 1 1 calc(100% - 348px);
    flex: 1 1 calc(100% - 348px);
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    padding-right: 30px;
    padding-top: 0;
    text-align: left;
  }

  .simple-image-and-copy__copy--big {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 70%;
    flex: 0 1 70%;
    -ms-flex: 0 1 calc(100% - 223px);
    flex: 0 1 calc(100% - 223px);
  }

  .simple-image-and-copy__copy--no-image {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 100%;
    flex: 0 1 100%;
    padding-right: 0;
  }

  .simple-image-and-copy__copy--bg {
    padding: 40px;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 calc(100% - 391px);
    flex: 1 1 calc(100% - 391px);
  }

  .simple-image-and-copy__copy--alt-content {
    display: block;
  }

  .simple-image-and-copy__title {
    font-size: 1.625em;
  }

  .simple-image-and-copy__title,
  .simple-image-and-copy__title--with-line {
    position: relative;
    margin-top: 10px;
    display: inline-block;
  }

  .simple-image-and-copy__title--with-line:before {
    background: #a80432;
    height: 3px;
    display: block;
    content: " ";
    width: 50%;
    min-width: 130px;
    left: 0;
    position: absolute;
    top: -12px;
  }

  .simple-image-and-copy__title--big {
    font-size: 2.625em;
  }

  .simple-image-and-copy--desktop-padding-top {
    padding-top: 30px;
  }

  .simple-image-and-copy--desktop-padding-bottom {
    padding-bottom: 30px;
  }
}

@media all and (min-width: 900px) and (-ms-high-contrast: none) {
  .simple-image-and-copy__content-wrap {
    display: block;
  }

  .simple-image-and-copy__content-wrap:after,
  ::-ms-backdrop {
    display: block;
    content: " ";
    clear: both;
  }

  .simple-image-and-copy__copy,
  ::-ms-backdrop {
    width: calc(100% - 348px);
    float: left;
  }

  .simple-image-and-copy__copy {
    -ms-flex: 1 1 66%;
    flex: 1 1 66%;
  }

  .simple-image-and-copy__img-wrap {
    -ms-flex: 1 1 34%;
    flex: 1 1 34%;
    width: 348px;
    float: left;
  }

  .simple-image-and-copy__copy--no-image,
  ::-ms-backdrop {
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    width: 100%;
  }
}

.side-by-side {
  background: #c8dfee;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.side-by-side__img-wrap {
  height: 0;
  padding-bottom: 49.62519%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  overflow: hidden;
  position: relative;
}

.side-by-side__img-wrap img {
  left: 50%;
  top: 50%;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: block;
  min-width: 100%;
}

.side-by-side__title {
  color: #00387a;
  display: inline-block;
  font-size: 2em;
  line-height: 1.2;
  margin-bottom: 12px;
  padding-top: 12px;
  position: relative;
  width: auto;
}

.side-by-side__title::before {
  background: #a80432;
  content: " ";
  display: block;
  height: 4px;
  position: absolute;
  left: 0;
  top: 0;
  width: 40%;
}

.side-by-side__body {
  font-family: "Montserrat", sans-serif;
  color: #373a36;
  font-size: 0.875em;
}

.side-by-side__copy-wrap {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 560px;
  padding: 30px;
}

.side-by-side__img {
  height: auto;
  min-height: 110%;
  min-width: 100%;
  position: absolute;
  width: 110%;
}

.side-by-side__img--image-left,
.side-by-side__img--image-right {
  left: auto !important;
  right: 0 !important;
  -webkit-transform: translate(0, -50%) !important;
  transform: translate(0, -50%) !important;
}

.side-by-side__video-button {
  background: 0 0;
  border: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.side-by-side__video-button:active,
.side-by-side__video-button:focus {
  outline-color: #00387a;
}

.side-by-side__video-button .play-button {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#003875),
    to(#00aad9)
  );
  background: linear-gradient(90deg, #003875, #00aad9);
  border: 0;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

.side-by-side__video-button:active .play-button,
.side-by-side__video-button:focus .play-button,
.side-by-side__video-button:hover .play-button {
  background: #009cd6;
}

@media all and (min-width: 900px) {
  .side-by-side {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .side-by-side--flip {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .side-by-side__body {
    font-size: 1em;
  }

  .side-by-side__img-wrap {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    height: auto;
    padding-bottom: 0;
  }

  .side-by-side__img {
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: 50% 50%;
    object-position: 50% 50%;
    top: 50% !important;
    -webkit-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
    left: 50% !important;
  }

  .side-by-side__copy-wrap {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    padding: 50px 60px;
    max-width: 50%;
  }
}

.multicolumn-list {
  padding: 40px 30px;
}

.multicolumn-list__item {
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fff),
    to(#e8e8e8)
  );
  background: linear-gradient(180deg, #fff, #e8e8e8);
  margin-bottom: 25px;
  position: relative;
}

.multicolumn-list__item-cover-link {
  color: #373a36;
  display: block;
  padding: 10px 20px 20px;
  width: 100%;
}

.multicolumn-list__item-cover-link::before {
  background: #a80432;
  content: " ";
  display: block;
  height: 3px;
  left: 0;
  position: absolute;
  top: -8px;
  -webkit-transform-origin: left top;
  transform-origin: left top;
  -webkit-transition: -webkit-transform 0.15s ease-out;
  transition: transform 0.15s ease-out;
  transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
  width: 30%;
}

.multicolumn-list__item-cover-link:active::before,
.multicolumn-list__item-cover-link:focus::before,
.multicolumn-list__item-cover-link:hover::before {
  -webkit-transform: scaleX(1.3);
  transform: scaleX(1.3);
}

.multicolumn-list__item-title {
  font-family: "Titillium Web", sans-serif;
  color: #00387a;
  font-size: 1.375em;
  line-height: 1.18;
  margin-bottom: 12px;
  min-height: 50px;
  padding-left: 70px;
  position: relative;
}

.multicolumn-list__item-body {
  font-size: 0.875em;
}

.multicolumn-list__item-icon {
  left: 0;
  position: absolute;
  top: 0;
}

@media screen and (min-width: 768px) {
  .multicolumn-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 1060px;
    padding-bottom: 0;
    padding-top: 60px;
  }

  .multicolumn-list__item {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    margin-bottom: 40px;
    max-width: 490px;
  }

  .multicolumn-list__item-title {
    font-size: 1.5em;
  }

  .multicolumn-list__item:nth-child(odd) {
    margin-right: 10px;
  }

  .multicolumn-list__item:nth-child(even) {
    margin-left: 10px;
  }
}

.product-bundle__list,
.product-strip__list {
  color: #00387a;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  line-height: 1.125;
  margin-top: 30px;
  position: relative;
  text-align: center;
}

.product-bundle__list a,
.product-strip__list a {
  color: #00387a;
  display: block;
}

.product-bundle__list a:active,
.product-bundle__list a:focus,
.product-bundle__list a:hover,
.product-strip__list a:active,
.product-strip__list a:focus,
.product-strip__list a:hover {
  text-decoration: underline;
}

.product-bundle__list--blue,
.product-bundle__list--blue a,
.product-bundle__list--blue-weave,
.product-bundle__list--blue-weave a,
.product-strip__list--blue,
.product-strip__list--blue a,
.product-strip__list--blue-weave,
.product-strip__list--blue-weave a {
  color: #fff;
}

.product-bundle__list-item,
.product-strip__list-item {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  margin: 0 15px;
  width: 33.3%;
  width: calc(33.3% - 20px);
}

.product-bundle__list-item:first-child,
.product-strip__list-item:first-child {
  margin-left: 0;
}

.product-bundle__list-item:last-child,
.product-strip__list-item:last-child {
  margin-right: 0;
}

.product-bundle__list-item img,
.product-strip__list-item img {
  display: inline;
  height: auto;
  width: 100%;
}

.product-bundle__list-item::before,
.product-strip__list-item::before {
  display: block;
  position: absolute;
  font-weight: 700;
  left: 50%;
  top: 25%;
  font-size: 25px;
  -webkit-transform: translateX(-50%, -50%);
  transform: translateX(-50%, -50%);
}

.product-bundle__list-item--mystery img,
.product-strip__list-item--mystery img {
  width: 70%;
}

.product-bundle__list-item-copy,
.product-strip__list-item-copy {
  font-size: 0.8125em;
  margin-top: 10px;
}

.product-bundle {
  padding-bottom: 30px;
}

.product-bundle__list {
  padding: 0 20px;
}

.product-bundle__list-item {
  display: block;
  max-width: 140px;
}

.product-bundle__list-item::before {
  content: "+";
  z-index: 1;
}

.product-bundle__list-item-link {
  display: block;
  position: relative;
  height: 0;
  padding-bottom: 110%;
  z-index: 2;
}

.product-bundle__list-item-link img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}

.product-bundle__list-item img {
  position: static;
  z-index: 2;
}

.product-bundle__list-item--of-3:before,
.product-bundle__list-item--of-three:before {
  display: none;
}

.product-bundle__list-item--of-3:last-child:before,
.product-bundle__list-item--of-three:last-child:before {
  display: block;
  left: auto;
  right: 33.3%;
  right: calc(33.3% - 5px);
}

.product-bundle__list-item--of-3:first-child:before,
.product-bundle__list-item--of-three:first-child:before {
  display: block;
  left: 33.3%;
  left: calc(33.3% - 15px);
  right: auto;
}

.bundle-strip,
.product-strip {
  background: rgba(0, 0, 0, 0.15);
  color: #373a36;
  padding: 30px;
}

.bundle-strip--blue,
.product-strip--blue {
  background: #00387a;
  color: #fff;
}

.bundle-strip--blue-weave,
.product-strip--blue-weave {
  background: #00387a;
  background: url(https://www.fnb-online.com/assets/images/banner-bg-blue.png)
    center center;
  background-size: 460px auto;
}

.bundle-strip--weave,
.product-strip--weave {
  background: url(https://www.fnb-online.com/assets/images/weave-bg-ltgray.png)
    center top;
  background-size: 229px auto;
}

.bundle-strip--centered,
.product-strip--centered {
  text-align: center;
  padding: 30px 0;
}

.bundle-strip__title,
.product-strip__title {
  font-family: "Titillium Web", sans-serif;
  color: #00387a;
  font-size: 2em;
  line-height: 1.06525;
}

.bundle-strip__title--blue,
.bundle-strip__title--blue-weave,
.product-strip__title--blue,
.product-strip__title--blue-weave {
  color: #95f7ff;
}

.bundle-strip__body,
.product-strip__body {
  font-size: 0.875em;
  line-height: 1.42825;
  margin-top: 20px;
}

.bundle-strip__body--blue-weave,
.product-strip__body--blue-weave {
  color: #fff;
}

.bundle-strip__centered-title,
.product-strip__centered-title {
  color: #00387a;
  font-size: 2em;
  line-height: 1.15;
  margin: 0 20px 15px;
}

.bundle-strip__centered-title-wrap,
.product-strip__centered-title-wrap {
  padding: 0 30px;
}

.bundle-strip__centered-body,
.product-strip__centered-body {
  color: #6a6a6a;
  font-size: 0.875em;
  max-width: 787px;
  margin: 0 auto 25px;
  text-align: left;
}

.bundle-strip__products,
.product-strip__products {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 40px;
}

.bundle-strip__product,
.product-strip__product {
  margin: 5px;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 27%;
  flex: 0 1 27%;
  max-width: 218px;
  min-width: 170px;
}

.bundle-strip__product--cover,
.product-strip__product--cover {
  width: 100%;
  height: auto;
  background-color: #fff;
  display: block;
  border-radius: 2px;
  position: relative;
  padding: 10px;
}

.bundle-strip__product--cover:active .product-strip__product--cover,
.bundle-strip__product--cover:focus .product-strip__product--cover,
.bundle-strip__product--cover:hover .product-strip__product--cover,
.product-strip__product--cover:active .product-strip__product--cover,
.product-strip__product--cover:focus .product-strip__product--cover,
.product-strip__product--cover:hover .product-strip__product--cover {
  padding-left: 120px;
}

.bundle-strip__product--cover:active::before,
.bundle-strip__product--cover:focus::before,
.bundle-strip__product--cover:hover::before,
.product-strip__product--cover:active::before,
.product-strip__product--cover:focus::before,
.product-strip__product--cover:hover::before {
  -webkit-transform: scaleX(1.2);
  transform: scaleX(1.2);
}

.bundle-strip__product--cover:active:after,
.bundle-strip__product--cover:focus:after,
.bundle-strip__product--cover:hover:after,
.product-strip__product--cover:active:after,
.product-strip__product--cover:focus:after,
.product-strip__product--cover:hover:after {
  -webkit-transform: translate(10px, 0) scale(1, 2) rotate(45deg);
  transform: translate(10px, 0) scale(1, 2) rotate(45deg);
}

.bundle-strip__product--cover::after,
.product-strip__product--cover::after {
  border: 1px solid #00387a;
  border-bottom: 0;
  border-left: 0;
  content: " ";
  display: block;
  height: 18px;
  position: absolute;
  right: 20px;
  top: 50%;
  top: calc(50% - 4px);
  -webkit-transform: translate(0, 0) scale(1, 2) rotate(45deg);
  transform: translate(0, 0) scale(1, 2) rotate(45deg);
  -webkit-transition: -webkit-transform 0.15s ease-out;
  transition: transform 0.15s ease-out;
  transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
  width: 18px;
}

.bundle-strip__product--img,
.product-strip__product--img {
  width: 120px;
  margin: auto;
  padding-top: 5px;
}

.bundle-strip__product-title,
.product-strip__product-title {
  margin-top: 8px;
  color: #00387a;
  font-size: 0.8125em;
}

@media all and (min-width: 768px) {
  .product-bundle__copy-wrap,
  .product-strip__copy-wrap {
    text-align: left;
  }

  .product-bundle__list,
  .product-strip__list {
    -ms-flex-pack: distribute;
    justify-content: space-around;
    margin-top: 0;
  }

  .product-bundle__list-item-copy,
  .product-strip__list-item-copy {
    font-size: 0.875em;
    margin-top: 12px;
  }

  .product-bundle--centered-title,
  .product-strip--centered-title {
    font-size: 2em;
    margin-bottom: 10px;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }

  .product-bundle--centered-body,
  .product-strip--centered-body {
    margin-left: auto;
    margin-right: auto;
  }

  .product-bundle__products,
  .product-strip__products {
    font-size: 1em;
    margin-bottom: 40px;
  }

  .product-bundle__product,
  .product-strip__product {
    margin: 0 10px 30px;
  }

  .product-bundle__product--cover,
  .product-strip__product--cover {
    border-radius: 5px;
  }

  .product-bundle__product--img,
  .product-strip__product--img {
    width: 130px;
    padding-top: 15px;
    margin: 0 auto 0 20px;
  }

  .product-bundle__product-title,
  .product-strip__product-title {
    font-size: 1.125em;
  }

  .product-bundle__list {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .product-bundle__list-item {
    max-width: 33.3%;
  }

  .product-bundle__list-item:before {
    top: 30%;
  }

  .product-bundle__list-item--of-3:before,
  .product-bundle__list-item--of-three:before {
    top: 15%;
  }

  .bundle-strip__copy-wrap,
  .product-strip__copy-wrap {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    padding-top: 30px;
  }

  .bundle-strip__body,
  .product-strip__body {
    max-width: 335px;
  }

  .bundle-strip__title,
  .product-strip__title {
    font-size: 2.25em;
  }

  .bundle-strip__content-wrap,
  .product-strip__content-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 auto;
    max-width: 1060px;
    padding: 20px 60px 30px;
  }
}

.process-overview-slider {
  background: #00387a;
  overflow: hidden;
  padding: 25px 0 25px 25px;
  position: relative;
  text-align: center;
}

.process-overview-slider .swiper-pagination-bullet-active,
.process-overview-slider--no-bg {
  background: #fff;
}

.process-overview-slider .swiper-pagination-bullet:only-child {
  visibility: hidden;
}

.process-overview-slider--no-bg .swiper-pagination-bullet-active {
  background: #009cd6;
}

.process-overview-slider__body,
.process-overview-slider__title {
  color: #fff;
  position: relative;
  z-index: 2;
}

.process-overview-slider__body {
  margin-top: 12px;
  padding-right: 25px;
}

.process-overview-slider__body--no-bg {
  color: #373a36;
}

.process-overview-slider__title {
  font-size: 1.625em;
  padding-right: 25px;
}

.process-overview-slider__title--no-bg {
  color: #00387a;
}

.process-overview-slider__cta {
  position: relative;
  z-index: 2;
}

.process-overview-slider__container {
  margin: 20px 0;
  max-width: 1000px;
  padding-bottom: 60px;
  padding-right: 100px;
}

.process-overview-slider__bg {
  height: auto;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: auto;
  z-index: 1;
}

.process-overview-slider::before {
  background: #009cd6;
  content: " ";
  height: 100%;
  left: 0;
  opacity: 0.95;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.process-overview-slider--no-bg::before {
  display: none;
}

.process-overview-slide {
  background: rgba(255, 255, 255, 0.75);
  height: auto;
  padding: 25px 0 20px 15px;
  position: relative;
}

.process-overview-slide__before {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  content: "";
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  width: 32px;
  pointer-events: none;
}

.process-overview-slide__before-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.process-overview-slide__before img {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  overflow: hidden;
  width: auto;
}

.process-overview-slide__next-button {
  background: 0 0;
  border: 0;
  left: 100%;
  width: 42px;
  position: absolute;
  top: 0;
  text-indent: -999em;
  height: 100%;
}

.process-overview-slide__next-button:active,
.process-overview-slide__next-button:focus {
  outline-color: #00387a;
}

.process-overview-slide__next-button--disabled {
  pointer-events: none;
  cursor: default;
}

.process-overview-slide__next-button img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: auto;
}

.process-overview-slide__next-button:focus {
  outline: 0;
}

.process-overview-slide__before {
  left: 0;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.process-overview-slide--no-bg {
  background: #e6f1f8;
}

.process-overview-slide__title {
  font-family: "Montserrat", sans-serif;
  color: #373a36;
  font-size: 1.125em;
  font-weight: 700;
  margin-bottom: 8px;
}

.process-overview-slide__body {
  color: rgba(0, 0, 0, 0.75);
  font-size: 0.875em;
}

.process-overview-slide__body::after {
  background: url(https://www.fnb-online.com/assets/images/blue-slider-arrow-red.png)
    no-repeat;
  background-size: auto 100%;
  content: " ";
  display: block;
  height: 100%;
  pointer-events: none;
  position: absolute;
  right: 100%;
  right: calc(100% - 17px);
  top: 2px;
  width: 32px;
}

@media all and (min-width: 1000px) {
  .process-overview-slide,
  .process-overview-slider__slide {
    max-width: 33%;
  }

  .process-overview-slide::before {
    left: 0;
    -webkit-transform: translateX(calc(-100% + 0.5px));
    transform: translateX(calc(-100% + 0.5px));
  }

  .process-overview-slider {
    padding: 40px;
  }

  .process-overview-slider__body,
  .process-overview-slider__title {
    padding: 0;
  }

  .process-overview-slider__container {
    margin: 30px auto;
    overflow: hidden;
    padding-bottom: 0;
    padding-right: 40px;
  }

  .process-overview-slider__container--over-4 {
    padding-bottom: 60px;
  }
}

.filters {
  margin: 15px 0;
  float: left;
  clear: left;
  padding: 0;
  width: 100%;
}

.filters .inner,
.tag_group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.tag_group label {
  margin-right: 10px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}

.tag_group .label {
  margin-right: 15px;
  font-weight: 700;
  text-transform: uppercase;
  color: #373a36;
  font-family: "Titillium Web", sans-serif;
  font-size: 13px;
  line-height: 2em;
}

.filter-box {
  font-size: 14px;
  height: calc(100vh - 60px);
  padding: 0 30px 0 0;
  width: calc(100vw - 60px);
}

.filter-box--desktop {
  min-width: 0;
  padding-bottom: 50px;
  display: none;
  font-size: 14px;
  padding-top: 24px;
}

.filter-box--no-pad-top {
  padding-top: 6px;
}

.filter-box__back-close {
  background: 0 0;
  border: 0;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#e6e1dd),
    to(#fff)
  );
  background: linear-gradient(90deg, #e6e1dd, #fff);
  position: relative;
  display: block;
  font-size: 16px;
  padding: 6px 10px 7px 55px;
  text-align: left;
  width: 100%;
}

.filter-box__back-close:active,
.filter-box__back-close:focus {
  outline-color: #00387a;
}

.article-list__cta::after,
.filter-box__back-close:before {
  border: 1px solid #00387a;
  border-bottom: 0;
  content: " ";
  display: block;
  position: absolute;
}

.filter-box__back-close:before {
  left: 25px;
  -webkit-transform: translateY(-50%) rotate(-45deg);
  transform: translateY(-50%) rotate(-45deg);
  border-right: 0;
  width: 12px;
  height: 12px;
  top: 50%;
}

.filter-box__fieldset {
  font-family: "Montserrat", sans-serif;
  border: 0;
  padding: 20px 30px;
}

.filter-box__fieldset legend {
  text-transform: uppercase;
  line-height: 1.2;
  letter-spacing: -0.15px;
}

.filter-box__scroll-content {
  -webkit-overflow-scrolling: touch;
  max-height: calc(100% - 37px);
  overflow: auto;
  scroll-behavior: smooth;
}

.filter-box-desktop__header {
  line-height: 1.25;
  margin-top: 20px;
  margin-bottom: 8px;
  font-size: 16px;
  color: #00387a;
}

.filter-box-desktop__subheader {
  font-size: 1em;
  font-weight: 700;
  line-height: 1.25;
  margin: 0 0 4px;
  color: #373a36;
}

@media all and (min-width: 768px) {
  .tag_group label {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
}

@media all and (min-width: 900px) {
  .filter-box-desktop__header--extra-mb {
    margin-bottom: 30px;
  }

  .filter-box {
    height: auto;
    width: 100%;
  }

  .filter-box--desktop {
    display: block;
  }

  .filter-box--desktop--no-pad-top {
    padding-top: 0;
  }

  .filter-box__fieldset {
    padding: 12px 0 20px;
  }

  .filter-box-filter-box-desktop__header {
    font-size: 18px;
    font-weight: 700;
  }

  .filter-box-filter-box-desktop__callout {
    font-size: 16px;
    margin-top: 30px;
  }

  .filter-box__results-info {
    font-size: 18px;
    margin: 0;
  }
}

.article-list {
  margin: 0 auto;
  max-width: 1060px;
  text-align: center;
  width: 100%;
}

.article-list--pad {
  padding: 0 30px;
}

.article-list--sidebar {
  padding: 0;
}

.article-list--weave {
  background: url(https://www.fnb-online.com/assets/images/weave-bg-ltgray.png)
    center top;
  background-size: 229px auto;
  padding: 30px;
  max-width: none;
}

.article-list--gray {
  background: rgba(0, 0, 0, 0.1);
  max-width: none;
  padding: 30px;
}

.article-list--sidebar {
  max-width: 400px;
}

.article-list__intro {
  text-align: left;
  margin-top: 30px;
}

.article-list__intro--centered {
  text-align: center;
}

.article-list__cta {
  display: none;
  font-family: "Titillium Web", sans-serif;
  color: #00387a;
  font-size: 1em;
  margin-bottom: 60px;
  padding-right: 30px;
  position: relative;
}

.article-list__cta::after {
  border-left: 0;
  height: 15px;
  right: 0;
  top: 60%;
  -webkit-transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
  -webkit-transform: scaleY(1.2) translateY(-50%) rotate(45deg);
  transform: scaleY(1.2) translateY(-50%) rotate(45deg);
  width: 15px;
}

.article-list__cta--show-mobile {
  display: inline-block;
  margin: 30px auto 60px;
}

.article-list__block-cta {
  background: #f4f4f2;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  margin-top: 20px;
  padding: 20px 5%;
}

.article-list__ctas {
  font-family: "Montserrat", sans-serif;
  color: #6a6a6a;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.article-list__list {
  text-align: left;
}

.article-list__list--bg,
.article-list__list--gray {
  margin: 0 auto;
  max-width: 1060px;
}

.article-list__title {
  color: #00387a;
  font-size: 1.5em;
  margin-bottom: 20px;
  margin-top: 30px;
  text-align: left;
}

.article-list__title--bg,
.article-list__title--no-images {
  color: #6a6a6a;
}

.article-list__title--bg {
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
}

.article-list__title--center {
  text-align: center;
}

.article-list__title--sidebar {
  color: #6a6a6a;
  font-size: 1.125em;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 10px;
  margin-top: 0;
}

.article-list__sub-title {
  color: #6a6a6a;
  font-size: 0.875em;
  line-height: 1.425;
  margin: 8px 0;
  text-align: left;
}

@media all and (min-width: 650px) {
  .article-list__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    width: 100%;
  }

  .article-list__list--sidebar {
    display: block;
  }
}

@media all and (min-width: 900px) {
  .article-list {
    text-align: center;
  }

  .article-list--gray {
    padding: 40px 0 60px;
  }

  .article-list__list--gray {
    padding: 0 30px;
  }

  .article-list__block-cta {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    -ms-flex: 0 0 calc(50% - 10px);
    flex: 0 0 calc(50% - 10px);
    max-width: 50%;
    max-width: calc(50% - 10px);
  }

  .article-list__intro {
    margin: 0 auto;
    max-width: 720px;
  }

  .article-list__cta {
    display: inline-block;
  }

  .article-list__cta:active::after,
  .article-list__cta:focus::after,
  .article-list__cta:hover::after {
    -webkit-transform: scaleY(1.2) translate(15px, -50%) rotate(45deg);
    transform: scaleY(1.2) translate(15px, -50%) rotate(45deg);
  }

  .article-list__title {
    font-size: 2em;
    line-height: 1.25;
  }

  .article-list__title--no-margin-bottom {
    margin-bottom: 0;
  }

  .article-list__title--bg {
    font-size: 44px;
    margin-bottom: 40px;
  }

  .article-list__title--small-blue {
    color: #00387a;
    font-size: 1.625em;
    margin-bottom: 0;
  }

  .article-list__title--center {
    text-align: center;
  }

  .article-list__title--big {
    font-size: 2.625em;
  }

  .article-list__title--medium {
    font-size: 2.25em;
    margin-bottom: 0;
    margin-top: 0;
  }

  .article-list__title--sidebar {
    font-size: 1.125em;
  }

  .article-list__title--no-images {
    font-size: 2em;
    margin-top: 50px;
  }

  .article-list__sub-title {
    font-size: 1em;
  }
}

.article-thumb {
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  margin: 40px auto 0;
  position: relative;
}

.article-thumb__extra-link {
  color: #00387a;
  position: relative;
  padding-right: 30px;
}

.article-thumb .play-button {
  bottom: 12px;
}

.article-thumb--pointer {
  cursor: pointer;
}

.article-thumb__close-modal {
  border: 0;
  text-indent: -999em;
  border-radius: 90px;
  position: absolute;
  top: 25px;
  right: 25px;
  height: 40px;
  width: 40px;
  background: #fff;
  cursor: pointer;
}

.article-thumb__close-modal:active,
.article-thumb__close-modal:focus {
  outline-color: #00387a;
  outline: 2px solid #00387a;
}

.article-thumb__close-modal__with-border {
  border: 1px solid #00387a;
}

.article-thumb__close-modal::after,
.article-thumb__close-modal::before {
  background: #00387a;
  content: " ";
  display: block;
  height: 50%;
  left: 50%;
  position: absolute;
  top: 25%;
  width: 2px;
}

.article-thumb__close-modal::before {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.article-thumb__close-modal::after {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.article-thumb--bg {
  background-color: #fff;
}

.article-thumb--collapsed {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 auto;
}

.article-thumb--collapsed:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.article-thumb--expanded {
  max-width: 100%;
  background: #f4f4f2;
  padding: 50px 25px;
}

.article-thumb--no-image {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.article-thumb__img-wrap {
  height: 0;
  overflow: hidden;
  padding-bottom: 61.7284%;
  position: relative;
}

.article-thumb__img-wrap img {
  left: 50%;
  top: 50%;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: block;
  min-width: 100%;
  width: 100%;
  height: auto;
}

.article-thumb__img-wrap--collapsed {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 32.894%;
  flex: 0 0 32.894%;
  padding-bottom: 30.40068%;
}

.article-thumb__alt-link {
  position: relative;
  padding-right: 20px;
  margin-top: 20px;
  display: inline-block;
  font-size: 16px;
}

.article-thumb__alt-link:before {
  border: 1px solid #00387a;
  content: " ";
  display: block;
  width: 12px;
  height: 12px;
  right: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(135deg);
  transform: translateY(-50%) rotate(135deg);
  border-right: 0;
  border-bottom: 0;
  -webkit-transition: -webkit-transform 0.15s;
  transition: transform 0.15s;
  transition: transform 0.15s, -webkit-transform 0.15s;
}

.article-thumb__alt-link:active:before,
.article-thumb__alt-link:focus:before,
.article-thumb__alt-link:hover:before {
  -webkit-transform: translateY(-50%) translateX(15px) rotate(135deg);
  transform: translateY(-50%) translateX(15px) rotate(135deg);
  border-color: #00387a;
}

.article-thumb__cover-link {
  background: 0 0;
  border: 0;
  width: 100%;
}

.article-thumb__cover-link::before {
  background: #a80432;
  content: " ";
  display: block;
  height: 3px;
  left: 0;
  position: absolute;
  top: -8px;
  -webkit-transform-origin: left top;
  transform-origin: left top;
  -webkit-transition: -webkit-transform 0.15s ease-out;
  transition: transform 0.15s ease-out;
  transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
  width: 50%;
}

.article-thumb__cover-link:active::before,
.article-thumb__cover-link:focus::before,
.article-thumb__cover-link:hover::before {
  -webkit-transform: scaleX(1.3);
  transform: scaleX(1.3);
}

.article-thumb__cover-link:active,
.article-thumb__cover-link:focus {
  outline-color: #00387a;
}

.article-thumb__cover-link--collapsed {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  padding: 7px 0;
}

.article-thumb__cover-link--collapsed::before {
  display: none;
}

.article-thumb__cover-link--expanded::before {
  top: auto;
  left: auto;
  width: 16%;
}

.article-thumb__body {
  color: #6a6a6a;
  font-size: 0.875em;
  line-height: 1.425;
  margin: 8px 0;
}

.article-thumb__body--collapsed {
  display: none;
}

.article-thumb__copy-wrap {
  padding: 15px 15px 20px;
  text-align: left;
}

.article-thumb__copy-wrap--no-image {
  padding: 10px 0 0;
}

.article-thumb__copy-wrap--collapsed {
  padding: 0 0 0 15px;
}

.article-thumb__title {
  font-family: "Titillium Web", sans-serif;
  color: #00387a;
  font-size: 1.375em;
  line-height: 1.18;
}

.article-thumb__title--small {
  font-size: 1.375em;
  line-height: 1.27;
  margin-bottom: 20px;
}

.article-thumb__title--collapsed {
  font-family: "Montserrat", sans-serif;
  font-size: 1em;
}

.article-thumb__label {
  font-family: "Titillium Web", sans-serif;
  color: #6a6a6a;
  font-size: 0.75em;
  font-weight: 700;
  margin-bottom: 8px;
  text-transform: uppercase;
}

@media all and (min-width: 650px) {
  .article-thumb {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 10px);
    flex: 0 0 calc(50% - 10px);
    margin: 20px 5px;
  }

  .article-thumb:first-child,
  .article-thumb:nth-child(2) {
    margin-top: 20px;
  }

  .article-thumb__close-modal {
    top: 50px;
    right: 50px;
  }

  .article-thumb--collapsed {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin: -1px 0 0;
  }

  .article-thumb--expanded {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    background: #f4f4f2;
    padding: 50px 140px 50px 50px;
  }
}

@media all and (min-width: 900px) {
  .article-thumb {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 calc(33.3% - 13px);
    flex: 1 1 calc(33.3% - 13px);
    margin: 35px 20px 20px 0;
    width: calc(33.3% - 14px);
    max-width: calc(33.3% - 14px);
  }

  .article-thumb--grow {
    max-width: calc(100% - 23px);
  }

  .article-thumb--stay-mobile {
    max-width: none;
    width: 100%;
  }

  .article-thumb:first-child,
  .article-thumb:nth-child(2) {
    margin-top: 35px;
  }

  .article-thumb--two-wide:nth-child(even),
  .article-thumb:nth-child(3n) {
    margin-right: 0;
  }

  .article-thumb--two-wide {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 calc(50% - 13px);
    flex: 1 1 calc(50% - 13px);
    max-width: calc(50% - 14px);
  }

  .article-thumb--collapsed {
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    border: 0;
  }

  .article-thumb--collapsed:first-child {
    border: 0;
  }

  .article-thumb--expanded {
    max-width: 100%;
    background: #f4f4f2;
    padding: 50px 140px 50px 50px;
  }

  .article-thumb--plain-desktop {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .article-thumb--stay-mobile {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin: 0;
  }

  .article-thumb--stay-mobile:first-child {
    margin: 20px 0 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .article-thumb--stay-mobile:nth-child(2) {
    margin: 0;
  }

  .article-thumb--stay-mobile:last-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }

  .article-thumb__title {
    font-size: 1.5em;
  }

  .article-thumb__title--collapsed {
    font-family: "Titillium Web", sans-serif;
    font-size: 1.25em;
  }

  .article-thumb__title--small {
    font-size: 1.375em;
  }

  .article-thumb__title--stay-mobile {
    font-size: 1em;
    line-height: 1.5;
  }

  .article-thumb__label {
    font-size: 0.875em;
  }

  .article-thumb__img-wrap--collapsed {
    padding-bottom: 41.875%;
  }

  .article-thumb__img-wrap--plain-desktop {
    padding-bottom: 68.378%;
  }

  .article-thumb__img-wrap--stay-mobile {
    padding-bottom: 30.40068%;
  }

  .article-thumb__img-wrap--stay-mobile img {
    height: 100%;
    width: auto;
  }

  .article-thumb__copy-wrap--collapsed {
    padding: 15px 15px 20px;
  }

  .article-thumb__copy-wrap--plain-desktop {
    padding: 15px 0 20px;
  }

  .article-thumb__copy-wrap--stay-mobile {
    padding: 0 15px 20px;
  }

  .article-thumb__body {
    font-size: 1em;
  }

  .article-thumb__body--collapsed,
  .article-thumb__cover-link--collapsed::before {
    display: block;
  }

  .article-thumb__body--plain-desktop,
  .article-thumb__cover-link--stay-mobile::before {
    display: none;
  }

  .article-thumb__cover-link {
    display: block !important;
  }

  .article-thumb__cover-link--collapsed {
    padding: 0;
  }

  .article-thumb__cover-link--stay-mobile {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    padding: 10px 0;
    width: 100%;
  }
}

@media screen and (min-width: 900px) and (-ms-high-contrast: none) {
  .article-thumb--two-wide,
  ::-ms-backdrop {
    width: calc(50% - 14px);
  }
}

.article-detail {
  padding-bottom: 80px;
}

.article-detail__related-articles {
  padding: 0 35px;
}

.article-detail-section--no-full-width-items {
  max-width: 1060px;
  margin: 0 auto;
  padding: 10px 30px;
}

.article-detail-sidebar--desktop-only {
  display: none;
}

.article-detail-sidebar__subheadline {
  font-size: 1em;
  font-weight: 700;
  line-height: 1.25;
  margin: 0 0 4px;
  color: #373a36;
}

@media all and (min-width: 900px) {
  .article-detail-section--no-full-width-items {
    padding-right: calc(33.33% + 20px);
  }

  .article-detail {
    margin-top: 40px;
    position: relative;
  }

  .article-detail__related-articles {
    padding: 0;
  }

  .article-detail-sidebar {
    display: block;
    padding: 10px 0;
    position: absolute;
    right: 60px;
    top: 0;
    width: calc(33.33333% - 60px);
    will-change: position, top, transform;
  }

  .article-detail-sidebar--desktop-only {
    display: block;
  }

  .article-detail-sidebar--locked {
    position: fixed;
    top: 125px;
  }

  .article-detail-sidebar__buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1060px) {
  .article-detail-section--no-full-width-items {
    padding-right: 373px;
  }
}

@media all and (min-width: 1120px) {
  .article-detail-sidebar {
    max-width: 313px;
    right: calc((100vw - 1120px) / 2 + 60px);
  }
}

.article-detail-header {
  background: rgba(0, 207, 224, 0.2);
  color: #373a36;
  padding-top: 32px;
  position: relative;
}

.article-detail-header__img {
  width: 100%;
  height: auto;
  max-width: 70%;
}

.article-detail-header__img--box {
  max-width: 30%;
}

.article-detail-header__img--portrait {
  max-width: none !important;
  width: 100%;
}

.article-detail-header__main-content {
  padding: 20px 32px 32px;
}

.article-detail-header__main-content--pad {
  padding-left: 32px !important;
  padding-top: 0;
  padding-bottom: 40px;
  -webkit-box-flex: 0 !important;
  -ms-flex: 0 0 calc(100% - 200px) !important;
  flex: 0 0 calc(100% - 200px) !important;
  margin-top: 10px;
  width: 100% !important;
}

.article-detail-header__img-wrap {
  padding: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #fff;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.article-detail-header__img-wrap--empty {
  padding: 0;
}

.article-detail-header__img-wrap--portrait {
  display: block !important;
  width: auto !important;
  -webkit-box-flex: 0 !important;
  -ms-flex: 0 0 auto !important;
  flex: 0 0 auto !important;
  max-width: none;
  background: 0 0;
  padding: 32px !important;
}

.article-detail-header--blue-weave-bg {
  background: url(https://www.fnb-online.com/assets/images/banner-bg-blue.png)
    center center;
  background-size: 460px auto;
}

.article-detail-header__buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  right: 0;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.article-detail-header--gray-bg {
  background: #f4f4f2;
}

.article-detail-header--half-gray-bg {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(25%, #f4f4f2),
    color-stop(25%, #fff)
  );
  background: linear-gradient(to bottom, #f4f4f2 25%, #fff 25%);
}

.article-detail-header--white-bg {
  background: #fff;
}

.article-detail-header--no-body {
  padding: 60px 0 15px;
}

.article-detail-header--no-body-or-breadcrumbs {
  padding: 15px 0 5px;
}

@media (max-width: 768px) {
  .article-detail-header--no-breadcrumb-mobile .breadcrumb {
    display: none;
  }
}

.article-detail-header__content-wrap {
  margin: 0 auto;
  max-width: 1000px;
  position: relative;
}

.article-detail-header__content-wrap--not-relative,
.article-detail-header__content-wrap--not-relative-until-desktop {
  padding-top: 0;
  position: static;
}

.article-detail-header__content-wrap--no-body {
  padding-top: 0;
}

.article-detail-header__content-wrap--margin-top {
  margin-top: 40px;
  display: block !important;
}

.article-detail-header__content-wrap--top {
  right: 0;
  text-align: center;
  display: inline !important;
}

.article-detail-header__title {
  color: #00387a;
  font-size: 1.5em;
  line-height: 1.25;
  margin-bottom: 12px;
  text-transform: none;
  letter-spacing: 0;
}

.article-detail-header__title--blue {
  color: #95f7ff;
}

.article-detail-header__title--no-mar {
  margin-bottom: 0;
  font-size: 2.625em;
}

.article-detail-header__title--wrap {
  width: auto;
  text-align: center;
}

.article-detail-header__subheader,
.article-detail-header__subheader--white {
  color: #00387a;
  font-size: 1em;
  font-weight: 700;
  margin-bottom: 12px;
}

.article-detail-header__subheader--white {
  color: #fff;
}

.article-detail-header__body {
  font-size: 16px;
  line-height: 1.5;
}

.article-detail-header__body--blue {
  color: #fff;
}

.article-detail-header__button--caption {
  font-size: 0.75em;
  padding-top: 20px;
  color: #6a6a6a;
  margin-bottom: 10px;
}

.article-detail-header__button--caption--blue {
  color: #fff;
}

.article-detail-header__features-wrap {
  text-align: center;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(50% - 120px);
  flex: 0 0 calc(50% - 120px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 40px auto auto;
}

.article-detail-header__features-list {
  -webkit-box-pack: center;
  justify-content: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-pack: distribute;
  display: -ms-flexbox;
  margin: 0 20px;
  width: 100%;
}

.article-detail-header__features-list-item {
  -ms-flex: 0;
  flex: 0;
  min-width: 80px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  padding: 0 4px;
  margin: 0 9px;
}

.article-detail-header__features-list-item img {
  width: 30px;
  height: 32px;
}

.article-detail-header__secondary-content {
  padding-bottom: 20px;
}

.article-detail-header__secondary-content--read-more {
  max-height: 325px;
  overflow: hidden;
}

.article-detail-header--caption-wrap {
  background: #f4f4f2;
  height: 35px;
  padding: 5px 10px;
  margin-top: -6px;
}

.article-detail-header--caption,
.responsive-table a {
  font-weight: 700;
  color: #00387a;
}

@media all and (min-width: 600px) {
  .article-detail-header__img {
    max-width: 40%;
  }

  .article-detail-header__img--box {
    max-width: 25%;
  }
}

@media all and (min-width: 768px) {
  .article-detail-header {
    padding: 32px 32px 40px;
  }

  .article-detail-header--no-body {
    padding: 10px 32px;
  }

  .article-detail-header--no-body-or-breadcrumbs {
    padding: 20px 32px;
  }

  .article-detail-header__img {
    max-width: 100%;
  }

  .article-detail-header__img--box {
    max-width: 175px;
  }

  .article-detail-header__title {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .article-detail-header__img-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 35%;
    flex: 0 0 35%;
    width: 35%;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    background-color: transparent;
  }

  .article-detail-header__img-wrap--portrait {
    padding: 0 !important;
    margin-top: 50px;
  }

  .article-detail-header--has-breadcrumbs {
    padding-top: 52px;
  }

  .article-detail-header__main-content {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 65%;
    flex: 0 0 65%;
    width: 65%;
  }

  .article-detail-header__main-content--pad {
    margin-top: 150px;
    padding-bottom: 0 !important;
  }

  .article-detail-header__content-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .article-detail-header__content-wrap--not-relative {
    padding-top: 0;
  }

  .article-detail-header__content-wrap--top {
    position: absolute !important;
    left: 120px !important;
    -ms-flex-pack: distribute;
    display: -ms-flexbox !important;
    padding-right: 40px;
    top: 50px;
  }

  .article-detail-header__content-wrap--margin-top {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .article-detail-header__body {
    font-size: 16px !important;
  }

  .article-detail-header__title {
    font-size: 2.625em;
  }

  .article-detail-header__title--no-body {
    margin-top: -10px;
    margin-bottom: 0;
  }

  .article-detail-header__title--no-shift {
    margin-top: 0;
  }

  .article-detail-header__title--wrap {
    position: absolute;
    left: 160px;
  }

  .article-detail-header__features-wrap {
    position: absolute;
    top: 360px;
    left: -140px;
    min-width: 280px;
  }

  .article-detail-header__features-list-item {
    margin: 0 auto;
  }

  .article-detail-header__img--portrait {
    max-width: 250px !important;
  }

  .article-detail-header--half-gray-bg {
    background: linear-gradient(to bottom, #f4f4f2 200px, #fff 200px);
    min-height: 600px;
  }

  .article-detail-header__buttons {
    position: absolute;
    padding: 0 15px;
  }
}

@media all and (min-width: 900px) {
  .article-detail-header {
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 0;
  }

  .article-detail-header--no-body-or-breadcrumbs {
    padding-top: 20px;
  }

  .article-detail-header__main-content {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(100% - 310px);
    flex: 0 0 calc(100% - 310px);
    width: calc(100% - 310px);
    padding-left: 0;
  }

  .article-detail-header__main-content--no-bottom-pad {
    padding-bottom: 0;
  }

  .article-detail-header__main-content--pad {
    margin-top: 70px;
  }

  .article-detail-header__img-wrap {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 310px;
    flex: 0 0 310px;
    width: 310px;
    padding-right: 0;
  }

  .article-detail-header__img-wrap--portrait {
    margin-top: 0;
  }

  .article-detail-header__content-wrap--not-relative-until-desktop {
    position: relative;
    padding-top: 0;
  }

  .article-detail-header__content-wrap--top {
    padding-right: 0;
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
    top: 0;
  }

  .article-detail-header__buttons {
    top: 20px;
    padding: 0;
  }

  .article-detail-header__features-wrap {
    margin-top: 0;
    position: relative;
    left: calc(38% - 20px);
    top: 0;
    min-width: 0;
  }

  .article-detail-header__features-list {
    padding-left: 98px;
  }

  .article-detail-header__features-list-item {
    margin: 0 9px;
  }

  .article-detail-header__title--wrap {
    text-align: left;
  }

  .article-detail-header--half-gray-bg {
    background: linear-gradient(
      to bottom,
      #f4f4f2 150px,
      #fff 150px
    ) !important;
    min-height: 0;
  }

  .article-detail-header__secondary-content-wrap {
    margin-top: 30px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }

  .article-detail-header__secondary-content--split {
    border-left: 2px dotted #f4f4f2;
    height: auto;
    margin-left: 20px;
    margin-right: 20px;
  }

  .article-detail-header__secondary-content--max {
    max-width: 450px;
  }

  .article-detail-header__secondary-content--contact {
    min-width: 30%;
  }
}

@media all and (min-width: 982px) {
  .article-detail-header__main-content--pad {
    margin-top: 80px;
  }
}

.article-detail-header
  .product-detail-intro__buttons
  .content-page-header__cta {
  float: none !important;
}

.responsive-table {
  color: #373a36;
}

.responsive-table__table {
  width: 100%;
  border-collapse: collapse;
}

.responsive-table__single-mobile-table {
  margin: 0 auto;
}

.responsive-table img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}

.responsive-table tbody tr:last-child td {
  border-bottom: 8px solid #00387a;
}

.responsive-table thead td {
  background: #00387a;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  color: #fff;
  font-weight: 700;
}

.responsive-table thead td:empty,
.responsive-table thead td:first-child {
  background: 0 0;
  border-top: 0;
  border-left: 0;
}

.responsive-table td {
  border: 1px solid #98b5c3;
  min-width: 100px;
  padding: 5px;
  text-align: center;
  vertical-align: middle;
}

.responsive-table td:first-child {
  background: #eff4f6;
  max-width: 200px;
  text-align: left;
}

.responsive-table__desktop {
  display: none;
  padding: 0 30px;
}

.responsive-table__desktop--active {
  display: block;
}

.regional-rate__change-form[aria-hidden="true"],
.regional-rate__messages[aria-hidden="true"],
.responsive-table__mobile--inactive {
  display: none;
}

@media all and (min-width: 768px) {
  .responsive-table__desktop {
    display: block;
  }

  .responsive-table__desktop--inactive,
  .responsive-table__mobile {
    display: none;
  }

  .responsive-table__mobile--active {
    display: block;
  }
}

@media all and (min-width: 900px) {
  .responsive-table__desktop {
    padding: 0;
  }
}

.responsive-table a.blue-gradient-button {
  font-weight: 400;
  color: #fff;
  text-decoration: none;
}

.rate-table__table {
  margin-bottom: 10px;
}

.regional-rate__view-all {
  font-size: 0.875em;
  margin-top: 20px;
  display: inline-block;
}

.regional-rate__form {
  margin-bottom: 15px;
  max-width: 70%;
}

.regional-rate__messages {
  font-size: 14px;
  margin-bottom: 15px;
}

.regional-rate__messages--errors {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.regional-rate__messages--errors .regional-rate__message {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
}

.regional-rate__text {
  color: #6a6a6a;
  font-weight: 400;
}

.regional-rate__change-button {
  background: 0 0;
  border: 0;
  color: #00387a;
}

.recommended-product__video-button:active,
.recommended-product__video-button:focus,
.regional-rate__change-button:active,
.regional-rate__change-button:focus {
  outline-color: #00387a;
}

.regional-rate__change-form {
  margin-bottom: 15px;
  max-width: 634px;
}

.rate-table__product-name {
  color: #00387a;
  font-size: 2em;
  margin-bottom: 20px;
}

.regional-pricing-listing {
  margin: 0 auto;
  max-width: 1120px;
  padding: 30px;
  position: relative;
}

.regional-pricing-listing:after {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  content: " ";
  display: block;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  -webkit-transform: translateX(-20px);
  transform: translateX(-20px);
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  margin: 0;
  top: 35%;
  z-index: 3;
}

.regional-pricing-listing:before {
  display: block;
  content: " ";
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  width: 100%;
  height: 100%;
  top: -10px;
  left: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  opacity: 1;
  z-index: 3;
  pointer-events: none;
}

.regional-pricing-listing::after,
.regional-pricing-listing::before {
  opacity: 0;
  pointer-events: none;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.regional-pricing-listing--loading::after,
.regional-pricing-listing--loading::before {
  opacity: 1;
  pointer-events: auto;
}

@media screen and (min-width: 800px) {
  .regional-pricing-listing {
    padding: 30px 60px;
  }
}

.condensed-product-strip {
  color: #00387a;
  margin: 30px auto;
  max-width: calc(100% - 70px);
  text-align: center;
}

.condensed-product-strip__content-wrap {
  border-bottom: 1px solid #979797;
  border-top: 2px solid #aeaca6;
  padding-bottom: 12px;
}

.condensed-product-strip__title {
  background: #fff;
  color: #6a6a6a;
  display: inline-block;
  font-size: 0.875em;
  font-weight: 700;
  margin: 0 auto;
  padding: 5px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  -webkit-transform: translateY(-55%);
  transform: translateY(-55%);
  width: auto;
  z-index: 2;
}

.condensed-product-strip__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  overflow: hidden;
  padding: 0 10px;
}

.condensed-product-strip__list--ga {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.recommended-product {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 150px;
  padding: 0 15px;
  position: relative;
}

.recommended-product--ga {
  max-width: 50%;
  margin-bottom: 20px;
}

.recommended-product__video-button {
  background: 0 0;
  border: 0;
  position: relative;
}

.recommended-product__img {
  margin: 0 auto;
}

.recommended-product--hid {
  display: none;
}

.recommended-product a {
  color: #00387a;
}

.recommended-product a:active,
.recommended-product a:focus,
.recommended-product a:hover {
  text-decoration: underline;
}

@media all and (min-width: 600px) {
  .recommended-product--hid-3 {
    display: list-item;
  }

  .recommended-product__name {
    color: #00387a;
    display: block;
    padding: 10px;
  }
}

@media all and (min-width: 650px) {
  .recommended-product--hid-4 {
    display: list-item;
  }
}

@media all and (min-width: 900px) {
  .condensed-product-strip__list--ga {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .recommended-product {
    font-size: 1.3125em;
    line-height: 1.25;
    padding: 0 5px;
  }

  .recommended-product--ga {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    max-width: 150px;
  }

  .recommended-product--sidebar.recommended-product--hid-4 {
    display: none;
  }

  .condensed-product-strip {
    margin: 30px auto;
    max-width: 1120px;
    padding: 0 60px;
  }

  .condensed-product-strip__title {
    font-size: 1.125em;
    padding: 5px 15px;
  }

  .condensed-product-strip__list {
    padding: 0;
  }

  .condensed-product-strip__content-wrap--with-sidebar {
    margin-right: calc(33.33% + 20px);
  }
}

@media all and (min-width: 1020px) {
  .recommended-product--sidebar.recommended-product--hid-4 {
    display: list-item;
  }
}

.content-list {
  background: #fff;
  margin: 0 auto;
  max-width: 1060px;
  padding: 30px 0 60px;
  text-align: center;
}

.content-list:last-child {
  padding-bottom: 30px;
}

.content-list--full-width {
  max-width: none;
  text-align: left;
}

.content-list--padding-top {
  margin-top: 40px;
}

.content-list__content-wrap {
  margin: 0 auto;
  max-width: 1060px;
  padding: 0 30px 20px;
}

.content-list__content-wrap--left {
  text-align: left;
}

.content-list__content-wrap--full-width {
  max-width: none;
  padding-bottom: 0;
}

.content-list__title {
  color: #00387a;
  font-size: 1.625em;
  line-height: 1.25;
  margin: 20px auto;
}

.content-list__title--full-width {
  margin-bottom: 0;
  font-size: 1.5em;
  text-align: center;
}

.content-list__title--small {
  font-size: 1.375em;
}

.content-list__body {
  color: #6a6a6a;
  font-size: 0.875em;
  line-height: 1.4285;
  margin-top: 15px;
}

.content-list__list {
  margin: 0;
  padding: 0;
  text-align: center;
}

.content-list__list--loading {
  position: relative;
}

.content-list__list--loading:after {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  content: " ";
  display: block;
  margin: 30px auto;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  -webkit-transform: translateX(-20px);
  transform: translateX(-20px);
}

.content-list__list--grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.content-list-item,
.content-list__item {
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fff),
    to(rgba(0, 0, 0, 0.1))
  );
  background: linear-gradient(180deg, #fff, rgba(0, 0, 0, 0.1));
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 30px;
  text-align: left;
  position: relative;
}

.content-list-item--with-video,
.content-list__item--with-video {
  padding-bottom: 40px;
}

.content-list-item--not-full-width,
.content-list__item--not-full-width {
  max-width: 1060px;
  margin: 0 auto;
}

.content-list-item__box-img,
.content-list__item__box-img {
  display: block;
  z-index: 2;
}

@media all and (min-width: 768px) {
  .content-list-item__box-img,
  .content-list__item__box-img {
    left: 100% !important;
    top: 50% !important;
    -webkit-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
    height: 250px !important;
    width: auto;
    min-width: 0 !important;
  }
}

@media only screen and (max-width: 768) {
  .content-list-item__box-img--mobile-only,
  .content-list__item__box-img--mobile-only {
    display: none;
  }
}

.content-list-item--with-offer .content-list-item__link::before,
.content-list-item__bg-img,
.content-list__item--with-offer .content-list-item__link::before,
.content-list__item__bg-img {
  display: none;
}

@media all and (min-width: 768px) {
  .content-list-item__bg-img,
  .content-list__item__bg-img {
    display: block;
  }
}

.content-list-item--alt,
.content-list__item--alt {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fff),
    to(#e8e8e8)
  );
  background: linear-gradient(180deg, #fff, #e8e8e8);
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  margin-top: 40px;
  position: relative;
  text-align: left;
}

.content-list-item--full-width:first-child,
.content-list__item--full-width:first-child {
  margin-top: 0;
}

.cart-table > div,
.content-list-item__img-wrap,
.content-list__item__img-wrap {
  overflow: hidden;
}

.content-list-item__img-wrap--alt,
.content-list__item__img-wrap--alt {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  height: 0;
  overflow: hidden;
  position: relative;
}

@media all and (min-width: 768px) {
  .content-list-item__img-wrap,
  .content-list__item__img-wrap {
    overflow: visible;
  }

  .content-list-item__img-wrap--alt,
  .content-list__item__img-wrap--alt {
    overflow: hidden;
  }

  .content-list-item__img-wrap--not-full-width,
  .content-list__item__img-wrap--not-full-width {
    max-width: 1060px;
    margin: 0 auto;
  }
}

.content-list-item__img-wrap--not-full-width,
.content-list__item__img-wrap--not-full-width {
  max-width: 1060px;
  margin: 0 auto;
}

.content-list-item__video-link,
.content-list__item__video-link {
  position: absolute !important;
  right: 50px;
  bottom: 20px;
}

.content-list-item__copy-wrap--alt,
.content-list__item__copy-wrap--alt {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  padding: 20px 25px;
  position: relative;
}

.content-list-item__copy-wrap--alt::after,
.content-list__item__copy-wrap--alt::after {
  border: 1px solid #00387a;
  border-bottom: 0;
  border-left: 0;
  content: " ";
  display: block;
  height: 18px;
  position: absolute;
  right: 30px;
  top: 50%;
  top: calc(50% - 4px);
  -webkit-transform: translate(0, 0) scale(1, 2) rotate(45deg);
  transform: translate(0, 0) scale(1, 2) rotate(45deg);
  -webkit-transition: -webkit-transform 0.15s ease-out;
  transition: transform 0.15s ease-out;
  transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
  width: 18px;
}

.content-list-item__copy-wrap--alt:active::after,
.content-list-item__copy-wrap--alt:focus::after,
.content-list-item__copy-wrap--alt:hover::after,
.content-list__item__copy-wrap--alt:active::after,
.content-list__item__copy-wrap--alt:focus::after,
.content-list__item__copy-wrap--alt:hover::after {
  border-color: #00387a;
  -webkit-transform: translate(0, 0) scale(1, 2) rotate(45deg);
  transform: translate(0, 0) scale(1, 2) rotate(45deg);
}

.content-list-item__title--redline-mobile-only::before,
.content-list-item__title--redline::before,
.content-list__item__title--redline-mobile-only::before,
.content-list__item__title--redline::before {
  background: #a80432;
  content: " ";
  display: block;
  height: 3px;
  left: 0;
  position: absolute;
  top: -8px;
  -webkit-transform-origin: left top;
  transform-origin: left top;
  -webkit-transition: -webkit-transform 0.15s ease-out;
  transition: transform 0.15s ease-out;
  transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
  width: 30%;
}

.content-list-item__title--redline-mobile-only:active::before,
.content-list-item__title--redline-mobile-only:focus::before,
.content-list-item__title--redline-mobile-only:hover::before,
.content-list-item__title--redline:active::before,
.content-list-item__title--redline:focus::before,
.content-list-item__title--redline:hover::before,
.content-list__item__title--redline-mobile-only:active::before,
.content-list__item__title--redline-mobile-only:focus::before,
.content-list__item__title--redline-mobile-only:hover::before,
.content-list__item__title--redline:active::before,
.content-list__item__title--redline:focus::before,
.content-list__item__title--redline:hover::before {
  -webkit-transform: scaleX(1.3);
  transform: scaleX(1.3);
}

.content-list-item__title--alt,
.content-list__item__title--alt {
  color: #00387a;
  margin-bottom: 12px;
}

.content-list-item__body--alt,
.content-list__item__body--alt {
  font-family: "Montserrat", sans-serif;
  color: #373a36;
  font-size: 0.875em;
  padding-right: 30px;
}

.content-list-item__link--alt,
.content-list__item__link--alt {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-bottom: 40px;
  position: relative;
}

.content-list-item__link--alt::before,
.content-list__item__link--alt::before {
  background: #a80432;
  content: " ";
  display: block;
  height: 3px;
  left: 0;
  position: absolute;
  top: -8px;
  -webkit-transform-origin: left top;
  transform-origin: left top;
  -webkit-transition: -webkit-transform 0.15s ease-out;
  transition: transform 0.15s ease-out;
  transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
  width: 106px;
}

.content-list-item__link--alt:active::before,
.content-list-item__link--alt:focus::before,
.content-list-item__link--alt:hover::before,
.content-list__item__link--alt:active::before,
.content-list__item__link--alt:focus::before,
.content-list__item__link--alt:hover::before {
  -webkit-transform: scaleX(1.5);
  transform: scaleX(1.5);
}

.content-list-item__link--alt:active .product-box__promo-inner-wrap,
.content-list-item__link--alt:focus .product-box__promo-inner-wrap,
.content-list-item__link--alt:hover .product-box__promo-inner-wrap,
.content-list__item__link--alt:active .product-box__promo-inner-wrap,
.content-list__item__link--alt:focus .product-box__promo-inner-wrap,
.content-list__item__link--alt:hover .product-box__promo-inner-wrap {
  padding-left: 120px;
}

.content-list-item__link,
.content-list__item__link {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  width: 100%;
}

@media all and (min-width: 768px) {
  .content-list-item__link,
  .content-list__item__link {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
}

.content-list-item__link:active .content-list-item__copy-wrap::after,
.content-list-item__link:focus .content-list-item__copy-wrap::after,
.content-list-item__link:hover .content-list-item__copy-wrap::after,
.content-list__item__link:active .content-list-item__copy-wrap::after,
.content-list__item__link:focus .content-list-item__copy-wrap::after,
.content-list__item__link:hover .content-list-item__copy-wrap::after {
  -webkit-transform: translate(20px, 0) scale(1, 2) rotate(45deg) !important;
  transform: translate(20px, 0) scale(1, 2) rotate(45deg) !important;
}

.content-list-item__link:active .content-list-item__title::before,
.content-list-item__link:focus .content-list-item__title::before,
.content-list-item__link:hover .content-list-item__title::before,
.content-list__item__link:active .content-list-item__title::before,
.content-list__item__link:focus .content-list-item__title::before,
.content-list__item__link:hover .content-list-item__title::before {
  -webkit-transform: scaleX(1.3);
  transform: scaleX(1.3);
}

.content-list-item__img-wrap,
.content-list__item__img-wrap {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  padding-bottom: 66%;
  position: relative;
}

@media screen and (min-width: 768px) {
  .content-list-item__img-wrap,
  .content-list__item__img-wrap {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 34.375%;
    flex: 0 0 34.375%;
  }
}

.content-list-item__img-wrap--alt,
.content-list__item__img-wrap--alt {
  padding-bottom: 60%;
}

.content-list-item__img-wrap img,
.content-list__item__img-wrap img {
  height: auto;
  left: 50%;
  min-width: 100%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
}

.content-list-item__title,
.content-list__item__title {
  font-family: "Titillium Web", sans-serif;
  color: #00387a;
  font-size: 1.5em;
  margin-bottom: 15px;
  position: relative;
}

.content-list-item__body-wrap,
.content-list__item__body-wrap {
  font-size: 0.875em;
}

@media all and (min-width: 768px) {
  .content-list-item__body-wrap,
  .content-list__item__body-wrap {
    font-size: 1em;
  }
}

.content-list-item__copy-wrap,
.content-list__item__copy-wrap {
  font-family: "Montserrat", sans-serif;
  color: #373a36;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 65.625%;
  flex: 1 1 65.625%;
  max-width: 660px;
  padding: 30px 60px 20px 25px;
  position: relative;
}

.content-list-item__copy-wrap::after,
.content-list__item__copy-wrap::after {
  border: 1px solid #00387a;
  border-bottom: 0;
  border-left: 0;
  content: " ";
  display: block;
  height: 18px;
  position: absolute;
  right: 30px;
  top: 50%;
  top: calc(50% - 4px);
  -webkit-transform: translate(0, 0) scale(1, 2) rotate(45deg);
  transform: translate(0, 0) scale(1, 2) rotate(45deg);
  -webkit-transition: -webkit-transform 0.15s ease-out;
  transition: transform 0.15s ease-out;
  transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
  width: 18px;
}

.content-list-item__copy-wrap:active::after,
.content-list-item__copy-wrap:focus::after,
.content-list-item__copy-wrap:hover::after,
.content-list__item__copy-wrap:active::after,
.content-list__item__copy-wrap:focus::after,
.content-list__item__copy-wrap:hover::after {
  border-color: #00387a;
  -webkit-transform: translate(0, 0) scale(1, 2) rotate(45deg);
  transform: translate(0, 0) scale(1, 2) rotate(45deg);
}

@media all and (min-width: 768px) {
  .content-list-item__copy-wrap,
  .content-list__item__copy-wrap {
    background: 0 0;
  }

  .two-col-desktop .content-list__list {
    margin-top: 60px;
    margin-bottom: 80px;
  }

  .two-col-desktop .content-list__list--grid {
    margin-top: 55px;
    margin-bottom: 0;
  }

  .simple-image-and-copy + .two-col-desktop .content-list__list {
    margin-top: 0;
    margin-bottom: 0;
  }

  .simple-image-and-copy + div > .content-list--no-title,
  .simple-image-and-copy + div > div .content-list__list {
    margin-top: 0;
  }

  .content-list {
    padding: 60px 30px;
  }

  .content-list--full-width {
    padding: 0;
    margin: 60px 0;
  }

  .content-list--full-width:last-child {
    margin-bottom: 80px;
  }

  .content-list--no-title {
    margin-top: 0;
    padding-top: 20px;
  }

  .content-list--no-vertical-pad {
    padding-top: 0;
  }

  .content-list--no-vertical-pad .content-list-item--alt:first-child {
    margin-top: 0;
  }

  .content-list__title {
    font-size: 2.625em;
    margin: 30px auto 50px;
  }

  .content-list__title--small {
    font-size: 1.625em;
    margin: 0;
  }

  .content-list__body {
    color: #373a36;
  }

  .content-list__content-wrap {
    padding: 0;
  }

  .content-list--padding-top {
    margin-top: 60px;
  }

  .product-box + .content-list-item {
    margin-top: 20px !important;
  }

  .product-box + .content-list-item--with-offer {
    margin-top: 30px !important;
  }

  .content-list-item,
  .content-list__item {
    margin-top: 20px;
  }

  .content-list-item--with-offer,
  .content-list__item--with-offer {
    margin-top: 30px;
  }

  .content-list-item--with-video,
  .content-list__item--with-video {
    padding-bottom: 0;
  }

  .content-list-item--alt,
  .content-list__item--alt {
    margin-top: 60px;
  }

  .content-list-item__body,
  .content-list__item__body {
    font-size: 1em;
  }

  .content-list-item__link,
  .content-list__item__link {
    padding-bottom: 0;
  }

  .content-list-item__link--redline::before,
  .content-list__item__link--redline::before {
    background: #a80432;
    content: " ";
    display: block;
    height: 3px;
    left: 0;
    position: absolute;
    top: -8px;
    -webkit-transform-origin: left top;
    transform-origin: left top;
    -webkit-transition: -webkit-transform 0.15s ease-out;
    transition: transform 0.15s ease-out;
    transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
    width: 106px;
  }

  .content-list-item__link--redline:active::before,
  .content-list-item__link--redline:focus::before,
  .content-list-item__link--redline:hover::before,
  .content-list__item__link--redline:active::before,
  .content-list__item__link--redline:focus::before,
  .content-list__item__link--redline:hover::before {
    -webkit-transform: scaleX(1.3);
    transform: scaleX(1.3);
  }

  .content-list-item__link:active::before,
  .content-list-item__link:focus::before,
  .content-list-item__link:hover::before,
  .content-list__item__link:active::before,
  .content-list__item__link:focus::before,
  .content-list__item__link:hover::before {
    -webkit-transform: scaleX(1.5);
    transform: scaleX(1.5);
  }

  .content-list-item__copy-wrap,
  .content-list__item__copy-wrap {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 61.4%;
    flex: 0 0 61.4%;
    padding: 40px 40px 40px 170px;
  }

  .content-list-item__copy-wrap--alt,
  .content-list-item__copy-wrap--box-image,
  .content-list__item__copy-wrap--alt,
  .content-list__item__copy-wrap--box-image {
    padding-left: 50px;
  }

  .content-list-item__title,
  .content-list__item__title {
    font-size: 2.125em;
    margin-bottom: 20px;
  }

  .content-list-item__title--alt,
  .content-list__item__title--alt {
    font-size: 1.875em;
  }

  .content-list-item__title--alt:before,
  .content-list__item__title--alt:before {
    display: none;
  }

  .content-list-item__title--redline:before,
  .content-list__item__title--redline:before {
    display: block;
  }

  .content-list-item__title--redline-mobile-only:before,
  .content-list__item__title--redline-mobile-only:before {
    display: none;
  }

  .content-list-item__img-wrap,
  .content-list__item__img-wrap {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 38.6%;
    flex: 0 0 38.6%;
    padding-bottom: 16%;
  }

  .content-list-item__img-wrap--box-image,
  .content-list__item__img-wrap--box-image {
    min-height: 290px;
  }

  .content-list-item__img-wrap--alt,
  .content-list__item__img-wrap--alt {
    padding-bottom: 0;
    height: 100%;
    max-width: 45vw;
    min-height: 250px;
  }

  .content-list-item__img-wrap img,
  .content-list__item__img-wrap img {
    position: absolute;
    right: auto;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    min-width: 0;
    max-width: none;
    height: 100%;
    width: auto;
  }

  .content-list-item__img-wrap img.content-list-item__bg-img,
  .content-list__item__img-wrap img.content-list-item__bg-img {
    right: 0;
    -webkit-transform: none;
    transform: none;
    left: auto;
    top: 0;
  }

  .content-list-item__bg-img,
  .content-list__item__bg-img {
    height: 100% !important;
    width: auto !important;
    min-width: 0 !important;
  }

  .content-list-item__box-img--box-only,
  .content-list__item__box-img--box-only {
    left: 50% !important;
  }
}

@media all and (min-width: 900px) {
  .content-list__list {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }

  .content-list-item,
  .content-list__item {
    min-height: 290px;
  }

  .content-list-item--cap-width,
  .content-list__item--cap-width {
    max-width: 1060px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media screen and (min-width: 1000px) {
  .content-list-item__img-wrap img,
  .content-list__item__img-wrap img {
    height: 100%;
  }
}

@media all and (min-width: 1300px) {
  .content-list-item,
  .content-list__item {
    position: relative;
    min-height: 360px;
  }
}

@media all and (min-width: 1400px) {
  .content-list-item,
  .content-list__item {
    position: relative;
    min-height: 305px;
  }

  .content-list-item__video-link,
  .content-list__item__video-link {
    right: calc((100vw - 1000px) / 2 + 90px);
  }

  .content-list-item__video-link--not-full-width,
  .content-list__item__video-link--not-full-width {
    right: 100px;
  }

  .content-list-item__video-link--not-full-width-and-not-contained,
  .content-list__item__video-link--not-full-width-and-not-contained {
    right: calc((100vw - 1000px) / 2 + 90px);
  }

  .content-list-item__img-wrap,
  .content-list__item__img-wrap {
    left: 0;
    -webkit-transform: none;
    transform: none;
    top: 0;
    height: 100%;
    max-width: 38.6%;
    position: relative;
    width: 38.6%;
  }

  .content-list-item__img-wrap--full-width,
  .content-list__item__img-wrap--full-width {
    width: calc((100vw - 1060px) / 2);
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc((100vw - 1060px) / 2);
    flex: 0 0 calc((100vw - 1060px) / 2);
  }

  .content-list-item__img-wrap--box-image,
  .content-list__item__img-wrap--box-image {
    left: calc((100vw - 1060px) / 2);
  }

  .content-list-item__img-wrap--box-image-not-full-width,
  .content-list__item__img-wrap--box-image-not-full-width {
    left: 100px;
  }

  .content-list-item__img-wrap--full-width-and-blue-image,
  .content-list__item__img-wrap--full-width-and-blue-image {
    width: calc((100vw - 760px) / 2);
    max-width: calc((100vw - 760px) / 2);
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc((100vw - 760px) / 2);
    flex: 0 0 calc((100vw - 760px) / 2);
  }

  .content-list-item__img-wrap img,
  .content-list__item__img-wrap img {
    left: auto;
    height: 100%;
    width: auto;
    right: 0;
    top: 0;
    -webkit-transform: none;
    transform: none;
  }

  .content-list-item__img-wrap--alt img,
  .content-list__item__img-wrap--alt img {
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
  }

  .content-list-item__box-img--box-only,
  .content-list__item__box-img--box-only {
    right: auto !important;
    left: 50% !important;
    -webkit-transform: translate(-62%, -50%) !important;
    transform: translate(-62%, -50%) !important;
  }

  .content-list-item__copy-wrap,
  .content-list__item__copy-wrap {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 61.4%;
    flex: 1 1 61.4%;
    margin: 0;
    min-height: 305px;
    max-width: 880px;
    padding-left: 170px;
    padding-right: 100px;
    padding-bottom: 60px;
  }

  .content-list-item__copy-wrap--alt,
  .content-list__item__copy-wrap--alt {
    max-width: 61.4%;
    padding-left: 70px;
  }

  .content-list-item__copy-wrap--box-image,
  .content-list__item__copy-wrap--box-image {
    padding-left: 250px;
    max-width: 1060px;
  }

  .content-list-item__copy-wrap--box-image-not-full-width,
  .content-list__item__copy-wrap--box-image-not-full-width {
    padding-left: 100px;
  }
}

@media all and (min-width: 768px) and (-ms-high-contrast: none) {
  .content-list-item__copy-wrap--alt {
    max-width: 61.4%;
  }
}

.content-list-item--no-white-bg {
  background-color: transparent;
}

.content-list-item__box-img--alt {
  padding-right: 50%;
}

.grid-blocks {
  padding: 25px 20px 30px 30px;
  max-width: 1060px;
  margin: 0 auto;
}

.grid-blocks--top-margin {
  margin-top: 100px;
}

.grid-blocks--even-pad {
  padding: 15px 30px 30px;
  text-align: center;
}

.grid-blocks--with-bg {
  max-width: none;
}

.grid-blocks--half-bg {
  max-width: none;
  padding: 15px 25px 30px;
}

.grid-blocks--extra-pad {
  padding: 15px 45px 30px;
}

.grid-blocks--with-cta {
  padding-bottom: 45px;
}

.grid-blocks__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  text-align: left;
}

.grid-blocks__list--wrap[aria-hidden="true"],
.grid-blocks__list[aria-hidden="true"] {
  display: none;
}

.grid-blocks__list--with-bg {
  max-width: 1018px;
  margin: 0 auto;
}

.grid-blocks__list--wrap {
  padding: 0 5px;
}

.grid-blocks__title {
  color: #00387a;
  margin-top: 15px;
  margin-bottom: 25px;
  text-align: center;
}

.tab-navigation {
  font-family: "Titillium Web", sans-serif;
  -webkit-box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.15);
  border-radius: 23px;
  background-clip: padding-box;
  max-width: 1000px;
  margin: 0 auto 45px;
}

.tab-navigation__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.tab-navigation__button {
  background: 0 0;
  border: 0;
  color: #6a6a6a;
  background: #fff;
  display: inline-block;
  position: relative;
  padding: 11px;
  width: 100%;
  background-clip: padding-box;
  z-index: 1;
  height: 100%;
  border-right: 2px solid #979797;
}

.tab-navigation__button:active,
.tab-navigation__button:focus {
  outline-color: #00387a;
}

.tab-navigation__button:active,
.tab-navigation__button:focus,
.tab-navigation__button:hover,
.tab-navigation__button[aria-selected="true"] {
  z-index: 2;
  background: #70c9ea;
  color: #000;
}

.tab-navigation__button[aria-selected="true"]:after {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: #70c9ea transparent transparent;
  border-width: 8px;
  margin-left: -8px;
}

.tab-navigation__button--wide {
  padding: 5px;
}

.tab-navigation__item {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
}

.tab-navigation__item:last-child .tab-navigation__button {
  border-top-right-radius: 23px;
  border-bottom-right-radius: 23px;
}

.tab-navigation__item:first-child .tab-navigation__button {
  border-top-left-radius: 23px;
  border-bottom-left-radius: 23px;
}

.tab-navigation--alt-font {
  font-size: 0.9em;
  line-height: 1.3em;
}

.grid-block {
  font-family: "Montserrat", sans-serif;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  margin-bottom: 45px;
  color: #00387a;
  position: relative;
}

.grid-block--image {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  -ms-flex: 0 0 calc(50% - 15px);
  flex: 0 0 calc(50% - 15px);
  margin-bottom: 30px;
}

.grid-block__cover-link {
  color: #00387a;
  text-decoration: none;
}

.grid-block__cover-link:active,
.grid-block__cover-link:active .grid-block__title,
.grid-block__cover-link:focus,
.grid-block__cover-link:focus .grid-block__title,
.grid-block__cover-link:hover,
.grid-block__cover-link:hover .grid-block__title,
.grid-block__cover-link:visited,
.grid-block__cover-link:visited .grid-block__title {
  color: #00387a;
}

.grid-block img,
.grid-block svg {
  position: absolute;
  left: 20px;
  width: 30px;
  top: 15px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.grid-block img.block-img,
.grid-block svg.block-img {
  position: relative;
  width: 100%;
  height: auto;
  top: auto;
  left: auto;
  margin-bottom: 10px;
  -webkit-transform: none;
  transform: none;
}

.grid-block--with-icon {
  padding: 20px 15px 20px 50px;
}

.grid-block--on-bg-with-cta,
.grid-block--with-icon {
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fff),
    to(#e8e8e8)
  );
  background: linear-gradient(180deg, #fff, #e8e8e8);
  margin: 10px 0;
}

.grid-block--on-bg-with-cta img,
.grid-block--on-bg-with-cta svg,
.grid-block--with-icon img,
.grid-block--with-icon svg {
  top: 32px;
  left: 25px;
}

.grid-block--stay-two-wide:last-child {
  margin-right: 0;
  margin-left: 0;
}

.grid-block--on-bg {
  background: #fff;
  padding: 10px 15px 3px;
  margin-bottom: 30px;
}

.grid-block--on-bg-with-cta {
  padding: 20px !important;
  border-radius: 4px;
}

.grid-block__title {
  font-size: 1.125em;
  margin-bottom: 12px;
}

.grid-block__title--alt-font {
  font-size: 1.25em;
  font-family: "Titillium Web", sans-serif;
}

.grid-block__body {
  color: #373a36;
  font-size: 0.875em;
  margin-bottom: 12px;
}

.grid-block a {
  text-decoration: underline;
}

.grid-block a.blue-block-button,
.grid-block a.cover-link {
  text-decoration: none;
}

@media screen and (min-width: 600px) {
  .grid-blocks__list--with-bg {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .grid-blocks__list--wrap {
    padding: 0;
  }

  .tab-navigation {
    -webkit-box-shadow: none;
    box-shadow: none;
    text-align: center;
  }

  .tab-navigation--alt-font {
    font-size: inherit;
  }

  .tab-navigation__list {
    -webkit-box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.15);
    border-radius: 23px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin: 0 auto;
  }

  .tab-navigation__list:after {
    clear: both;
    content: " ";
    display: table;
  }

  .tab-navigation__item {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    float: left;
  }

  .tab-navigation__button {
    width: auto;
    padding: 11px 35px;
    min-width: 150px;
  }

  .tab-navigation__button--wide {
    min-width: 190px;
  }

  .grid-block {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 20px;
  }

  .grid-block--on-bg {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 9px);
    flex: 0 0 calc(50% - 9px);
    max-width: calc(50% - 9px);
    padding-right: 15px;
  }

  .grid-block--with-icon {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 15px);
    flex: 0 0 calc(50% - 15px);
    max-width: calc(50% - 15px);
  }

  .grid-block--with-icon:last-child:nth-child(3n + 2) {
    margin-left: 24px;
    margin-right: auto;
  }

  .grid-block--with-icon:last-child:nth-child(odd) {
    margin-left: 0;
  }

  .grid-block--on-bg-with-cta {
    margin: 10px;
    max-width: 45%;
    -webkit-box-flex: 0 !important;
    -ms-flex: 0 0 45% !important;
    flex: 0 0 45% !important;
  }

  .grid-block--with-icon:last-child {
    margin-left: 0;
  }

  .grid-block--stay-two-wide:last-child {
    margin-right: 0;
    margin-left: 0;
  }
}

@media screen and (min-width: 1000px) {
  .grid-blocks,
  .grid-blocks__list {
    padding: 20px 30px;
  }

  .grid-blocks__list--with-bg {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .grid-blocks__list--on-bg {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .grid-blocks__title {
    font-size: 2em;
    margin-bottom: 40px;
  }

  .grid-blocks--with-cta {
    padding-bottom: 50px;
  }

  .grid-blocks--half-bg {
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.1) 330px,
      #fff 150px
    ) !important;
  }

  .grid-block {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3%;
    flex: 0 0 33.3%;
    max-width: 33.3%;
  }

  .grid-block--with-icon {
    padding-right: 45px;
    margin-bottom: 60px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(33.3% - 15px);
    flex: 0 0 calc(33.3% - 15px);
    max-width: calc(33.3% - 15px);
  }

  .grid-block--with-icon:last-child:nth-child(odd) {
    margin-left: 23px;
  }

  .grid-block--with-icon:nth-child(3):last-child {
    margin-left: 0;
  }

  .grid-block--stay-two-wide {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    max-width: calc(50% - 12px);
  }

  .grid-block--on-bg {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(33.3% - 18px);
    flex: 0 0 calc(33.3% - 18px);
    border-radius: 4px;
    max-width: 33.3%;
    max-width: calc(33.3% - 18px);
    margin: 0 9px 18px;
  }

  .grid-block--on-bg:nth-child(5):last-child {
    margin-right: auto;
  }

  .grid-block--on-bg-with-cta {
    padding: 24px;
    min-height: 300px;
    -webkit-box-flex: 0 !important;
    -ms-flex: 0 0 250px !important;
    flex: 0 0 250px !important;
  }

  .grid-block--image {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    -ms-flex: 0 0 calc(25% - 82.5px);
    flex: 0 0 calc(25% - 82.5px);
    max-width: 25%;
    max-width: calc(25% - 82.5px);
    padding-right: 0;
    margin-right: 110px;
    margin-bottom: 50px;
  }

  .grid-block--image:nth-child(4n + 0) {
    margin-right: 0;
  }

  .grid-block--image:last-child {
    margin-left: 0;
    margin-right: auto;
  }

  .grid-block__title {
    font-size: 1.25em;
  }

  .grid-block__title--alt-font {
    font-size: 1.375em;
  }

  .grid-block__body {
    font-size: 1em;
  }
}

.grid-block__body img {
  position: relative;
  top: auto !important;
  left: auto !important;
  -webkit-transform: none;
  transform: none;
}

.offer-banner {
  background-color: #c8dfee;
  color: #373a36;
  padding: 20px 0 30px;
}

.offer-banner__content-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 1060px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 0 30px;
}

.offer-banner__title {
  font-size: 1.5em;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  margin-bottom: 12px;
}

.offer-banner__img-wrap {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 30%;
  flex: 1 1 30%;
  max-width: 135px;
}

.offer-banner__body {
  max-width: 650px;
  padding-right: 12px;
  font-size: 0.875em;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 70%;
  flex: 1 1 70%;
}

.article-list + .offer-banner {
  margin-top: 40px;
}

@media screen and (min-width: 768px) {
  .offer-banner {
    overflow: visible;
    padding: 80px 0 20px;
    position: relative;
    z-index: 2;
  }

  .offer-banner--shift-up {
    padding-top: 20px;
    margin-top: 40px;
  }

  .offer-banner__body {
    font-size: 1em;
  }

  .offer-banner__content-wrap {
    position: relative;
    padding-bottom: 0;
  }

  .offer-banner__title {
    font-size: 2.25em;
    margin-bottom: 5px;
  }

  .offer-banner__img-wrap {
    position: absolute;
    right: 80px;
    bottom: -20px;
  }
}

.expanded-content {
  padding: 15px 0 30px;
}

.expanded-content--no-top-padding {
  padding: 0 0 30px;
}

.expanded-content[aria-hidden="true"] {
  display: none;
}

.read-more {
  margin-top: 30px;
}

.read-more-button {
  background: 0 0;
  border: 0;
  color: #00387a;
  padding-right: 40px;
  display: inline-block;
  position: relative;
}

.read-more-button:active,
.read-more-button:focus {
  outline-color: #00387a;
}

.read-more-button::after {
  border: 1px solid #00387a;
  border-right: 0;
  border-bottom: 0;
  content: " ";
  display: block;
  height: 10px;
  position: absolute;
  right: 15px;
  top: 50%;
  -webkit-transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
  -webkit-transform: translateY(-55%) scaleX(1.4) rotate(-135deg);
  transform: translateY(-55%) scaleX(1.4) rotate(-135deg);
  width: 10px;
}

.read-more-button:hover:after {
  -webkit-transform: translateY(-15%) scaleX(1.4) rotate(-135deg);
  transform: translateY(-15%) scaleX(1.4) rotate(-135deg);
}

.read-more-button--white {
  color: #fff;
}

.read-more-button--white::after {
  border-color: #fff;
}

.read-more__wrap {
  width: 100%;
  margin-top: 0;
  min-height: 75px;
  position: relative;
  text-align: center;
  z-index: 2;
}

.read-more__wrap--align-left {
  text-align: left;
}

.read-more__wrap--padding-top {
  padding-top: 20px;
}

.read-more__wrap--full-width {
  width: 100%;
}

.read-more__wrap::before {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(255, 255, 255, 0)),
    to(#fff)
  );
  background: linear-gradient(180deg, rgba(255, 255, 255, 0), #fff);
  display: block;
  content: " ";
  width: 100%;
  position: absolute;
  left: 0;
  top: -100px;
  height: 100px;
}

.summary-accordion {
  max-width: 1060px;
  padding: 30px 20px;
  margin: 0 auto;
}

.summary-accordion__title {
  font-size: 1.375em;
}

.cart-item-promotion {
  margin-bottom: 30px;
  font-family: "Montserrat", sans-serif;
}

.cart-item-promotion__headline {
  font-family: "Titillium Web", sans-serif;
  color: #00387a;
  font-size: 1.375rem;
  margin: 0 0 5px;
}

.cart-item-promotion__headline--mar {
  margin-bottom: 12px;
}

.cart-item-promotion__price,
.cart-item-promotion__title {
  font-weight: 700;
  line-height: 1rem;
  margin: 0 0 5px;
}

.cart-item-promotion__price--mar,
.cart-item-promotion__title--mar {
  margin-top: 20px;
}

.cart-item-promotion__title {
  color: #373a36;
}

.cart-item-promotion __summary,
.cart-item-promotion__price {
  color: #6a6a6a;
}

.cart-item-promotion__summary {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 20px;
}

.cart-item-promotion __link--bold,
.cart-item-promotion__link {
  color: #00387a;
}

.cart-item-promotion__link--bold {
  font-weight: 600;
  font-size: 14px;
}

.cart-item-promotion__description {
  color: #6a6a6a;
  font-size: 0.875rem;
  line-height: 1.2rem;
  margin: 0 0 3px;
}

.cart-item-promotion__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.cart-item-promotion__half {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(50% - 5px);
  flex: 0 0 calc(50% - 5px);
}

.cart-item-promotion__half:only-child {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}

.cart-item-promotion__half--img-round {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(100% - 120px);
  flex: 0 0 calc(100% - 120px);
}

.cart-item-promotion__img {
  width: 100%;
  height: auto;
}

.cart-item-promotion__image-round {
  max-width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
}

.cart-item-promotion__image-round--wrap {
  overflow: hidden;
  padding: 6px;
  width: 102px;
  height: 102px;
  border-radius: 50%;
  margin: 0 20px 5px 0;
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.cart-item-promotion__image-round--wrap:after {
  position: absolute;
  content: " ";
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.cart-item-promotion__banner--wrap {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fff),
    to(#e8e8e8)
  );
  background: linear-gradient(180deg, #fff, #e8e8e8);
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 25px;
  opacity: 1;
  position: relative;
  text-align: left;
  -webkit-transition: opacity 0.2s ease-out;
  transition: opacity 0.2s ease-out;
  min-height: 120px;
  padding: 20px;
}

.cart-item-promotion__banner--wrap:active
  .cart-item-promotion__banner-inner-wrap,
.cart-item-promotion__banner--wrap:focus
  .cart-item-promotion__banner-inner-wrap,
.cart-item-promotion__banner--wrap:hover
  .cart-item-promotion__banner-inner-wrap {
  padding-left: 120px;
}

.cart-item-promotion__banner--wrap:active::before,
.cart-item-promotion__banner--wrap:focus::before,
.cart-item-promotion__banner--wrap:hover::before {
  -webkit-transform: scaleX(1.2);
  transform: scaleX(1.2);
}

.cart-item-promotion__banner--wrap:active:after,
.cart-item-promotion__banner--wrap:focus:after,
.cart-item-promotion__banner--wrap:hover:after {
  -webkit-transform: translate(10px, 0) scale(1, 2) rotate(45deg);
  transform: translate(10px, 0) scale(1, 2) rotate(45deg);
}

.cart-item-promotion__banner--wrap::after {
  border: 1px solid #00387a;
  border-bottom: 0;
  border-left: 0;
  content: " ";
  display: block;
  height: 10px;
  position: absolute;
  right: 20px;
  top: 50%;
  -webkit-transform: translate(0, 0) scale(1, 2) rotate(45deg);
  transform: translate(0, 0) scale(1, 2) rotate(45deg);
  -webkit-transition: -webkit-transform 0.15s ease-out;
  transition: transform 0.15s ease-out;
  transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
  width: 10px;
}

.cart-item-promotion__banner,
.cart-item-promotion__banner-inner-wrap {
  -webkit-transition: padding-left 0.15s ease-out;
  transition: padding-left 0.15s ease-out;
}

.cart-item-promotion__banner {
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  left: 0;
  overflow: hidden;
  padding-right: 40px;
  text-transform: uppercase;
  top: -2px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  z-index: 5;
}

.cart-item-promotion__banner::after {
  background: #a80432;
  height: 35px;
  width: 35px;
  content: " ";
  display: block;
  right: 20px;
  pointer-events: none;
  position: absolute;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  bottom: 5px;
  z-index: 1;
}

.cart-item-promotion__banner-inner-wrap {
  background: #a80432;
  display: block;
  height: 100%;
  padding: 0 10px 0 50px;
  position: relative;
  width: 100%;
  z-index: 2;
}

.cart-item-promotion__banner--headline {
  display: none;
}

.cart-item-promotion__banner--headline-mobile {
  margin: 10px 25px 15px;
}

.cart-item-promotion__banner--content {
  margin: 0 25px 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.cart-item-promotion__banner--img-wrap {
  max-width: 40%;
}

.cart-item-promotion__banner--img-wrapimg {
  max-width: 120px;
  max-height: 60%;
}

.cart-item-promotion__banner--half {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(60% - 15px);
  flex: 0 0 calc(60% - 15px);
  padding-right: 10px;
}

@media only screen and (min-width: 40em) {
  .cart-item-promotion__title {
    font-size: 0.875rem;
  }

  .cart-item-promotion__content {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .cart-item-promotion__half:first-child {
    max-width: 30%;
    margin-right: 20px;
  }

  .cart-item-promotion__half:first-child:only-child {
    max-width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .cart-item-promotion {
    margin-top: 20px;
  }

  .cart-item-promotion__headline {
    font-size: 1.5rem;
    margin-bottom: 12px;
  }

  .cart-item-promotion__price,
  .cart-item-promotion__title {
    font-size: 1rem;
  }

  .cart-item-promotion__half:first-child {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 115px;
    flex: 0 0 115px;
    max-width: 115px;
  }

  .cart-item-promotion__half:last-child {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(100% - 125px);
    flex: 0 0 calc(100% - 125px);
  }

  .cart-item-promotion__half:last-child:only-child {
    max-width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }

  .cart-item-promotion__banner--half {
    -webkit-box-flex: 0 !important;
    -ms-flex: 0 0 calc(100% - 150px) !important;
    flex: 0 0 calc(100% - 150px) !important;
  }
}

.thanks-header {
  max-width: 1060px;
  margin: 0 auto;
  padding: 60px 60px 0;
  text-align: center;
}

.thanks-header__body {
  max-width: 688px;
  margin: 0 auto;
}

.thanks-header__icon {
  margin: 0 auto;
  max-width: 69px;
}

.thanks-header__icon i,
.thanks-header__icon img,
.thanks-header__icon svg {
  display: block;
  width: 100%;
  height: auto;
}

.thanks-header__title {
  color: #00387a;
  font-size: 2em;
  line-height: 1.2;
  margin: 10px 0;
}

.cart-table,
.checkout-table {
  margin: 0 auto;
  max-width: 1060px;
  position: relative;
  text-align: left;
}

.cart-table th,
.checkout-table th {
  color: #a80432;
  font-size: 14px;
  font-weight: 700;
}

.cart-table thead th,
.checkout-table thead th {
  padding: 6px 0 6px 12px;
}

.cart-table thead tr,
.checkout-table thead tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.cart-table-item,
.checkout-table-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.timetrade-error {
  border: 1px solid #a80432;
  margin: 20px 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 12px 12px 20px;
}

.timetrade-error__icon {
  width: 50px;
}

.timetrade-error p {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  line-height: 1.5;
  width: 100%;
}

.timetrade-error__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 15px;
}

.timetrade-error__column {
  width: 100%;
  margin-bottom: 10px;
}

.branch-item {
  float: left;
  width: 300px;
  height: 100px;
  margin-top: 35px;
}

.branch-list__radio {
  width: 10%;
  float: left;
  height: 100px;
}

@media all and (max-width: 768px) {
  .branch-item {
    float: none;
  }
}

@media all and (max-width: 600px) {
  .kiosk-actions__fieldset,
  .kiosk-actions__row {
    display: block !important;
  }

  .kiosk-actions__row {
    margin: auto;
  }

  .kiosk-actions__label--wide {
    max-width: 228px;
  }
}

.location-eligibility-error__icon {
  float: left;
  width: 75px;
}

.location-eligibility-error__msg {
  margin-left: 120px;
}

.checkout-accordion {
  counter-reset: cart-count;
  max-width: 1000px;
  margin: 0 auto;
  padding-bottom: 80px;
  text-align: left;
}

.checkout-accordion__branch-list {
  overflow: auto;
  max-height: 310px;
}

.checkout-accordion__content {
  padding: 30px 20px;
  position: relative;
  border: 1px solid #00387a;
  border-top: 0;
}

.checkout-accordion__content--kiosk {
  border-top: 1px solid #00387a;
  border-radius: 10px;
}

.checkout-accordion__content--no-border {
  border: 0;
}

.checkout-accordion__content[aria-hidden="true"] {
  display: none;
}

.checkout-accordion__content-wrap {
  padding-bottom: 70px;
  position: relative;
}

.checkout-accordion__content--reduced-side-pad {
  padding: 30px 15px !important;
}

.checkout-accordion__todo-button,
.checkout-accordion__toggle {
  background: 0 0;
  border: 0;
}

.checkout-accordion__todo-button:active,
.checkout-accordion__todo-button:focus,
.checkout-accordion__toggle:active,
.checkout-accordion__toggle:focus {
  outline-color: #00387a;
}

.checkout-accordion__todo-button {
  padding-right: 12px;
  position: relative;
  font-weight: 700;
  color: #fff;
}

.checkout-accordion__todo-button::after {
  border: 1px solid #fff;
  border-bottom: 0;
  border-left: 0;
  content: " ";
  display: block;
  height: 7px;
  position: absolute;
  right: 0;
  top: 50%;
  top: calc(50% - 4px);
  -webkit-transform: translate(0, 0) scale(1, 2) rotate(45deg);
  transform: translate(0, 0) scale(1, 2) rotate(45deg);
  -webkit-transition: -webkit-transform 0.15s ease-out;
  transition: transform 0.15s ease-out;
  transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
  width: 7px;
}

.checkout-accordion__todo-button:active::after,
.checkout-accordion__todo-button:focus::after,
.checkout-accordion__todo-button:hover::after {
  border-color: #fff;
  -webkit-transform: translate(0, 0) scale(1, 2) rotate(45deg);
  transform: translate(0, 0) scale(1, 2) rotate(45deg);
}

.checkout-accordion__todo-button[aria-hidden="true"] {
  display: none;
}

.checkout-accordion__todo-button--openable {
  color: #fff;
  font-weight: 700;
  font-size: 1.125em;
}

.checkout-accordion__todo-button[disabled] {
  color: #afaba0;
  font-weight: 400;
}

.checkout-accordion__todo-button[disabled]::after {
  border-color: #afaba0;
}

.checkout-accordion__todo-button[disabled]:hover::after {
  -webkit-transform: translateY(-50%) scaleY(2) rotate(45deg);
  transform: translateY(-50%) scaleY(2) rotate(45deg);
}

.checkout-accordion__bar {
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-top: 20px;
  padding: 10px 20px;
  width: 100%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.checkout-accordion__bar .checkout-accordion__todo-button {
  display: inline-block;
}

.checkout-accordion__bar .checkout-accordion__todo-button--show-status {
  display: block;
  color: #373a36;
}

.checkout-accordion__bar .checkout-accordion__todo-button--show-status::after {
  border-color: #373a36;
}

.checkout-accordion__bar .checkout-accordion__todo-button--done::after {
  width: 12px;
  height: 6px;
  border-width: 2px;
  -webkit-transform: translateX(3px) translateY(-50%) rotate(135deg);
  transform: translateX(3px) translateY(-50%) rotate(135deg);
}

.checkout-accordion__bar--openable {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#003875),
    to(#00aad9)
  );
  background: linear-gradient(90deg, #003875, #00aad9);
  border-color: #00387a;
  color: #fff;
}

.checkout-accordion__bar--openable .checkout-accordion__todo-button {
  display: block;
}

.checkout-accordion__bar--done,
.checkout-accordion__bar--no-border {
  border: 1px solid transparent;
  border-color: transparent;
}

.checkout-accordion__bar--enabled-but-incomplete {
  color: #fff;
}

.checkout-accordion__bar--complete {
  border-color: rgba(0, 0, 0, 0.1);
  background: #efefef;
}

.checkout-accordion__step-status {
  color: #373a36;
  padding-right: 40px;
  position: relative;
}

.checkout-accordion__step-status svg {
  margin-left: 10px;
}

.checkout-accordion__step-status:after {
  display: block;
  content: " ";
  width: 24px;
  height: 12px;
  border-bottom: 2px solid #000;
  border-left: 2px solid #000;
  right: 0;
  top: 30%;
  position: absolute;
  -webkit-transform: translateY(-50%) rotate(-45deg);
  transform: translateY(-50%) rotate(-45deg);
}

.checkout-accordion__step-status--done {
  color: #fff;
}

.checkout-accordion__step-status--done:after {
  border-color: #fff;
}

.checkout-accordion__step-status[aria-hidden] {
  display: none;
}

.checkout-accordion__toggle {
  font-family: "Titillium Web", sans-serif;
  margin-right: auto;
  margin-bottom: 0;
  font-size: 1.125em;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  text-align: left;
}

.checkout-accordion__toggle--enabled-but-incomplete,
.checkout-accordion__toggle--openable {
  color: #fff;
}

.checkout-accordion__toggle--step-complete {
  color: #373a36;
}

.checkout-accordion__toggle::before {
  content: counter(cart-count) ": ";
  counter-increment: cart-count;
  font-weight: 700;
}

.checkout-accordion__toggle--numberless:before {
  content: none;
}

.checkout-accordion__toggle[aria-disabled] {
  cursor: not-allowed;
}

.checkout-accordion__toggle--no-numbers::before {
  display: none;
}

.kiosk-actions__fieldset {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.kiosk-actions__fieldset::after,
.kiosk-actions__fieldset::before {
  content: "";
  display: block;
  width: 2px;
}

.kiosk-actions__row {
  text-align: center;
  height: 305px;
  width: 229px;
  margin-bottom: 20px;
  display: inline-block;
}

.kiosk-actions__input {
  display: none;
}

.kiosk-actions__input:checked + label {
  color: #00387a;
  background-color: #e5ebf2;
  border: 2px solid #00387a;
}

.kiosk-actions__label,
.kiosk-actions__label--wide {
  width: 228px;
  height: 220px;
  border-radius: 10px;
  border: 1px solid #e8e8e8;
}

.kiosk-actions__label img,
.kiosk-actions__label svg,
.kiosk-actions__label--wide img,
.kiosk-actions__label--wide svg {
  height: 104px;
  width: 104px;
}

.kiosk-actions__label--wide {
  width: 468px;
}

.kiosk-tile-form {
  background: #f8f8f8;
  padding: 30px;
  border-radius: 10px;
  margin: 30px 0;
}

.kiosk-header {
  margin-bottom: 30px;
  color: rgba(0, 0, 0, 0.7) !important;
  font-size: 20px !important;
}

.kiosk-header--sub {
  font-size: 16px !important;
  clear: left;
}

.checkout-table {
  padding: 20px 30px 60px;
}

.checkout-table-item {
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fff),
    to(rgba(0, 0, 0, 0.1))
  );
  background: linear-gradient(180deg, #fff, rgba(0, 0, 0, 0.1));
  padding: 15px 15px 30px;
}

.checkout-table-item__count,
.checkout-table-item__title {
  color: #00387a;
  font-size: 1.375em;
}

.checkout-table-item__title {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(100% - 36px);
  flex: 0 0 calc(100% - 36px);
}

.checkout-table-item__count {
  -webkit-box-flex: 36px;
  -ms-flex: 36px;
  flex: 36px;
}

.checkout-table-item__note {
  color: #6a6a6a;
  line-height: 1.1875;
  margin: 10px 0 25px;
  padding-left: 36px;
}

.checkout-table-item__buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.checkout-table td {
  padding: 20px 0;
}

.cart__print {
  display: none;
}

.cart-promotion {
  font-family: "Montserrat", sans-serif;
  border-bottom: 1px solid #bfb3a7;
  border-top: 1px solid #bfb3a7;
  color: #6a6a6a;
  margin-top: 10px;
  padding: 20px 10px;
}

.cart-promotion__title {
  font-family: "Titillium Web", sans-serif;
  color: #00387a;
  font-size: 1.5em;
  margin-bottom: 20px;
}

.cart__header {
  background: url(https://www.fnb-online.com/assets/images/weave-bg-ltgray.png)
    center top;
  background-size: 229px auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #00387a;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 20px 106px 40px;
  position: relative;
}

.cart__header--even-pad {
  padding: 20px;
}

.cart__header-cta,
.cart__header-title {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.cart__header-cta {
  left: 0;
  width: 100%;
  position: absolute;
  bottom: 0;
  background: #fff;
  padding: 30px;
  text-align: center;
}

.cart__header-wrap {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  margin: 0 auto;
  max-width: 1060px;
  width: 100%;
}

.cart__instructions {
  color: #373a36;
  margin: 30px auto;
  max-width: 1120px;
  padding: 0 20px;
  text-align: left;
}

.cart__instructions--big {
  font-family: "Titillium Web", sans-serif;
  font-size: 1.25em;
  margin-bottom: 0;
}

.cart__back {
  padding-right: 20px;
  color: #00387a;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 14px;
  position: relative;
}

.cart__back:before {
  border: 1px solid #00387a;
  content: " ";
  display: block;
  width: 12px;
  height: 12px;
  right: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(135deg);
  transform: translateY(-50%) rotate(135deg);
  border-right: 0;
  border-bottom: 0;
  -webkit-transition: -webkit-transform 0.15s;
  transition: transform 0.15s;
  transition: transform 0.15s, -webkit-transform 0.15s;
}

.cart__back:active:before,
.cart__back:focus:before,
.cart__back:hover:before {
  -webkit-transform: translateY(-50%) translateX(15px) rotate(135deg);
  transform: translateY(-50%) translateX(15px) rotate(135deg);
  border-color: #00387a;
}

.cart__back-wrap {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.cart-table {
  padding: 40px 15px 60px;
}

.cart-table__table {
  max-width: 100%;
  table-layout: fixed;
}

.cart-table td {
  padding: 10px;
}

.cart-table__desktop-only {
  display: none;
}

.cart-table__bottom,
.cart-table__top {
  text-align: center;
}

.cart-table__top {
  background: #fff;
  left: 0;
  padding: 20px 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.cart-table__top--locked {
  position: fixed;
  top: 45px;
  z-index: 10;
}

.cart-table__bottom {
  font-size: 14px;
  padding-top: 40px;
}

.cart-table__bottom p {
  margin: 25px 0 8px;
}

.cart-table tbody {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fff),
    to(rgba(0, 0, 0, 0.1))
  );
  background: linear-gradient(180deg, #fff, rgba(0, 0, 0, 0.1));
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
}

.cart-table-applied-promo,
.cart-table-promo {
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  margin-top: 20px;
  padding: 20px 0 0;
}

.cart-table-applied-promo {
  margin-top: 40px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cart-table-applied-promo__unified-app {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.cart-table-applied-promo__unified-app p {
  font-family: "Titillium Web", sans-serif;
  padding-right: 60px;
  font-weight: 600;
  font-size: 22px;
}

.cart-table-promo {
  font-size: 14px;
  padding-bottom: 20px;
  max-width: 100%;
}

.cart-table-promo__buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.cart-table-promo__buttons a {
  margin-top: 15px;
}

.cart-table-promo__divider {
  margin: 0 5px;
}

.cart-table-promo__title {
  font-size: 1.144em;
  font-weight: 700;
  margin: 0 0 5px;
}

.cart-table-applied-promo {
  font-size: 16px;
}

.cart-table-applied-promo__title {
  font-size: 1.5em;
}

.cart-table-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  padding: 0 0 50px;
  max-width: calc(100vw - 50px);
  overflow: hidden;
}

.cart-table-item__subtitle {
  top: 8px;
  position: relative;
}

.cart-table-item__subtitle p {
  font-size: 11px;
}

.cart-table-item__img-wrap {
  background: 0 0;
  border: 0;
  margin-bottom: 20px;
}

.cart-table-item__img-wrap:active,
.cart-table-item__img-wrap:focus,
.cart-table-item__play:active,
.cart-table-item__play:focus {
  outline-color: #00387a;
}

.cart-table-item__img-wrap:active .cart-table-item__play,
.cart-table-item__img-wrap:focus .cart-table-item__play,
.cart-table-item__img-wrap:hover .cart-table-item__play {
  background-color: #009cd6;
  border-color: #009cd6;
}

.cart-table-item--with-bundle,
.cart-table-item--with-promo {
  border-bottom: 0;
}

.cart-table-item--with-bundle {
  padding-bottom: 10px;
}

.cart-table-item__desktop-only {
  display: none;
}

.cart-table-item__play {
  background: 0 0;
  border: 1px solid #00387a;
  background: #00387a;
  border-radius: 4px;
  text-indent: -999em;
  width: 39px;
  height: 24px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
}

.cart-table-item__play-inner-wrap {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}

.cart-table-item__play-inner-wrap:after {
  left: 50%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent transparent transparent #fff;
  border-width: 8px;
  margin-top: -8px;
  -webkit-transform: translateX(-15%) scaleY(0.75);
  transform: translateX(-15%) scaleY(0.75);
}

@media all and (min-width: 768px) {
  .cart-table-item__play {
    width: 54px;
    height: 33px;
  }
}

.cart-table-item__title-wrap {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  margin-bottom: 20px;
}

.cart-table-item__title {
  color: #00387a;
  font-size: 1.375em;
}

.cart-table-item__img {
  height: auto;
  width: 100%;
}

.cart-table-item__img-wrap {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 50%;
  flex: 0 1 50%;
  padding-right: 30px;
  position: relative;
}

.cart-table-item__remove {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  font-size: 0.875em;
  text-align: right;
}

.cart-table-item__buttons {
  font-family: "Montserrat", sans-serif;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  font-size: 0.875em;
}

.cart-table-item__divider {
  font-weight: 400;
  margin: 10px 0;
}

.cart-table-item__note {
  color: #6a6a6a;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  font-size: 0.875em;
  padding: 0;
}

.cart-table-item__note--emphasis {
  font-weight: 700;
}

.cart-table-item__note--tagline {
  color: #00387a;
}

@media screen and (min-width: 360px) {
  .cart-table-promo__buttons a {
    margin-top: 0;
  }

  .cart-table-promo__buttons {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

@media screen and (min-width: 500px) {
  .cart-item-promotion__banner--wrap::after {
    width: 18px !important;
    height: 18px !important;
    right: 30px;
  }

  .cart-item-promotion__banner--headline {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 0;
  }

  .cart-item-promotion__banner--headline-mobile {
    display: none;
  }

  .cart-item-promotion__banner--content {
    margin-top: 25px;
  }
}

@media screen and (min-width: 768px) {
  .thanks-header__title {
    font-size: 2.625em;
    margin-top: 0;
  }

  .timetrade-error__icon {
    width: 80px;
  }

  .timetrade-error__body {
    padding-right: 60px;
    width: calc(100% - 80px);
    padding-left: 30px;
  }

  .timetrade-error__column {
    margin-bottom: 0;
    width: 50%;
  }

  .summary-accordion {
    padding: 30px;
  }

  .checkout-accordion__content {
    padding: 30px !important;
  }

  .checkout-accordion__bar {
    padding: 20px 30px;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .checkout-accordion__toggle {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    max-width: calc(100% - 110px);
  }

  .cart-table th,
  .checkout-table th {
    font-size: 1.5em;
  }

  .cart-table__table-header {
    padding-left: 8px;
  }

  .cart-table-promo {
    font-size: 1em;
  }

  .cart-table-promo p {
    font-size: 0.875em;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
  }

  .checkout-table-item {
    padding: 30px 30px 20px;
    position: relative;
  }

  .checkout-table-item__count,
  .checkout-table-item__title {
    font-size: 2em;
    font-weight: 300;
  }

  .checkout-table-item__count {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 60px;
    flex: 0 0 60px;
  }

  .checkout-table-item__title {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(100% - 60px);
    flex: 0 0 calc(100% - 60px);
    padding-right: 200px;
  }

  .checkout-table-item__note {
    margin-bottom: 0;
    padding-left: 60px;
  }

  .checkout-table-item__buttons {
    position: absolute;
    right: 30px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .checkout-table th:last-child {
    padding-right: 30px;
    text-align: right;
  }

  .checkout-table td {
    padding: 10px 0;
  }

  .checkout-table tr:first-child td {
    padding-top: 30px;
  }

  .cart__instructions {
    padding: 0 60px;
  }

  .cart__print {
    display: block;
  }

  .cart-promotion {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 30px;
    padding: 20px 60px;
  }

  .cart-promotion__title {
    margin: 0 20px 0 0;
  }

  .cart-table {
    margin: 0 auto;
    padding: 40px 30px 60px;
  }

  .cart-table__desktop-only {
    display: inline-block;
  }

  .cart-table__bottom {
    font-family: "Titillium Web", sans-serif;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 60px 0 0;
    letter-spacing: 0;
  }

  .cart-table__bottom span {
    font-weight: 400;
  }

  .cart-table__bottom p {
    font-size: 1.5em;
    margin: 0 60px;
    font-weight: 700;
  }

  .cart-table__bottom p.p--no-margin {
    margin: 0;
  }

  .cart-table__deposit-total {
    display: block;
    font-size: 1.5em;
    padding-bottom: 25px;
    text-align: right;
    width: 100%;
  }

  .cart-table__deposit-total-label {
    color: #a80432;
    font-weight: 700;
  }

  .cart-table__deposit-total-amount {
    color: #6a6a6a;
    font-weight: 400;
  }

  .cart-table__mobile-only {
    display: none;
  }

  .cart-table__top {
    display: block;
    position: relative;
  }

  .cart-table tbody {
    background: 0 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }

  .cart-table td {
    padding: 0 0 30px;
  }

  .cart-table td.cart-table__table-cell--part-of-promo-top {
    padding: 0;
  }

  .cart-table-applied-promo {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 20px;
  }

  .cart-table-item {
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#fff),
      to(#e8e8e8)
    );
    background: linear-gradient(180deg, #fff, #e8e8e8);
    border: 0;
    border-radius: 3px;
    display: block;
    max-width: 1030px;
    padding: 20px 30px 30px 50px;
    position: relative;
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    min-height: 240px;
  }

  .cart-table-item--part-of-promo {
    background: 0 0;
  }

  .cart-table-item--part-of-promo-top {
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#fff),
      to(#f3f4f4)
    );
    background: linear-gradient(180deg, #fff, #f3f4f4);
  }

  .cart-table-item--part-of-promo-top:after {
    display: block;
    content: " ";
    left: 30px;
    right: 30px;
    height: 1px;
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    bottom: 0;
  }

  .cart-table-item--part-of-promo-bottom {
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#f3f4f4),
      to(#e8e8e8)
    );
    background: linear-gradient(180deg, #f3f4f4, #e8e8e8);
  }

  .cart-table-item--with-promo {
    padding-bottom: 20px;
  }

  .cart-table-item__img-wrap {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 180px;
    flex: 0 1 180px;
    max-width: 180px;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    position: absolute;
    top: 25px;
    left: 30px;
  }

  .cart-table-item__title-wrap {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 calc(60% - 300px);
    flex: 1 1 calc(60% - 300px);
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    margin-left: 170px;
    margin-bottom: 10px;
    padding-right: 240px;
    min-height: 0;
  }

  .cart-table-item__title-wrap .cart-table-item__note {
    min-height: 21px;
  }

  .cart-table-item__title {
    font-size: 2em;
    font-weight: 300;
    line-height: 1.1;
    margin-bottom: 10px;
  }

  .cart-table-item__buttons {
    color: #6a6a6a;
    max-width: 240px;
    position: absolute;
    right: 30px;
    top: 30px;
    font-size: 1em;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 240px;
    flex: 0 0 240px;
    width: 240px;
  }

  .cart-table-item__note {
    font-size: 0.875em;
    margin-bottom: 10px;
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
    max-width: 450px;
    padding: 0;
    text-align: left;
  }

  .cart-table-item__note--freestanding {
    margin-left: 170px;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 calc(60% - 300px);
    flex: 1 1 calc(60% - 300px);
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  .cart-table-item__note--sm {
    min-height: 80px;
  }

  .cart-table-item__note--w {
    width: calc(100% - 420px);
  }

  .cart-table-item__desktop-only {
    display: block;
  }

  .cart-table-item__remove {
    font-size: 1em;
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
    padding-right: 30px;
  }

  .cart__header {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0;
  }

  .cart__header-title {
    font-size: 42px;
  }

  .cart__header-cta {
    text-align: right;
    position: relative;
    background: 0 0;
    width: auto;
    left: auto;
    bottom: auto;
    padding-right: 0;
  }

  .cart__header-wrap {
    padding: 5px 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .cart__print {
    background: 0 0;
    border: 0;
    padding-left: 35px;
    position: relative;
    color: #00387a;
    font-size: 0.875em;
  }

  .cart__print:active,
  .cart__print:focus {
    outline-color: #00387a;
  }

  .cart__print img,
  .cart__print svg {
    left: 0;
    max-width: 25px;
    overflow: visible;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .cart__back {
    position: relative;
    padding: 0 0 0 20px;
    font-size: 1em;
  }

  .cart__back:before {
    border: 1px solid #00387a;
    content: " ";
    display: block;
    width: 12px;
    height: 12px;
    left: 0;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%) rotate(-45deg);
    transform: translateY(-50%) rotate(-45deg);
    border-right: 0;
    border-bottom: 0;
  }

  .cart__back:active::before,
  .cart__back:focus::before,
  .cart__back:hover::before {
    -webkit-transform: translateY(-50%) rotate(-45deg);
    transform: translateY(-50%) rotate(-45deg);
  }

  .cart__back-inner-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1000px;
  }

  .cart__back-wrap {
    background: #fff;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    padding: 15px 30px;
    width: 100%;
    margin: 0 auto;
    max-width: 100%;
  }
}

@media all and (min-width: 900px) {
  .cart-table-promo--single-button {
    max-width: 930px;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .cart-table-promo--single-button p {
    max-width: 55% !important;
  }

  .cart-table-applied-promo--single-button,
  .cart-table-promo--single-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-ordinal-group: 21;
    -ms-flex-order: 20;
    order: 20;
    width: 100%;
  }

  .cart-table-applied-promo--single-button__title,
  .cart-table-promo--single-button__title {
    font-size: 1.5em;
    font-weight: 400;
    padding-right: 20px;
    white-space: nowrap;
  }

  .cart-table-applied-promo--single-button__buttons,
  .cart-table-promo--single-button__buttons {
    padding-right: 0;
    max-width: 350px;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .cart-table-applied-promo--single-button__buttons a,
  .cart-table-applied-promo--single-button__buttons button,
  .cart-table-promo--single-button__buttons a,
  .cart-table-promo--single-button__buttons button {
    margin: 0 10px;
  }

  .cart-table-applied-promo--single-button {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

@media all and (min-width: 1060px) {
  .cart-table-promo {
    max-width: 930px;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .cart-table-promo p {
    max-width: 40%;
  }

  .cart-table-applied-promo,
  .cart-table-promo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-ordinal-group: 21;
    -ms-flex-order: 20;
    order: 20;
    width: 100%;
  }

  .cart-table-applied-promo__unified-app,
  .cart-table-promo__unified-app {
    left: -20px;
    position: relative;
    padding-left: 20px;
    width: calc(100% + 20px);
  }

  .cart-table-applied-promo__title,
  .cart-table-promo__title {
    font-size: 1.5em;
    font-weight: 400;
    padding-right: 20px;
    white-space: nowrap;
  }

  .cart-table-applied-promo__buttons,
  .cart-table-promo__buttons {
    padding-right: 0;
    max-width: 350px;
  }

  .cart-table-applied-promo__buttons a,
  .cart-table-applied-promo__buttons button,
  .cart-table-promo__buttons a,
  .cart-table-promo__buttons button {
    margin: 0 10px;
  }

  .cart-table-applied-promo {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

@media all and (min-width: 768px) and (-ms-high-contrast: none) {
  .cart-table__table-header,
  ::-ms-backdrop {
    width: 250px;
    max-width: 250px;
    min-width: 250px;
  }

  .cart-table__table-header:first-child,
  ::-ms-backdrop {
    min-width: calc(100% - 250px);
    max-width: calc(100% - 250px);
    width: calc(100% - 250px);
  }
}

.timetrade-error[aria-hidden="true"] {
  display: none;
}

.pagination {
  font-family: "Montserrat", sans-serif;
  padding: 30px 0 60px;
}

.pagination--no-padding {
  padding: 0;
}

.pagination .active {
  font-weight: 700;
}

.pagination a {
  color: #00387a;
}

.pagination__number,
.pagination__page {
  min-width: 40px;
  min-height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 10px;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.pagination__next-or-prev {
  color: #00387a;
  font-size: 0.875em;
  position: relative;
  display: inline-block;
  max-width: 40px;
  height: 40px;
  text-indent: -999em;
}

.pagination__next-or-prev[aria-hidden="true"] {
  opacity: 0;
  pointer-events: none;
}

.pagination__next-or-prev:before {
  border: 1px solid #00387a;
  position: absolute;
  top: 50%;
  height: 8px;
  width: 8px;
  display: block;
  content: " ";
}

.pagination__next-or-prev--prev {
  padding-left: 15px;
}

.pagination__next-or-prev--prev:before {
  left: 0;
  -webkit-transform: translateY(-50%) scaleY(1.5) rotate(-45deg);
  transform: translateY(-50%) scaleY(1.5) rotate(-45deg);
  border-bottom: 0;
  border-right: 0;
}

.pagination__next-or-prev--next {
  padding-right: 15px;
}

.pagination__next-or-prev--next:before {
  right: 0;
  border-bottom: 0;
  border-left: 0;
  -webkit-transform: translateY(-50%) scaleY(1.5) rotate(45deg);
  transform: translateY(-50%) scaleY(1.5) rotate(45deg);
}

.pagination__item,
.pagination__wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.pagination__wrap {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  text-align: left;
}

.pagination__item {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: center;
  align-content: center;
}

.pagination__spacer {
  margin: 0 5px;
}

@media all and (min-width: 900px) {
  .pagination--center {
    width: 100%;
  }

  .pagination__wrap {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .pagination__wrap--center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
  }

  .pagination__page--with-extra {
    max-width: 40px;
  }

  .pagination__item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .pagination__item--with-extra {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    max-width: 75px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .pagination__next-or-prev {
    max-width: none;
    text-indent: 0;
    font-size: 1em;
    -webkit-transform: none;
    transform: none;
    min-height: 0;
    height: auto;
  }

  .pagination__next-or-prev--prev {
    margin-right: 30px;
  }

  .pagination__next-or-prev--next {
    margin-left: 30px;
  }

  .pagination__spacer {
    margin: 4px 0 0;
    display: inline-block;
  }
}

@media screen and (min-width: 900px) and (-ms-high-contrast: none) {
  .pagination__page,
  ::-ms-backdrop {
    padding-top: 8px;
  }
}

.site-alert-box {
  border-top: 2px solid #6a6a6a;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 15px;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
  z-index: 1050;
  width: 100%;
  background: #00387a;
  color: #fff;
}

.site-alert-box__title {
  font-family: "Titillium Web", sans-serif;
  font-weight: 600px;
  font-size: 1.75em;
  margin-bottom: 10px;
}

.site-alert-box a {
  color: #00cfe0;
  text-decoration: underline;
}

.site-alert-box a:active,
.site-alert-box a:focus,
.site-alert-box a:hover {
  color: #00cfe0;
}

.site-alert-box a:hover {
  text-decoration: underline;
}

.site-alert-box--active {
  -webkit-transform: none;
  transform: none;
}

.stock-ticker {
  font-family: "Montserrat", sans-serif;
  color: #fff;
  background: #373a36;
  padding: 15px;
  text-align: center;
}

.stock-ticker__inner {
  margin: 0 auto;
  background: url(https://www.fnb-online.com/assets/images/dots.png) center
    center;
  background-size: 7.5px 8px;
}

.stock-ticker__price {
  font-size: 1.375em;
  margin: 0 12px;
}

.stock-ticker__percent {
  background: #a80432;
  padding: 3px 15px 5px;
  margin: 0 12px 5px;
  border-radius: 14px;
  height: 28px;
}

.stock-ticker__percent--positive {
  background: #00b316;
}

.stock-ticker__updated {
  margin: 0 12px;
  display: block;
  text-align: center;
}

.stock-ticker__content {
  display: inline-block;
  margin: 0 auto;
  background: #373a36;
  padding: 8px 15px;
}

.stock-ticker p {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media screen and (min-width: 600px) {
  .stock-ticker__percent {
    margin: 0 12px;
  }

  .stock-ticker {
    padding: 20px;
  }
}

.controller-render-exception,
.scChromeData,
.view-render-exception,
code {
  max-width: 100%;
  display: block;
  padding: 30px;
  white-space: normal;
  position: relative;
  top: auto;
  left: auto;
  width: 100%;
  font-size: 1rem;
  word-break: break-all;
}

.controller-render-exception > *,
.scChromeData > *,
.view-render-exception > *,
code > * {
  display: block;
  width: 100%;
  font-size: 1rem;
}

.article-detail-section > .controller-render-exception,
.article-detail-section > .scChromeData,
.article-detail-section > .view-render-exception {
  max-width: 66% !important;
}

span.field-validation-error {
  color: #a80532;
}

.read-more__collapse {
  text-align: center;
  display: none;
}

.read-more__collapse--align-left {
  text-align: left;
}

.read-more__collapse--expanded {
  display: block;
  width: 100%;
}

.read-more-collapse-button {
  background: 0 0;
  border: 0;
  color: #00387a;
  padding-right: 40px;
  display: inline-block;
  position: relative;
}

.read-more-collapse-button:active,
.read-more-collapse-button:focus,
.white-read-more-collapse-button:active,
.white-read-more-collapse-button:focus {
  outline-color: #00387a;
}

.read-more-collapse-button::after,
.white-read-more-collapse-button::after {
  content: " ";
  display: block;
  height: 10px;
  position: absolute;
  right: 15px;
  top: 50%;
  -webkit-transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
  -webkit-transform: translateY(-15%) scaleX(1.4) rotate(-135deg);
  transform: translateY(-15%) scaleX(1.4) rotate(-135deg);
  width: 10px;
}

.read-more-collapse-button::after {
  border: 1px solid #00387a;
  border-left: 0;
  border-top: 0;
}

.read-more-collapse-button:hover:after,
.white-read-more-collapse-button:hover:after {
  -webkit-transform: translateY(-55%) scaleX(1.4) rotate(-135deg);
  transform: translateY(-55%) scaleX(1.4) rotate(-135deg);
}

.white-read-more-collapse-button {
  background: 0 0;
  border: 0;
  color: #fff;
  padding-right: 40px;
  display: inline-block;
  position: relative;
}

.white-read-more-collapse-button::after {
  border-width: 0 1px 1px 0;
  border-color: #fff;
  border-style: solid;
}

.accordion-promotions__list {
  margin-top: 30px;
}

.promotion-bar {
  background-color: #6a6a6a;
  color: #fff;
  padding: 10px 20px;
  font-family: "Titillium Web", sans-serif;
}

.promotion-bar--full-width {
  background-color: transparent;
  padding: 0;
  overflow: hidden;
  position: relative;
}

.promotion-bar__img-wrap {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 120px;
  flex: 0 0 120px;
  width: 120px;
  max-width: 120px;
  min-width: 120px;
  max-height: 70px;
  position: absolute;
  bottom: -10px;
  left: 0;
}

.promotion-bar__img-wrap--full-width {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 100%;
  bottom: unset;
  height: auto;
  max-width: none;
  margin: 0 !important;
}

.promotion-bar__headline {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 700;
  max-width: 50%;
  line-height: 1.3rem;
  padding-bottom: 75px;
}

.promotion-bar__copy,
.promotion-bar__headline {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
}

.promotion-bar__tagline {
  font-family: "Titillium Web", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.3rem;
  margin: 0 0 4px;
}

.promotion-bar__body {
  font-size: 0.875rem;
  font-family: "Montserrat", sans-serif;
  line-height: 1rem;
}

.promotion-bar__body a {
  color: #fff;
  text-decoration: underline;
}

.promotion-bar__content-wrap {
  max-width: 873px;
  padding: 0;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
}

.promotion-bar__content-wrap--full-width {
  min-width: 100%;
}

.promotion-bar__img--full-width {
  max-width: 200%;
  max-height: 60px;
  min-height: 60px;
  width: auto;
  height: auto;
  -o-object-fit: fill;
  object-fit: fill;
  overflow: visible;
}

@media screen and (min-width: 768px) {
  .promotion-bar__img--full-width {
    width: 100%;
    height: 60px;
  }

  .accordion-promotion-option--modal {
    padding-left: 20px !important;
  }
}

@media screen and (min-width: 960px) {
  .promotion-bar {
    padding: 0;
    max-height: 60px;
  }

  .promotion-bar--full-width {
    height: 60px;
  }

  .promotion-bar__content-wrap {
    max-height: 60px;
    padding: 8px 20px;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .promotion-bar__content-wrap--full-width {
    padding: 0;
  }

  .promotion-bar__tagline {
    line-height: 1;
  }

  .promotion-bar__copy,
  .promotion-bar__headline {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }

  .promotion-bar__headline {
    max-width: 100%;
    min-width: 0;
    padding-bottom: 0;
  }

  .promotion-bar__copy {
    -ms-flex-item-align: auto;
    align-self: auto;
  }

  .promotion-bar__img-wrap {
    -ms-flex-item-align: start;
    align-self: flex-start;
    bottom: 0;
    top: -18px;
    position: relative;
    margin: 0 15px;
  }

  .promotion-bar__img-wrap--full-width {
    top: auto;
  }

  .promotion-bar + .accordion {
    padding-top: 30px;
  }
}

.accordion-promotion {
  border-top: 1px solid #6a6a6a;
  color: #6a6a6a;
  padding-top: 15px;
  margin-bottom: 30px;
}

.accordion-promotion .accordion__content-title {
  font-family: "Titillium Web", sans-serif;
}

.accordion-promotion__img {
  width: 100%;
  height: auto;
}

.accordion-promotion__subtitle {
  font-family: "Titillium Web", sans-serif;
  font-weight: 700;
}

.accordion-promotion__text-wrap {
  color: #373a36;
  margin: 20px 0;
}

.accordion-promotion__title {
  font-size: 1rem;
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

.accordion-promotion__body {
  line-height: 1.5rem;
  margin-bottom: 8px;
}

.accordion-promotion__body-cta {
  font-family: "Montserrat", sans-serif;
  margin-bottom: 20px;
  display: inline-block;
  text-decoration: none !important;
}

.accordion-promotion__body-cta:hover {
  text-decoration: underline !important;
}

.accordion-promotion__body,
.accordion-promotion__subtitle {
  font-size: 0.875rem;
}

.accordion-promotion-option {
  padding-left: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin: 10px 0 20px;
  font-family: "Montserrat", sans-serif;
  font-size: 0.875rem;
}

.accordion-promotion-option:last-child {
  margin-bottom: 30px;
}

.accordion-promotion-option__body,
.accordion-promotion-option__title {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(100% - 30px);
  flex: 0 0 calc(100% - 30px);
  -ms-flex-preferred-size: calc(100% - 30px);
  flex-basis: calc(100% - 30px);
}

.accordion-promotion-option__body {
  margin-right: 0;
  margin-left: auto;
}

.accordion-promotion-option__title {
  margin: 0 0 10px;
  color: #6a6a6a;
  font-weight: 700;
}

.accordion-promotion-option .input-wrap--radio {
  max-height: 20px;
  width: 20px;
  margin: 0 8px 10px 0;
}

.accordion-promotion-option.label--radio span {
  top: auto;
}

.accordion-promotion-option--modal {
  padding-left: 0;
}

@media screen and (min-width: 960px) {
  .accordion-promotion-option {
    padding-left: 40px;
  }

  .accordion-promotion__img-wrap {
    position: relative;
    margin-bottom: 20px;
  }

  .accordion-promotion {
    max-width: 833px;
    margin: 0 auto 30px;
    position: relative;
  }

  .accordion-promotion .blue-gradient-button {
    position: absolute;
    right: 20px;
    top: 68px;
  }

  .accordion__content-title--promotion {
    max-width: 833px;
    margin: 0 auto;
  }

  .accordion-promotion__title {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }

  .accordion-promotion__text-wrap {
    right: 0;
    color: #fff;
    position: absolute;
    left: 20px;
    margin: 0;
    bottom: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: center;
    align-content: center;
    height: 70px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.contact-box__wrap {
  position: relative;
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px 30px 30px;
  color: #373a36;
  font-family: "Montserrat", sans-serif;
  margin-top: 10px;
}

.contact-box__gradient {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-image: radial-gradient(
    farthest-side at 50% 60%,
    transparent 0%,
    #fff 60%
  );
  background-image: -webkit-radial-gradient(
    farthest-side at 50% 60%,
    transparent 0%,
    #fff 60%
  );
  height: 101%;
  width: 100%;
  z-index: 1;
  background-size: 300% 115%;
  background-position: center -50px;
}

.contact-box__content-wrap {
  z-index: 2;
  position: relative;
  height: 100%;
}

.contact-box__title-wrap {
  width: calc(100% - 40px);
  margin: 0 auto;
  max-width: 700px;
}

.contact-box__title {
  font-family: "Titillium Web", sans-serif;
  text-align: center;
  font-size: 2em;
  line-height: 1.25;
  color: #00387a;
  margin-bottom: 20px;
  margin-top: 30px;
}

.contact-box__subtitle {
  width: 70%;
  margin: 0 auto;
  text-align: center;
}

.contact-box__main-content-wrap {
  border-top: 6px solid #a80432;
  background: #fff;
  height: 80%;
  padding: 50px 20px 40px;
  position: relative;
  width: calc(100% - 40px);
  max-width: 700px;
  margin: 40px auto 0;
  font-weight: 600;
}

.contact-box__main-content {
  position: relative;
  height: 100%;
}

.contact-box__main-content .field-wrap {
  font-size: 16px;
}

.contact-box__content-title {
  text-align: center;
  width: 100%;
  margin-bottom: 12px;
  font-size: 18px;
}

.contact-box__checkbox {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 26px;
  color: #00387a;
  font-size: 14px !important;
}

.contact-box__margin-field {
  padding: 0;
  margin-top: 50px;
}

.contact-box__submit-button {
  margin-top: 30px;
  margin-left: auto;
  font-weight: 400;
  padding: 5px 0 7px 12px;
}

.contact-box__confirmation-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  margin: auto;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  display: -ms-flexbox;
  display: -webkit-flex;
  -ms-flex-pack: center;
  -ms-flex-align: center;
}

.contact-box__hidden {
  display: none;
}

.contact-box__confirmation-content-wrap {
  display: block;
  text-align: center;
}

.contact-box__confirmation-content {
  margin-top: 20px;
  line-height: 20px;
}

.contact-box__confirmation-content p {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
}

@media all and (min-width: 768px) {
  .contact-box__wrap {
    height: 825px;
  }

  .contact-box__gradient {
    background-size: 200% 115%;
  }

  .contact-box__main-content-wrap {
    padding: 30px 60px;
  }

  .contact-box__main-content .error {
    position: absolute;
    bottom: 5px;
    z-index: 1;
  }

  .contact-box__content-title {
    font-size: 20px;
    margin-bottom: 24px;
  }

  .contact-box__submit-button {
    padding-right: 74px;
  }
}

@media all and (min-width: 1060px) {
  .contact-box__gradient {
    background-size: 150% 115%;
  }
}

@media only screen and (max-width: 40em) {
  .contact-box__title {
    font-size: 1.8em;
  }

  .contact-box__subtitle {
    width: 100%;
    font-size: 1em;
  }

  .contact-box__submit-button {
    width: 100%;
    text-align: left;
    padding-left: 20px;
  }
}

@media all and (-ms-high-contrast: active), all and (-ms-high-contrast: none) {
  .contact-box__confirmation-content-wrap img {
    width: 50px !important;
  }
}

.mortgage-grid-product {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(50% - 7.5px);
  flex: 0 0 calc(50% - 7.5px);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(-57.45%, #fff),
    color-stop(96.69%, #e8e8e8)
  );
  background: linear-gradient(180deg, #fff -57.45%, #e8e8e8 96.69%);
  margin-bottom: 26px;
  padding: 15px 10px;
  position: relative;
  overflow: visible;
}

.mortgage-grid-product__product-img {
  height: auto;
  width: 100%;
}

.mortgage-grid-product__product-img-wrap {
  width: 75%;
  position: relative;
}

.mortgage-grid-product__link::before,
.mortgage-grid-product__product-img-wrap::after {
  content: " ";
  position: absolute;
  -webkit-transition: -webkit-transform 0.15s ease-out;
  transition: transform 0.15s ease-out;
  transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
}

.mortgage-grid-product__product-img-wrap::after {
  border: 1px solid #00387a;
  border-bottom: 0;
  border-left: 0;
  z-index: 5;
  display: block;
  right: -20px;
  top: calc(50% - 4px);
  -webkit-transform: translate(0, 0) scale(1, 2) rotate(45deg);
  transform: translate(0, 0) scale(1, 2) rotate(45deg);
  height: 18px;
  top: 50%;
  width: 18px;
}

.mortgage-grid-product__product-title {
  font-size: 1rem;
  margin: 12px 0 0;
  text-align: center;
}

.mortgage-grid-product__link {
  display: block;
}

.mortgage-grid-product__link::before {
  top: -4px;
  left: 0;
  background-color: #a80432;
  width: 80px;
  height: 4px;
  -webkit-transform-origin: left top;
  transform-origin: left top;
}

.mortgage-grid-product__link:active::before,
.mortgage-grid-product__link:focus::before,
.mortgage-grid-product__link:hover::before {
  -webkit-transform: scaleX(1.25);
  transform: scaleX(1.25);
}

.mortgage-grid-product__link:active .product-box__promo-inner-wrap,
.mortgage-grid-product__link:focus .product-box__promo-inner-wrap,
.mortgage-grid-product__link:hover .product-box__promo-inner-wrap {
  padding-left: 120px;
}

.mortgage-grid-product__link:active
  .mortgage-grid-product__product-img-wrap::after,
.mortgage-grid-product__link:focus
  .mortgage-grid-product__product-img-wrap::after,
.mortgage-grid-product__link:hover
  .mortgage-grid-product__product-img-wrap::after {
  -webkit-transform: translate(20px, 0) scale(1, 2) rotate(45deg);
  transform: translate(20px, 0) scale(1, 2) rotate(45deg);
}

@media all and (min-width: 768px) {
  .mortgage-grid-product {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(33.3% - 18px);
    flex: 0 0 calc(33.3% - 18px);
    width: calc(33.3% - 18px);
    margin-bottom: 33px;
  }

  .mortgage-grid-product__product-title {
    font-size: 1.25rem;
  }

  .mortgage-grid-product:last-child:nth-child(3n + 2) {
    margin-left: 27px;
    margin-right: auto;
  }
}

.search-backdrop {
  display: none;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  margin-top: 50px;
  height: 100vh;
  width: 100vw;
}

.desktop-search {
  width: 100%;
  margin-top: 10px;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
}

.desktop-search .yxt-SearchBar-button {
  margin: 2px;
}

.desktop-search .yxt-SearchBar-button:active,
.desktop-search .yxt-SearchBar-button:focus {
  background-color: #fff;
  outline: 2px solid #00387a;
}

.desktop-search .yxt-SearchBar-container,
.press-releases-search .yxt-SearchBar-container {
  border: 1px solid #979797;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  z-index: 100;
  text-align: left;
}

.press-releases-search {
  width: 100%;
  height: 50px;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
}

.press-releases-search .yxt-SearchBar-button {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#003875),
    to(#00aad9)
  );
  background: linear-gradient(90deg, #003875, #00aad9);
  margin: 0;
}

.estore-search .yxt-SearchBar-button:focus,
.estore-search .yxt-SearchBar-button:hover,
.press-releases-search .yxt-SearchBar-button:focus,
.press-releases-search .yxt-SearchBar-button:hover {
  background: #00387a;
}

.press-releases-search .Icon-image {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.estore-search {
  width: 700px;
  height: 50px;
  margin: auto auto 50px;
  max-width: 100%;
}

.estore-search-container {
  width: 100%;
}

.estore-search .yxt-SearchBar-button {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#003875),
    to(#00aad9)
  );
  background: linear-gradient(90deg, #003875, #00aad9);
}

.estore-search .yxt-SearchBar-container {
  border: 1px solid #979797;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  z-index: 100;
  text-align: left;
}

.estore-search .Icon-image {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.search-container {
  height: 0;
  width: 100%;
  position: absolute;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  padding: 0;
}

.search-gradient-overlay {
  width: 100%;
  height: 100%;
  padding: 30px 0;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0.1)),
    to(rgba(255, 255, 255, 0.05))
  );
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.1),
    rgba(255, 255, 255, 0.05)
  );
}

.yxt-SearchBar-clear {
  margin: 2px;
}

.yxt-SearchBar-clear:active,
.yxt-SearchBar-clear:focus {
  background-color: #fff;
  outline: 2px solid #00387a;
}

.yxt-AutoComplete-option--item,
.yxt-AutoComplete-option--promptHeader {
  font-family: "Titillium Web", sans-serif;
}

.yxt-AutoComplete:before {
  display: none;
}

.desktop-search .yxt-SearchBar-form :nth-child(1) {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.desktop-search .yxt-SearchBar-form :nth-child(2) {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
}

.desktop-search .yxt-SearchBar-form :nth-child(3) {
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4;
}

.desktop-search .yxt-SearchBar-form :nth-child(4) {
  -webkit-box-ordinal-group: 6;
  -ms-flex-order: 5;
  order: 5;
}

.desktop-search .yxt-SearchBar-form :nth-child(5) {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

.yxt-SearchBar-input {
  font-family: "Titillium Web", sans-serif;
  margin: 2px !important;
}

.yxt-SearchBar-input:active,
.yxt-SearchBar-input:focus {
  outline: 2px solid #00387a;
}

@media (min-width: 900px) {
  .search-backdrop {
    display: block;
  }

  .desktop-search .yxt-SearchBar-form :nth-child(1) {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .desktop-search .yxt-SearchBar-form :nth-child(2) {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .desktop-search .yxt-SearchBar-form :nth-child(3) {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }

  .desktop-search .yxt-SearchBar-form :nth-child(4) {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }

  .desktop-search .yxt-SearchBar-form :nth-child(5) {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }

  .desktop-search {
    width: 700px;
    height: 50px;
    margin: 20px auto auto;
  }

  .desktop-search .yxt-SearchBar-button {
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#003875),
      to(#00aad9)
    );
    background: linear-gradient(90deg, #003875, #00aad9);
    margin: 0;
  }

  .desktop-search .yxt-SearchBar-button:focus,
  .desktop-search .yxt-SearchBar-button:hover {
    background: #00387a;
  }

  .desktop-search .Icon-image {
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
  }

  .search-container {
    height: 150px;
  }
}

.product-selector-title {
  font-family: "Titillium Web", sans-serif;
  background: #00387a;
  color: #fff;
  padding: 30px;
  position: relative;
  text-align: center;
}

.product-selector-title__product-list {
  max-width: 800px;
  margin: auto;
}

.product-selector-title__title {
  font-size: 26px;
  font-weight: 400;
  margin-bottom: 15px;
}

.product-selector-title__dropdown {
  max-width: 500px;
  margin: auto;
}

.product-selector-title p {
  font-size: 16px;
}

.product-selector-title__wrap {
  padding: 0;
}

.product-selector-title__list {
  margin-left: 35px;
}

.product-selector-title__check {
  height: 20px;
  position: absolute;
  padding-top: 5px;
}

.product-selector-title__check::after,
.product-selector-title__check::before {
  content: " ";
  display: block;
  position: relative;
}

.product-selector-title__check::after {
  border: 5px solid #6a6a6a;
  border-right: 0;
  border-top: 0;
  height: 10px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  width: 20px;
  margin: 0;
}

@media all and (min-width: 900px) {
  .product-selector-title {
    background: url(https://www.fnb-online.com/assets/images/banner-bg-blue.png)
      center center;
    background-size: 460px auto;
    color: #fff;
    font-size: 16px;
    overflow: hidden;
    padding: 0;
    position: relative;
    text-align: center;
  }

  .product-selector-title p {
    font-size: 18px;
    line-height: 1.625em;
  }

  .product-selector-title__wrap {
    margin: 0 auto;
    max-width: 1060px;
    position: relative;
    z-index: 2;
    padding: 90px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .product-selector-title__title {
    font-size: 2.625em;
    line-height: 1em;
  }

  .product-selector-title__heading {
    color: #fff;
    font-size: 18px;
  }
}

@media print and (min-width: 0px) {
  body,
  html {
    margin: 0 auto;
    width: 1160px;
  }

  .article-detail-header {
    max-width: 1000px;
    margin: 0 auto !important;
  }

  .article-detail-sidebar,
  .breadcrumb,
  .footer__link-list-item {
    display: none !important;
  }

  .footer__link-list {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .main {
    padding-top: 0 !important;
  }

  .icon-scroller-slide {
    opacity: 1;
  }

  .main-nav:after,
  .navbar__under {
    display: none !important;
  }

  .main-nav,
  .navbar__left {
    width: 100%;
    text-align: center;
  }

  .navbar__left {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 5px;
  }

  .main-nav {
    position: relative;
    height: 61px;
    min-height: 0;
    overflow: hidden;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .article-detail-section > * {
    max-width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .rate-table {
    max-width: 90%;
    margin: 0 auto;
  }

  .process-overview-slide:nth-child(4):last-child {
    margin-right: 0 !important;
  }

  .article-detail-header__content-wrap {
    max-width: 100% !important;
  }

  .article-detail-header {
    padding: 32px 0 !important;
    background: 0 0 !important;
  }

  .icon-scroller {
    background: 0 0;
    width: 100%;
    max-width: none !important;
  }

  .icon-scroller > div {
    max-width: none !important;
    width: 100% !important;
  }

  .footer a,
  .icon-scroller__title.white,
  .white {
    color: #373a36 !important;
  }

  .article-list__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .article-thumb {
    max-width: calc(33.3% - 14px);
  }

  .icon-scroller__wrap,
  .swiper-container {
    width: 100%;
    max-width: none !important;
  }

  .article-detail {
    padding-bottom: 0 !important;
  }

  .article-tags,
  .notices {
    margin: 30px 0 !important;
    max-width: 100% !important;
    padding: 0;
  }

  .footer__link-list {
    max-width: 100%;
  }

  .footer {
    border: 0 !important;
    color: #373a36 !important;
    background: 0 0;
  }

  .swiper-slide {
    margin-bottom: 30px;
  }

  .swiper-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-transform: none !important;
    transform: none !important;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .main-nav__logo--mobile-up {
    display: none;
  }

  .accordion__content--stay-on-desktop[aria-hidden] {
    display: block;
    max-height: none;
  }

  .burger-dropdown-toggle,
  .burger-navigation,
  .login-box,
  .navbar__center,
  .navbar__right {
    display: none !important;
  }

  .main-nav__logo--desktop,
  .main-nav__logo--mobile-up {
    width: 316px !important;
    margin: 0 auto !important;
    height: 31px !important;
  }

  .main-nav__logo--desktop {
    display: block;
  }
}

@media (max-width: 760px) {
  .main {
    padding-top: 20px;
  }
  .login-box {
    background: linear-gradient(90deg, #003875, #00aad9);
    color: #fff;
    padding: 15px 0 30px;
    right: 0;
    width: calc(100vw - 0px);
  }
  .login-box__tabpanel .login-box__mobile-only {
    display: none;
  }
  .login-box__mobile-only form {
    width: 100% !important;
  }
}
