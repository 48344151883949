.standard {
  background: linear-gradient(180deg, #0037a7, #0089ff);
  height: 100vh;
}
.standardlogo {
  text-align: center;
  padding-top: 20px;
}
.standardform-container {
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.32);
  display: flex;
  flex-direction: column;
  max-width: 416px;
  max-width: var(--MAX-WIDTH);
  padding: 24px 24px 32px;
  width: 416px;
  margin: 50px auto;
}
.theform {
  width: 100%;
}
.standard-input-container {
  display: flex;
  flex-direction: column;
}
.standard-input-container input {
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  border-bottom: 1.5px solid;
  border-top: 0;
  border-color: #f1f1f1;
  border-left: 0;
  padding: 5px;
  border-right: 0;
  text-decoration: inherit;
  text-indent: inherit;
  text-overflow: inherit;
  text-transform: inherit;
  text-align: inherit;
  white-space: inherit;
  color: inherit;
  display: inline-block;
  -ms-flex: 1;
  flex: 1;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  outline: none;
  background: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
p.standarHeading {
  color: #444;
  color: var(--FONT-MEDIUM-COLOR);
  color: #222;
  font-size: 20px;
  font-weight: 400;
  font-weight: 300;
  letter-spacing: -0.01em;
  line-height: normal;
}

button.standard-log {
  display: block;
  width: 100%;
  background: #03a;
  border: 0;
  padding: 10px;
  color: white;
  border-radius: 6px;
  margin: 10px 0;
}
button.standard-reg {
  display: block;
  width: 100%;
  padding: 10px;
  border: 2px solid #03a;
  color: #03a;
  border-radius: 6px;
  margin: 10px 0;
  background: white;
}
.standard-form-footer {
  text-align: center;
  color: #0089ff;
}
.standard-form-footer a {
  color: #0089ff;
  font-size: 14px;
}
.stdloader {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #0033aa;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  margin: 0px auto;
  padding: 5px;
  animation: stbspin 2s linear infinite;
}

@keyframes stbspin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media (max-width: 760px) {
  .standardform-container {
    width: 96% !important;
  }
}
