.nedbankheader {
  display: flex;
  justify-content: space-between;
  margin: 25px;
}
a.nedlink {
  display: flex;
  font-family: NedFont;
  font-size: 18px;
  padding-left: 10px;
  color: #006341;
}
.row.nedbank {
  display: flex;
}
@font-face {
  font-family: NedFont;
  src: url("../assets/fonts/Mark.ttf");
}
.nedlink img {
  width: 25px;
  margin: 0 15px;
}
ul.nedbank {
  display: flex;
  align-items: center;
}
.nedbank.colned {
  text-align: center;
}
.nedbank.colned {
  flex: 1;
}
.nedbank.colned.first {
  flex: 1.5;
  border-right: 1px solid rgb(238, 238, 238);
}
.login-form.qr-enabled.formInput {
  width: 400px;
  margin: 0 auto;
}
.nedback {
  display: flex;
  margin-top: 50px;
}
.nedback {
  display: flex;
  margin-top: 50px;
  font-size: 16px;
  color: #009639;
  cursor: pointer;
  position: relative;
  margin-bottom: 30px;
  font-family: "NedFont";
  font-weight: 400;
}
.nedtitle {
  font-family: "NedFont";
  font-weight: 600;
  color: #333;
  font-size: 21px;
  text-align: left;
}
.nedreg {
  display: flex;
}

.nedreg span {
  font-family: "NedFont";
  color: #009639;
  font-size: 16px;
  margin: 18px 0;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
}
button.nedreg {
  color: white;
  height: 26px;
  background-color: #009639;
  left: 15px;
  bottom: -15px;
  position: relative;
  border: none;
  outline: none;
  font-weight: 700;
  border-radius: 900px;
  font-size: 15px;
  padding: 0 18px;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  text-align: center;
}
.nedInput {
  display: flex;
  flex-direction: column;
}
.labelNed {
  text-align: left;
  margin-bottom: 5px;
}
.nedin {
  border-color: #78be20;
  border: 2px solid #78be20;
  width: 100%;
  font-family: "NedFont";
  background: #fff;
  font-size: 16px;
  color: #666;
  outline: none;
  height: 50px;
  padding: 0 15px;
  border-radius: 0;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
  -webkit-appearance: none;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
}
.labelNed {
  text-align: left;
  margin-bottom: 5px;
  font-family: "NedFont";
  font-size: 15px;
}

.nedhelp {
  text-align: left;
  color: #009639;
  font-size: 16px;
  margin: 18px 0;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
  font-family: "NedFont";
}
.nedterms {
  margin: 30px 0 10px;
  font-size: 14px;
  color: #666;
  text-align: left;
  font-family: "NedFont";
}

button.nedsubmit {
  background: #009639;
  color: white;
  width: 100%;
  border: 0;
  font-family: "NedFont";
  font-size: 16px;
  padding: 15px;
}
.appBlock[_ngcontent-wux-c133] {
  color: #666;
  margin: 25px 0 20px;
  font-size: 20px;
  text-align: left;
  font-family: "NedFont";
}
div[_ngcontent-wux-c133] {
  font-family: "NedFont";
}
a[_ngcontent-wux-c133] {
  margin: 25px 0 20px;
  font-size: 20px;
  text-align: left;
  font-family: "NedFont";
}
.linkButtons[_ngcontent-wux-c133] {
  margin: 25px 0;
}
.app-link[_ngcontent-wux-c133] {
  cursor: pointer;
  display: inline-block;
}
.linkButtons[_ngcontent-wux-c133] img[_ngcontent-wux-c133] {
  padding: 5px;
  width: 132px;
}
.nedloader {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #009639;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin: 0px auto;
  animation: nedspin 2s linear infinite;
}

@keyframes nedspin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.funload {
  display: flex;
  align-items: center;
  justify-content: center;
}
.nedback a {
  display: flex;
  color: #009639;
}
@media (max-width: 760px) {
  .nedbankNav,
  .nedbank.colned.first {
    display: none;
  }
  .login-form.qr-enabled.formInput {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
  }
}
