/* //300px mobile */
@media (min-width: 320) and (max-width: 769px) {
  .mw-50 {
    max-width: 100% !important;
  }
  .medias {
    width: 100%;
    gap: 0px;
    grid-gap: 6px !important;
    justify-content: space-between;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1200px;
  }
}

@media (min-width: 768px) {
  .rcpstyle {
    margin: 0;
    padding: 0;
  }
  .heroR {
    position: relative;
    top: -107px;
    background-size: cover;
    height: 80vh;
    width: 100%;
  }
  .mw-50 {
    max-width: 50%;
  }
  .customD {
    margin-left: 75px !important;
  }
  .customB {
    margin-right: 80px;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.333333%;
  }
}

@media (max-width: 820px) {
  .navFooterVerticalRow {
    display: flex !important;
    flex-wrap: wrap !important;
    flex-direction: column !important;
  }
  .navFooterColSpacerInner {
    display: none !important;
  }
  #navFooter.navLeftFooter {
    min-width: 100% !important;
  }
  header {
    width: 100%;
    background-color: #131921;
    overflow: hidden;
  }

  .banner-content .links,
  nav .address,
  nav .cart,
  nav .sign-in,
  nav .nav-search {
    display: none;
  }
  .amazon_navbar {
    padding: 10px;
  }
  .wrapper {
    padding: 0 11px !important;
    margin: 0 auto;
  }
  .userbal {
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .userbal {
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .product-row {
    display: flex;
    flex-wrap: wrap;
    gap: 10px !important;
  }
  .product-container {
    width: 48% !important;
    background: white;
    position: relative;
  }
  .product-media img {
    width: 100%;
  }
  .prem img {
    width: 15px;
    height: 15px;
  }
  .col-md-7 > .col-md-4 {
    width: 26.333333%;
  }
  .col-md-5,
  .col-md-7 {
    flex: 0 0 auto;
    width: 100%;
    margin: 0 auto;
  }
}
@media (max-width: 1024px) {
  .mw-50 {
    min-width: 50%;
  }
  .customD {
    margin-left: 75px;
  }
  .customB {
    margin-right: 80px;
  }
  .pd-intro h2,
  .hero-contentP h2 {
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 60px;
    color: white;
    line-height: 30px;
  }
  .hero-tick {
    position: absolute;
    width: 253px;
    left: 0;
    bottom: -13px;
  }
  .pd-intro p {
    color: white;
    font-size: 14px;
    margin-bottom: 40px;
    padding-top: 14px;
    line-height: 30px;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 26.333333%;
  }
}

@media (max-width: 1280px) {
  .customD {
    margin-left: 75px;
  }
  .customB {
    margin-right: 80px;
  }
  .pd-intro h2,
  .hero-contentP h2 {
    font-style: normal;
    font-weight: 900;
    font-size: 62px;
    line-height: 60px;
    color: white;
  }
}
@media (max-width: 540px) {
  .pd-intro h2,
  .hero-contentP h2 {
    font-style: normal;
    font-weight: 900;
    font-size: 33px;
    line-height: 38px;
    color: white;
  }
  .hero-tick {
    position: absolute;
    width: 260px !important;
    bottom: -16px !important;
    left: 0;
  }
}

/* nest hub */
@media (min-width: 770px) and (max-width: 959px) {
  .desktop-navigation {
    display: none;
  }
  .mobile-navigation {
    position: absolute;
    right: 14px;
    z-index: 99;
    top: 5px;
  }
  .w-1100 {
    width: 100%;
  }
  .medias {
    max-width: 482px;
    margin: 0 auto;
  }
  .sm-thumbnail > a img {
    margin: 6px 0;
    width: 145px;
  }
  .sm-thumbnail > img {
    margin: 6px;
    width: 150px;
  }
  .col-md-5 .podcast-title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 0;
    line-height: 70px;
    color: white;
  }
  .subscribe {
    background: #2e3192;
    border-radius: 20px;
    padding: 0px;
    margin: 12px;
  }
  .footer-logo img {
    width: 195px;
  }
  .footer-details {
    padding-left: 0px;
  }

  .pd-intro {
    position: absolute;
    width: 52%;
    top: 220px;
  }
  .hide-large {
    display: none;
  }
  .hero-tick {
    position: absolute;
    width: 175px;
    left: 0;
    bottom: -2px;
  }
  .s-content {
    width: 100%;
    margin-left: 20px;
    text-align: center;
  }
  .hero {
    position: relative;
    top: -107px;
    /* z-index: -1; */
    background-size: cover;
    height: 70vh;
    width: 100%;
  }
}

@media (min-width: 1440px) and (max-width: 2000px) {
  .x-4 {
    width: 30.333333% !important;
  }
}
@media (max-width: 320px) {
  .hero-contentP h2,
  .pd-intro h2 {
    color: #fff;
    font-size: 39px !important;
    font-style: normal;
    font-weight: 900;
    line-height: 50px;
  }
  button.secondary {
    width: 165px !important;
    margin-left: 20px;
    font-size: 14px !important;
  }
  button.featured {
    margin-left: 0 !important;
  }
}

/* portrait tablets, portrait  landscape */
@media (max-width: 1024px) {
  .pd-intro p {
    color: white;
    font-size: 14px;
    margin-bottom: 20px;
    padding-top: 14px;
    line-height: 30px;
  }
}
@media (max-width: 769px) {
  .nav-search {
    padding: 0 10px;
  }
  .text-container {
    width: 100%;
    margin: 0 auto;
    font-size: 14px;
    line-height: 2;
  }
  .fs-10px {
    font-size: 10px;
  }

  h2.title {
    color: black;
    font-size: 14px;
    font-weight: bolder;
    line-height: 1.7;
  }
  h2.reviews {
    font-size: 16px;
    font-weight: 700;
    color: black;
  }
  .premium_label {
    padding: 3px;
    font-size: 7px;
  }
  #navFooter .navAccessibility.navFooterVerticalColumn {
    display: block !important;
    margin: 0 auto;
  }
  .review-button {
    margin-top: 30px;
    text-align: center;
  }
  .page-centered {
    max-width: 100%;
    margin: 0 auto;
    background: #222f3d;
    margin-top: 50px;
    padding: 20px;
    margin: 10px;
  }
  .nav-logo img {
    margin-top: 10px;
    width: 75px !important;
  }
  .review-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .pod-content > .ptx-10 {
    padding-left: 34px;
  }
  #sttop {
    position: fixed;
    bottom: 100px;
    right: 78px !important;
    cursor: pointer;
  }
  .mw-50px {
    min-width: 100% !important;
  }

  #sttop img {
    width: 150px !important;
  }

  .sticky {
    position: fixed;
    top: -31px;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    height: 80px;
    padding: 0;
    padding-top: 30px;
  }
  .mobile-p10 .row {
    background-image: none !important;
  }

  .w-20 {
    top: 27px;
    width: 134px !important;
  }
  .no-mobile {
    display: none !important;
  }
  .breadcrumbs {
    left: 11px;
    position: absolute;
    top: 97px;
  }
  .breadcrumbs ul a,
  .breadcrumbs ul li {
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
  }
  .sort-title {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 60px;
    color: white;
  }
  .flex {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    gap: 10px;
    margin-bottom: 10px;
  }
  .sort-list span {
    display: inline-table;
  }
  .sort-list span a {
    margin: 3px;
    padding: 3px;
    cursor: pointer;
    color: #abadd3 !important;
    font-size: 20px;
    font-weight: 500;
  }
  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-around;
    justify-content: space-between;
    align-items: stretch;
  }
  .list-item {
    color: white;
    height: auto;
    text-decoration: underline;
    font-size: 16px;
    margin: 10px 10px;
  }
  .youtubem,
  .googlem,
  .podm,
  .applem,
  .spotifym {
    width: 160px;
  }
  .list-itema {
    text-align: right;
  }
  .col-md-4 {
    width: 100%;
  }
  .col-md-8 {
    width: 100%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 100%;
  }
  .pod-content-img {
    width: 100%;
    object-fit: fill;
    max-height: fit-content;
    border-radius: 10px;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 100%;
  }
  .s-content {
    margin-left: 0;
    width: fit-content;
  }
  .sm-thumbnail {
    max-width: 100%;
    margin: 0px;
  }

  .desktop-navigation,
  .hide-small {
    display: none !important;
  }
  .mobile-navigation {
    position: absolute;
    right: 14px;
    z-index: 99;
    top: 9px;
  }
  .logo {
    width: 77px;
    height: auto;
  }

  .ico-img {
    position: absolute;
    right: 25px;
    top: 100px;
    width: 650px px;
  }
  .pd-intro h2 {
    font-style: normal;
    font-weight: 900;
    font-size: 35px;
    line-height: 45px;
    color: white;
    margin-bottom: 40px;
  }
  .btn-container {
    max-width: 450px;
  }
  .hero {
    height: 100vh;
  }
  .pd-intro {
    position: relative;
    width: 100% !important;
    top: 155px;
    z-index: 20;
  }
  .btn {
    font-size: 12px;
  }
  .abt-text {
    font-size: 40px;
    line-height: 60px;
  }
  /* .container {
    padding: 0 10px;
  } */
  .hero-tick {
    position: absolute;
    width: 248px;
    bottom: -18px;
    left: 0;
  }
  button.large {
    padding: 18px 21px;
    margin-right: 14px;
    z-index: 99;
    font-size: 16px;
    width: 100%;
  }
  .btn-container .col-8 {
    flex: 0 0 auto;
    width: 63.666667%;
  }

  .df {
    display: block;
  }
  .df img {
    margin: 25px auto;
    display: block;
  }
  .spotify {
    width: 150px;
  }
  p {
    font-size: 14px;
  }
  .product-media {
    height: 150px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin: 10px;
  }
  .product-title {
    font-weight: 500;
    font-size: 12px;
    font-family: poppins;
    width: 114px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .prem {
    font-size: 10px;
  }
  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 48px;
    color: white;
  }
  .latest-container {
    width: 100%;
    padding: 0px;
  }
  .podcast-title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 0px;
    margin-top: 15px;
  }
  .medias {
    width: 100%;
    gap: 10px;
    grid-gap: 10px;
    justify-content: space-between;
  }
  .sm-thumbnail {
    max-width: 47%;
  }
  a.ptx-10 {
    padding-left: 40px;
  }
  .col-md-8 > .podcast {
    padding: 0;
  }
  .sm-thumbnail > a img {
    width: 100% !important;
  }
  .col-md-5 .podcast-title {
    font-size: 18px;
    text-align: center;
    color: white;
  }
  .subscribe {
    border-radius: 20px;
    padding: 10px;
  }
  .subscribe-msg {
    color: white;
    text-align: center;
  }
  .sm-thumbnail > img {
    margin: 0px !important;
    width: 100% !important;
    padding: 5px !important;
  }
  .footer-logo img {
    width: 150px;
  }
  .hero-contentP {
    width: 100%;
  }
  .heroP {
    background-position: center;
    background-size: cover;
    top: -86px;
    height: 90vh;
    position: relative;
  }

  .heroB {
    position: relative;
    top: -107px;
    /* z-index: -1; */
    background-size: cover;
    height: 35vh;
    width: 100%;
  }

  .custom-position {
    position: relative;
    top: -200px;
  }
  .featured-high {
    padding: 0px;
  }
  .scroll-h {
    display: flex;
    flex-direction: row;
    overflow-x: overlay;
    overflow-y: hidden;
    padding: 5px;
  }

  .pod-content {
    margin: 10px 0;
  }
  .play-ico {
    display: block !important;
    position: absolute;
  }
  .innerPodcast {
    max-width: 93%;
  }
  .behind-img {
    position: absolute;
    z-index: 1;
    top: 125px;
    left: 11px;
  }
  .thumbnail {
    z-index: 9;
    position: relative;
  }
  .innerContent {
    text-align: justify;
    padding: 40px 10px;
  }
  .heading {
    font-size: 20px;
    color: #fff;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .heroPD {
    background-position: inherit;
    background-size: cover;
    top: -59px;
    height: 103vh;
  }
  .hero-tick2 {
    position: absolute;
    width: 150px !important;
    left: 0;
    bottom: -11px;
  }
  .p0 {
    padding: 4px !important;
  }
  .mobile-p10 {
    padding: 10px !important;
  }
  .bottom-banner {
    background-size: cover;
    background-position: center;
  }
  .form-container form input {
    margin: 0;
  }
  .form-content {
    padding: 10px;
  }
  .form-container form textarea {
    margin: 0;
  }
  .heroB {
    background-size: cover;
    background-position: center;
  }
  .pd-intro h2,
  .hero-contentP h2 {
    font-style: normal;
    font-weight: 900;
    font-size: 33px;
    line-height: 50px;
    color: white;
    margin: 0;
  }
  .mw-110 {
    min-width: 110px;
  }
  .buttom-banner {
    background-size: cover;
    background-position: center;
  }
  .pop .col-sm-6 {
    padding: 10px;
    width: 50%;
  }
  .modal-body h2 {
    font-size: 25px;
    padding-top: 20px;
  }
  .close-modal {
    position: absolute;
    right: 16px;
    color: white;
    background: black;
    border: none;
  }
  .ahero-tick {
    position: absolute;
    width: 144px;
    left: 0px;
    bottom: -10px;
  }
  .w-1100 {
    width: 100%;
  }
  .ahero-tick2 {
    width: 98px;
    position: absolute;
    left: 159px;
    bottom: 81px;
    height: 15px;
  }
  .form-container {
    height: 676px;
  }
  .previewThumbnail {
    width: 100%;
    padding: 10px;
  }
  .p-10 {
    padding: 10px !important;
  }
  .heroA {
    height: 132vh;
    z-index: 1;
  }
  .contant button.large {
    display: flex;
    align-items: baseline;
    width: 100%;
  }

  /* SKELETON */
  .pd-introSk {
    position: absolute;
    width: 100%;
    top: 220px;
  }

  /* .heroP {
    display: none;
  }
  .custom-position {
    position: relative;
    top: 0px;
  }
  .featured-high {
    background-color: #07080f !important;
    padding: 0;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/wiftz-podcasts.appspot.com/o/bg.png?alt=media&token=361ef195-bb96-479b-819f-9750d056bea9");
  }
 
   */
}

/* tablet, landscape iPad, lo-res laptops ands desktops */
@media (min-width: 961px) {
  .mw-50px {
    min-width: 550px !important;
  }
  .innerContent {
    text-align: unset;
    position: relative;

    top: -110px;
    margin-bottom: -110px;
  }
  .content > .innerContent {
    top: 0;
    margin-bottom: 0px;
  }

  .mobile-navigation,
  .hide-large {
    display: none !important;
  }
  .desktop-navigation {
    display: flex;
    justify-content: space-evenly;
    align-items: baseline;
  }
  .x-4 {
    width: 31.333333%;
  }
  /* .hero-tick2 {
    width: 202px;
    position: absolute;
    left: 0;
    bottom: -4px;
  } */
}

/* big landscape tablets, laptops, and desktops */
@media (min-width: 1025px) {
  /* .hero {
    height: 90vh;
  } */
}

/* hi-res laptops and desktops */
@media (min-width: 1281px) {
  /* .hero {
    height: 90vh;
  } */
}

@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 100%;
  }
}

.wrapper-x {
  padding: 0 20px !important;
  max-width: 1200px;
  margin: 0 auto;
}
