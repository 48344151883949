body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
button,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset,
img {
  border: 0;
}

address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var,
optgroup {
  font-style: inherit;
  font-weight: inherit;
}

del,
ins {
  text-decoration: none;
}

li {
  list-style: none;
}

caption,
th {
  text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

q:before,
q:after {
  content: "";
}

abbr,
acronym {
  border: 0;
  font-variant: normal;
}

sup {
  vertical-align: baseline;
}

sub {
  vertical-align: baseline;
}

legend {
  color: #000;
}

input,
button,
textarea,
select,
optgroup,
option {
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
}

input,
button,
textarea,
select {
  *font-size: 100%;
}

.vi-hidden {
  height: 1px;
  left: -10000px !important;
  overflow: hidden !important;
  position: absolute !important;
  top: auto !important;
  width: 1px;
}

#offscreen a,
#offscreen label {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

#offscreen a:focus,
#offscreen label:focus {
  position: static;
  width: auto;
  height: auto;
}

.vi-screenreader-line {
  position: absolute;
  left: -10000px;
  width: 1px;
  height: 0;
  line-height: 0;
  overflow: hidden;
  font-size: 3px;
}

.vi-tabbable-content:focus {
  border-width: 0 0 1px 0;
  border-style: dotted;
  border-color: #ccc;
  outline: 0;
}

.ui-datePicker {
  position: relative;
  width: 90px;
  display: inline-block;
  margin-right: 3px;
}

.ie7 .ui-datePicker,
.ie7 .ui-datePicker {
  margin-right: 6px;
  display: inline;
}

.ui-datePicker--input,
.ui-datePicker--input_disabled {
  border: 1px solid #c8c8c8;
  width: 100%;
  height: 18px;
  padding-left: 3px;
  border-radius: 4px 4px 4px 4px;
  -moz-border-radius: 4px 4px 4px 4px;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
}

.ui-datePicker--input {
  background: #fff url(https://www.absa.co.za.2009.ui/datePicker/datePicker.png)
    no-repeat right center scroll;
}

.ie8 .ui-datePicker--input,
.ie7 .ui-datePicker--input {
  height: 16px;
  padding-top: 2px;
}

.ui-datePicker--input_disabled {
  background: #fff
    url(https://www.absa.co.za.2009.ui/datePicker/datePicker-grey.gif) no-repeat
    right center scroll;
}

.ui-datePickerRoot {
  width: 193px;
  z-index: 999999;
  position: absolute;
  left: -1000px;
  top: -1000px;
}

.ui-dp-calendar {
  background-color: #f4f4f4;
  border: 1px solid #c8c8c8;
  padding: 10px;
}

.ie6 .ui-dp-calendar {
  position: absolute;
}

.ui-dp-calendar,
.ui-dp-calendar * {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -khtml-box-sizing: border-box;
}

.ui-dp-control {
  position: relative;
  text-align: center;
  margin-bottom: 14px;
  border: 1px solid #c8c8c8;
  background-color: #d9d9d9;
  height: 19px;
  line-height: 17px;
}

.ie6 .ui-dp-control,
.ie7 .ui-dp-control {
  height: 17px;
}

.ui-dp-button {
  position: absolute;
  width: 19px;
  height: 19px;
  background-position: 0 0;
  background-repeat: no-repeat;
  overflow: hidden;
  cursor: pointer;
  top: -1px;
}

.ui-dp-button-pmonth {
  left: -1px;
  background-image: url(https://www.absa.co.za.2009.ui/datePicker/icons.gif);
  background-position: 0 0;
}

.ui-dp-button-nmonth {
  right: -1px;
  background-image: url(https://www.absa.co.za.2009.ui/datePicker/icons.gif);
  background-position: -19px 0;
}

.ie6 .ui-dp-button-nmonth {
  right: -2px;
}

.ui-dp-monthDropdown {
  position: absolute;
  top: 12px;
  left: 47px;
  background-color: #fff;
  border-top: 1px solid;
  border-left: 1px solid;
  border-right: 1px solid;
  display: none;
}

.ui-dp-monthDropdown div {
  border-bottom: 1px solid;
  cursor: pointer;
}

.ui-dp-monthDropdownEle-select {
  background-color: blue;
}

.ui-dp-monthDropdownEle-hover {
  background-color: #af144b;
}

.ui-dp-monthDropdownEle-restricted {
  text-decoration: line-through;
}

.ui-dp-yearDropdown {
  position: absolute;
  top: 12px;
  left: 93px;
  background-color: #fff;
  border: 1px solid;
  height: 167px;
  display: none;
  width: 33px;
  overflow: hidden;
}

.ui-dp-yearDropdown-yearContainer {
  height: 135px;
  overflow: hidden;
  position: absolute;
  width: 31px;
  top: 16px;
  left: 0;
}

.ui-dp-yearDropdown-yearWrapper {
  position: absolute;
  width: 31px;
  text-align: center;
  left: 0;
  top: 0;
}

.ui-dp-yearDropdown .ui-dp-yearDropdown-yearWrapper div {
  border-bottom: 1px solid;
  cursor: pointer;
  height: 15px;
}

.ui-dp-yearDropdown-yearWrapper .ui-dp-yearDropdownEle-select {
  background-color: blue;
}

.ui-dp-yearDropdown-yearWrapper .ui-dp-yearDropdownEle-hover {
  background-color: #af144b;
}

.ui-dp-yearDropdown-button-nyear {
  height: 15px;
  width: 31px;
  position: absolute;
  top: 0;
  left: 0;
}

.ui-dp-yearDropdown-button-nyear-hover {
  background-color: blue;
}

.ui-dp-yearDropdown-button-pyear {
  height: 15px;
  width: 31px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.ui-dp-yearDropdown-button-pyear-hover {
  background-color: blue;
}

.ui-dp-YearDropdownHideP .ui-dp-yearDropdown-button-pyear {
  display: none;
}

.ui-dp-YearDropdownHideN .ui-dp-yearDropdown-button-nyear {
  display: none;
}

.ie6 .ui-dp-yearDropdown,
.ie7 .ui-dp-yearDropdown {
  width: 31px;
}

.ie6 .ui-dp-yearDropdown .ui-dp-yearDropdown-yearWrapper div,
.ie7 .ui-dp-yearDropdown .ui-dp-yearDropdown-yearWrapper div {
  height: 14px;
}

.ui-dp-month,
.ui-dp-year {
  display: inline;
  text-align: center;
  padding: 0 2px;
  font-weight: bold;
}

.ui-dp-table-wrapper {
  background-color: #fff;
}

.ui-dp-table {
  width: 100%;
  table-layout: fixed;
}

.ui-dp-table thead tr {
  background-color: #f4f4f4;
}

.ui-dp-calendar td {
  padding: 2px 5px;
  text-align: center;
  border: 1px solid #c8c8c8;
  cursor: pointer;
}

.ui-dp-calendar th {
  padding: 2px 4px;
  text-align: center;
  border: none;
  font-size: 9px;
  font-weight: normal;
}

.ui-dp-calendar .ui-dp-today {
  color: #fff;
  font-weight: bold;
  background-color: #af154b;
}

.ui-dp-calendar .ui-dp-restricted {
  cursor: default;
  font-weight: normal;
  text-decoration: line-through;
}

.ui-dp-calendar .ui-dp-selected-day {
  padding: 0;
}

.ui-dp-calendar .ui-dp-selected-day div {
  padding: 1px;
  border: 1px solid #af154b;
}

.ui-dp-calendar .ui-dp-day-hover {
  background-color: #e4e4e4;
}

.ui-dp-previousMonth,
.ui-dp-nextMonth {
  background-color: #d9d9d9;
}

.ui-datePicker-clickableImgContainer,
.ui-datePicker-clickableImgContainer_disabled {
  position: absolute;
  top: 1px;
  right: -3px;
  width: 18px;
  height: 17px;
  border: 0;
  cursor: pointer;
  z-index: 149;
}

.ie .ui-datePicker-clickableImgContainer,
.ie .ui-datePicker-clickableImgContainer_disabled {
  top: 3px;
  right: -3px;
  width: 17px;
}

.ui-datePicker-clickableImgContainer_disabled {
  z-index: -1;
}

.ui-datePicker-clickableImgContainer img {
  border: 0;
  margin: 0;
  padding: 0;
}

.ui-dp-ie6-mask {
  z-index: 999999;
  background: #fff;
}

.ui-datePicker2 {
  position: relative;
  width: 90px;
  display: inline-block;
  margin-right: 3px;
}

.ui-datePicker2--input {
  background: #fff url(https://www.absa.co.za.2009.ui/datePicker/datePicker.png)
    no-repeat right center scroll;
}

.ie8 .ui-datePicker2--input,
.ie7 .ui-datePicker2--input {
  height: 16px;
  padding-top: 2px;
}

.ui-datePicker2--input,
.ui-datePicker2--input_disabled {
  border: 1px solid #c8c8c8;
  width: 100%;
  height: 18px;
  padding-left: 3px;
}

.ui-datePicker2--input {
  background: #fff url(https://www.absa.co.za.2009.ui/datePicker/datePicker.png)
    no-repeat right center scroll;
}

.ui-datePicker2--input_disabled {
  background: #fff
    url(https://www.absa.co.za.2009.ui/datePicker/datePicker-grey.gif) no-repeat
    right center scroll;
}

.ui-datePicker2Root {
  width: 393px;
  z-index: 999999;
  position: absolute;
  left: -1000px;
  top: -1000px;
}

.ui-datePicker2Root .ui-dp-calendar {
  height: 124px;
}

.ui-datePicker2Root .ui-dp-control {
  width: 101px;
  height: 81px;
  border-top: 1px solid #c8c8c8;
  border-left: 1px solid #c8c8c8;
  background: none;
}

.ui-datePicker2Root .ui-dp-month {
  float: left;
  width: 33px;
  height: 20px;
  border-right: 1px solid #c8c8c8;
  border-bottom: 1px solid #c8c8c8;
  text-align: center;
  padding: 1 2px;
  font-weight: normal;
  background: white;
  cursor: pointer;
}

.ui-datePicker2Root .ui-dp-selected-month,
.ui-datePicker2Root .ui-dp-selected-day {
  color: #fff !important;
  font-weight: bold !important;
  background: #af154b !important;
}

.ui-datePicker2Root .ui-dp-calendar .ui-dp-today {
  background-color: #fff;
  color: #646464;
  font-weight: normal;
  border: 0;
}

.ui-datePicker2Root .ui-dp-calendar-grid-col1,
.ui-datePicker2Root .ui-dp-calendar-grid-col2,
.ui-datePicker2Root .ui-dp-calendar-grid-col3 {
  float: left;
  width: 100px;
  height: 100px;
}

.ui-datePicker2Root .ui-dp-calendar-grid-col1,
.ui-datePicker2Root .ui-dp-calendar-grid-col2 {
  margin-right: 4px;
}

.ui-datePicker2Root .ui-dp-calendar-grid-col3 {
  width: 162px;
}

.ui-datePicker2Root .ui-dp-table {
  width: auto;
}

.ui-datePicker2-clickableImgContainer,
.ui-datePicker2-clickableImgContainer_disabled {
  position: absolute;
  top: 1px;
  right: -3px;
  width: 18px;
  height: 17px;
  border: 0;
  cursor: pointer;
  z-index: 149;
}

.ie .ui-datePicker2-clickableImgContainer,
.ie .ui-datePicker2-clickableImgContainer_disabled {
  top: 3px;
  right: -3px;
  width: 17px;
}

.ui-datePicker2-clickableImgContainer_disabled {
  z-index: -1;
}

.ui-datePicker2-clickableImgContainer img {
  border: 0;
  margin: 0;
  padding: 0;
}

.ui-datePicker2Root .ui-dp-year {
  display: inline-block;
  font-weight: normal;
}

.ui-datePicker2Root .ui-dp-calendar-grid-col1 .years {
  width: 100px;
  height: 101px;
  display: inline-block;
  overflow-y: scroll;
  background: white;
  border: 1px solid #c8c8c8;
}

.ui-datePicker2Root .ui-dp-calendar-grid-col1 .year {
  width: 100%;
  height: 24px;
  line-height: 24px;
  padding-left: 2px;
  padding-right: 2px;
  text-align: center;
  border-bottom: 1px solid #c8c8c8;
  cursor: pointer;
}

.ui-datePicker2Root .ui-dp-calendar-grid-col1 .year-unusable {
  width: 150px;
  height: 30px;
  margin: 0 10px 7px;
  opacity: 0.3;
  display: inline-block;
  position: relative;
}

.ui-datePicker2Root .ui-dp-calendar-grid-col1 .ui-dp-selected-year {
  color: #fff;
  font-weight: bold;
  background: #af154b;
}

.ui-datePicker2Root .ui-dp-calendar .ui-dp-year-hover,
.ui-datePicker2Root .ui-dp-month-hover,
.ui-datePicker2Root .ui-dp-calendar .ui-dp-day-hover {
  background-color: #e4e4e4 !important;
}

.ui-datePicker2Root .ui-dp-calendar .ui-dp-selected-year,
.ui-datePicker2Root .ui-dp-calendar .ui-dp-selected-month,
.ui-datePicker2Root .ui-dp-calendar .ui-dp-selected-day div {
  padding: 1px;
  border: 1px solid #af154b;
}

.ui-radioGroup-horizontal .ui-radioItem {
  margin-right: 5px;
}

.ui-radioGroup-vertical .ui-radioItem {
  display: block;
}

.ui-radioItem .ui-radioItem--input {
  margin-right: 5px;
  vertical-align: text-top;
}

.ie7 .ui-radioItem .ui-radioItem--input,
.ie7 .ui-radioItem .ui-label {
  vertical-align: middle;
}

.ie7 .ui-radioItem {
  height: 30px;
}

.ui-radioGroup {
  text-align: left;
  display: inline-block;
  line-height: 30px;
  white-space: nowrap;
}
.no-color {
  background: white !important;
}

.ui-textBox {
  padding-left: 3px;
  background-color: #ffffdf;
  border: 1px solid #c8c8c8;
  border-radius: 4px 4px 4px 4px;
  -moz-border-radius: 4px 4px 4px 4px;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
}

.ui-textBox-multiLine {
  padding-left: 3px;
  border: 1px solid #c8c8c8;
  width: 203px;
  border-radius: 4px 4px 4px 4px;
  -moz-border-radius: 4px 4px 4px 4px;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
}

.ui-textBox-singleLine {
  height: 18px;
  padding-right: 3px;
  width: 200px;
  border-radius: 4px 4px 4px 4px;
  -moz-border-radius: 4px 4px 4px 4px;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
}

.ie8 .ui-textBox-singleLine,
.ie7 .ui-textBox-singleLine {
  height: 16px;
  padding-top: 2px;
  border-radius: 4px 4px 4px 4px;
  -moz-border-radius: 4px 4px 4px 4px;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
}

.ui-textBox-inCell {
  width: 100%;
  border-radius: 4px 4px 4px 4px;
  -moz-border-radius: 4px 4px 4px 4px;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
}

.ui-checkBox {
  margin-right: 5px;
  vertical-align: text-bottom;
  margin-left: 1px;
}

.ie7 .ui-checkBox {
  vertical-align: middle;
  margin-left: -3px;
}

.ui-suggestBox {
  border: 1px solid #c8c8c8;
  padding-left: 3px;
  padding-right: 3px;
  height: 18px;
  width: 200px;
  background: url(https://www.absa.co.za.2009.ui/suggestBox/suggestBox.gif)
    no-repeat right center scroll white;
  border-radius: 4px 4px 4px 4px;
  -moz-border-radius: 4px 4px 4px 4px;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
}

.ie8 .ui-suggestBox {
  height: 16px;
  padding-top: 2px;
}

.ui-suggestBox-list {
  border: 1px solid #999;
  background: #fff;
  display: none;
  padding: 0;
  position: absolute;
  z-index: 999999;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 179px;
}

.ui-suggestBox-item {
  position: relative;
  display: block;
  padding: 8px 25px 8px 5px;
  cursor: pointer;
  border-bottom: solid 1px #eee;
  text-decoration: none;
  white-space: nowrap;
  line-height: 1em;
  text-align: left;
}

.ui-suggestBox-match {
  font-weight: bold;
}

.ui-suggestBox-item-hover {
  background-color: #f4f4f4;
}

.ui-suggestBox-loading-message {
  height: 50px;
}

.ui-suggestBox-heading {
  background-color: #ddd;
  text-align: center;
  font-weight: bold;
}

.ui-suggestBox-ie6-mask {
  background: white;
  z-index: 1;
}

.ui-searchBox {
  position: relative;
  display: inline;
}

.ui-searchBox--input {
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: #c8c8c8;
  height: 18px;
  width: 180px;
  padding-left: 3px;
  padding-right: 20px;
}

.ie8 .ui-searchBox--input,
.ie7 .ui-searchBox--input {
  height: 16px;
  padding-top: 2px;
}

.ie7 .ui-searchBox--input {
  margin-top: 0;
}

.ui-searchBox--button,
ui-searchBox--button_reset {
  margin: 0;
  background: transparent
    url("https://ib.absa.co.za/absa-online/static/style/resources/icons-sprite-2019.png")
    no-repeat top left;
  background-position: -38px -973px;
}

.ui-searchBox--button_reset {
  background-position: -38px -1573px;
}

.ui-searchBox--button,
.ui-searchBox--button_reset {
  height: 15px;
  width: 22px;
  vertical-align: top;
  margin-left: 0;
  border-width: 0;
  border-style: solid;
  border-color: #c8c8c8;
  position: absolute;
  top: -1px;
  right: 1px;
}

.ui-searchBox .ui-button {
  border: 0 !important;
}

.ie7 .ui-searchBox--button,
.ie7 .ui-searchBox--button_reset {
  top: 4px;
  right: 2px;
}

.ui-searchBox--button_disabled {
  background: url("https://www.absa.co.za.2009.ui/searchBox/Grey-Arrow.png")
    no-repeat scroll right center transparent;
}

.android .ui-searchBox {
  border: 1px solid #c8c8c8;
  display: inline-block;
  background: white;
}

.android .ui-searchBox--input {
  border: none;
  width: 159px;
}

.android .ui-searchBox--button {
  right: 4px;
  width: 18px;
}

.android .ui-searchBox--button,
.android .ui-searchBox--button_reset {
  position: relative;
}

.ipad .ui-searchBox--button,
.ipad .ui-searchBox--button_reset {
  top: 2px;
}

.ui-searchBox--button_tick {
  background-position: -38px -1481px;
}

.ui-label--icon {
  display: inline-block;
}

.touch .ui-label--icon {
  display: inline;
}

.ui-header {
  padding: 6.5px 10px;
  background-color: #e5e5e5;
}

.ui-cell .ui-header {
  padding: 0;
}

.ui-link {
  text-decoration: underline;
  cursor: pointer;
  color: #870a3c !important;
}

.ui-select {
  border: 1px solid #c0c0c0;
  width: 208px;
  height: 20px;
  padding-top: 2px;
  vertical-align: bottom;
  border-radius: 4px 4px 4px 4px;
  -moz-border-radius: 4px 4px 4px 4px;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
}

.ie8 .ui-select {
  padding-top: 0;
  padding-bottom: 2px;
  border-radius: 4px 4px 4px 4px;
  -moz-border-radius: 4px 4px 4px 4px;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
}

.ie7 .ui-select {
  vertical-align: baseline;
  border-radius: 4px 4px 4px 4px;
  -moz-border-radius: 4px 4px 4px 4px;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
}

.ui-inputReadOnlyText {
  width: 202px;
  height: 20px;
  padding-left: 3px;
  padding-right: 3px;
  background-color: transparent;
  border: 0 solid #fff;
  text-align: right;
  color: #646464;
}

.ie8 .ui-inputReadOnlyText,
.ie7 .ui-inputReadOnlyText {
  height: 16px;
  padding-top: 4px;
}

.ui-inputReadOnlyText-multiline {
  height: 28px;
  text-align: right;
  overflow: hidden;
  resize: none;
}

.ie7 .ui-inputReadOnlyText-multiline,
.ie8 .ui-inputReadOnlyText-multiline {
  height: 25px;
  padding-top: 3px;
}

.ui-timePicker {
  width: 95px;
  border: 1px solid #c0c0c0;
  height: 20px;
  padding-top: 2px;
  vertical-align: bottom;
  border-radius: 4px 4px 4px 4px;
  -moz-border-radius: 4px 4px 4px 4px;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
}

.ie8 .ui-timePicker {
  padding-top: 0;
  padding-bottom: 2px;
}

.ie7 .ui-timePicker {
  vertical-align: baseline;
}

.ui-slider {
  height: 20px;
  display: inline-block;
  margin-right: 12px;
}

.ie7 .ui-slider {
  display: inline;
}

.ui-slider-rail {
  cursor: pointer;
  font-size: 0;
  height: 1px;
  position: relative;
  background: #c0c0c0;
  width: 128px;
}

.ui-slider--sliderContainer {
  display: inline-block;
  position: relative;
  height: 5px;
}

.ui-slider--sliderContainer-flipped {
  float: left;
  margin-top: 10px;
  margin-right: 10px;
}

.ie8 .ui-slider--sliderContainer {
  height: 3px;
}

.ie7 .ui-slider--sliderContainer,
.ie7 .ui-slider--inputContainer {
  display: inline;
  position: relative;
}

.ui-slider--inputContainer {
  display: inline-block;
  position: relative;
  height: 20px;
}

.ui-slider-rail-calc {
  position: relative;
  height: 10px;
  cursor: pointer;
  font-size: 0;
  background: #444;
  width: 120px;
}

.ui-slider-grippy,
.ui-slider-grippy_disabled {
  cursor: pointer;
  display: block;
  margin-left: 0;
  position: absolute;
  z-index: 0;
  height: 20px;
  width: 20px;
  top: -10px;
}

.ui-slider-grippy {
  background: url("https://www.absa.co.za.2009.ui/slider/grippy.png") no-repeat
    scroll right center transparent;
}

.ui-slider-grippy_disabled {
  background: url("https://www.absa.co.za.2009.ui/slider/grippy_grey.png")
    no-repeat scroll right center transparent;
  cursor: default;
  left: -5px;
}

.ui-slider-selectedArea {
  background: #999;
  cursor: pointer;
  font-size: 0;
  height: 10px;
  position: absolute;
  top: 0;
  display: none;
}

.ui-slider-grippy.pressed {
  cursor: e-resize;
}

.ui-slider--input,
.ui-slider--input_disabled {
  height: 18px;
  padding-right: 3px;
  width: 45px;
  padding-left: 3px;
  border: 1px solid #c8c8c8;
  margin-right: 10px;
}

.ie7 .ui-slider--input,
.ie7 .ui-slider--input_disabled {
  height: 16px;
  padding-top: 2px;
}

.ui-meter {
  width: 100%;
  background: url("https://www.absa.co.za.2009.ui/meter/gradient.png") repeat-x
    scroll left top #fff;
  height: 24px;
  border: 1px solid #c0c0c0;
}

.ui-meter--value {
  height: 24px;
}

.ui-meter--greenBar {
  background: url("https://www.absa.co.za.2009.ui/meter/greenBar.png") repeat-x
    scroll left top #fff;
}

.ui-meter--redBar {
  background: url("https://www.absa.co.za.2009.ui/meter/redBar.png") repeat-x
    scroll left top #fff;
}

.ui-fileUpload {
  width: 200px;
  height: 18px;
}

.ui-fileUpload--label {
  width: 100px;
  text-align: left;
}

.ui-fileUpload--input {
  width: 127px;
}

.ui-fileUpload--errorMessage {
  width: 127px;
}

.ui-fileUpload_loading {
  width: 20px;
  height: 20px;
  float: left;
  background: url(https://ib.absa.co.za/absa-online/static/style/resources/fileUpload-loading.gif)
    no-repeat scroll right;
}

.ui-fileUpload_success {
  width: 20px;
  height: 20px;
  float: left;
  background: url(https://ib.absa.co.za/absa-online/static/style/resources/fileUpload-success.png);
}

.ui-fileUpload_error {
  float: left;
  background: url(https://ib.absa.co.za/absa-online/static/style/resources/fileUpload-error.png);
  width: 22px;
  height: 22px;
}

.ui-fileUpload--cancelButton,
.ui-fileUpload--uploadButton,
.ui-fileUpload--clearButton {
  float: right;
}

.ui-fileUpload .ui-fileUpload--input,
.ui-fileUpload .ui-fileUpload--uploadButton {
  display: inline;
}

.ui-fileUpload .ui-fileUpload--label,
.ui-fileUpload .ui-fileUpload--cancelButton,
.ui-fileUpload .ui-fileUpload--clearButton,
.ui-fileUpload .ui-fileUpload--icon {
  display: none;
}

.ui-fileUpload_busy .ui-fileUpload--label,
.ui-fileUpload_busy .ui-fileUpload--cancelButton,
.ui-fileUpload_busy .ui-fileUpload--icon {
  display: inline;
}

.ui-fileUpload_busy .ui-fileUpload--input,
.ui-fileUpload_busy .ui-fileUpload--uploadButton,
.ui-fileUpload_busy .ui-fileUpload--clearButton {
  display: none;
}

.ui-fileUpload_finish .ui-fileUpload--label,
.ui-fileUpload_finish .ui-fileUpload--clearButton,
.ui-fileUpload_finish .ui-fileUpload--icon {
  display: inline;
}

.ui-fileUpload_finish .ui-fileUpload--input,
.ui-fileUpload_finish .ui-fileUpload--uploadButton,
.ui-fileUpload_finish .ui-fileUpload--cancelButton {
  display: none;
}

.ui-captchaCheck_container {
  background: #f4f4f4;
  width: 135px;
}

.ui-captchaCheck_img {
  vertical-align: middle;
  width: 132px;
  height: 55px;
}

.ui-captchaCheck_menu {
  position: relative;
  height: 24px;
  padding: 2px;
  border: 1px solid #c8c8c8;
}

.ui-captchaCheck_lnk {
  border-right: 1px solid #c8c8c8;
  border-left: 1px solid #c8c8c8;
}

.ui-captchaCheck_iframe {
  border: 0;
  margin: 0;
  width: 0;
  height: 0;
}

.ui-captchaCheck_input {
  position: relative;
  height: 24px;
  padding: 2px;
  border-right: 1px solid #c8c8c8;
  border-bottom: 1px solid #c8c8c8;
  border-left: 1px solid #c8c8c8;
}

.ui-captchaCheck_input .ui-captchaCheck-textBox {
  position: absolute;
  top: 4px;
  margin: 0 10%;
  width: 100px;
  height: 18px;
  line-height: 18px;
}

.ui-button.ui-captchaCheck--iconSound,
.ui-button.ui-captchaCheck--iconImage,
.ui-button.ui-captchaCheck--iconRefresh,
.ui-button.ui-captchaCheck--iconHelp {
  border: solid 1px #c8c8c8 !important;
}

.ui-captchaCheck--iconSound,
.ui-captchaCheck--iconImage,
.ui-captchaCheck--iconRefresh,
.ui-captchaCheck--iconHelp {
  position: absolute;
  border: solid 1px #c8c8c8 !important;
  width: 22px;
  height: 20px;
  margin: 2px 2px;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  background: transparent
    url("https://ib.absa.co.za/absa-online/static/style/resources/sprite-icons-captcha.png")
    no-repeat top left;
}

.ui-captchaCheck--iconSound {
  background-position: 0 -2px;
  top: 2px;
  right: 27px;
  cursor: pointer;
}

.ui-captchaCheck--iconImage {
  background-position: 1px -32px;
  top: 2px;
  right: 27px;
  cursor: pointer;
}

.ui-captchaCheck--iconRefresh {
  background-position: 1px -62px;
  top: 2px;
  right: 2px;
  cursor: pointer;
}

.ui-captchaCheck--iconHelp {
  background-position: 1px -94px;
  top: 2px;
  right: 2px;
  cursor: pointer;
}

.ui-captchaCheck--iconHide {
  display: none !important;
}

.ui-deck-leftButton {
  background: url("https://ib.absa.co.za/absa-online/static/style/resources/greyLeftPointer.JPG")
    no-repeat;
  width: 17px;
  height: 31px;
  float: left;
  margin-left: 8px;
  cursor: pointer;
}

.ui-deck-rightButton {
  background: url("https://ib.absa.co.za/absa-online/static/style/resources/greyRightPointer.JPG")
    no-repeat;
  width: 21px;
  height: 31px;
  float: right;
  margin-left: 8px;
  cursor: pointer;
}

.ui-deckItem {
  display: none;
  float: left;
  width: 100%;
}

.ui-deckItem-selected {
  display: block;
}

.ui-deck-navigationCell {
  width: 60px;
}

.ap-loading-message-div {
  width: 100%;
}

.ui-button {
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
  border: 1px solid #fa551f !important;
  border-radius: 4px 4px 4px 4px;
  -moz-border-radius: 4px 4px 4px 4px;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
}

.ui-button_disabled {
  display: inline-block;
  cursor: pointer;
  border-width: 0;
  background-color: transparent;
  vertical-align: middle;
  border: 1px solid #fa551f !important;
  border-radius: 4px 4px 4px 4px;
  -moz-border-radius: 4px 4px 4px 4px;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  cursor: no-drop;
}

.ui-button_disabled .ui-button-center {
  color: #959595;
}

.ui-button-left {
  padding-left: 10px;
}

.ui-button-right {
  padding-right: 10px;
}

.ui-button-center {
  line-height: 22px;
  font-weight: bold;
  font-size: 13px;
  font-family: sans-serif, Tahoma, "MS Trebuchet";
  white-space: nowrap;
}

.ap-button-large-grey .ui-button-left,
.ap-button-large-red .ui-button-left,
.ap-button-large-red-next .ui-button-left {
  padding-left: 10px;
}

.ap-button-small-grey .ui-button-left,
.ap-button-small-red .ui-button-left {
  padding-left: 10px;
}

.ap-button-large-grey .ui-button-right,
.ap-button-large-red .ui-button-right,
.ap-button-large-red-nextOLD .ui-button-right {
  padding-right: 10px;
}

.ap-button-small-grey .ui-button-right,
.ap-button-small-red .ui-button-right {
  padding-right: 10px;
}

.ap-button-large-grey .ui-button-center,
.ap-button-large-red .ui-button-center,
.ap-button-large-red-next .ui-button-center {
  line-height: 27px;
}

.ap-button-large-red-next {
  border-radius: 4px 20px 20px 4px;
  background-color: #870a3c;
  padding-right: 12px;
}

.ap-button-small-grey .ui-button-center,
.ap-button-small-red .ui-button-center {
  line-height: 20px;
  font-weight: normal;
  font-size: 12px;
}

.ui-button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.ie7 .ui-button {
  overflow: visible;
}

.ui-form {
  overflow: hidden;
}

.ui-exception-container {
  height: 30px;
  padding-right: 10px;
  padding-bottom: 1px;
  background-color: transparent;
  background-position: top left;
  background-repeat: repeat-x;
  border-bottom: 1px solid #c8c8c8;
}

.ui-exception-container .ui-button {
  margin-bottom: 1px;
}

.ie7 .ui-exception-container .ui-button {
  margin-top: 3px;
}

.ui-exception-container .ap-button-next,
.ui-exception-container .ap-button-finish,
.ui-exception-container .ap-button-extra,
.ui-modalFooter .ap-button-next,
.ui-modalFooter .ap-button-finish,
.ui-modalFooter .ap-button-extra {
  margin-left: 10px;
}

.ui-exception-container .ap-button-cancel,
.ui-exception-container .ap-button-reset,
.ui-exception-container .ap-button-back,
.ui-modalFooter .ap-button-cancel,
.ui-modalFooter .ap-button-reset,
.ui-modalFooter .ap-button-back {
  margin-right: 10px;
}

.ui-formFoot {
  border-top: 1px solid #ccc;
  background-color: white;
  text-align: right;
}

.ie6 .ui-formFoot {
  zoom: 1;
}

.ui-buttonFooter {
  line-height: 30px;
}

.ap-error-message {
  display: none;
}

.ui-incorrect-value.ui-form-field {
  border: 1px solid red;
  background-color: #fcd3d7;
}

input[type="checkbox"].ui-form-field.ui-incorrect-value {
  outline: 1px solid red;
}

.genericMessage-place-holder,
.ioErrorMessage-place-holder {
  display: none;
}

.ui-form-incorrect-values .genericMessage-place-holder,
.ui-form-ioError .ioErrorMessage-place-holder {
  display: block;
}

.ui-form-ioError .ui-buttonFooter .ap-button-back,
.ui-form-ioError .ui-buttonFooter .ap-button-reset,
.ui-form-ioError .ui-buttonFooter .ap-button-next {
  display: none;
}

.ui-accordion {
  border: 0 solid #c4c4c4;
}

.ui-accordionHead,
.ui-accordionHead-selected {
  height: 29px;
  background: #f4f4f4
    url("https://ib.absa.co.za/absa-online/static/style/resources/Plus-minus.png")
    no-repeat;
  background-repeat: no-repeat;
  padding-left: 29px;
  vertical-align: middle;
  border-bottom: 1px solid #c4c4c4;
}

.ui-accordionHead-content {
  height: 29px;
  line-height: 29px;
  overflow: hidden;
}

.ui-accordionHead {
  background-position: 7px 6px;
}

.ui-accordionHead-selected {
  background-position: 7px -28px;
}

.ui-accordionBody {
  display: none;
}

.ui-accordionBody-selected {
  display: block;
  border-bottom: 1px solid #c4c4c4;
  padding-left: 30px;
}

.ui-accordionBody-content {
  border-bottom: 0;
  border-left: 1px solid #c4c4c4;
}

.ui-accordion-hoverable .ui-accordionHead:hover,
.ui-accordion-hoverable .ui-accordionHead.hover,
.ui-accordion-hoverable .ui-accordionHead-selected:hover,
.ui-accordion-hoverable .ui-accordionHead-selected.hover {
  background-color: #e4e4e4 !important;
}

.ui-tabHeads {
  height: 25px;
  border-bottom: 1px solid #c8c8c8;
  background-color: #f4f4f4;
  padding-left: 3px;
  padding-top: 13px;
}

.ie6 .ui-tabHeads,
.ie7 .ui-tabHeads {
  display: inline-block;
}

.ui-tabHeads li {
  float: left;
  color: black;
  cursor: pointer;
  text-align: center;
  line-height: 16px;
  top: 1px;
  padding: 3px 11px 5px;
  background-color: #e1e1e1;
  border-top: 1px solid #c8c8c8;
  border-right: 1px solid #c8c8c8;
  border-left: 1px solid #c8c8c8;
  border-bottom: none;
  -moz-border-radius: 4px 4px 0 0;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -moz-user-select: none;
  -khtml-user-select: none;
}

li.ui-tab-disabled {
  cursor: pointer;
  opacity: 0.4;
  filter: alpha(opacity = 40);
}

li.ui-tab-first-tab,
.ui-tab-selected + li {
  background-image: none;
}

.ap-dashBoard-tabBox li.ui-tab-first-tab {
  padding-left: 0;
}

li.ui-tab-selected {
  background-color: #fff;
  font-weight: bold;
  cursor: default;
  padding-top: 4px;
  color: #847e7e;
}

li.ui-tab-selected-logon {
  background-color: #f4f4f4;
  font-weight: bold;
  cursor: default;
  padding-top: 0px;
  color: #847e7e;
  font-size: 14px;
  border: 0;
  border-bottom: 1px solid #c8c8c8;
}

.ie6 .ui-tabBodies,
.ie7 .ui-tabBodies {
  z-index: -1;
}

.ie6 .ui-tabBodies {
  zoom: 1;
}

.ui-tabBody {
  display: none;
}

.ui-tabBody-selected {
  display: block;
}

.ui-tabHeads-titlebar {
  position: absolute;
  right: 45px;
  top: 13px;
  border: 0;
}

.ui-tabHeads-titlebar li {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-image: url("https://ib.absa.co.za/absa-online/static/style/resources/sprite-dividers.gif");
  background-repeat: no-repeat;
  background-position: left -26px;
  line-height: 15px;
}

.ui-tabHeads-titlebar li {
  line-height: 11px;
  height: 14px;
}

.ui-tabHeads-titlebar li:first-child {
  background-image: none;
}

.ui-tabHeads-titlebar li.ui-tab-selected {
  margin-right: -2px;
  background-image: none;
  z-index: 100;
  padding-left: 10px;
  padding-right: 12px;
}

.ui-tabBodies-noborder {
  border: none;
}

.chartTab-dark li.ui-tab-selected {
  background-color: #f4f4f4;
}

.chartTab-dark .ui-tabBodies {
  background-color: #f4f4f4;
}

.chartTab-gradient .ui-tabHeads {
  padding-top: 3px;
}

.ui-tabBox--type2 .ui-tabHeads li {
  border-width: 1px 1px medium;
  float: left;
  line-height: 10px;
  text-align: center;
  top: 1px;
  border: none;
  padding: 0;
  padding-left: 10px;
  padding-right: 10px;
  color: #000;
  font-weight: bold;
  background-color: #fff;
}

.ui-tabBox--type2 .ui-tabHeads {
  background-color: #fff;
  border-bottom: 1px solid #640032;
}

.ui-tabBox--type2 li.ui-tab-selected {
  color: #af154b;
}

.ui-tabBox--type2 li.ui-tab-selected .ui-tabBox--type2--image {
  background: url("https://ib.absa.co.za/absa-online/static/style/resources/redPointer_2019.jpg")
    repeat-x 2px 2px;
  width: 23px;
  height: 9px;
  margin: 0 auto;
  margin-top: 6px;
}

.ui-tabBoxVertical {
  height: 212px;
  padding: 2px;
  border: 1px solid #c9c9c9;
  border-radius: 4px;
  -moz-border-radius: 4px 4px 4px 4px;
  -webkit-border-radius: 4px;
  -khtml-border-radius: 4px;
}

.ui-tabBoxVerticalHeads-container {
  padding: 0;
}

.ui-tabBoxVerticalHeads {
  height: 207px;
  margin-top: -2px;
  width: 186px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  -webkit-border-radius: 4px 0 0 4px;
  background: #870a3c;
}

.ui-tabBoxVerticalHead {
  line-height: 30px;
  vertical-align: middle;
}

.ui-tabBoxVerticalHead .ui-tabBoxVerticalHead-text {
  display: table-cell;
  vertical-align: middle;
  font-size: 11px;
  font-weight: bold;
  line-height: 14px;
  padding: 0 22px 5px 16px;
  background: url("https://ib.absa.co.za/absa-online/static/style/resources/logon-right-arrow-white_2019.png")
    no-repeat scroll 160px 11px transparent;
}

.ui-tabBoxVerticalHead .ui-tabBoxVerticalHead-text {
  color: #fff;
}

.ui-tabBoxVerticalHead_active .ui-tabBoxVerticalHead-text {
  background: none;
  color: #af154b;
}

.ui-tabBoxVerticalBodies {
  -moz-border-radius: 0 4px 4px 0;
  border: 1px solid #c9c9c9;
  margin-top: -3px;
  width: 100%;
}

.ui-tabBoxVertical-gap {
  padding: 4px;
}

.ui-tabBoxVerticalBody {
  display: none;
}

.ui-tabBoxVerticalBody_active {
  display: block;
  height: 210px;
  vertical-align: top;
}

.ie .ui-tabBoxVerticalHeads {
  margin-top: 3px;
  margin-left: 3px;
}

.ui-tabBoxVerticalHead {
  cursor: pointer;
  padding-top: 4px;
}

.ui-tabBoxVertical-pager {
  position: absolute;
  top: 174px;
  left: 13px;
  color: #fff;
}

.ui-tabBoxVertical-pager a {
  color: #fff;
  cursor: pointer;
}

.ui-tabBoxVertical-table-header {
  font-weight: bold;
}

.ui-tabBoxVerticalHead-content {
  background: transparent;
  display: table;
  width: 100%;
  height: 37.5px;
}

.ie7 .ui-tabBoxVerticalHead-content {
  display: block;
  position: relative;
  width: 100%;
}

.ui-tabBoxVerticalHead_active .ui-tabBoxVerticalHead-content {
  background: url("https://ib.absa.co.za/absa-online/static/style/resources/verticalTab-roundCorner-redWhite.gif")
    no-repeat scroll 5px 0 transparent;
  display: table;
  width: 100%;
}

.ie7 .ui-tabBoxVerticalHead_active .ui-tabBoxVerticalHead-content {
  display: block;
  position: relative;
  width: 100%;
}

.ie8 .ui-tabBoxVerticalHead .ui-tabBoxVerticalHead-text {
  height: 31.5px;
}

.ie7 .ui-tabBoxVerticalHead .ui-tabBoxVerticalHead-text,
.ie7 .ui-tabBoxVertical-text-content {
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  cursor: pointer;
}

.ie7 .ui-tabBoxVerticalHead .ui-tabBoxVerticalHead-text {
  height: 36px;
}

.ie7 .ui-tabBoxVerticalHead .ui-tabBoxVerticalHead-right {
  margin-top: -1px;
}

.ie7 .ui-tabBoxVertical-text-content {
  top: expression(
    this.parentElement.parentElement.clientHeight / 2 - this.clientHeight / 2 -
      3
  );
  width: 140px;
  left: 14px;
}

.ui-tabBoxVertical-body-end:focus,
.ui-tabBoxVertical-tab-end:focus {
  outline: none;
}

.ui-wizard {
  clear: both;
}

.ie7 .ui-wizard {
  position: static;
}

.ui-wizard-head-wrapper {
  margin: 0;
  height: 30px;
  text-align: right;
  border-bottom: 1px solid #c8c8c8;
  position: relative;
}

.ie6 .ui-wizard-head-wrapper,
.ie7 .ui-wizard-head-wrapper {
  display: inline-block;
  position: static;
}

.ui-wizard-head {
  font-weight: bold;
  -moz-user-select: none;
  -khtml-user-select: none;
  display: none;
  padding-right: 5px;
}

.ui-wizard-description {
  left: 10px;
  position: absolute;
  top: 8px;
  font-weight: bold;
}

.ui-wizard-selected {
  display: block;
  color: #646464;
}

.ui-wizard-head.ui-wizard-selected .ui-wizard-step-name {
  font-weight: bold !important;
}

.ui-wizard-classic .ui-wizard-selected {
  display: block;
  font-weight: bold !important;
}

.ui-wizard-titles {
  line-height: 26px;
  height: 26px;
  display: inline-block;
  float: left;
  padding-left: 3px;
}

.ie7 .ui-wizard-titles {
  display: inline-block;
  float: left;
}

.ie .ui-wizard-titles {
  line-height: 26px;
}

.ui-wizard-heads {
  text-align: right;
  height: 26px;
  margin-top: 1px;
  margin-right: 3px;
  padding-left: 5px;
  border: 1px solid white;
  position: relative;
}

.ie7 .ui-wizard-heads {
  display: inline;
  float: right;
}

.ie6 .ui-wizard-head {
  position: static;
}

.ui-wizard-step-name {
  font-weight: normal;
}

.ui-wizard-first,
.ui-wizard-middle,
.ui-wizard-last {
  padding: 0 10px;
  border-top: 1px solid #c8c8c8;
  border-bottom: 1px solid #c8c8c8;
  width: 100px;
  text-align: left;
  position: relative;
}

.ui-wizard-first {
  border-left: 1px solid #c8c8c8;
  -moz-border-radius: 4px 0 0 4px;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
}

.ui-wizard-last {
  border-right: 1px solid #c8c8c8;
  -moz-border-radius: 0 4px 4px 0;
  -webkit-border-top-right-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
}

.ui-wizard-selected .ui-wizard-first,
.ui-wizard-selected .ui-wizard-middle,
.ui-wizard-selected .ui-wizard-last {
  background-color: #aaa;
  color: #fff;
}

.ui-wizard-arrow,
.ui-wizard-selected .ui-wizard-arrow,
.ui-wizard-selected .ui-wizard-tail {
  position: absolute;
  height: 19px;
  width: 11px;
  top: 0;
}

.ui-wizard-arrow {
  right: -5px;
  background-image: url("https://www.absa.co.za.2009.ui/wizard/triangle-notselected.gif");
  z-index: 1;
}

.ui-wizard-selected .ui-wizard-arrow {
  right: -5px;
  background-image: url("https://www.absa.co.za.2009.ui/wizard/triangle-selected.gif");
  z-index: 2;
}

.ui-wizard-selected .ui-wizard-tail {
  background-image: url("https://www.absa.co.za.2009.ui/wizard/triangle-noselected-selected.gif");
  left: -6px;
  z-index: 2;
}

.ui-wizard-body-wrapper {
  clear: both;
}

.ie6 .ui-wizard-body-wrapper,
.ie7 .ui-wizard-body-wrapper {
  z-index: -1;
}

.ui-wizard-body {
  display: none;
}

.ui-wizard-body-selected {
  display: block;
}

.ie6 .ui-wizard-body-selected {
  zoom: 1;
}

.ui-wizard-buttons {
  float: right;
  margin-left: -1px;
}

.ui-wizard-buttons .ui-button {
  margin-top: 2px;
}

.ie7 .ui-wizard-buttons {
  float: left;
}

.ui-wizard--showMeHow {
  margin: 2px 2px;
}

.ui-wizard-classic .ui-wizard-selected,
.ui-wizard-classic .ui-wizard-head {
  display: inline;
}

.ui-wizard-classic .ui-wizard-titles,
.ui-wizard-classic .ui-wizard-heads {
  float: right;
}

.ui-modalWindow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.2;
  z-index: 100;
}

.ui-modal-standard {
  width: 876px;
}

.ui-modal-genericButton,
.ui-modal-closeButton,
.ui-modal-printButton {
  width: 24px;
  height: 24px;
  display: inline-block;
  border-radius: 2px;
  cursor: pointer;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  background: transparent
    url("https://ib.absa.co.za/absa-online/static/style/resources/icons-sprite-2019.png")
    no-repeat top left;
  margin: 4px 2px;
  border: 0;
}

.ie7 .ui-modal-closeButton,
.ie7 .ui-modal-printButton {
  display: inline;
}

.ui-modal-closeButton {
  background-position: -8px -1148px;
}

.ui-modal-printButton {
  background-position: -8px -68px;
}

.ui-modal-iframe {
  display: none;
}

.ie6 .ui-modal-iframe {
  display: inline;
  filter: alpha(opacity = 0);
  width: 100%;
  height: 100%;
}

.ui-modal-content {
  position: absolute;
  left: 50%;
  z-index: 121;
  background-color: #fff;
  top: 56%;
  border-radius: 8px 8px 0 0;
  -moz-border-radius: 8px 8px 0 0;
  -webkit-border-top-left-radius: 8px;
  -webkit-border-top-right-radius: 8px;
}

.ui-modalFooter {
  text-align: center;
  height: 30px;
  margin: 3px;
  padding-right: 3px;
  background-color: transparent;
  background-position: top left;
  background-repeat: repeat-x;
}

.ui-modalFooter .ui-button {
  margin-top: 4px;
}

.ui-modalHeader {
  padding: 10px;
  line-height: 11px;
  height: 11px;
  background: #870a3c;
  border-radius: 8px 8px 0 0;
  -moz-border-radius: 8px 8px 0 0;
  -webkit-border-top-left-radius: 8px;
  -webkit-border-top-right-radius: 8px;
}

.ui-modalHeader-title {
  font-weight: bold;
  color: #fff;
}

.ui-modalHeader-icons {
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 4px;
}

.ui-modalBody {
  border-width: 1px 0;
  border-color: #870a3c;
  border-width: 0 4px 4px 4px;
}

.ui-noticeBox {
  padding: 8px;
  margin: 3px 3px 3px;
}

.ui-noticeBox li {
  list-style: disc outside none;
  margin-left: 13px;
}

.ui-noticeBox .no-disc {
  list-style: none outside none;
}

.ui-noticeBox b,
.ui-noticeBox .bold {
  line-height: 20px;
  color: #646464;
}

.ui-message {
  margin-top: 3px;
  margin-bottom: 3px;
  padding-right: 8px;
  padding-left: 3px;
  background-color: white;
  text-align: left;
  line-height: 30px;
  overflow: hidden;
  border-radius: 4px 4px 4px 4px;
  -moz-border-radius: 4px 4px 4px 4px;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
}

.ui-message .ui-message-title {
  font-weight: bold;
  margin-right: 6px;
  display: inline;
}

.ie6 .ui-message-title,
.ie7 .ui-message-title {
  zoom: 1;
}

.ie7 .ui-message .ui-message-body .ui-message-multipleMessages {
  display: inline;
}

.ui-message .ui-message-body .ui-message-multipleMessages {
  line-height: 15px;
  display: block;
}

.ui-message .ui-message-body {
  display: inline;
}

.ie6 .ui-message-body,
.ie7 .ui-message-body {
  zoom: 1;
}

.ui-message .ui-message-timeStamp {
  font-weight: normal;
  float: right;
  line-height: normal;
  padding-top: 9px;
}

.ui-message-content {
  line-height: normal;
  padding-left: 30px;
  padding: 9px;
}

.ui-message-iip {
  padding-left: 5px;
  padding-right: 5px;
  color: #777;
  font-weight: normal;
  line-height: normal;
  padding-top: 5px;
  white-space: nowrap;
}

.ui-message-iip a {
  margin-right: 10px;
  margin-left: 10px;
  text-decoration: underline;
  color: #777;
}

.ui-message .ui-message--icon {
  float: left;
  margin: 5px;
  width: 22px;
  height: 22px;
}

.ui-message .ui-message-warning-small {
  border: 3px solid green;
  color: green;
}

.ui-message.ui-message-error {
  border: 3px solid #c33;
  background-color: #fef2f2;
  margin: 4px;
}

.ui-message-error .ui-message--icon {
  background-image: url("https://ib.absa.co.za/absa-online/static/style/resources/fileUpload-error.png");
}

.ui-message.ui-message-warning {
  border: 3px solid #f28f06;
  background-color: #fefbf7;
  margin: 4px;
}

.ui-message-warning .ui-message--icon {
  background-image: url("https://ib.absa.co.za/absa-online/static/style/resources/message-icon-warning_2019.png");
}

.ui-message.ui-message-success {
  border: 3px solid #b3e2c5;
  background-color: #59bf75;
  margin: 4px;
  color: white;
}

.ui-message-success .ui-message--icon {
  background-image: url("https://ib.absa.co.za/absa-online/static/style/resources/message-icon-success_2019.png");
}

.ui-message.ui-message-help {
  border: 3px solid #2f9fff;
  background-color: #f4faff;
  margin: 4px;
}

.ui-message-help .ui-message--icon {
  background-image: url("https://ib.absa.co.za/absa-online/static/style/resources/message-icon-help_2019.png");
}

.ui-message.ui-message-noAuth {
  background-color: RGB(233, 233, 233);
  color: #777;
}

.ui-message-tip .ui-message--icon {
  background-image: url("https://ib.absa.co.za/absa-online/static/style/resources/message-icon-noAuth_2019.png");
}

.ui-message-error .ui-link,
.ui-message-success .ui-link {
  color: white;
}

.ui-message-warning .ui-link,
.ui-message-help .ui-link {
  color: #646464;
}

.ui-informationBox {
  width: 100%;
  height: 100px;
}

.ui-informationBox--header {
  background-color: #e5e5e5;
  height: 20px;
  padding: 10px;
  font-size: 13pt;
  border-bottom: 1px solid #c0c0c0;
  position: relative;
}

.ui-informationBox--headerContent {
  position: relative;
  overflow: hidden;
  right: 0;
}

.ui-informationBox--container {
  height: 100%;
  position: relative;
  top: 0;
}

.ui-informationBox--text {
  padding: 10px;
  vertical-align: top;
}

.ui-informationBox--content {
  height: 100%;
  position: absolute;
  width: 100%;
}

.ui-informationBox--table {
  width: 100%;
  height: 100%;
}

.ui-informationBox--scrollContainer {
  bottom: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  position: absolute;
  top: 0;
  width: 100%;
  margin-top: 41px;
}

.ie7 .ui-informationBox--scrollContainer {
  overflow-x: hidden;
  overflow-y: scroll;
}

.ui-informationBox--scrollBar {
  width: 2px;
  border-left: 1px solid #c0c0c0;
  padding: 0;
}

.ie7 .ui-informationBox--scrollBar {
  width: 18px;
}

.ui-graph .ui-graph-buttons {
  height: 30px;
  line-height: 30px;
}

.ui-graph .ap-icon-tools {
  float: left;
  margin-left: 5px;
}

.ui-graph .ui-graph-updateButton-text {
  margin-left: 5px;
  line-height: 26px;
}

.ui-graph .ap-icon-barChart {
  float: right;
  margin-right: 5px;
}

.ui-graph .ap-icon-pieChart,
.ui-graph .ap-icon-3dPieChart {
  float: right;
}

.ui-graph .ui-graph-paper {
  height: 150px;
  clear: both;
  background-color: #fff;
  border-top: 1px solid #c8c8c8;
  overflow: hidden;
}

.ui-graph .ui-verticalBarGraph-paper {
  height: 200px;
  clear: both;
  background-color: #fff;
  overflow: hidden;
}

.ui-graph .ui-donutgraph-paper {
  height: 250px;
  clear: both;
  background-color: #fff;
  overflow: hidden;
}

.ie6 .ui-graph .ui-graph-paper div {
  width: 100% !important;
}

.ui-keypad-input-selected {
  background-color: #ffffdf;
}

.ui-keypad-padContainer {
  background: url(https://www.absa.co.za.2009.ui/keypad/keypad-bg.gif) no-repeat
    0 0;
  width: 81px;
  height: 125px;
  padding: 2px;
}

.ui-keypad-padContainer p {
  text-align: center;
  font-weight: bold;
  padding-top: 3px;
  padding-bottom: 3px;
}

.ui-keypad-padContainer table {
  border: none;
  border-collapse: collapse;
}

.ui-keypad .ui-keypad-padContainer td {
  padding: 3px;
  border: none;
}

.ui-keypad-padContainer .ui-keypad-button {
  width: 20px;
  height: 20px;
  background: url(https://www.absa.co.za.2009.ui/https://ib.absa.co.za/absa-online/static/style/resources/key-button.gif)
    no-repeat 0 0;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
  line-height: 20px;
}

.ui-keypad-padContainer .ui-keypad-button_active {
  background-position: -20px 0;
}

.ui-keypad-padContainer .ui-keypad-button .backspace {
  background: url(https://www.absa.co.za.2009.ui/https://ib.absa.co.za/absa-online/static/style/resources/keypad-backspace.png)
    no-repeat center center;
  height: 20px;
  width: 20px;
}

.ie6 .ui-keypad-padContainer .ui-keypad-button .backspace {
  background-image: url(https://www.absa.co.za.2009.ui/https://ib.absa.co.za/absa-online/static/style/resources/keypad-backspace.gif);
}

.touch .ui-keypad-padContainer {
  display: none;
}

.ui-keyboard-input-selected {
  background-color: #ffffdf;
}

.ui-keyboard-keyboardContainer {
  background: url(https://www.absa.co.za.2009.ui/keyboard/keyboard-bg.gif)
    no-repeat 0 0;
  width: 358px;
  height: 110px;
  padding: 3px;
}

.ui-keyboard-keyboardContainer-row3 {
  padding-left: 26px;
}

.ui-keyboard-keyboardContainer-row4 {
  padding-left: 52px;
}

.ui-keyboard-keyboardContainer .ui-keyboard-button {
  float: left;
  width: 20px;
  height: 20px;
  background: url(https://www.absa.co.za.2009.ui/https://ib.absa.co.za/absa-online/static/style/resources/key-button.gif)
    no-repeat 0 0;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
  line-height: 20px;
  margin: 3px;
}

.ui-keyboard-keyboardContainer .ui-keyboard-button_active {
  background-position: -20px 0;
}

.ui-keyboard-keyboardContainer .ui-keyboard-button.util {
  float: none;
  width: 86px;
  height: 20px;
  font-weight: bold;
  background: url(https://www.absa.co.za.2009.ui/https://ib.absa.co.za/absa-online/static/style/resources/key-button-long.gif)
    no-repeat 0 0;
  margin-top: 3px;
  margin-bottom: 6px;
}

.ui-keyboard-keyboardContainer .ui-keyboard-button_active.util {
  background-position: -86px 0;
}

.ie6 .ui-keyboard-keyboardContainer .ui-keyboard-button.util {
  margin-right: 0;
}

.ui-keyboard-keyboardContainer .ui-keyboard-button .backspace {
  background: url(https://www.absa.co.za.2009.ui/https://ib.absa.co.za/absa-online/static/style/resources/keypad-backspace.png)
    no-repeat center center;
  display: inline-block;
  height: 10px;
  width: 14px;
}

.ie7 .ui-keyboard-keyboardContainer .ui-keyboard-button .backspace {
  display: inline;
  height: 15px;
}

.ie6 .ui-keyboard-keyboardContainer .ui-keyboard-button .backspace {
  display: inline;
  height: 17px;
  background-image: url(https://www.absa.co.za.2009.ui/https://ib.absa.co.za/absa-online/static/style/resources/keypad-backspace.gif);
}

.ui-keyboard-keyboardContainer .caps-on {
  color: #af154b;
}

.ui-keyboard-keyboardContainer .floatleft {
  float: left;
}

.ui-keyboard .floatright {
  float: right;
}

.ui-keyboard .clearfix {
  width: auto;
  overflow: hidden;
}

.ui-keyboard .clearBoth {
  clear: both;
}

.touch .ui-keyboard-keyboardContainer {
  display: none;
}

.ui-axisTable {
  width: 537px;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
}

.ui-axisTableHead,
.ui-axisTableBody {
  border-collapse: collapse;
}

.ui-axisTable--sortHandle .ui-axisTableHeadCell--text {
  padding-right: 1px;
}

.ie .ui-axisTable--sortHandle .ui-axisTableHeadCell--text {
  display: inline-block;
}

.ui-axisTableHeadCell,
.ui-axisTableBodyCell,
.ui-axisTableBody {
  border-color: #ccc;
  border-style: solid;
}

.ui-axisTableCell {
  cursor: pointer;
}

.ui-axisTableBody {
  border-color: #ccc;
  border-style: solid;
  border-width: 0 0 1px;
}

.ui-axisTableHeadCell {
  height: 15px;
  overflow: hidden;
  border-width: 0 1px 1px 0;
  background-color: #e5e5e5;
  text-decoration: none;
  font-weight: 500;
  text-align: center;
}

.ui-axisTableHeadCell-rearrangeTable,
.ui-axisTableBodyCell-rearrangeTable {
  border-left: 0;
}

.ui-axisTableBodyCell {
  border-width: 0 1px 0 0;
  padding: 0;
  height: 30px;
  text-align: center;
}

.ui-axisTableBodyCell--noPadding {
  padding: 0;
}

.ui-axisTable-last-Column {
  border-right-width: 0;
}

.ui-axisTableBodyCell .align-right {
  text-align: right;
}

.ui-axisTableBodyCellContent {
  padding: 0 5px;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  word-wrap: break-word;
}

.ui-axisTableBodyCell--noPadding .ui-axisTableBodyCellContent {
  padding: 0;
}

.ui-axisTableHeadCell .ui-axisTableHeadCell--content {
  display: block;
  padding: 6.5px 5px;
  height: 100%;
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-color: transparent;
  word-wrap: break-word;
}

.ui-axisTableHeadCell .ui-axisTableHeadCell--content:focus {
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-color: #ccc;
  outline: 0;
}

.ui-axisTableHeadCell .ui-axisTableHeadCell--text,
.ui-axisTableHeadCell .ui-axisTableHeadCell--addition {
  line-height: 35px;
  display: inline;
}

.ui-axisTableHeadCellAddition {
  padding-right: 10px;
}

.ui-axisTableBodyCellContent:focus {
  border-width: 0 0 1px 0;
  border-style: dotted;
  border-color: #ccc;
  outline: 0;
}

.ui-axisTableHead--gapFiller,
.ui-axisTable--gapFiller {
  width: 100%;
}

.ui-axisTableHead--gapFiller {
  border-width: 0 0 1px 1px;
}

.ui-axisTable--gapFiller {
  border-width: 0 0 0 1px;
  padding: 0;
  background-color: white;
}

.ui-axisTable--scrollContainer {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  border-width: 0;
}

.ui-sortableTable-border {
  border: solid #ccc;
  border-width: 0 1px 1px 1px;
}

.ui-axisTableBodyRow--rowAlternate {
  background-color: #f4f4f4;
}

.ui-axisTable-hoverable .ui-axisTableBodyRow:hover,
.ui-axisTable-hoverable .ui-axisTableBodyRow.hover,
.ui-axisTableHeadCell .ui-axisTableHeadCell--content:hover,
.ui-axisTableHeadCell .ui-axisTableHeadCell--content.hover {
  cursor: pointer;
}

.ui-sortableTable {
  empty-cells: show;
}

.ui-axisTable--sortHandle {
  text-align: inherit;
  color: #646464;
  overflow: hidden;
  background: transparent
    url("https://ib.absa.co.za/absa-online/static/style/resources/sort-arrows_2019.png")
    no-repeat right -53px;
}

.align-right .ui-axisTable--sortHandle,
.align-right .ui-axisTable--sortHandleClientSide {
  background-position: left -45px;
}

.sorted-ASC {
  background: transparent
    url("https://ib.absa.co.za/absa-online/static/style/resources/sort-arrows_2019.png")
    no-repeat right -114px;
}

.sorted-DESC {
  background: transparent
    url("https://ib.absa.co.za/absa-online/static/style/resources/sort-arrows_2019.png")
    no-repeat right 8px;
}

.align-right .sorted-ASC {
  background-position: left -108px;
}

.align-right .sorted-DESC {
  background-position: left 7px;
}

.ui-expandableTable-firstHead {
  width: 14px;
}

.ui-col-small-icon .ui-axisTableBodyCellContent,
.ui-col-small-icon .ui-axisTableHeadCell--text {
  text-align: center;
  width: 14px;
}

.ui-col-radio,
.ui-col-checkbox {
  text-align: center;
  width: 39px;
}

.ui-axisTableBodyCellContent-radioButton {
  text-align: center;
  width: 33px;
}

.ui-axisTableBodyCellContent-rearrangeButtons {
  width: 80px;
}

.ui-col-small-icon .ui-axisTableHeadCell--text {
  padding: 0 7px;
}

.ui-expandableTable-icon-expanded,
.ui-expandableTable-icon {
  display: inline-block;
  width: 16px;
  height: 22px;
  background: transparent
    url("https://ib.absa.co.za/absa-online/static/style/resources/Plus-minus.png")
    no-repeat;
  background-position: 0 4px;
  overflow: hidden;
}

.ui-expandableTable-icon-expanded {
  background-position: 0 -31px;
}

.ie7 .ui-expandableTable-icon-expanded {
  width: 14px;
  background-position: -1px -31px;
}

.ie7 .ui-expandableTable-icon {
  width: 14px;
  background-position: -1px 4px;
}

.ui-axisTable-alternate-row .ui-expandableTable-icon {
  background-position: -12px -12px;
}

.ui-axisTable-alternate-row .ui-expandableTable-icon-expanded {
  background-position: -12px 0;
}

.ui-expandableTable .ui-col-icon.ui-axisTableHeader--cell .ui-col-content {
  background-color: #e2e2e2 !important;
}

.ui-expandable-row .ui-expandedDetail-view,
.ui-expanded-row .ui-expandedDetail-view {
  cursor: pointer;
}

.ui-axisTableBodyRowExpandableExtension_hidden {
  display: none;
}

.ui-expandable-disabled-row .ui-icon,
.ui-expandable-disabled-row .ui-expandableTable-icon,
.ui-expanded-disabled-row .ui-icon,
.ui-expanded-disabled-row .ui-expandableTable-icon-expanded {
  display: none;
}

.ui-expandable-disabled-message {
  display: none;
}

.ui-expandable-disabled-row .ui-expandable-disabled-message,
.ui-expanded-disabled-row .ui-expandable-disabled-message {
  display: block;
}

.ui-expandableTable .ui-axisTableBodyRowExpandableExtension--expandedContent {
  border: 1px solid #c0c0c0;
}

.ui-scroller-table {
  width: 100%;
  overflow: hidden;
  background-color: #fff;
}

.ui-rearrangeTable-hint,
.ui-rearrangeTable-icons {
  display: none;
}

.ui-rearrangeTable
  .ui-axisTable--scrollContainer
  table
  .ui-axisTableBodyCellContent {
  position: relative;
  overflow: visible;
}

.ui-rearrangeTable-hint {
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
}

.ui-rearrangeTable-icons {
  position: absolute;
  right: 6px;
  top: -12px;
}

.ui-rearrangeTable_showRow {
  background-color: #e4e4e4;
}

.ui-rearrangeTable_showRow .ui-rearrangeTable-hint,
.ui-rearrangeTable_showRow .ui-rearrangeTable-icons {
  display: block;
}

.ui-rearrangeTable_showRow .ui-rearrangeTable-icons .ap-icon-upSharePrice,
.ui-rearrangeTable_showRow .ui-rearrangeTable-icons .ap-icon-downSharePrice {
  margin: 0 2px;
}

.ui-rearrangeTable_showRowFirst .ui-rearrangeTable-icons .ap-icon-upSharePrice {
  display: none;
}

.ui-rearrangeTable_showRowLast
  .ui-rearrangeTable-icons
  .ap-icon-downSharePrice {
  display: none;
}

.ui-rearrangeTable .ui-axisTable--scrollContainer .ui-rearrangeTable-drag {
  cursor: move;
}

.ui-rearrangeTable .ui-axisTableHeadCell,
.ui-rearrangeTable .ui-axisTableBodyCell {
  border-right-width: 0;
  border-left-width: 1px;
}

.ui-rearrangeTable .ui-axisTableHeadCell--rearrangeRadio,
.ui-rearrangeTable .ui-axisTableBodyCell--rearrangeRadio {
  border-left-width: 0;
}

.ap-table-loadingMessage .ap-loadingMessage-overlay {
  position: relative;
}

.touch .ui-rearrangeTable .ui-rearrangeTable-hint {
  display: none;
}

.touch .ui-axisTableBodyRow-loadMore td {
  background-color: white;
  padding: 5px;
}

.touch .ui-axisTable-loadMore {
  width: 100%;
  border: medium none;
  height: 20px;
  background-color: #c8c8c8;
  color: #646464;
  text-align: center;
  padding: 5px;
}

.ui-axisTableLegend {
  background-color: #e5e5e5;
  color: #000;
  border-top: 1px solid #ccc;
}

.ui-axisTable .hlt,
.ui-axisTable .hlt-head {
  color: #fff;
  font-weight: normal;
  background-color: #b4312e;
}

.ui-axisTableLegend.hlt-head {
  background: url("https://www.absa.co.za.2009.ui/axisTable/axisTable-selected-row-b.gif")
    no-repeat left transparent;
  border-right: 1px solid #fefacd !important;
}

.ui-axisTableHeadCell.hlt-head {
  background: url("https://www.absa.co.za.2009.ui/axisTable/axisTable-selected-col-b.gif")
    no-repeat center transparent;
  background-color: #fefacd;
  border-bottom: 1px solid #fefacd !important;
}

.ui-axisTable .hlt-head {
  border: 1px solid #ccc;
  font-weight: bold !important;
}

.ui-axisTable .hlt-col {
  background-color: #fefacd;
  color: #646464;
  border-top: 1px solid #f4f4f4;
}

.ui-axisTable .hlt {
  background-color: #fefacd;
  color: #646464;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.ui-axisTable .hlt .hlt-col {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  background-color: #b3e2c5 !important;
  color: #646464 !important;
  font-weight: bold;
  font-size: 13pt;
}

.ui-axisTableBodyCell:hover {
  background: #e1e7f3;
  color: #646464;
}

.ui-axisTableHeadCell:hover,
.ui-axisTableLegend:hover {
  background: #f4f4f4;
  color: #646464;
}

.ui-tableHead,
.ui-tableBody {
  border-collapse: collapse;
}

.ui-table--sortHandle .ui-tableHeadCell--text {
  padding-right: 1px;
}

.ie .ui-table--sortHandle .ui-tableHeadCell--text {
  display: inline-block;
}

.ui-tableHeadCell,
.ui-tableBodyCell,
.ui-tableBody {
  border-color: #ccc;
  border-style: solid;
}

.ui-tableBody {
  width: 100%;
  border-color: #ccc;
  border-style: solid;
  border-width: 0 0 1px;
}

.ui-tableHeadCell {
  height: 15px;
  overflow: hidden;
  border-width: 0 1px 1px 0;
  background-color: #e5e5e5;
  text-decoration: none;
  font-weight: 500;
}

.ui-tableHeadCell-rearrangeTable,
.ui-tableBodyCell-rearrangeTable {
  border-left: 0;
}

.ui-tableBodyCell {
  border-width: 0 1px 0 0;
  padding: 0 5px;
  height: 30px;
}

.ui-tableBodyCell--noPadding {
  padding: 0;
}

.ui-table-last-Column {
  border-right-width: 0;
}

.ui-tableBodyCell .align-right {
  text-align: right;
}

.ui-tableBodyCellContent {
  padding: 0 5px;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: transparent;
  word-wrap: break-word;
}

.ui-tableBodyCell--noPadding .ui-tableBodyCellContent {
  padding: 0;
}

.ui-tableHeadCell .ui-tableHeadCell--content {
  display: block;
  padding: 6.5px 10px;
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-color: transparent;
  word-wrap: break-word;
}

.ui-tableHeadCell .ui-tableHeadCell--content:focus {
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-color: #ccc;
  outline: 0;
}

.ui-tableHeadCell .ui-tableHeadCell--text,
.ui-tableHeadCell .ui-tableHeadCell--addition {
  line-height: 13px;
  display: inline;
}

.ui-tableHeadCellAddition {
  padding-right: 10px;
}

.ui-tableBodyCellContent:focus {
  border-width: 0 0 1px 0;
  border-style: dotted;
  border-color: #ccc;
  outline: 0;
}

.ui-tableHead--gapFiller,
.ui-table--gapFiller {
  width: 100%;
}

.ui-tableHead--gapFiller {
  border-width: 0 0 1px 1px;
}

.ui-table--gapFiller {
  border-width: 0 0 0 1px;
  padding: 0;
  background-color: white;
}

.ui-table--scrollContainer {
  height: 419px;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  border-width: 0;
}

.ui-sortableTable-border {
  border: solid #ccc;
  border-width: 0 1px 1px 1px;
}

.ui-tableBodyRow--rowAlternate {
  background-color: #f4f4f4;
}

.ui-table-hoverable .ui-tableBodyRow:hover,
.ui-table-hoverable .ui-tableBodyRow.hover,
.ui-tableHeadCell .ui-tableHeadCell--content:hover,
.ui-tableHeadCell .ui-tableHeadCell--content.hover {
  background-color: #e4e4e4;
}

.ui-sortableTable {
  empty-cells: show;
}

.ui-table--sortHandle {
  text-align: inherit;
  color: #646464;
  overflow: hidden;
  background: transparent
    url("https://ib.absa.co.za/absa-online/static/style/resources/sort-arrows_2019.png")
    no-repeat right -53px;
}

.align-right .ui-table--sortHandle,
.align-right .ui-table--sortHandleClientSide {
  background-position: left -45px;
}

.sorted-ASC {
  background: transparent
    url("https://ib.absa.co.za/absa-online/static/style/resources/sort-arrows_2019.png")
    no-repeat right -114px;
}

.sorted-DESC {
  background: transparent
    url("https://ib.absa.co.za/absa-online/static/style/resources/sort-arrows_2019.png")
    no-repeat right 8px;
}

.align-right .sorted-ASC {
  background-position: left -108px;
}

.align-right .sorted-DESC {
  background-position: left 7px;
}

.ui-expandableTable-firstHead {
  width: 14px;
}

.ui-col-small-icon .ui-tableBodyCellContent,
.ui-col-small-icon .ui-tableHeadCell--text {
  text-align: center;
  width: 14px;
}

.ui-col-radio,
.ui-col-checkbox {
  text-align: center;
  width: 39px;
}

.ui-tableBodyCellContent-radioButton {
  text-align: center;
  width: 33px;
}

.ui-tableBodyCellContent-rearrangeButtons {
  width: 80px;
}

.ui-col-small-icon .ui-tableHeadCell--text {
  padding: 0 7px;
}

.ui-expandableTable-icon-expanded,
.ui-expandableTable-icon {
  display: inline-block;
  width: 16px;
  height: 22px;
  background: transparent
    url("https://ib.absa.co.za/absa-online/static/style/resources/Plus-minus.png")
    no-repeat;
  background-position: 0 4px;
  overflow: hidden;
}

.ui-expandableTable-icon-expanded {
  background-position: 0 -31px;
}

.ie7 .ui-expandableTable-icon-expanded {
  width: 14px;
  background-position: -1px -31px;
}

.ie7 .ui-expandableTable-icon {
  width: 14px;
  background-position: -1px 4px;
}

.ui-table-alternate-row .ui-expandableTable-icon {
  background-position: -12px -12px;
}

.ui-table-alternate-row .ui-expandableTable-icon-expanded {
  background-position: -12px 0;
}

.ui-expandableTable .ui-col-icon.ui-tableHeader--cell .ui-col-content {
  background-color: #e2e2e2 !important;
}

.ui-expandable-row .ui-expandedDetail-view,
.ui-expanded-row .ui-expandedDetail-view {
  cursor: pointer;
}

.ui-tableBodyRowExpandableExtension_hidden {
  display: none;
}

.ui-expandable-disabled-row .ui-icon,
.ui-expandable-disabled-row .ui-expandableTable-icon,
.ui-expanded-disabled-row .ui-icon,
.ui-expanded-disabled-row .ui-expandableTable-icon-expanded {
  display: none;
}

.ui-expandable-disabled-message {
  display: none;
}

.ui-expandable-disabled-row .ui-expandable-disabled-message,
.ui-expanded-disabled-row .ui-expandable-disabled-message {
  display: block;
}

.ui-expandableTable .ui-tableBodyRowExpandableExtension--expandedContent {
  border: 1px solid #c0c0c0;
}

.ui-scroller-table {
  width: 100%;
  overflow: hidden;
  background-color: #fff;
}

.ui-rearrangeTable-hint,
.ui-rearrangeTable-icons {
  display: none;
}

.ui-rearrangeTable .ui-table--scrollContainer table .ui-tableBodyCellContent {
  position: relative;
  overflow: visible;
}

.ui-rearrangeTable-hint {
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
}

.ui-rearrangeTable-icons {
  position: absolute;
  right: 6px;
  top: -12px;
}

.ui-rearrangeTable_showRow {
  background-color: #e4e4e4;
}

.ui-rearrangeTable_showRow .ui-rearrangeTable-hint,
.ui-rearrangeTable_showRow .ui-rearrangeTable-icons {
  display: block;
}

.ui-rearrangeTable_showRow .ui-rearrangeTable-icons .ap-icon-upSharePrice,
.ui-rearrangeTable_showRow .ui-rearrangeTable-icons .ap-icon-downSharePrice {
  margin: 0 2px;
}

.ui-rearrangeTable_showRowFirst .ui-rearrangeTable-icons .ap-icon-upSharePrice {
  display: none;
}

.ui-rearrangeTable_showRowLast
  .ui-rearrangeTable-icons
  .ap-icon-downSharePrice {
  display: none;
}

.ui-rearrangeTable .ui-table--scrollContainer .ui-rearrangeTable-drag {
  cursor: move;
}

.ui-rearrangeTable .ui-tableHeadCell,
.ui-rearrangeTable .ui-tableBodyCell {
  border-right-width: 0;
  border-left-width: 1px;
}

.ui-rearrangeTable .ui-tableHeadCell--rearrangeRadio,
.ui-rearrangeTable .ui-tableBodyCell--rearrangeRadio {
  border-left-width: 0;
}

.ap-table-loadingMessage .ap-loadingMessage-overlay {
  position: relative;
}

.touch .ui-rearrangeTable .ui-rearrangeTable-hint {
  display: none;
}

.touch .ui-tableBodyRow-loadMore td {
  background-color: white;
  padding: 5px;
}

.touch .ui-table-loadMore {
  width: 100%;
  border: medium none;
  height: 20px;
  background-color: #c8c8c8;
  color: #646464;
  text-align: center;
  padding: 5px;
}

.ui-grid {
  width: 100%;
}

.ui-row {
  height: 30px;
}

.ui-cell {
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
  border: 0;
}
tr.ui-row {
  display: flex;
  border: 0;
  align-items: center;
  justify-content: space-between;
}

.ui-cell-25perc {
  width: 25%;
  padding-left: 10px;
  padding-right: 10px;
}

.ui-cell-50perc {
  width: 50%;
  padding-left: 10px;
  padding-right: 10px;
}

.ui-cell-alignLeft {
  text-align: left;
}

.ui-cell-alignRight {
  text-align: right;
}

.ap-loading-message {
  position: relative;
  height: 100px;
}

.ap-loadingMessage-overlay {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  overflow: hidden;
}

.ap-loadingMessage-outter {
  position: relative;
  display: table;
  left: 50%;
  height: 100%;
}

.ap-loadingMessage-hideNode {
  display: none;
}

.ie6 .ap-loadingMessage-outter,
.ie7 .ap-loadingMessage-outter {
  position: absolute;
  display: block;
  top: 50%;
  height: auto;
}

.ap-loadingMessage-centered {
  vertical-align: middle;
  display: table-cell;
}

.ie6 .ap-loadingMessage-centered .ie7 .ap-loadingMessage-centered {
  display: block;
}

.ap-loadingMessage-message {
  position: relative;
  left: -50%;
  text-align: center;
  padding: 15px 15px 15px 50px;
  background: #fff
    url(https://ib.absa.co.za/absa-online/static/style/resources/ajax-loader.gif)
    no-repeat 10px center;
  border: 1px solid #333;
}

.ap-loadingMessage-message-surecheck2 {
  position: relative;
  top: -50%;
  left: -50%;
  text-align: center;
  padding: 15px 15px 15px 50px;
  background: url(https://ib.absa.co.za/absa-online/static/style/resources/2fa/apple_phone.png)
    no-repeat 10px center;
}

.ap-loadingMessage-message-surecheck2-2018 {
  position: relative;
  top: -50%;
  left: -50%;
  text-align: center;
  padding: 15px 15px 15px 50px;
  background: url(https://ib.absa.co.za/absa-online/static/style/resources/2fa/apple_phone_2018.png)
    no-repeat 10px center;
}

.ap-helpText-icon {
  width: 12px;
  height: 12px;
  background: transparent
    url(https://ib.absa.co.za/absa-online/static/style/resources/sprite-icons-bar-status_2019.png)
    no-repeat 0 -99px;
  cursor: pointer;
}

.ap-helpText-baloon {
  position: absolute;
  width: 200px;
  margin-left: 8px;
  z-index: 999;
}

.ap-helpText-baloon-icon-info {
  width: auto;
}

.ap-helpText-baloon-icon-info li {
  list-style: disc outside none;
  margin-left: 13px;
}

.ap-helpText-showMeHow {
  color: grey;
}

.ap-helpText-incorrect-value {
  color: #640032;
}

.ap-helpText-baloon.ap-helpText-right {
  margin-left: -8px;
}

.ap-helpText-pin {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: -7px;
  width: 8px;
  height: 16px;
  background: transparent
    url(https://www.absa.co.za.2009.ui/helpText/baloon-pin.gif) no-repeat 0 0;
}

.ap-helpText-right .ap-helpText-pin {
  margin-right: -7px;
  left: auto;
  right: 0;
  background-position: 0 -16px;
}

.ap-helpText-bottom .ap-helpText-pin {
  top: auto;
  bottom: 0;
}

.ap-helpText-frame {
  margin: -4px 0;
  position: relative;
  padding: 4px;
  border: 1px solid;
  border-color: #646464;
  background-color: #fff;
  border-radius: 4px;
  min-height: 14px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-box-shadow: 0 2px 0 #646464;
  -webkit-box-shadow: 0 2px 0 #646464;
  box-shadow: 0 2px 0 #646464;
}

.ie.ap-helpText-frame {
  height: 14px;
}

.ap-helpText-close-button {
  position: relative;
  float: right;
  font-size: 0;
  background: #fff url(https://www.absa.co.za.2009.ui/helpText/closeButton.gif)
    no-repeat 0 0;
  width: 13px;
  height: 14px;
  cursor: pointer;
  cursor: hand;
}

.ui-showMeHow .ui-form-field,
.ui-showMeHow .ui-select--select {
  background-color: #ffffc9;
}

.ui-showMeHow .ui-checkBox.ui-form-field {
  padding: 3px 2px 2px 3px;
  border: 1px solid #c8c8c8;
  outline: 4px solid #ffffc9;
}

input[type="checkbox"].ui-form-field.ui-showMeHow {
  padding: 4px;
  outline: 4px solid #c8c8c8;
}

.ui-showMeHow .ui-form-field-readonly {
  background: none;
}

.ui-showMeHow--icon {
  background-color: #fefacd;
  border: 1px solid #fee591;
}

.ap-helpText-showMeHow {
  color: #640032;
}

.ap-helpText-showMeHow-black {
  color: #000;
  width: auto;
}

.ap-tooltipElement {
  position: absolute;
  padding: 2px 5px;
  border: 1px solid #666;
  background-color: #fffacd;
  z-index: 999999;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}

html {
  background-color: #f4f4f4;
  overflow-y: scroll;
}

.ap-info-box-ul li {
  list-style: disc outside none;
  margin-left: 13px;
}

.ap-info-box-ol li {
  margin-left: 18px;
  list-style-type: decimal;
}

.ap-main-content-wrapper {
  height: auto;
  margin-bottom: 10px;
  width: 974px;
}

.ap-main-content-wrapper-top,
.ap-main-content-wrapper-bottom {
  height: 10px;
  overflow: hidden;
}

.postlogin .ap-main-content-wrapper-top,
.postlogin .ap-main-content-wrapper-bottom {
  display: hidden;
}

.ap-main-content-wrapper-top .ap-corners-rounded-top,
.ap-main-content-wrapper-bottom .ap-corners-rounded-bottom {
  width: 958px;
  background: #fff;
}

.postlogin .ap-main-content-wrapper-top .ap-corners-rounded-top,
.postlogin .ap-main-content-wrapper-bottom .ap-corners-rounded-bottom {
  background: transparent;
}

.postlogin .ap-container {
  border-width: 0;
  border-style: solid;
  border-color: #c8c8c8;
}

.postlogin .p-gadget .ap-container {
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: #c8c8c8;
  border-radius: 4px 4px 4px 4px;
  -moz-border-radius: 4px 4px 4px 4px;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
}

.prelogin .ap-container {
  border-style: solid;
  border-width: 0 1px 0 1px;
  border-color: #c8c8c8;
}

.ie6 .ap-container,
.ie7 .ap-container {
  zoom: 1;
}

.ap-container-highlevel {
  width: 946px;
}

.ap-corners-rounded {
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}

.ap-corners-rounded-top,
.ap-corners-rounded-bottom {
  float: left;
  height: 9px;
  overflow: hidden;
}

.ap-corners-rounded-top {
  border-top: solid 1px #c8c8c8;
}

.ap-corners-rounded-bottom {
  border-bottom: solid 1px #c8c8c8;
}

.postlogin .ap-corners-rounded-top,
.postlogin .ap-corners-rounded-bottom {
  border: 0;
}

.ap-corners-rounded-top-left,
.ap-corners-rounded-top-right,
.ap-corners-rounded-bottom-left,
.ap-corners-rounded-bottom-right {
  width: 8px;
  height: 10px;
  background: transparent
    url("https://ib.absa.co.za/absa-online/static/style/resources/sprite-corners-rounded.png")
    no-repeat left top;
  float: left;
}

.postlogin .ap-corners-rounded-top-left,
.postlogin .ap-corners-rounded-top-right,
.postlogin .ap-corners-rounded-bottom-left,
.postlogin .ap-corners-rounded-bottom-right {
  background: transparent;
}

.ap-corners-grey-rounded-top-left,
.ap-corners-grey-rounded-top-right,
.ap-corners-grey-rounded-bottom-left,
.ap-corners-grey-rounded-bottom-right {
  width: 8px;
  height: 10px;
  background: transparent
    url("https://ib.absa.co.za/absa-online/static/style/resources/sprite-corners-grey-rounded.png")
    no-repeat left top;
  float: left;
}

.ap-corners-rounded-top-left,
.ap-corners-grey-rounded-top-left {
  background-position: right -10px;
}

.ap-corners-rounded-top-right,
.ap-corners-grey-rounded-top-right {
  background-position: left -10px;
}

.ap-corners-rounded-bottom-left,
.ap-corners-grey-rounded-bottom-left {
  background-position: right top;
}

.ap-footer {
  line-height: 14px;
  margin-bottom: 30px;
}

.ap-footer a,
.ap-footer a:visited {
  text-decoration: none;
}

.ap-footer li,
.ap-footer a {
  display: inline-block;
  position: relative;
  white-space: nowrap;
  color: #af154b;
  font-weight: bold;
}

.ap-footer li {
  padding-left: 5px;
  margin-left: 5px;
  border: 0;
  border-left: 1px solid #646464;
}

.ap-footer a:hover {
  text-decoration: none;
}

.ie6 .ap-footer li,
.ie7 .ap-footer li {
  display: inline;
}

.ap-footer ul li:first-child {
  padding-left: 0;
  margin-left: 0;
  border-left: 0;
}

.ap-titlebar {
  position: relative;
  height: 36px;
  cursor: pointer;
}

.postlogin .ap-titlebar {
  height: 52px;
}

.ap-sub-section {
  clear: both;
  zoom: 1;
  overflow: hidden;
  background-color: #f4f4f4;
}

.ap-sub-bar {
  margin: 4px;
  height: 18px;
  background-color: #e5e5e5;
  overflow: hidden;
}

.ap-bar-section {
  float: left;
  cursor: default;
}

.ap-bar-section-m {
  width: 148px;
  padding: 0 10px;
}

.ap-bar-section.p-gadget-title {
  cursor: pointer;
  margin: 10px;
}

.ap-balances,
.ap-otherInv-balances {
  float: right;
}

.postlogin .ap-balances,
.postlogin .ap-otherInv-balances {
  padding-top: 10px !important;
}

.ap-bar-section-right {
  float: left;
  margin: 10px;
}

.ap-bar-section-icons {
  margin: 2px 0 0 20px;
  width: 125px;
  text-align: right;
}

.ap-save-invest-container .ap-bar-section-icons {
  margin: 2px 0 0 20px;
  width: 75px;
  text-align: right;
}

.ap-container-footerbar .ap-bar-section-icons {
  margin: 0 10px;
}

.ap-page-container {
  width: 974px;
  padding-top: 0;
  margin: 0 auto;
}

.ap-page-header {
  height: 134px;
}

.prelogin .ap-page-header {
  height: 88px;
}

.ap-page-headerVI {
  height: 100%;
}

.ap-page-content {
  background: #fff;
  padding: 4px 13px;
  border-width: 0 1px;
}

.postlogin .ap-page-content {
  background: transparent;
}

.prelogin .ap-page-content {
  padding: 4px 13px 13px 13px;
}

.ap-container-content {
  padding: 13px 0 13px 0;
  width: 944px;
}

.ap-container-bottom,
.ap-container-bottom-save-invest {
  height: 10px;
  overflow: hidden;
}

.ap-container-bottom .ap-corners-rounded-bottom {
  width: 930px;
  background: #fff;
}

.ap-container-bottom-save-invest .ap-corners-rounded-bottom {
  width: 900px;
}

.ap-container-footer {
  width: 944px;
  border: solid 1px #c8c8c8;
  height: 52px;
}

.ap-container-bottom {
  display: none !important;
}

.ap-container-footerbar {
  border-top-color: #c8c8c8;
  border: solid 1px #fff;
  padding-right: 13px;
  height: 50px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
}

.ap-container-footer-l2 .ap-container-footerbar {
  height: 50px;
}

.ap-container-footer-l2 .ap-Networth {
  line-height: 10px;
  float: right;
  width: 200px;
  padding: 0 140px;
}

.ap-container-footer-l2 .ap-bar-title-netPosition {
  padding-left: 10px;
  line-height: 50px;
}

.ap-container-footer-rounded {
  border: 0;
  height: 35px;
  width: 946px;
  overflow: hidden;
}

.ap-container-footer-rounded .ap-container-footerbar {
  border: 0;
  height: 60px;
  background: #fff
    url("https://ib.absa.co.za/absa-online/static/style/resources/gadget-bg.png")
    no-repeat left -120px;
  padding-top: 2.5px;
}

.ap-container-bottom,
.ap-container-footer {
  display: none;
}

.p-gadget-minimized .p-gadget-bottom {
  display: none;
}

.ap-account .ap-titlebar,
.ap-accountbar .ap-titlebar {
  padding-right: 4px;
  background: #fff;
  border-radius: 4px 4px 4px 4px;
  -moz-border-radius: 4px 4px 4px 4px;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
}

.ap-container-highlevel .ap-heading-titlebar {
  border: 20;
  padding-top: 2px;
  padding-left: 1px;
  height: 32px;
  cursor: default;
  background: #af154b;
  border-radius: 4px 4px 4px 4px;
  -moz-border-radius: 4px 4px 4px 4px;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
}

.postlogin .ap-container-highlevel .ap-heading-titlebar {
  display: none;
  height: 38px !important;
}

.postlogin .ap-container-highlevel .ap-heading-titlebar.ap-titlebar-offers {
  display: block !important;
}

.ap-heading-pebble {
  border: 20;
  padding-top: 5px;
  margin-top: 5px;
  padding-left: 10px;
  margin-left: 10px;
  background-color: #f0f0f5;
  height: 32px;
  cursor: default;
  font-weight: bold;
}

.ap-heading-businessBanking {
  border: 20;
  padding-top: 5px;
  margin-top: 5px;
  padding-left: 10px;
  margin-left: 10px;
  background-color: #f0f0f5;
  height: 32px;
  cursor: default;
  font-weight: bold;
}

.ap-account .ap-titlebar {
  background-position: left -62px;
}

.ap-accountbar .ap-titlebar {
  background-position: left -160px;
}

.ap-account .p-gadget-content,
.ap-accountbar .p-gadget-content {
  border-top: solid 1px #c9c9c9;
  display: block;
  clear: both;
  border-left: solid 1px #c9c9c9;
  border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  -webkit-border-bottom-left-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
}

.ap-insurance .p-gadget-content,
.ap-insurancebar .p-gadget-content {
  border-top: solid 1px #c9c9c9;
  display: block;
  clear: both;
  border-left: solid 1px #c9c9c9;
}

.ap-icon-bar-status {
  background-position: right top;
  width: 10px;
  cursor: pointer;
  padding-right: 0;
}

.ap-container-highlevel .ap-bar-section {
  font-size: 16px;
  line-height: 1;
}

.ap-dashboard-dummy .p-gadget .ap-titlebar .ap-bar-section {
  font-size: 14px;
  border-radius: 4px 0 0 0;
  -moz-border-radius: 4px 0 0 0;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-bottom-right-radius: 0;
}

.ap-dashboard-dummy .p-gadget-minimized .p-gadget .ap-titlebar .ap-bar-section {
  font-size: 14px;
  border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-left-radius: 4px;
  -webkit-border-bottom-right-radius: 0;
}

.ap-container-footerbar .ap-bar-section {
  font-size: 16px;
}

.ap-bar-title {
  font-weight: bold;
}

.ap-bar-align-right {
  text-align: right;
}

.ap-insurances-balances--label {
  width: 148px;
  padding-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 6px 10px;
}

.ap-insurances-balances--value {
  width: 605px;
  padding-right: 10px;
  padding-top: 6px;
}

.ap-accountbar .ap-titlebar .ap-bar-divider {
  background-position: 0 -93px;
}

.ap-insurancebar .ap-titlebar .ap-bar-divider {
  background-position: 0 -93px;
}

.ap-container-highlevel .ap-bar-divider {
  background: transparent
    url("https://ib.absa.co.za/absa-online/static/style/resources/sprite-dividers.gif")
    no-repeat left 3px;
}

.p-gadget .ap-titlebar .ap-bar-divider {
  background: transparent
    url("https://ib.absa.co.za/absa-online/static/style/resources/sprite-titlebar-gradients.png")
    no-repeat left -93px;
}

.ap-heading-titlebar .ap-bar-section,
.ap-heading-save-invest .ap-bar-section {
  color: #fff;
  margin: 8px;
  margin-left: 10px;
}

.ap-heading-titlebar-login .ap-bar-title {
  color: #847e7e !important;
}

.ap-save-invest-container .ap-heading-save-invest .ap-bar-section {
  margin-top: 16px;
}

.ap-sub-bar .ap-bar-section {
  line-height: 17px;
}

.ap-sub-bar .ap-title {
  font-weight: bold;
}

.ap-heading-titlebar-item {
  display: none;
}

.ap-container-highlevel .ap-container-top-show,
.ap-container-highlevel .ap-container-bottom-show {
  display: block;
}

.ap-heading-save-invest {
  border: 0;
  height: 32px;
  background: #870a3c;
  color: #fff;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
}

.ap-container-bottom-save-invest-totals {
  border: 0;
  background: url("https://ib.absa.co.za/absa-online/static/style/resources/gadget-bg.png")
    no-repeat 0 -203px;
  height: 50px;
  font-size: 15px;
}

.ap-global-message div {
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #af154b;
}

.clear-both {
  clear: both;
}

.ap-titlebar .ap-bar-title:focus {
  border-width: 0 0 1px 0;
  border-style: dotted;
  border-color: #ccc;
  outline: 0;
}

.ap-pleasewait {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: #000;
  z-index: 1000;
  opacity: 0.2;
  -moz-opacity: 0.2;
  -khtml-opacity: 0.2;
  filter: alpha(opacity = 20);
}

.ap-pleasewait--viewport {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.ap-pleasewait--content {
  position: relative;
  top: 50%;
  text-align: center;
  z-index: 1000;
}

.ap-pleasewait--label {
  font-size: 14px;
  padding-left: 10px;
  line-height: 32px;
  vertical-align: top;
}

.ap-container-borders-all {
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
}

.p-portal {
  position: relative;
}

.p-gadget,
.p-portal-dragPlaceholder {
  position: absolute;
  top: -10000px;
  left: -10000px;
  z-index: 1;
}

.ap-saveAndInvestGadget {
  margin-bottom: 9px;
}

.ie .p-portal-dragPlaceholder {
  z-index: -1;
}

.ap-gadget-nochrome {
  zoom: 1;
  background-color: transparent;
  overflow: hidden;
  border: 0 solid grey;
}

.ap-gadget-nochrome .ap-titlebar {
  display: none;
}

.ap-gadget-nochrome .p-gadget-content {
  background-color: transparent;
  border: none;
}

.ap-gadget-nochrome .ap-container {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
}

.p-gadget-content {
  zoom: 1;
  background-color: #fff;
  overflow: hidden;
}

.ie6 .p-gadget-content {
  position: relative;
}

.p-portal-dragPlaceholder {
  background-color: #f4f4f4;
  border: 1px dotted #c8c8c8;
  -moz-border-radius: 4px;
}

.p-gadget-button {
  padding-left: 0;
  height: 15px;
  margin: 0 10px 0 0;
  width: 5px;
}

.p-gadget-button:focus {
  border-width: 0 0 1px 0;
  border-style: dotted;
  border-color: #ccc;
  outline: 0;
}

.p-gadget-button-restore {
  display: none;
}

.p-gadget-button-minimize {
  display: block;
}

.p-gadget-minimized .p-gadget-button-minimize {
  display: none;
}

.p-gadget-minimized .p-gadget-button-restore {
  display: block;
}

.ap-grouped-layout-content .p-gadget-button-minimize,
.ap-container-footer .p-gadget-button-minimize {
  position: relative;
  background: #f03254;
  top: 0;
  height: 36px;
  border-radius: 4px 0 0 0;
  -moz-border-radius: 4px 0 0 0;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-bottom-right-radius: 0;
}

.postlogin .ap-grouped-layout-content .p-gadget-button-minimize,
.ap-container-footer .p-gadget-button-minimize {
  height: 52px;
  border-radius: 4px 0 0 0;
  -moz-border-radius: 4px 0 0 0;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-bottom-right-radius: 0;
}

.gadget-placeholders
  .ap-grouped-layout-content
  .p-gadget-minimized
  .p-gadget-button-restore {
  background: #f05a7d;
}

.gadget-placeholders {
  padding-top: 14px;
}

.ap-grouped-layout-content .p-gadget-minimized .p-gadget-button-restore,
.ap-container-footer .p-gadget-minimized .p-gadget-button-restore {
  position: relative;
  background: #870a3c;
  top: 0;
  height: 36px;
  border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-left-radius: 4px;
  -webkit-border-bottom-right-radius: 0;
}

.postlogin
  .ap-grouped-layout-content
  .p-gadget-minimized
  .p-gadget-button-restore,
.postlogin .ap-container-footer .p-gadget-minimized .p-gadget-button-restore {
  height: 52px;
}

.current .p-gadget-button-restore,
.postlogin .ap-container-footer .p-gadget-button-restore {
  background: #870a3c !important;
}

.corporatePropertyFinance .p-gadget-button-restore {
  background: #f0325a !important;
}

.passiveInvest .p-gadget-button-restore {
  background: #dc0032 !important;
}

.creditCard .p-gadget-button-restore {
  background: #870a3c !important;
}

.noticeDeposit .p-gadget-button-restore {
  background: #f0325a !important;
}

.termDeposit .p-gadget-button-restore {
  background: #dc0032 !important;
}

.ap-ostAccount .p-gadget-button-restore {
  background: #870a3c !important;
}

.ap-aimsAccount .p-gadget-button-restore {
  background: #f0325a !important;
}

.ap-unitTrustAccount .p-gadget-button-restore {
  background: #dc0032 !important;
}

.ap-absaReward .p-gadget-button-restore {
  background: #870a3c !important;
}

.ap-currencyInvestmentAccount .p-gadget-button-restore {
  background: #f0325a !important;
}

.p-gadget-minimized .p-gadget-content {
  display: none;
}

#ssr-gadget-definitions {
  display: none;
}

.ap-gadget-button-container {
  float: right;
  padding-right: 4px;
  display: none;
  padding-top: 13px;
}

.p-gadget-header .ap-gadget-button-container {
  display: block;
}

.p-gadget-minimized .ap-gadget-button-container .ap-icon-print,
.p-gadget-minimized .ap-otherInv-balances .ap-icon-print,
.p-gadget-minimized .ap-gadget-button-container .ap-icon-shoppingCartFS,
.p-gadget-minimized .ap-otherInv-balances .ap-icon-shoppingCartFS {
  display: none;
}

.ap-otherInv-balances .ap-bar-section-icons-options {
  margin-bottom: 0;
}

.p-gadget-minimized .p-gadget-header .ui-tabHead {
  background-color: transparent;
  border: none;
  padding-top: 5px;
}

.p-gadget-title-container {
  float: left;
}

.postlogin .p-gadget-title-container {
  margin-top: 8px;
}

.p-gadget-title-container .p-gadget-title {
  float: none;
  margin: 3px 0 0;
  color: #000;
}

.p-gadget-minimized .p-gadget-title-container .p-gadget-title {
  color: black;
}

.p-gadget-title-container .p-gadget-title:hover {
  text-decoration: underline;
  color: #af154b;
}

.p-gadget-title-container .p-gadget-subTitle {
  font-size: 12px;
}

.p-gadget-draggable-section {
  width: 52px;
  float: right;
  height: 24px;
  padding-top: 6px;
  display: none;
}

.p-gadget-draggable-button {
  background: transparent
    url("https://ib.absa.co.za/absa-online/static/style/resources/gadgetDrag.png")
    no-repeat;
  width: 50px;
  height: 30px;
  border: none;
}

.touch .p-gadget-draggable-section {
  display: block;
}

.touch .ap-accounts-container .p-gadget-draggable-section,
.touch .ap-save-invest-container .p-gadget-draggable-section {
  display: none;
}

.ap-navigation-main {
  position: fixed;
  top: 0;
  margin-left: 128px;
  width: 70%;
  height: 85px;
}

.ap-navigation-main .ap-tabStrip-rounded-left {
  float: left;
  margin-top: 16px;
  background: #cac7c7;
}

.ap-navigation-main .ap-tabStrip-rounded-right {
  float: left;
  margin-top: 16px;
  background: #cac7c7;
}

.prelogin .ap-navigation-main .ap-tabStrip-rounded-right {
  background: #fff;
}

.ap-navigation-main .ap-tabStrip-rounded-left,
.ap-navigation-main .ap-tabStrip-rounded-right {
  height: 32px;
  width: 1px;
}

.ap-tabStrip-absaOnline-text-shadow,
.ap-tabStrip-absaOnline-text {
  width: 117px;
  height: 19px;
  position: absolute;
  top: 20px;
  left: 692px;
  font-size: 14pt;
  color: #fff;
  font-weight: bold;
  background: transparent;
}

.ap-tabStrip-absaOnline-text-shadow {
  color: #555;
  padding: 1px 0 0 1px;
}

.ap-navigation-sub {
  background-color: #fff;
}

.prelogin .ap-navigation-sub {
  display: none;
}

.ap-tabStrip-tabs {
  height: 64px;
  padding: 0 0 0 33px;
  float: left;
  width: 85%;
}

.ap-tabStrip-tabs li,
.ap-tabStrip-tabs a {
  display: inline-block;
  color: #847e7e;
  position: relative;
  white-space: nowrap;
  z-index: 1;
}

.ap-tabStrip-tabs a {
  height: 70px;
  padding: 25px 10px 3px 10px;
}

.ap-tabStrip-tabs a:hover {
  text-decoration: none;
  color: #af154b !important;
  background: #f4f4f4;
  height: 70px;
  border-bottom: 5px solid #f4f4f4;
}

.ap-tabStrip-tabs li,
.ap-tabStrip-tabs a:hover {
  color: #af154b;
}

.ap-tabStrip-tabs a:hover {
  background: #f4f4f4;
}

.ie6 .ap-tabStrip-tabs li,
.ie7 .ap-tabStrip-tabs li {
  display: inline;
}

.ap-tabStrip-tabs .ap-tab-title {
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  text-align: center;
}

.ap-tabStrip-tabs .ap-tab-title.ap-tab-expressB {
  color: #000;
}

.ap-tabStrip-tabs li:not(.ap-tab-active):last-child .ap-tab-title {
  background: none;
}

.ap-tabStrip-tabs .ap-tab-active {
  border-bottom: 5px solid #af154b;
  margin: 0 8px 0 0;
  background-color: #fff;
}

.ie .ap-tabStrip-tabs .ap-tab-active,
.opera .ap-tabStrip-tabs .ap-tab-active {
  border: none;
  padding-left: 5px;
  margin-right: -3px;
}

.ap-tabStrip-tabs .ap-tab-active .ap-tab-title {
  position: static;
  font-size: 14px;
  font-weight: bold;
  color: #af154b;
}

.prelogin .ap-tabStrip-tabs .ap-tab-active .ap-tab-title {
  padding: 8px 0 7px 0 !important;
  margin-right: 0 !important;
  line-height: 46px !important;
}

.ie .ap-tabStrip-tabs .ap-tab-active .ap-tab-title,
.opera .ap-tabStrip-tabs .ap-tab-active .ap-tab-title {
  height: 30px;
  padding-top: 3px;
  border: none;
  padding-left: 9px;
}

.ie6 .ap-tabStrip-tabs .ap-tab-active .ap-tab-title {
  width: 0;
}

.ap-tabStrip-tabs .ap-tab-title-hidden {
  padding: 0 7px;
  font-size: 15px;
  font-weight: bold;
  white-space: nowrap;
  visibility: hidden;
  position: relative;
  top: 0;
  width: 0;
  height: 0;
}

.ap-tabStrip-tabs .ap-tab-button .ap-tab-title-hidden {
  position: absolute;
  top: 0;
  width: 10px !important;
  height: 0 !important;
}

.ie .ap-tabStrip-tabs .ap-tab-title-hidden {
  padding: 0 6px;
}

.ie6 .ap-tabStrip-tabs .ap-tab-title-hidden {
  margin: -18px 0 0;
}

.ap-tabStrip-subnav {
  border-width: 0 0 3px 0;
  position: fixed;
  top: 64px;
  left: 0;
  width: 100%;
  height: 50px;
  background: #af144b;
  color: #fff;
  z-index: 105;
}

.ap-tabStrip-subnav ul {
  display: none;
  border-top: 0;
  padding: 0 4px;
  width: 755px;
  height: 35px;
  line-height: 35px;
}

.ap-tabStrip-subnav li {
  float: left;
  padding: 0 10px;
  -moz-user-select: none;
  -khtml-user-select: none;
}

.ap-tabStrip-subnav li div {
  font-family: "Source Sans Pro", sans-serif, arial;
  color: white;
  font-size: 14px !important;
}

.ie .ap-tabStrip-subnav li,
.opera .ap-tabStrip-subnav li {
  padding: 0;
}

.ie .ap-tabStrip-subnav li div,
.opera .ap-tabStrip-subnav li div {
  padding: 0 10px;
}

.ap-tabStrip-subnav .ap-subnav-active {
  display: block;
  padding-top: 8px;
  font-weight: 600;
  font-size: 24px;
}

.ap-tabStrip-subnav .ap-subtab-active {
  height: 19px;
  line-height: 19px;
  margin-top: 8px;
  background-color: #eaeaea;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}

.ap-navigation-message {
  position: fixed;
  top: 83px;
  right: 10px;
  z-index: 108;
  color: #fff;
}

.ap-navigation-message .clock {
  display: inline;
  color: #fff;
}

.ap-navigation-message span {
  color: #fff;
}

.ap-button-logout {
  position: absolute;
  top: -7px;
  right: 0;
}

.ie6 .ap-logout-button {
  right: 140px;
}

.ap-navigation-links {
  float: right;
}

.ap-navigation-links {
  z-index: 106;
  position: fixed;
  top: 28px;
  right: 20px;
}

.ie7 .ap-navigation-links {
  margin-top: -15px;
}

.ap-navigation-links .ui-link {
  color: #646464 !important;
  text-decoration: none;
}

html {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: normal;
  color: #646464;
  font-size: 11px;
}

.htmlFont11 {
  font-size: 11px !important;
}

.htmlFont12 {
  font-size: 12px !important;
}

.htmlFont13 {
  font-size: 13px !important;
}

.htmlFont14 {
  font-size: 14px !important;
}

.bold {
  font-weight: bold;
}

.white {
  color: #fff;
}

.red {
  color: #af154b;
}

h1 {
  font-size: 136.36%;
  font-weight: bold;
}

h2 {
  font-size: 118.18%;
  font-weight: bold;
}

h3 {
  font-size: 118.18%;
}

.ui-button_disabled.ap-button-back .ui-button-left,
.ui-button_disabled.ap-button-cancel .ui-button-left,
.ui-button_disabled.ap-button-reset .ui-button-left {
  background-position: 0 0;
}

.ap-button-cancel .ui-button-left,
.ap-button-reset .ui-button-left,
.ap-button-logout .ui-button-left,
.ap-button-back .ui-button-left,
.ap-button-finishOLD .ui-button-left,
.ap-button-extra .ui-button-left {
  padding-left: 10px;
  color: #fa551f;
}

.ap-button-finish .ui-button-left {
  color: #fff;
}

.ap-button-extra .ui-button-left {
  color: #fa551f;
}

.ap-button-next .ui-button-right {
  color: white;
}

.ap-button-finish,
.ap-button-next {
  border-width: 0;
}

.ap-button-cancel,
.ap-button-reset,
.ap-button-logout,
.ap-button-back,
.ap-button-extra,
.ap-button-large-grey,
.ap-button-large-red,
.ap-button-large-red-nextOLD,
.ap-button-small-grey,
.ap-button-small-red,
.ap-button-large-red-square,
.ap-button-large-grey-square {
  border-width: 0;
  background-color: transparent;
}

.ap-button-logout_disabled .ui-button-right {
  background-repeat: no-repeat;
  background-position: 100% -78px;
}

.ap-button-finish .ui-button-left,
.ap-button-finish .ui-button-center,
.ap-button-finish .ui-button-right,
.ap-button-finish_disabled .ui-button-left,
.ap-button-finish_disabled .ui-button-center,
.ap-button-finish_disabled .ui-button-right {
  background-color: #fa551f;
}

.ap-button-next .ui-button-left,
.ap-button-next .ui-button-center,
.ap-button-next .ui-button-right,
.ap-button-next_disabled .ui-button-left,
.ap-button-next_disabled .ui-button-center,
.ap-button-next_disabled .ui-button-right {
  background-color: #fa551f;
}

.ap-button-large-grey .ui-button-left,
.ap-button-large-grey .ui-button-center,
.ap-button-large-grey .ui-button-right,
.ap-button-large-grey_disabled .ui-button-left,
.ap-button-large-grey_disabled .ui-button-center,
.ap-button-large-grey_disabled .ui-button-right {
  background: #acacac;
}

.ap-button-small-grey .ui-button-left,
.ap-button-small-grey .ui-button-center,
.ap-button-small-grey .ui-button-right,
.ap-button-small-grey_disabled .ui-button-left,
.ap-button-small-grey_disabled .ui-button-center,
.ap-button-small-grey_disabled .ui-button-right {
  background-color: #cacaca;
}

.ap-button-large-red .ui-button-left,
.ap-button-large-red .ui-button-center,
.ap-button-large-red .ui-button-right,
.ap-button-large-red_disabled .ui-button-left,
.ap-button-large-red_disabled .ui-button-center,
.ap-button-large-red_disabled .ui-button-right {
  background-color: #870a3c;
}

.ap-button-large-red-nextOLD .ui-button-left,
.ap-button-large-red-nextOLD .ui-button-center,
.ap-button-large-red-nextOLD .ui-button-right,
.ap-button-large-red-next_disabledOLD .ui-button-left,
.ap-button-large-red-next_disabledOLD .ui-button-center,
.ap-button-large-red-next_disabledOLD .ui-button-right {
  background-color: #870a3c;
}

.ap-button-small-red .ui-button-left,
.ap-button-small-red .ui-button-center,
.ap-button-small-red .ui-button-right,
.ap-button-small-red_disabled .ui-button-left,
.ap-button-small-red_disabled .ui-button-center,
.ap-button-small-red_disabled .ui-button-right {
  background-color: #870a3c;
}

.ap-button-large-red-next .ui-button-right {
  background-position: 100% -84px;
  color: #fff;
}

.ap-button-large-grey,
.ap-button-large-grey-square,
.ap-button-large-redOLD,
.ap-button-large-red-square,
.ap-button-large-red-nextOLD,
.ap-button-small-grey,
.ap-button-small-redOLD {
  color: white;
  border: 1px solid #acacac !important;
}

.ap-button-large-red,
.ap-button-large-red-next {
  color: white;
  border: 1px solid #870a3c !important;
}

.ap-button-small-red {
  color: white;
  border: 1px solid #870a3c !important;
}

.ap-button-large-red-square {
  background-color: #e7222b;
  vertical-align: top;
}

.ap-button-large-grey-square {
  background-color: #999;
  vertical-align: top;
}

.ap-icon-pymnt .ui-button-left,
.ap-icon-pymnt .ui-button-center,
.ap-icon-pymnt .ui-button-right {
  background-image: url("https://ib.absa.co.za/absa-online/static/style/resources/stop_payment.png");
}

.ap-button-retry_disabled .ui-button-right,
.ap-button-logout_disabled .ui-button-right {
  background-repeat: no-repeat;
  background-position: 100% -78px;
}

.ap-icon-expandPlus,
.ap-icon-contractMinus,
.ap-icon-print,
.ap-icon-info,
.ap-icon-help,
.ap-icon-info-faded,
.ap-icon-help-inactive,
.ap-icon-price,
.ap-icon-price1,
.ap-icon-statement,
.ap-icon-statement-inactivated,
.ap-icon-mobile,
.ap-icon-mobile-inactivated,
.ap-icon-view,
.ap-icon-tools,
.ap-icon-remove,
.ap-icon-calendar,
.ap-icon-upSharePrice,
.ap-icon-downSharePrice,
.ap-icon-addBen,
.ap-icon-removeBen,
.ap-icon-sms,
.ap-icon-email,
.ap-icon-fax,
.ap-icon-none,
.ap-icon-1030,
.ap-icon-3010,
.ap-icon-1040,
.ap-icon-4010,
.ap-icon-1045,
.ap-icon-4510,
.ap-icon-link,
.ap-icon-linkShareTrading,
.ap-icon-linkAims,
.ap-icon-pieChart,
.ap-icon-changeGraph-lightGrey,
.ap-icon-3dPieChart,
.ap-icon-barChart,
.ap-icon-arrowRight,
.ap-icon-rightArrowRed,
.ap-icon-rightArrowGreen,
.ap-icon-logOff,
.ap-icon-greenLock,
.ap-icon-logOn,
.ap-icon-termsAndConditions,
.ap-icon-clearCalculation,
.ap-icon-addShare,
.ap-icon-delete,
.ap-icon-red-close,
.ap-icon-reinstateBen,
.ap-icon-interAccountTransfer,
.ap-icon-viewHistory,
.ap-icon-viewRecurringPayment,
.ap-icon-addRecurring,
.ap-icon-viewStopPayments,
.ap-icon-addStopPayments,
.ap-icon-pymnt,
.ap-icon-doPay,
.ap-icon-statusWarning,
.ap-icon-statusError,
.ap-icon-statusSuccess,
.ap-icon-green-tick,
.ap-icon-reject,
.ap-icon-close-bullet,
.ap-icon-closeRed,
.ap-icon-paySingle,
.ap-icon-addNewBen,
.ap-icon-viewBen2,
.ap-icon-westernUnionSend,
.ap-icon-westernUnionRecieve,
.ap-icon-resend,
.ap-icon-settleBudget,
.ap-icon-settleAllBudgets,
.ap-icon-transact,
.ap-icon-sortBeneficiaries,
.ap-icon-replyMessage,
.ap-icon-reply,
.ap-icon-savgingsGoals,
.ap-icon-checkerFlag,
.ap-icon-download,
.ap-icon-viewPdf,
.ap-icon-note,
.ap-icon-note-white,
.ap-icon-change,
.ap-icon-sell,
.ap-icon-payOnceOff,
.ap-icon-cashSendSingle,
.ap-icon-cashSendOnceOff,
.ap-icon-prepaidSingle,
.ap-icon-prepaidOnceOff,
.ap-icon-payTrafficFine,
.ap-icon-authorisations,
.ap-icon-accountsInfo,
.ap-icon-viewMessage,
.ap-icon-archiveMessage,
.ap-icon-deleteMessage,
.ap-icon-resendMessage,
.ap-icon-help-active,
.ap-grey-plus,
.ap-icon-change-disabled,
.ap-icon-revert,
.ap-icon-resendWithdrawalCode,
.ap-icon-messageHistory,
.ap-icon-paySettleCCBudget,
.ap-icon-unlink,
.ap-icon-loadPayPass,
.ap-icon-settleAllCCBudgets,
.ap-icon-addSavingGoal,
.ap-icon-viewNotifyMeHistory,
.ap-icon-redeemRewards,
.ap-icon-minus,
.ap-icon-expandPlus-transparent,
.ap-icon-contractMinus-transparent,
.ap-icon-print-transparent,
.ap-icon-info-transparent,
.ap-icon-help-transparent,
.ap-icon-info-faded-transparent,
.ap-icon-help-inactive-transparent,
.ap-icon-price-transparent,
.ap-icon-statement-transparent,
.ap-icon-statement-inactivated-transparent,
.ap-icon-mobile-transparent,
.ap-icon-mobile-inactivated-transparent,
.ap-icon-configure-transparent,
.ap-icon-view-transparent,
.ap-icon-tools-transparent,
.ap-icon-remove-transparent,
.ap-icon-calendar-transparent,
.ap-icon-upSharePrice-transparent,
.ap-icon-downSharePrice-transparent,
.ap-icon-addBen-transparent,
.ap-icon-removeBen-transparent,
.ap-icon-sms-transparent,
.ap-icon-email-transparent,
.ap-icon-fax-transparent,
.ap-icon-none-transparent,
.ap-icon-1030-transparent,
.ap-icon-3010-transparent,
.ap-icon-1040-transparent,
.ap-icon-4010-transparent,
.ap-icon-1045-transparent,
.ap-icon-4510-transparent,
.ap-icon-link-transparent,
.ap-icon-linkShareTrading-transparent,
.ap-icon-linkAims-transparent,
.ap-icon-pieChart-transparent,
.ap-icon-changeGraph-lightGrey-transparent,
.ap-icon-3dPieChart-transparent,
.ap-icon-barChart-transparent,
.ap-icon-arrowRight-transparent,
.ap-icon-rightArrowRed-transparent,
.ap-icon-rightArrowGreen-transparent,
.ap-icon-logOff-transparent,
.ap-icon-greenLock-transparent,
.ap-icon-logOn-transparent,
.ap-icon-termsAndConditions-transparent,
.ap-icon-clearCalculation-transparent,
.ap-icon-addShare-transparent,
.ap-icon-delete-transparent,
.ap-icon-red-close-transparent,
.ap-icon-reinstateBen-transparent,
.ap-icon-interAccountTransfer-transparent,
.ap-icon-viewHistory-transparent,
.ap-icon-viewRecurringPayment-transparent,
.ap-icon-addRecurring-transparent,
.ap-icon-viewStopPayments-transparent,
.ap-icon-addStopPayments-transparent,
.ap-icon-statusWarning-transparent,
.ap-icon-statusError-transparent,
.ap-icon-statusSuccess-transparent,
.ap-icon-green-tick-transparent,
.ap-icon-reject-transparent,
.ap-icon-close-bullet-transparent,
.ap-icon-closeRed-transparent,
.ap-icon-paySingle-transparent,
.ap-icon-addNewBen-transparent,
.ap-icon-viewBen2-transparent,
.ap-icon-westernUnionSend-transparent,
.ap-icon-westernUnionRecieve-transparent,
.ap-icon-resend-transparent,
.ap-icon-settleBudget-transparent,
.ap-icon-settleAllBudgets-transparent,
.ap-icon-transact-transparent,
.ap-icon-sortBeneficiaries-transparent,
.ap-icon-replyMessage-transparent,
.ap-icon-reply-transparent,
.ap-icon-savgingsGoals-transparent,
.ap-icon-checkerFlag-transparent,
.ap-icon-download-transparent,
.ap-icon-viewPdf-transparent,
.ap-icon-note-transparent,
.ap-icon-note-white-transparent,
.ap-icon-change-transparent,
.ap-icon-sell-transparent,
.ap-icon-payOnceOff-transparent,
.ap-icon-cashSendSingle-transparent,
.ap-icon-cashSendOnceOff-transparent,
.ap-icon-prepaidSingle-transparent,
.ap-icon-prepaidOnceOff-transparent,
.ap-icon-payTrafficFine-transparent,
.ap-icon-authorisations-transparent,
.ap-icon-accountsInfo-transparent,
.ap-icon-viewMessage-transparent,
.ap-icon-archiveMessage-transparent,
.ap-icon-deleteMessage-transparent,
.ap-icon-resendMessage-transparent,
.ap-icon-help-active-transparent,
.ap-grey-plus-transparent,
.ap-icon-change-disabled-transparent,
.ap-icon-revert-transparent,
.ap-icon-resendWithdrawalCode-transparent,
.ap-icon-messageHistory-transparent,
.ap-icon-paySettleCCBudget-transparent,
.ap-icon-unlink-transparent,
.ap-icon-loadPayPass-transparent,
.ap-icon-settleAllCCBudgets-transparent,
.ap-icon-addSavingGoal-transparent,
.ap-icon-viewNotifyMeHistory-transparent,
.ap-icon-redeemRewards-transparent,
.ap-icon-minus-transparent,
.ap-icon-save,
.ap-icon-delete-disabled,
.ap-icon-rejectDevice,
.ap-icon-linkDevice,
.ap-icon-receiveMoney,
.ap-icon-callMe,
.ap-icon-receiveMoney,
.ap-icon-addBeneficiary,
.ap-icon-payArrears,
.ap-icon-reactivatePolicy,
.ap-icon-submitClaim,
.ap-icon-viewCorrespondence,
.ap-icon-managePolicy,
.ap-icon-addAdditionalFamilyMember,
.ap-icon-delinkDevice,
.ap-icon-endorsedDocument,
.ap-icon-natureOfPayment,
.ap-icon-save-transparent,
.ap-icon-delete-disabled-transparent,
.ap-icon-rejectDevice-transparent,
.ap-icon-linkDevice-transparent,
.ap-icon-advisor,
.ap-icon-advisor-transparent,
.ap-icon-contactUs,
.ap-icon-contactUs-transparent,
.ap-icon-switch,
.ap-icon-switch-transparent,
.ap-icon-growFont,
.ap-icon-growFont-transparent,
.ap-icon-shrinkFont,
.ap-icon-shrinkFont-transparent,
.ap-icon-bold,
.ap-icon-bold-transparent,
.ap-icon-colorPalette,
.ap-icon-colorPalette-transparent,
.ap-icon-stretch,
.ap-icon-stretch-transparent,
.ap-icon-3060,
.ap-icon-3060-transparent,
.ap-icon-removeStopPayments,
.ap-icon-removeStopPayments-transparent,
.ap-icon-removeHistory,
.ap-icon-removeHistory-transparent,
.ap-icon-transact2,
.ap-icon-transact2-transparent,
.ap-icon-transact3,
.ap-icon-transact3-transparent,
.ap-icon-transact1,
.ap-icon-transact1-transparent,
.ap-icon-zoomIn,
.ap-icon-zoomIn-transparent,
.ap-icon-zoomOut,
.ap-icon-zoomOut-transparent,
.ap-icon-rotateLeft,
.ap-icon-rotateLeft-transparent,
.ap-icon-rotateRight,
.ap-icon-rotateRight-transparent,
.ap-icon-configure,
.ap-icon-configure-transparent,
.ap-icon-shoppingCart,
.ap-icon-shoppingCart-transparent,
.ap-icon-shoppingCartFS,
.ap-icon-shoppingCartFS-transparent,
.ap-icon-calculator,
.ap-icon-calculator-transparent,
.ap-icon-calculatorB,
.ap-icon-calculatorB-transparent,
.ap-icon-device,
.ap-icon-device-transparent,
.ap-icon-mobileCheck,
.ap-icon-mobileCheck-transparent,
.ap-icon-stamp,
.ap-icon-stamp-transparent,
.ap-icon-reversePayment,
.ap-icon-reversePayment-transparent,
.ap-icon-stopPayment,
.ap-icon-stopPayment-transparent,
.ap-icon-rightArrowHand,
.ap-icon-rightArrowHand-transparent,
.ap-icon-verify,
.ap-icon-verify-transparent,
.ap-icon-char-asc,
.ap-icon-char-asc-transparent,
.ap-icon-char-desc,
.ap-icon-char-desc-transparent,
.ap-icon-num-asc,
.ap-icon-num-asc-transparent,
.ap-icon-num-desc,
.ap-icon-num-desc-transparent {
  width: 24px;
  margin: 2px 2px;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  background: transparent
    url("https://ib.absa.co.za/absa-online/static/style/resources/icons-sprite-2019.png")
    no-repeat top left;
  border: 0 !important;
  height: 22px;
}

.ap-icon-expandPlus {
  background-position: -8px -8px;
}

.ap-icon-contractMinus {
  background-position: -8px -38px;
}

.ap-icon-print {
  background-position: -8px -68px;
}

.ap-icon-info {
  background-position: -8px -98px;
}

.ap-icon-help {
  background-position: -8px -128px;
}

.ap-icon-help-inactive {
  background-position: -8px -158px;
}

.ap-icon-price {
  background-position: -8px -188px;
}

.ap-icon-price1 {
  background-position: -8px -188px;
}

.ap-icon-statement {
  background-position: -8px -218px;
}

.ap-icon-statement-inactivated {
  background-position: -8px -248px;
}

.ap-icon-mobile {
  background-position: -8px -278px;
}

.ap-icon-mobile-inactivated {
  background-position: -8px -308px;
}

.ap-icon-configure {
  background-position: -8px -338px;
}

.ap-icon-unsubscribeGadget {
  background-position: -8px -338px;
}

.ap-icon-view {
  background-position: -8px -368px;
}

.ap-icon-tools {
  background-position: -8px -398px;
}

.ap-icon-remove {
  background-position: -8px -428px;
}

.ap-icon-calendar {
  background-position: -8px -458px;
}

.ap-icon-upSharePrice {
  background-position: -8px -488px;
}

.ap-icon-downSharePrice {
  background-position: -8px -518px;
}

.ap-icon-addBen {
  background-position: -8px -548px;
}

.ap-icon-removeBen {
  background-position: -8px -578px;
}

.ap-icon-sms {
  background-position: -8px -608px;
}

.ap-icon-email {
  background-position: -8px -638px;
}

.ap-icon-fax {
  background-position: -8px -668px;
}

.ap-icon-none {
  background-position: -8px -698px;
}

.ap-icon-1030 {
  background-position: -8px -728px;
}

.ap-icon-3010 {
  background-position: -8px -758px;
}

.ap-icon-1040 {
  background-position: -8px -728px;
}

.ap-icon-4010 {
  background-position: -8px -758px;
}

.ap-icon-1045 {
  background-position: -8px -728px;
}

.ap-icon-4510 {
  background-position: -8px -758px;
}

.ap-icon-link {
  background-position: -8px -788px;
}

.ap-icon-linkShareTrading {
  background-position: -8px -788px;
}

.ap-icon-linkAims {
  background-position: -8px -788px;
}

.ap-icon-pieChart {
  background-position: -8px -818px;
}

.ap-icon-3dPieChart {
  background-position: -8px -848px;
}

.ap-icon-barChart {
  background-position: -8px -878px;
}

.ap-icon-arrowRight {
  background-position: -8px -908px;
}

.ap-icon-rightArrowRed {
  background-position: -8px -938px;
}

.ap-icon-rightArrowGreen {
  background-position: -8px -968px;
}

.ap-icon-logOff {
  background-position: -8px -998px;
}

.ap-icon-greenLock {
  background-position: -8px -1028px;
}

.ap-icon-logOn {
  background-position: -8px -1028px;
}

.ap-icon-termsAndConditions {
  background-position: -8px -1058px;
}

.ap-icon-clearCalculation {
  background-position: -8px -1088px;
}

.ap-icon-addShare {
  background-position: -8px -1118px;
}

.ap-icon-delete {
  background-position: -8px -1148px;
}

.ap-icon-red-close {
  background-position: -8px -1148px;
}

.ap-icon-reinstateBen {
  background-position: -8px -1178px;
}

.ap-icon-interAccountTransfer {
  background-position: -8px -1208px;
}

.ap-icon-viewHistory {
  background-position: -8px -1238px;
}

.ap-icon-viewRecurringPayment {
  background-position: -8px -1268px;
}

.ap-icon-addRecurring {
  background-position: -8px -1298px;
}

.ap-icon-viewStopPayments {
  background-position: -8px -1328px;
}

.ap-icon-addStopPayments {
  background-position: -8px -1358px;
}

.ap-icon-rightArrowHand {
  background-position: -8px -1388px;
}

.ap-icon-verify {
  background-position: -8px -1418px;
}

.ap-icon-char-asc {
  background-position: -8px -1414px;
}

.ap-icon-char-desc {
  background-position: -8px -1478px;
}

.ap-icon-num-asc {
  background-position: -8px -1508px;
}

.ap-icon-num-desc {
  background-position: -8px -1538px;
}

.ap-icon-pymnt {
  border: solid 1px #c8c8c8;
  width: 24px;
  height: 24px;
  margin: 3px 2px;
  display: inline-block;
  border-radius: 2px;
  cursor: pointer;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  background: transparent
    url("https://ib.absa.co.za/absa-online/static/style/resources/stop_payment.png")
    no-repeat top left;
}

.ap-icon-pymnt {
  background-position: center;
}

.ap-icon-doPay {
  border: solid 1px #c8c8c8;
  width: 24px;
  height: 24px;
  margin: 3px 2px;
  display: inline-block;
  border-radius: 2px;
  cursor: pointer;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  background: transparent
    url("https://ib.absa.co.za/absa-online/static/style/resources/dispute_debit_order.png")
    no-repeat top left;
}

.ap-icon-doPay {
  background-position: center;
}

.ap-icon-statusWarning {
  background-position: -8px -1388px;
}

.ap-icon-statusError {
  background-position: -8px -1418px;
}

.ap-icon-statusSuccess {
  background-position: -8px -1448px;
}

.ap-icon-green-tick {
  background-position: -8px -1478px;
}

.ap-icon-reject {
  background-position: -8px -1508px;
}

.ap-icon-close-bullet {
  background-position: -8px -1538px;
}

.ap-icon-closeRed {
  background-position: -8px -1568px;
}

.ap-icon-paySingle {
  background-position: -8px -1598px;
}

.ap-icon-addNewBen {
  background-position: -8px -1628px;
}

.ap-icon-viewBen2 {
  background-position: -8px -1658px;
}

.ap-icon-westernUnionSend {
  background-position: -8px -1688px;
}

.ap-icon-westernUnionRecieve {
  background-position: -8px -1718px;
}

.ap-icon-resend {
  background-position: -8px -1748px;
}

.ap-icon-settleBudget {
  background-position: -8px -1778px;
}

.ap-icon-settleAllBudgets {
  background-position: -8px -1808px;
}

.ap-icon-transact {
  background-position: -8px -1838px;
}

.ap-icon-sortBeneficiaries {
  background-position: -8px -1868px;
}

.ap-icon-replyMessage {
  background-position: -8px -1898px;
}

.ap-icon-reply {
  background-position: -8px -1898px;
}

.ap-icon-savgingsGoals {
  background-position: -8px -1928px;
}

.ap-icon-checkerFlag {
  background-position: -8px -1958px;
}

.ap-icon-download {
  background-position: -8px -1988px;
}

.ap-icon-viewPdf {
  background-position: -8px -2018px;
}

.ap-icon-note {
  background-position: -8px -2048px;
}

.ap-icon-note-white {
  background-position: -8px -2048px;
}

.ap-icon-change {
  background-position: -8px -2078px;
}

.ap-icon-sell {
  background-position: -8px -2108px;
}

.ap-icon-payOnceOff {
  background-position: -8px -2138px;
}

.ap-icon-cashSendSingle {
  background-position: -8px -2168px;
}

.ap-icon-cashSendOnceOff {
  background-position: -8px -2198px;
}

.ap-icon-prepaidSingle {
  background-position: -8px -2228px;
}

.ap-icon-prepaidOnceOff {
  background-position: -8px -2258px;
}

.ap-icon-payTrafficFine {
  background-position: -8px -2288px;
}

.ap-icon-authorisations {
  background-position: -8px -2318px;
}

.ap-icon-accountsInfo {
  background-position: -8px -2348px;
}

.ap-icon-viewMessage {
  background-position: -8px -2378px;
}

.ap-icon-archiveMessage {
  background-position: -8px -2408px;
}

.ap-icon-deleteMessage {
  background-position: -8px -2438px;
}

.ap-icon-resendMessage {
  background-position: -8px -2468px;
}

.ap-icon-help-active {
  background-position: -8px -2498px;
}

.ap-grey-plus {
  background-position: -8px -2528px;
}

.ap-icon-change-disabled {
  background-position: -8px -2558px;
}

.ap-icon-revert {
  background-position: -8px -2588px;
}

.ap-icon-resendWithdrawalCode {
  background-position: -8px -2618px;
}

.ap-icon-messageHistory {
  background-position: -8px -2648px;
}

.ap-icon-paySettleCCBudget {
  background-position: -8px -2678px;
}

.ap-icon-unlink {
  background-position: -8px -2708px;
}

.ap-icon-loadPayPass {
  background-position: -8px -2738px;
}

.ap-icon-settleAllCCBudgets {
  background-position: -8px -2768px;
}

.ap-icon-addSavingGoal {
  background-position: -8px -2798px;
}

.ap-icon-viewNotifyMeHistory {
  background-position: -8px -2828px;
}

.ap-icon-redeemRewards {
  background-position: -8px -2858px;
}

.ap-icon-minus {
  background-position: -8px -2888px;
}

.ap-icon-save {
  background-position: -8px -2918px;
}

.ap-icon-delete-disabled {
  background-position: -8px -2948px;
}

.ap-icon-rejectDevice {
  background-position: -8px -2978px;
}

.ap-icon-linkDevice {
  background-position: -8px -3008px;
}

.ap-icon-callMe {
  background-position: -8px -3068px;
}

.ap-icon-receiveMoney {
  background-position: -8px -3038px;
}

.ap-icon-addBeneficiary {
  background-position: -8px -3098px;
}

.ap-icon-payArrears {
  background-position: -8px -3128px;
}

.ap-icon-reactivatePolicy {
  background-position: -8px -3158px;
}

.ap-icon-submitClaim {
  background-position: -8px -3188px;
}

.ap-icon-viewCorrespondence {
  background-position: -8px -3218px;
}

.ap-icon-managePolicy {
  background-position: -8px -3248px;
}

.ap-icon-addAdditionalFamilyMember {
  background-position: -8px -3278px;
}

.ap-icon-delinkDevice {
  background-position: -8px -3338px;
}

.ap-icon-endorsedDocument {
  background-position: -8px -3368px;
}

.ap-icon-natureOfPayment {
  background-position: -8px -3398px;
}

.ap-icon-advisor {
  background-position: -8px -3428px;
}

.ap-icon-contactUs {
  background-position: -8px -3458px;
}

.ap-icon-switch {
  background-position: -8px -3488px;
}

.ap-icon-growFont {
  background-position: -8px -3518px;
}

.ap-icon-shrinkFont {
  background-position: -8px -3548px;
}

.ap-icon-bold {
  background-position: -8px -3578px;
}

.ap-icon-colorPalette {
  background-position: -8px -3608px;
}

.ap-icon-stretch {
  background-position: -8px -3638px;
}

.ap-icon-3060 {
  background-position: -8px -3668px;
}

.ap-icon-removeStopPayments {
  background-position: -8px -3698px;
}

.ap-icon-removeHistory {
  background-position: -8px -3728px;
}

.ap-icon-transact2 {
  background-position: -8px -3758px;
}

.ap-icon-transact3 {
  background-position: -8px -3788px;
}

.ap-icon-transact1 {
  background-position: -8px -3818px;
}

.ap-icon-zoomIn {
  background-position: -8px -3848px;
}

.ap-icon-zoomOut {
  background-position: -8px -3878px;
}

.ap-icon-rotateLeft {
  background-position: -8px -3908px;
}

.ap-icon-rotateRight {
  background-position: -8px -3938px;
}

.ap-icon-switch {
  background-position: -8px -3488px;
}

.ap-icon-shoppingCart {
  background-position: -8px -3968px;
}

.ap-icon-shoppingCartFS {
  background-position: -8px -3968px;
}

.ap-icon-calculator {
  background-position: -8px -3998px;
}

.ap-icon-calculatorB {
  background-position: -8px -4028px;
}

.ap-icon-device {
  background-position: -8px -4058px;
}

.ap-icon-mobileCheck {
  background-position: -8px -4058px;
}

.ap-icon-stamp {
  background-position: -8px -4088px;
}

.ap-icon-reversePayment {
  background-position: -8px -4118px;
}

.ap-icon-stopPayment {
  background-position: -8px -4148px;
}

.ap-icon-rightArrowHand {
  background-position: -8px -4178px;
}

.ap-icon-verify {
  background-position: -8px -4208px;
}

.ap-icon-char-asc {
  background-position: -8px -4238px;
}

.ap-icon-char-desc {
  background-position: -8px -4268px;
}

.ap-icon-num-asc {
  background-position: -8px -4298px;
}

.ap-icon-num-desc {
  background-position: -8px -4328px;
}

.ap-icon-expandPlus-transparent {
  background-position: -38px -8px;
}

.ap-icon-contractMinus-transparent {
  background-position: -38px -38px;
}

.ap-icon-print-transparent {
  background-position: -38px -68px;
}

.ap-icon-info-transparent {
  background-position: -38px -98px;
}

.ap-icon-help-transparent {
  background-position: -38px -128px;
}

.ap-icon-help-inactive-transparent {
  background-position: -38px -158px;
}

.ap-icon-price-transparent {
  background-position: -38px -188px;
}

.ap-icon-statement-transparent {
  background-position: -38px -218px;
}

.ap-icon-statement-inactivated-transparent {
  background-position: -38px -248px;
}

.ap-icon-mobile-transparent {
  background-position: -38px -278px;
}

.ap-icon-mobile-inactivated-transparent {
  background-position: -38px -308px;
}

.ap-icon-configure-transparent {
  background-position: -38px -338px;
}

.ap-icon-unsubscribeGadget-transparent {
  background-position: -38px -338px;
}

.ap-icon-view-transparent {
  background-position: -38px -368px;
}

.ap-icon-tools-transparent {
  background-position: -38px -398px;
}

.ap-icon-remove-transparent {
  background-position: -38px -428px;
}

.ap-icon-calendar-transparent {
  background-position: -38px -458px;
}

.ap-icon-upSharePrice-transparent {
  background-position: -38px -488px;
}

.ap-icon-downSharePrice-transparent {
  background-position: -38px -518px;
}

.ap-icon-addBen-transparent {
  background-position: -38px -548px;
}

.ap-icon-removeBen-transparent {
  background-position: -38px -578px;
}

.ap-icon-sms-transparent {
  background-position: -38px -608px;
}

.ap-icon-email-transparent {
  background-position: -38px -638px;
}

.ap-icon-fax-transparent {
  background-position: -38px -668px;
}

.ap-icon-none-transparent {
  background-position: -38px -698px;
}

.ap-icon-1030-transparent {
  background-position: -38px -728px;
}

.ap-icon-3010-transparent {
  background-position: -38px -758px;
}

.ap-icon-1040-transparent {
  background-position: -38px -728px;
}

.ap-icon-4010-transparent {
  background-position: -38px -758px;
}

.ap-icon-1045-transparent {
  background-position: -38px -728px;
}

.ap-icon-4510-transparent {
  background-position: -38px -758px;
}

.ap-icon-link-transparent {
  background-position: -38px -788px;
}

.ap-icon-linkShareTrading-transparent {
  background-position: -38px -788px;
}

.ap-icon-linkAims-transparent {
  background-position: -38px -788px;
}

.ap-icon-pieChart-transparent {
  background-position: -38px -818px;
}

.ap-icon-3dPieChart-transparent {
  background-position: -38px -848px;
}

.ap-icon-barChart-transparent {
  background-position: -38px -878px;
}

.ap-icon-arrowRight-transparent {
  background-position: -38px -908px;
}

.ap-icon-rightArrowRed-transparent {
  background-position: -38px -938px;
}

.ap-icon-rightArrowGreen-transparent {
  background-position: -38px -968px;
}

.ap-icon-logOff-transparent {
  background-position: -38px -998px;
}

.ap-icon-greenLock-transparent {
  background-position: -38px -1028px;
}

.ap-icon-logOn-transparent {
  background-position: -38px -1028px;
}

.ap-icon-termsAndConditions-transparent {
  background-position: -38px -1058px;
}

.ap-icon-clearCalculation-transparent {
  background-position: -38px -1088px;
}

.ap-icon-addShare-transparent {
  background-position: -38px -1118px;
}

.ap-icon-delete-transparent {
  background-position: -38px -1148px;
}

.ap-icon-red-close-transparent {
  background-position: -38px -1148px;
}

.ap-icon-reinstateBen-transparent {
  background-position: -38px -1178px;
}

.ap-icon-interAccountTransfer-transparent {
  background-position: -38px -1208px;
}

.ap-icon-viewHistory-transparent {
  background-position: -38px -1238px;
}

.ap-icon-viewRecurringPayment-transparent {
  background-position: -38px -1268px;
}

.ap-icon-addRecurring-transparent {
  background-position: -38px -1298px;
}

.ap-icon-viewStopPayments-transparent {
  background-position: -38px -1328px;
}

.ap-icon-addStopPayments-transparent {
  background-position: -38px -1358px;
}

.ap-icon-statusWarning-transparent {
  background-position: -38px -1388px;
}

.ap-icon-statusError-transparent {
  background-position: -38px -1418px;
}

.ap-icon-statusSuccess-transparent {
  background-position: -38px -1448px;
}

.ap-icon-green-tick-transparent {
  background-position: -38px -1478px;
}

.ap-icon-reject-transparent {
  background-position: -38px -1508px;
}

.ap-icon-close-bullet-transparent {
  background-position: -38px -1538px;
}

.ap-icon-closeRed-transparent {
  background-position: -38px -1568px;
}

.ap-icon-paySingle-transparent {
  background-position: -38px -1598px;
}

.ap-icon-addNewBen-transparent {
  background-position: -38px -1628px;
}

.ap-icon-viewBen2-transparent {
  background-position: -38px -1658px;
}

.ap-icon-westernUnionSend-transparent {
  background-position: -38px -1688px;
}

.ap-icon-westernUnionRecieve-transparent {
  background-position: -38px -1718px;
}

.ap-icon-resend-transparent {
  background-position: -38px -1748px;
}

.ap-icon-settleBudget-transparent {
  background-position: -38px -1778px;
}

.ap-icon-settleAllBudgets-transparent {
  background-position: -38px -1808px;
}

.ap-icon-transact-transparent {
  background-position: -38px -1838px;
}

.ap-icon-sortBeneficiaries-transparent {
  background-position: -38px -1868px;
}

.ap-icon-replyMessage-transparent {
  background-position: -38px -1898px;
}

.ap-icon-reply-transparent {
  background-position: -38px -1898px;
}

.ap-icon-savgingsGoals-transparent {
  background-position: -38px -1928px;
}

.ap-icon-checkerFlag-transparent {
  background-position: -38px -1958px;
}

.ap-icon-download-transparent {
  background-position: -38px -1988px;
}

.ap-icon-viewPdf-transparent {
  background-position: -38px -2018px;
}

.ap-icon-note-transparent {
  background-position: -38px -2048px;
}

.ap-icon-note-white-transparent {
  background-position: -38px -2048px;
}

.ap-icon-change-transparent {
  background-position: -38px -2078px;
}

.ap-icon-sell-transparent {
  background-position: -38px -2108px;
}

.ap-icon-payOnceOff-transparent {
  background-position: -38px -2138px;
}

.ap-icon-cashSendSingle-transparent {
  background-position: -38px -2168px;
}

.ap-icon-cashSendOnceOff-transparent {
  background-position: -38px -2198px;
}

.ap-icon-prepaidSingle-transparent {
  background-position: -38px -2228px;
}

.ap-icon-prepaidOnceOff-transparent {
  background-position: -38px -2258px;
}

.ap-icon-payTrafficFine-transparent {
  background-position: -38px -2288px;
}

.ap-icon-authorisations-transparent {
  background-position: -38px -2318px;
}

.ap-icon-accountsInfo-transparent {
  background-position: -38px -2348px;
}

.ap-icon-viewMessage-transparent {
  background-position: -38px -2378px;
}

.ap-icon-archiveMessage-transparent {
  background-position: -38px -2408px;
}

.ap-icon-deleteMessage-transparent {
  background-position: -38px -2438px;
}

.ap-icon-resendMessage-transparent {
  background-position: -38px -2468px;
}

.ap-icon-help-active-transparent {
  background-position: -38px -2498px;
}

.ap-grey-plus-transparent {
  background-position: -38px -2528px;
}

.ap-icon-change-disabled-transparent {
  background-position: -38px -2558px;
}

.ap-icon-revert-transparent {
  background-position: -38px -2588px;
}

.ap-icon-resendWithdrawalCode-transparent {
  background-position: -38px -2618px;
}

.ap-icon-messageHistory-transparent {
  background-position: -38px -2648px;
}

.ap-icon-paySettleCCBudget-transparent {
  background-position: -38px -2678px;
}

.ap-icon-unlink-transparent {
  background-position: -38px -2708px;
}

.ap-icon-loadPayPass-transparent {
  background-position: -38px -2738px;
}

.ap-icon-settleAllCCBudgets-transparent {
  background-position: -38px -2768px;
}

.ap-icon-addSavingGoal-transparent {
  background-position: -38px -2798px;
}

.ap-icon-viewNotifyMeHistory-transparent {
  background-position: -38px -2828px;
}

.ap-icon-redeemRewards-transparent {
  background-position: -38px -2858px;
}

.ap-icon-minus-transparent {
  background-position: -38px -2888px;
}

.ap-icon-save-transparent {
  background-position: -38px -2918px;
}

.ap-icon-delete-disabled-transparent {
  background-position: -38px -2948px;
}

.ap-icon-rejectDevice-transparent {
  background-position: -38px -2978px;
}

.ap-icon-linkDevice-transparent {
  background-position: -38px -3008px;
}

.ap-icon-advisor-transparent {
  background-position: -38px -3428px;
}

.ap-icon-contactUs-transparent {
  background-position: -38px -3038px;
}

.ap-icon-switch-transparent {
  background-position: -38px -3488px;
}

.ap-icon-growFont-transparent {
  background-position: -38px -3518px;
}

.ap-icon-shrinkFont-transparent {
  background-position: -38px -3548px;
}

.ap-icon-bold-transparent {
  background-position: -38px -3578px;
}

.ap-icon-colorPalette-transparent {
  background-position: -38px -3608px;
}

.ap-icon-stretch-transparent {
  background-position: -38px -3638px;
}

.ap-icon-3060-transparent {
  background-position: -38px -3668px;
}

.ap-icon-removeStopPayments-transparent {
  background-position: -38px -3698px;
}

.ap-icon-removeHistory-transparent {
  background-position: -38px -3728px;
}

.ap-icon-transact2-transparent {
  background-position: -38px -3758px;
}

.ap-icon-transact3-transparent {
  background-position: -38px -3788px;
}

.ap-icon-transact1-transparent {
  background-position: -38px -3818px;
}

.ap-icon-zoomIn-transparent {
  background-position: -38px -3848px;
}

.ap-icon-zoomOut-transparent {
  background-position: -38px -3878px;
}

.ap-icon-rotateLeft-transparent {
  background-position: -38px -3908px;
}

.ap-icon-rotateRight-transparent {
  background-position: -38px -3938px;
}

.ap-icon-switch-transparent {
  background-position: -38px -3488px;
}

.ap-icon-growFont-transparent {
  background-position: -38px -3518px;
}

.ap-icon-shrinkFont-transparent {
  background-position: -38px -3548px;
}

.ap-icon-bold-transparent {
  background-position: -38px -3578px;
}

.ap-icon-colorPalette-transparent {
  background-position: -38px -3608px;
}

.ap-icon-stretch-transparent {
  background-position: -38px -3638px;
}

.ap-icon-3060-transparent {
  background-position: -38px -3668px;
}

.ap-icon-removeStopPayments-transparent {
  background-position: -38px -3698px;
}

.ap-icon-removeHistory-transparent {
  background-position: -38px -3728px;
}

.ap-icon-transact2-transparent {
  background-position: -38px -3758px;
}

.ap-icon-transact3-transparent {
  background-position: -38px -3788px;
}

.ap-icon-transact1-transparent {
  background-position: -38px -3818px;
}

.ap-icon-zoomIn-transparent {
  background-position: -38px -3848px;
}

.ap-icon-zoomOut-transparent {
  background-position: -38px -3878px;
}

.ap-icon-rotateLeft-transparent {
  background-position: -38px -3908px;
}

.ap-icon-rotateRight-transparent {
  background-position: -38px -3938px;
}

.ap-icon-shoppingCart-transparent {
  background-position: -38px -3968px;
}

.ap-icon-shoppingCartFS-transparent {
  background-position: -38px -3968px;
}

.ap-icon-calculator-transparent {
  background-position: -38px -3998px;
}

.ap-icon-calculatorB-transparent {
  background-position: -38px -4028px;
}

.ap-icon-device-transparent {
  background-position: -38px -4058px;
}

.ap-icon-mobileCheck-transparent {
  background-position: -38px -4058px;
}

.ap-icon-stamp-transparent {
  background-position: -38px -4088px;
}

.ap-icon-reversePayment-transparent {
  background-position: -38px -4118px;
}

.ap-icon-stopPayment-transparent {
  background-position: -38px -4148px;
}

.ap-icon-rightArrowHand-transparent {
  background-position: -8px -4178px;
}

.ap-icon-verify-transparent {
  background-position: -8px -4208px;
}

.ap-icon-char-asc-transparent {
  background-position: -8px -4238px;
}

.ap-icon-char-desc-transparent {
  background-position: -8px -4268px;
}

.ap-icon-num-asc-transparent {
  background-position: -8px -4298px;
}

.ap-icon-num-desc-transparent {
  background-position: -8px -4328px;
}

.ap-icon-expandPlus-transparent,
.ap-icon-contractMinus-transparent,
.ap-icon-print-transparent,
.ap-icon-info-transparent,
.ap-icon-help-transparent,
.ap-icon-info-faded-transparent,
.ap-icon-help-inactive-transparent,
.ap-icon-price-transparent,
.ap-icon-statement-transparent,
.ap-icon-statement-inactivated-transparent,
.ap-icon-mobile-transparent,
.ap-icon-mobile-inactivated-transparent,
.ap-icon-configure-transparent,
.ap-icon-unsubscribeGadget-transparent,
.ap-icon-view-transparent,
.ap-icon-tools-transparent,
.ap-icon-remove-transparent,
.ap-icon-calendar-transparent,
.ap-icon-upSharePrice-transparent,
.ap-icon-downSharePrice-transparent,
.ap-icon-addBen-transparent,
.ap-icon-removeBen-transparent,
.ap-icon-sms-transparent,
.ap-icon-email-transparent,
.ap-icon-fax-transparent,
.ap-icon-none-transparent,
.ap-icon-1030-transparent,
.ap-icon-3010-transparent,
.ap-icon-1040-transparent,
.ap-icon-4010-transparent,
.ap-icon-1045-transparent,
.ap-icon-4510-transparent,
.ap-icon-link-transparent,
.ap-icon-linkShareTrading-transparent,
.ap-icon-linkAims-transparent,
.ap-icon-pieChart-transparent,
.ap-icon-changeGraph-lightGrey-transparent,
.ap-icon-3dPieChart-transparent,
.ap-icon-barChart-transparent,
.ap-icon-arrowRight-transparent,
.ap-icon-rightArrowRed-transparent,
.ap-icon-rightArrowGreen-transparent,
.ap-icon-logOff-transparent,
.ap-icon-greenLock-transparent,
.ap-icon-logOn-transparent,
.ap-icon-termsAndConditions-transparent,
.ap-icon-clearCalculation-transparent,
.ap-icon-addShare-transparent,
.ap-icon-delete-transparent,
.ap-icon-red-close-transparent,
.ap-icon-reinstateBen-transparent,
.ap-icon-interAccountTransfer-transparent,
.ap-icon-viewHistory-transparent,
.ap-icon-viewRecurringPayment-transparent,
.ap-icon-addRecurring-transparent,
.ap-icon-viewStopPayments-transparent,
.ap-icon-addStopPayments-transparent,
.ap-icon-statusWarning-transparent,
.ap-icon-statusError-transparent,
.ap-icon-statusSuccess-transparent,
.ap-icon-green-tick-transparent,
.ap-icon-reject-transparent,
.ap-icon-close-bullet-transparent,
.ap-icon-closeRed-transparent,
.ap-icon-paySingle-transparent,
.ap-icon-addNewBen-transparent,
.ap-icon-viewBen2-transparent,
.ap-icon-westernUnionSend-transparent,
.ap-icon-westernUnionRecieve-transparent,
.ap-icon-resend-transparent,
.ap-icon-settleBudget-transparent,
.ap-icon-settleAllBudgets-transparent,
.ap-icon-transact-transparent,
.ap-icon-sortBeneficiaries-transparent,
.ap-icon-replyMessage-transparent,
.ap-icon-reply-transparent,
.ap-icon-savgingsGoals-transparent,
.ap-icon-checkerFlag-transparent,
.ap-icon-download-transparent,
.ap-icon-viewPdf-transparent,
.ap-icon-note-transparent,
.ap-icon-note-white-transparent,
.ap-icon-change-transparent,
.ap-icon-sell-transparent,
.ap-icon-payOnceOff-transparent,
.ap-icon-cashSendSingle-transparent,
.ap-icon-cashSendOnceOff-transparent,
.ap-icon-prepaidSingle-transparent,
.ap-icon-prepaidOnceOff-transparent,
.ap-icon-payTrafficFine-transparent,
.ap-icon-authorisations-transparent,
.ap-icon-accountsInfo-transparent,
.ap-icon-viewMessage-transparent,
.ap-icon-archiveMessage-transparent,
.ap-icon-deleteMessage-transparent,
.ap-icon-resendMessage-transparent,
.ap-icon-help-active-transparent,
.ap-grey-plus-transparent,
.ap-icon-change-disabled-transparent,
.ap-icon-revert-transparent,
.ap-icon-resendWithdrawalCode-transparent,
.ap-icon-messageHistory-transparent,
.ap-icon-paySettleCCBudget-transparent,
.ap-icon-unlink-transparent,
.ap-icon-loadPayPass-transparent,
.ap-icon-settleAllCCBudgets-transparent,
.ap-icon-addSavingGoal-transparent,
.ap-icon-viewNotifyMeHistory-transparent,
.ap-icon-redeemRewards-transparent,
.ap-icon-minus-transparent,
.ap-icon-save-transparent,
.ap-icon-delete-disabled-transparent,
.ap-icon-rejectDevice-transparent,
.ap-icon-linkDevice-transparent,
.ap-icon-addAdditionalFamilyMember,
.ap-icon-advisor-transparent,
.ap-icon-contactUs-transparent,
.ap-icon-switch-transparent,
.ap-icon-growFont-transparent,
.ap-icon-shrinkFont-transparent,
.ap-icon-bold-transparent,
.ap-icon-colorPalette-transparent,
.ap-icon-stretch-transparent,
.ap-icon-3060-transparent,
.ap-icon-removeStopPayments-transparent,
.ap-icon-removeHistory-transparent,
.ap-icon-transact2-transparent,
.ap-icon-transact3-transparent,
.ap-icon-transact1-transparent,
.ap-icon-zoomIn-transparent,
.ap-icon-zoomOut-transparent,
.ap-icon-rotateLeft-transparent,
.ap-icon-rotateRight-transparent,
.ap-icon-shoppingCart-transparent,
.ap-icon-shoppingCartFS-transparent,
.ap-icon-calculator-transparent,
.ap-icon-calculatorB-transparent,
.ap-icon-device-transparent,
.ap-icon-stamp-transparent,
.ap-icon-reversePayment-transparent,
.ap-icon-stopPayment-transparent,
.ap-icon-rightArrowHand-transparent,
.ap-icon-verify-transparent,
.ap-icon-char-asc-transparent,
.ap-icon-char-desc-transparent,
.ap-icon-num-asc-transparent,
.ap-icon-num-desc-transparent {
  border: none;
}

.ap-dashboard-dummy .p-gadget-header {
  display: none;
}

.ap-dashboard-dummy .ap-container {
  border: none;
}

.ap-dashboard-dummy .ap-highlevel-gadget .p-gadget-header {
  height: 50px;
}

.ap-dashboard-dummy .ap-gadget-lazyContent .p-gadget-header {
  display: block;
  border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-bottom-right-radius: 0;
}

.ap-dashboard-dummy .p-gadget-button {
  background: #fff;
  height: 50px;
}

.ap-dashboard-dummy .ap-bar-section.p-gadget-title {
  margin: 6px;
}

.ap-dashboard-dummy .ap-gadget-button-container .ap-icon-print,
.ap-dashboard-dummy .ap-gadget-button-container .ap-icon-shoppingCartFS {
  position: absolute;
  top: -1px;
  right: 3px;
}

.ap-dashboard-dummy .ap-icon-print {
  margin-top: 13px;
}

.ap-dashboard-dummy .p-gadget .p-gadget-content {
  padding: 5px !important;
  border-width: 1px 0 0 1px;
  border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  -webkit-border-top-left-radius: 0;
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-left-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
}

.ap-dashboard-dummy .p-gadget-content {
  border: none;
  border-radius: 4px 4px 4px 4px;
  -moz-border-radius: 4px 4px 4px 4px;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
}

.ap-dashboard-content {
  height: 218px;
  overflow: hidden;
  position: relative;
}

.ap-dashboard-dummy .ap-titlebar .ui-tabHeads {
  background-color: transparent;
  width: 100%;
  color: #af154b;
}

.ap-dashboard-dummy .ap-titlebar .ui-tabHeads .ap-viewSecureMessage {
  float: right;
  background-color: transparent;
  background: transparent
    url("https://ib.absa.co.za/absa-online/static/style/resources/tabs-separator.png")
    no-repeat left 8px;
  padding-left: 10px;
}

.ap-dashboard-dummy .ap-titlebar .ui-tabHeads .ap-tabHead-contactPrivateAssist {
  float: right;
  background-color: transparent;
  background: transparent
    url("https://ib.absa.co.za/absa-online/static/style/resources/tabs-separator.png")
    no-repeat left 8px;
}

.ap-dashboard-dummy .ui-tabBox .ap-gadget-button-container {
  padding: 0;
}

.ap-dashboard-dummy .ap-titlebar .ap-icon-print,
.ap-dashboard-dummy .ap-titlebar .ap-icon-shoppingCartFS {
  position: absolute;
  right: 7px;
  top: 1px;
}

.ap-dashboard-dummy .p-gadget-minimized .ap-titlebar .ap-icon-print,
.ap-dashboard-dummy .p-gadget-minimized .ap-titlebar .ap-icon-shoppingCartFS {
  display: none;
}

.ap-dashboard-dummy .ap-titlebar .ap-icon-callMe {
  position: absolute;
  right: 35px;
  top: 13px;
}

.ap-dashboard-dummy .p-gadget-minimized .ap-titlebar .ap-icon-callMe {
  display: none;
}

.ap-dashboard-dummy .ap-titlebar .ui-tabHeads {
  width: 904px;
}

.ap-dashboard-insurance .ap-dashboard-dummy .ap-titlebar .ui-tabHeads {
  width: 876px;
}

.ap-dashboard-dummy .p-gadget-minimized .ap-titlebar .ui-tabHeads {
  width: 100%;
}

.ie7 .ap-dashboard-dummy .p-gadget-minimized .ap-titlebar .ui-tabHeads {
  width: 920px;
}

.ie7 .ap-dashboard-dummy .ap-titlebar .ui-tabHeads {
  margin-left: -15px;
  width: 885px;
  display: inline;
}

.ie7 .ap-dashboard-dummy .ap-titlebar .ui-tabHeads .ui-tabHead {
  padding-bottom: 5px;
}

.ap-dashboard-dummy .p-gadget-minimized .ap-titlebar .ui-tabHead {
  color: black;
}

.ap-dashboard-dummy .ap-titlebar .ui-tabHead {
  font-weight: bold;
  border: none;
  background-color: transparent;
  background: transparent
    url("https://ib.absa.co.za/absa-online/static/style/resources/tabs-separator.png")
    no-repeat left 8px;
  padding-top: 8px;
}

.ap-dashboard-dummy .ap-titlebar .ui-tab-first-tab {
  background-image: none;
}

.ap-dashboard-dummy .ap-titlebar .ui-tabHead:hover {
  text-decoration: underline;
  color: #af154b;
}

.ap-dashboard-dummy .ap-titlebar .ui-tabHeads-titlebar {
  position: static;
  right: 0;
}

.ap-dashboard-dummy .ap-titlebar li.ui-tab-selected {
  background-color: transparent;
  border: 0;
  font-weight: bold;
}

.ap-dashboard-content .ui-tabHeads {
  top: 0;
}

.ap-columns-50-50 .ap-column-1,
.ap-columns-50-50 .ap-column-2 {
  width: 50%;
}

.ap-columns-33 .ap-column-1 {
  width: 296px;
  padding-left: 0;
}

.ap-columns-33 .ap-column-2 {
  width: 296px;
  padding-left: 14px;
}

.ap-columns-33 .ap-column-3 {
  width: 296px;
  padding-left: 14px;
}

.ap-columns-75-25 .ap-column-1 {
  width: 211px;
  padding-left: 5px;
}

.ap-columns-75-25 .ap-column-2 {
  width: 713px;
  padding-left: 0;
}

.ap-columns-2-lhs .ap-column-1,
.ap-columns-2-rhs .ap-column-2 {
  width: 64%;
}

.ap-columns-2-lhs .ap-column-2,
.ap-columns-2-rhs .ap-column-1 {
  width: 35%;
}

.ap-columns-3 .ap-column-1,
.ap-columns-3 .ap-column-3 {
  width: 25%;
}

.ap-columns-3 .ap-column-2 {
  width: 50%;
}

.ap-columns {
  clear: both;
  padding-top: 3px;
  padding-left: 8px;
}

.ap-columns-clear {
  clear: both;
}

.ap-column-1 {
  float: left;
  padding-left: 7px;
}

.ap-column-2,
.ap-column-3 {
  float: right;
}

.ap-columns-33 .ap-column-2,
.ap-columns-33 .ap-column-3 {
  float: left;
}

.ap-rounded-corner {
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-bottomtight: 0;
  -moz-border-radius-topleft: 4px;
  -moz-border-radius-topright: 4px;
  border-color: #c8c8c8;
  border-width: 1px;
  border-style: solid;
}

.ap-page-content-dashboard {
  width: 944px;
}

.ap-highlevel-gadget {
  height: auto;
  overflow: hidden;
  position: static !important;
  width: auto;
}

.ap-highlevel-gadget .p-gadget-header {
  height: 36px;
  border-width: 0;
}

.ap-highlevel-gadget .p-gadget-content {
  clear: both;
  padding: 2px 12px;
  border: solid 1px #c8c8c8;
  border-width: 0 1px;
}

.ap-highlevel-gadget .p-gadget-bottom {
  background: transparent
    url("https://ib.absa.co.za/absa-online/static/style/resources/gadget-bg.png")
    no-repeat 0 -67px;
  clear: both;
  height: 6px;
  width: 964px;
  overflow: hidden;
}

.ap-dashboard-dummy
  .ap-titlebar
  .ui-tabHeads
  .ap-tabHead-contactPrivateAssist-fullName {
  color: black;
  font-weight: bold;
  text-decoration: none !important;
  background: none !important;
  opacity: 1 !important;
  padding-right: 10px !important;
  cursor: default !important;
  line-height: 16px !important;
  border: 0 !important;
  padding-top: 5px !important;
}

.ie .ap-notification-row .ap-notification-cell {
  padding-right: 10px;
}

.ap-notifications-icons,
.ap-notifications-content {
  float: right;
  padding-right: 7px;
}

.ap-notifications-label {
  font-size: 11px;
  font-family: tahoma;
}

.ui-modal .ap-notifications-icons,
.ui-tableBodyRowExpandableExtension--expandedContent .ap-notifications-icons,
.ui-modal .ap-notifications-content,
.ui-tableBodyRowExpandableExtension--expandedContent .ap-notifications-content {
  padding-right: 0;
}

.ap-notifications div,
.ap-notifications-icons,
.ap-notifications-iconTitle,
.ap-notifications-my,
.ap-notifications-ben,
.ap-notifications-content,
.ap-notifications-label {
  height: 30px;
  line-height: 30px;
}

.ap-notifications-iconTitle {
  line-height: 15px !important;
  vertical-align: middle;
  display: table-cell;
}

.ie7 .ap-notifications-iconTitle {
  vertical-align: top;
}

.ie7 .ap-notifications-icons,
.ie7 .ap-notifications-iconTitle,
.ie7 .ap-notifications-content,
.ie7 .ap-notifications-label {
  height: 25px;
  padding-top: 5px;
}

.ap-notifications-iconsEdit {
  line-height: 25px !important;
}

.ap-common-fontRed {
  color: #af144b;
}

.ap-common-alignLeft {
  text-align: left;
}

.ap-common-alignRight {
  text-align: right;
}

.ap-common-alignCenter {
  text-align: center;
}

.ap-common-fontBold {
  font-weight: bold !important;
}

.ap-common-fontNormal {
  font-weight: normal !important;
}

.ap-apb {
  width: 50px;
  float: left;
  height: 100%;
}

.ap-apb-cellalign {
  width: 220px;
  float: left;
}

.ap-apb-rightalign {
  height: 100%;
}

.ap-common-overFlow-hidden {
  overflow: hidden;
}

.ap-common-overFlow-auto {
  overflow: auto;
}

.ap-common-hide {
  display: none;
}

.ap-common-border {
  border: 1px solid #c8c8c8;
}

.ap-common-borderTop {
  border-top: 1px solid #c8c8c8;
}

.ap-common-margin {
  margin: 10px !important;
}

.ap-common-marginTop {
  margin-top: 10px !important;
}

.ap-common-borderRight {
  border-right: 1px solid #c8c8c8 !important;
}

.ap-common-marginRight {
  border-right: 10px !important;
}

.ap-common-borderBottom {
  border-bottom: 1px solid #c8c8c8;
}

.ap-common-marginBottom {
  margin-bottom: 10px !important;
}

.ap-common-borderLeft {
  border-left: 1px solid #c8c8c8;
}

.ap-common-marginLeft {
  margin-left: 10px !important;
}

.ap-common-wrap {
  height: 30px;
  line-height: 15px;
}

.ap-common-show {
  display: block;
}

.ap-common-verticalAlign-top {
  vertical-align: top;
}

.ap-common-verticalAlign-middle {
  vertical-align: middle;
}

.ap-common-verticalAlign-bottom {
  vertical-align: bottom;
}

.ap-common-floatMiddle {
  float: middle;
}

.ap-common-floatRight {
  float: right;
}

.ap-common-floatLeft {
  float: left;
}

.ap-common-noPadding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ap-common-paddingTop {
  padding-top: 10px !important;
}

.ap-common-paddingBottom {
  padding-bottom: 10px !important;
}

.ap-common-paddingLeft {
  padding-left: 10px !important;
}

.ap-common-paddingRight {
  padding-right: 10px !important;
}

.ap-common-noBorder {
  border: 0 !important;
}

.ap-common-noBorderTop {
  border-top: 0 !important;
}

.ap-common-noBorderBottom {
  border-bottom: 0 !important;
}

.ap-common-noBorderRight {
  border-right: 0 !important;
}

.ap-common-lightGreyBorderTop {
  border-top-color: #e5e5e5;
}

.ap-common-lightGreyBorderRight {
  border-right-color: #e5e5e5;
}

.ap-common-lightGreyBorderBottom {
  border-bottom-color: #e5e5e5;
}

.ap-common-lightGreyBorderLeft {
  border-left-color: #e5e5e5;
}

.ap-common-smallLineHeight {
  line-height: 15px;
}

.ap-common-noLineHeight {
  line-height: 0;
}

.ap-common-noWrap {
  white-space: nowrap;
}

.ap-cell-head {
  background-color: #e5e5e5;
}

.ap-row-head .ui-cell {
  background-color: #e5e5e5;
}

.ap-label-details .ui-label--icon {
  margin-left: 4px;
  background: transparent
    url("https://ib.absa.co.za/absa-online/static/style/resources/icons-sprite-2019.png")
    no-repeat scroll -38px -98px;
  vertical-align: middle;
  padding-bottom: 2px;
  width: 22px;
  height: 22px;
}

.ap-label-green-tick-transparent .ui-label--icon {
  width: 18px;
  height: 18px;
  margin-left: 4px;
  background: transparent
    url("https://ib.absa.co.za/absa-online/static/style/resources/icons-sprite-2019.png")
    no-repeat scroll -38px -1478px;
  vertical-align: middle;
  padding-bottom: 2px;
}

.ap-label-green-tick-transparent-avs .ui-label--icon {
  width: 18px;
  height: 25px;
  margin-left: 4px;
  background: transparent
    url("https://ib.absa.co.za/absa-online/static/style/resources/icons-sprite-2019.png")
    no-repeat scroll -38px -1478px;
  vertical-align: middle;
  padding-bottom: 2px;
}

.ap-label-red-cross-transparent-avs .ui-label--icon {
  width: 18px;
  height: 25px;
  margin-left: 4px;
  background: transparent
    url("https://ib.absa.co.za/absa-online/static/style/resources/icons-sprite-2019.png")
    no-repeat scroll -38px -1508px;
  vertical-align: middle;
  padding-bottom: 2px;
}

.ap-row-head-lightGrey .ui-cell {
  background-color: #f4f4f4;
}

.ap-common-cursor {
  cursor: pointer;
}

.ap-common-positionRelative {
  position: relative;
}

.ap-common-fontUnderLined {
  text-decoration: underline;
}

.android .ap-common-noTapHighlight {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
}

.android .ap-common-noTapHighlight .ap-common-defaultTapHighlight {
  -webkit-tap-highlight-color: rgba(10, 20, 30, 1);
  tap-highlight-color: rgba(10, 20, 30, 1);
}

.ap-common-fontSize7px {
  font-size: 7px !important;
}

.ap-common-fontSize8px {
  font-size: 8px !important;
}

.ap-common-fontSize9px {
  font-size: 9px !important;
}

.ap-common-fontSize10px {
  font-size: 10px !important;
}

.ap-common-fontSize11px {
  font-size: 11px !important;
}

.ap-common-fontSize12px {
  font-size: 12px !important;
}

.ap-common-fontSize13px {
  font-size: 13px !important;
}

.ap-common-fontSize14px {
  font-size: 14px !important;
}

.ap-common-fontSize15px {
  font-size: 15px !important;
}

.ap-common-fontSize16px {
  font-size: 16px !important;
}

.ap-common-fontSize17px {
  font-size: 17px !important;
}

.ap-common-fontSize18px {
  font-size: 18px !important;
}

.ap-common-fontSize19px {
  font-size: 19px !important;
}

.ap-common-fontSize20px {
  font-size: 20px !important;
}

.ap-common-fontSize14px .ui-suggestBox,
.ap-common-fontSize14px .ui-textBox,
.ap-common-fontSize14px .ui-datePicker,
.ap-common-fontSize14px .ui-timePicker {
  height: 24px !important;
}

.ap-common-fontSize15px .ui-suggestBox,
.ap-common-fontSize15px .ui-textBox,
.ap-common-fontSize15px .ui-datePicker,
.ap-common-fontSize15px .ui-timePicker {
  height: 24px !important;
}

.ap-common-fontSize16px .ui-suggestBox,
.ap-common-fontSize16px .ui-textBox,
.ap-common-fontSize16px .ui-datePicker,
.ap-common-fontSize16px .ui-timePicker {
  height: 24px !important;
}

.ap-common-fontSize17px .ui-suggestBox,
.ap-common-fontSize17px .ui-textBox,
.ap-common-fontSize17px .ui-datePicker,
.ap-common-fontSize17px .ui-timePicker {
  height: 26px !important;
}

.ap-common-fontSize18px .ui-suggestBox,
.ap-common-fontSize18px .ui-textBox,
.ap-common-fontSize18px .ui-datePicker,
.ap-common-fontSize18px .ui-timePicker {
  height: 26px !important;
}

.ap-common-fontSize19px .ui-suggestBox,
.ap-common-fontSize19px .ui-textBox,
.ap-common-fontSize19px .ui-datePicker,
.ap-common-fontSize19px .ui-timePicker {
  height: 28px !important;
}

.ap-common-fontSize20px .ui-suggestBox,
.ap-common-fontSize20px .ui-textBox,
.ap-common-fontSize20px .ui-datePicker,
.ap-common-fontSize20px .ui-timePicker {
  height: 28px !important;
}

.ap-common-fontSize14px .ui-select {
  height: 24px !important;
}

.ap-common-fontSize15px .ui-select {
  height: 24px !important;
}

.ap-common-fontSize16px .ui-select {
  height: 24px !important;
}

.ap-common-fontSize17px .ui-select {
  height: 26px !important;
}

.ap-common-fontSize18px .ui-select {
  height: 26px !important;
}

.ap-common-fontSize19px .ui-select {
  height: 28px !important;
}

.ap-common-fontSize20px .ui-select {
  height: 28px !important;
}

.ap-common-fontColor1,
.ap-common-fontGrey {
  color: #646464 !important;
}

.ap-common-fontColor2,
.ap-common-fontRed {
  color: #646464 !important;
}

.ap-common-fontColor3,
.ap-common-fontYellow {
  color: #990 !important;
}

.ap-common-fontColor4,
.ap-common-fontTerquoise {
  color: #099 !important;
}

.ap-common-fontColor5,
.ap-common-fontDarkBlue {
  color: #009 !important;
}

.ap-common-fontColor6,
.ap-common-fontPurple {
  color: #909 !important;
}

.ap-common-fontColor7,
.ap-common-fontDarkGrey {
  color: #404040 !important;
}

.ap-common-fontColor8,
.ap-common-fontBlack {
  color: #000 !important;
}

.ap-common-fontColor9,
.ap-common-fontDarkGreen {
  color: #090 !important;
}

.ap-common-fontBoldRed {
  font-weight: bold !important;
  color: #af154b;
}

.ap-common-borderRed {
  font-weight: bold !important;
  background-color: #fcd3d7;
  border: 1px solid #af154b;
}

.ap-common-backGroundRed {
  padding-right: 4px;
  background: #af154b;
}

.ap-common-fontWhite {
  color: #fefcff;
}

a {
  color: #af154b;
}

.spinner-container {
  margin: 161px auto 0;
}

.pre-loader .spinner-text {
  font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
  font-weight: 400;
  margin: 0;
  z-index: 2;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 4px;
  border: 4px solid #af144b;
  border-radius: 50%;
  animation: lds-ring 1.5s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #b91320 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and(max-width: 768px) {
  .pre-loader .side-nav {
    height: 70px;
  }
}

.ap-common-roundedBorder-orange {
  padding: 10px;
  border: 1px solid #870a3c;
  border-radius: 4px 4px 4px 4px;
  -moz-border-radius: 4px 4px 4px 4px;
  -webkit-border-bottom-left-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
}

.ap-common-roundedBorder-orange a {
  text-decoration: none;
}

.ap-common-roundedBorder-orange .redarrow {
  margin-bottom: 0;
}

.ap-button-solutionsforyou,
.ap-button-doneitRed,
.ap-button-doneitGray,
.ap-button-thinkingAboutRed,
.ap-button-thinkingAboutGray,
.ap-button-notYetRed,
.ap-button-notYetGray {
  border: solid 1px #c8c8c8;
  width: 32px;
  height: 32px;
  margin: 2px 2px;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  background-image: url("https://ib.absa.co.za/absa-online/static/style/resources/lifestage-icons-sprite-2019.png");
}

.ap-icon-tertiaryStudy,
.ap-icon-buyCar,
.ap-icon-getMarried,
.ap-icon-buyHouse,
.ap-icon-schoolChildren,
.ap-icon-growFamily,
.ap-icon-startBusiness,
.ap-icon-retire,
.ap-icon-finishingWell {
  border: solid 1px #c8c8c8;
  width: 82px;
  height: 80px;
  margin: 2px 2px;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  background-image: url("https://ib.absa.co.za/absa-online/static/style/resources/life_event_sprite-2019.png");
}

.ap-icon-finishingWell {
  background-position: -20px -0px;
}

.ap-icon-retire {
  background-position: -20px -80px;
}

.ap-icon-startBusiness {
  background-position: -20px -160px;
}

.ap-icon-growFamily {
  background-position: -20px -240px;
}

.ap-icon-schoolChildren {
  background-position: -20px -320px;
}

.ap-icon-buyHouse {
  background-position: -20px -400px;
}

.ap-icon-getMarried {
  background-position: -20px -480px;
}

.ap-icon-buyCar {
  background-position: -20px -560px;
}

.ap-icon-tertiaryStudy {
  background-position: -20px -640px;
}

.ap-icon-tertiaryStudy,
.ap-icon-buyCar,
.ap-icon-getMarried,
.ap-icon-buyHouse,
.ap-icon-schoolChildren,
.ap-icon-growFamily,
.ap-icon-startBusiness,
.ap-icon-retire,
.ap-icon-finishingWell {
  border: none !important;
}

.ap-button-solutionsforyou {
  background-position: -7px -345px;
  width: 142px;
}

.ap-button-doneitRed {
  background-position: -7px -381px;
}

.ap-button-doneitGray {
  background-position: -7px -422px;
}

.ap-button-thinkingAboutRed {
  background-position: -12px -462px;
}

.ap-button-thinkingAboutGray {
  background-position: -13px -503px;
}

.ap-button-notYetRed {
  background-position: -9px -545px;
}

.ap-button-notYetGray {
  background-position: -9px -586px;
}

.ap-button-solutionsforyou,
.ap-button-doneitRed,
.ap-button-doneitGray,
.ap-button-thinkingAboutRed,
.ap-button-thinkingAboutGray,
.ap-button-notYetRed,
.ap-button-notYetGray {
  border: none !important;
}

.ap-button-doneitRed,
.ap-button-doneitGray,
.ap-button-thinkingAboutRed,
.ap-button-thinkingAboutGray,
.ap-button-notYetRed,
.ap-button-notYetGray {
  width: 92px;
}

.ap-button-doneitRed,
.ap-button-doneitGray,
.ap-button-notYetRed,
.ap-button-notYetGray {
  height: 35px;
}

.ap-button-thinkingAboutRed,
.ap-button-thinkingAboutGray {
  height: 36px;
}

.ap-bar-getMarried,
.ap-bar-buyHouse,
.ap-bar-schoolChildren,
.ap-bar-growFamily,
.ap-bar-startBusiness,
.ap-bar-retire,
.ap-bar-finishingWell,
.ap-bar-buyCar,
.ap-bar-tertiaryStudy {
  width: 20px;
  height: 32px;
  margin: 2px 2px;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  background-image: url("https://ib.absa.co.za/absa-online/static/style/resources/lifestage-redhighlights-sprite.png");
}

.ap-bar-tertiaryStudy {
  background-position: -10px -104px;
  height: 34px;
}

.ap-bar-buyCar {
  background-position: -22px -61px;
  height: 55px;
  width: 30px;
}

.ap-bar-getMarried {
  background-position: -58px -46px;
  height: 21px;
  width: 18px;
}

.ap-bar-buyHouse {
  background-position: -74px -17px;
  height: 40px;
  width: 52px;
}

.ap-bar-schoolChildren {
  background-position: -127px -6px;
  height: 40px;
  width: 74px;
}

.ap-bar-growFamily {
  background-position: -200px -7px;
  height: 20px;
  width: 43px;
}

.ap-bar-startBusiness {
  background-position: -240px -12px;
  height: 20px;
  width: 29px;
}

.ap-bar-retire {
  background-position: -279px -28px;
  height: 83px;
  width: 100px;
}

.ap-bar-finishingWell {
  background-position: -325px -96px;
  height: 29px;
  width: 44px;
}

.ap-button-downloadToView {
  border: solid 1px #c8c8c8;
  width: 32px;
  height: 32px;
  margin: 2px 2px;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  background-image: url("https://ib.absa.co.za/absa-online/static/style/resources/abCap_brochure_button.png");
}

.ap-button-downloadToView {
  background-position: 0 -483px;
  width: 131px;
  height: 35px;
  border: none;
}

.ap-grouped-layout {
  display: none;
}

.ap-save-invest-container .ap-grouped-layout {
  display: block;
}

.ap-grouped-layout-portal {
  margin-top: 0;
}

.ap-save-invest-container .ap-grouped-layout-portal {
  margin-top: 13px;
}

.ap-grouped-layout-portal .ap-heading-save-invest {
  display: none;
}

.ap-save-invest-container .ap-grouped-layout-portal .ap-heading-save-invest {
  display: block;
}

.ap-grouped-layout-portal .ap-container-bottom-save-invest {
  display: none;
}

.ap-save-invest-container
  .ap-grouped-layout-portal
  .ap-container-bottom-save-invest {
  display: block;
}

.ap-grouped-layout-content {
  padding: 0;
  border: 0 solid #fff;
}

.ap-save-invest-container .ap-grouped-layout-content {
  padding: 13px 0 0 0;
}

.redarrow {
  list-style: none outside none;
  margin-left: 10px;
  padding-left: 10px;
  text-indent: -1em;
  margin-bottom: 1.25em;
}

.redarrow li {
  list-style-image: none;
  list-style-type: none;
  font-size: 11px;
}

.redarrow li:before {
  content: "\00BB \0020";
  color: #af144b;
}

.abc,
.abc li ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
}
.abc li {
  position: relative;
  width: 100%;
  padding: 2px 0 3px 0;
  border-top: 1px solid #ccc;
}
.abc li:first-child {
  margin: 0;
  padding: 2px 0 3px 0;
  border-top: 0;
}
.abc li a {
  background-image: url(https://ib.absa.co.za/absa-online/static/style/resources/sprite-icons-bar-status_2019.png);
  background-repeat: no-repeat;
  background-position: 10px -64px;
  padding: 4px 0 5px 32px;
  text-decoration: none;
  color: #747474;
  border: 1px solid #fff;
  text-align: left;
  display: block;
  width: auto;
}
.ie7 .abc li a {
  padding: 4px 0 3px 32px;
}
.abc li a:hover {
  color: #870a3c;
}
.pf {
  width: 34px;
  height: 27px;
  font-size: 10pt;
  text-align: center;
  vertical-align: middle;
  margin-right: 6px;
  padding-bottom: 2px;
}
.pf1 {
  border: 1px solid #c8c8c8;
  background: #f4f4f4;
  color: #ccc;
  readonly: true;
  border-radius: 4px 4px 4px 4px;
  -moz-border-radius: 4px 4px 4px 4px;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
}
.pf2 {
  color: #fff;
  border: 1px solid #af154b;
  background: #af154b;
  border-radius: 4px 4px 4px 4px;
  -moz-border-radius: 4px 4px 4px 4px;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
}
.ie7 .pf1,
.ie7 .pf2,
.ie8 .pf1,
.ie8 .pf2 {
  padding: 0;
  line-height: 25px;
}
.pfsmall {
  height: 20px;
  width: 25px;
  font-size: 10pt;
  text-align: center;
  vertical-align: middle;
  margin-right: 0;
}
.pfsmalltxt {
  font-size: 12px;
  padding: 0 7px 0 7px;
  margin-right: 6px;
  text-align: center;
  font-weight: bold;
}
.ie7 .pfsmall,
.ie7 .pfsmall,
.ie8 .pfsmall,
.ie8 .pfsmall {
  line-height: 20px;
}
.ie6 .ap-tab-active,
.ie7 .ap-tab-active {
  top: 2px;
}
.ap-tab-title a:hover {
  color: white;
}
.ap-info-box {
  border: 1px solid #c0c0c0;
  margin: 10px;
  padding: 10px;
  text-decoration: none;
}
.ap-info-box-ul li {
  list-style: disc outside none;
  margin-left: 7px;
}
.ap-info-box-ul2 li {
  list-style: disc inside none;
  margin-left: 7px;
}
#spansurephrase {
  font-weight: bold;
  font-size: 14pt;
  color: #640032;
}
.ap-container-highlevel .ap-heading-titlebar-login {
  background-color: #f4f4f4;
  border-top: 1px solid #cbc7c6;
  border-right: 1px solid #cbc7c6;
  border-bottom: 1px solid #cbc7c6;
  border-left: 1px solid #cbc7c6;
  border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 4px 4px;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
}
#ap-login-container .ap-container-content {
  margin: 0;
  padding: 0;
  border-right: 1px solid #cbc7c6;
  border-left: 1px solid #cbc7c6;
  background: #fff;
  width: 377px;
}
.passwordScreen #ap-login-container .ap-container-content {
  width: 604px;
}
.accessAccountScreen {
  width: 389px !important;
  padding: 0;
}
.accessAccountScreenAdds {
  width: 550px !important;
  padding: 0;
}
.passwordScreen {
  width: 604px !important;
  padding: 0;
}
.passwordScreenAdds {
  width: 328px !important;
  padding: 0;
}
#ap-login-container .ui-exception-container {
  padding-left: 10px;
}
#ap-login-container .ui-form {
  xoverflow: visible;
  width: 99%;
}
.ap-login-block-rounded .ap-login-block-rounded-top {
  height: 5px;
}
.ap-footer p,
.ap-footer-links a {
  font-size: 11px;
}
.ap-login-block-rounded .ap-corners-rounded-bottom,
.ap-login-block-rounded .ap-corners-rounded-top {
  width: 254px;
}
.passwordScreen .ap-login-block-rounded .ap-corners-rounded-bottom,
.passwordScreen .ap-login-block-rounded .ap-corners-rounded-top {
  width: 314px !important;
}
.ap-login-block-rounded
  .ap-login-block-rounded-top
  .ap-corners-rounded-top-left,
.ap-login-block-rounded .ap-login-block-rounded-top .ap-corners-rounded-top,
.ap-login-block-rounded
  .ap-login-block-rounded-top
  .ap-corners-rounded-top-right {
  height: 3px;
}
.ap-login-block-rounded .ui-tabBox {
  margin-bottom: -5px;
  margin-top: -3px;
  border-left: 1px solid #c3c3c3;
  border-right: 1px solid #c3c3c3;
}
.ap-login-block-rounded .ui-tabHeads {
  padding-top: 3px;
}
#ap-login-container .ap-container-bottom-corners {
  position: relative;
  top: -8px;
}
.touch .ap-login-keyBoardLabelCell {
  display: none;
}
.ap-container-privatebank {
  display: none;
}
.ap-login-columns {
  clear: both;
  padding-top: 3px;
}
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none !important;
}
.ap-login-campaign-image img {
  float: left;
  border-radius: 4px 4px 4px 4px;
  -moz-border-radius: 4px 4px 4px 4px;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
}
.img1 img,
.img2 img {
  width: 270px !important;
}
.img3 img {
  width: 328px !important;
}
.ap-container span {
  color: #646464;
  font-size: 11px;
}
.ap-container {
  font-size: 11px;
}
.ap-container a {
  font-size: 11px;
}

.abloader {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #fa551f;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  margin: 0px auto;
  padding: 5px;
  animation: abspin 2s linear infinite;
}

@keyframes abspin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
#absaOtp {
  text-align: center;
  margin-bottom: 10px;
}
